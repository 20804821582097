import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { Subscription, combineLatest, Observable } from "rxjs";
import { AccountService } from "app/_services/account.service";
import { SignupService } from "../sign-up/signup.service";
import { CountryService } from "app/_services/CountryService";
import { tap } from "rxjs/operators";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { SweetAlertService } from "ng2-sweetalert2";
import { AppState } from "@_services";
import { UserState } from "@_services/current/user";
import { QuestionnaireComponent } from "../questionnaire/questionnaire-form/questionnaire-form.component";
import { LanguageService } from "@_services/language.service";
@Component({
  selector: "app-questionarie-form",
  templateUrl: "./questionarie-form.component.html",
  styleUrls: ["./questionarie-form.component.scss"],
  providers: [CountryService],
})
export class QuestionarieFormComponent implements OnInit {
  @ViewChild("form") form: QuestionnaireComponent;
  completed = false;
  username: string;
  newList = [];
  isEULA = this.userstate.getCurrentUser().isEULA();
  subscriptions: Subscription[] = [];
  constructor(
    public userstate: UserState,
    public accountService: AccountService,
    private signupService: SignupService,
    public appState: AppState,
    private swalService: SweetAlertService,
    public dialogRef: MatDialogRef<QuestionarieFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public languageService: LanguageService
  ) {
    const subscription = this.signupService
      .receiveStep1Info()
      .subscribe((data) => {
        this.username = data.fullname;
      });
    this.subscriptions.push(subscription);
  }

  ngOnInit() {
    this.username = this.data.name;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  sendQuestionarie() {
    if (this.isEULA) {
      this.dialogRef.close();
      this.updateEULAObservable();
    }
    // const form = this.form.QuestionarieForm;
    // if (form.status === 'INVALID') return false
    // const id = this.data.id;
    // const count = this.data.count;
    // const questionarie = { "topic": "default-profile", "meta_data": form.value }
    // const formUpdateObservable = (count === 0) ? this.accountService.submitQuestionnaire(id, questionarie) : this.accountService.updateQuestionnaire(id, questionarie)
    // const successStrategy = () => {
    //   this.showSuccess()
    //   this.dialogRef.close()
    // }
    // const failStrategy = () => {
    //   this.showError();
    // }
    // const subscription = combineLatest(this.updateEULAObservable(), formUpdateObservable).subscribe(successStrategy, failStrategy);
    // this.subscriptions.push(subscription)
  }

  async skip() {
    this.updateEULAObservable().subscribe((res) => {
      window.sessionStorage.setItem("skipQuestion", "1");
      this.dialogRef.close();
    });
  }

  showSuccess() {
    this.swalService.swal({
      title: "Success",
      text: `The infomation has been sent successfully`,
      type: "success",
      buttonsStyling: false,
      showCancelButton: false,
      allowOutsideClick: true,
      confirmButtonClass: "btn btn-primary",
      confirmButtonText: "Confirm",
    });
  }

  showError() {
    this.swalService.swal({
      title: "Oops...",
      text: "Something went wrong, please try again",
      type: "warning",
      buttonsStyling: false,
      showCancelButton: false,
      confirmButtonClass: "btn btn-primary",
      confirmButtonText: "Confirm",
    });
  }

  updateEULAObservable() {
    return this.accountService.update({ is_agreement: this.isEULA }).pipe(
      tap(() => {
        this.userstate.getUser().is_agreement = this.isEULA;
      })
    );
  }
}
