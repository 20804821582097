<div class="mh-100">
  <div class="row justify-content-center h-100 align-items-center">
    <h1
      class="p-md-4"
      [translate]="'Coupon.Title'"
      [translateParams]="{ value: issue_type }"
    >
      Issue One Year {{ issue_type }} Subscription Coupon
    </h1>
    <div class="card col-12">
      <div class="card-block text-left">
        <div class="card-title text-center"></div>
        <!-- <p-messages [(value)]="msgs"></p-messages> -->
        <mvb-message [(value)]="msgs"></mvb-message>
        <div class="row d-flex justify-content-center">
          <div *ngIf="issue_type === 'Professional'" class="col-6">
            <form
              name="form"
              [formGroup]="userform"
              (ngSubmit)="f.form.valid && onSubmit(userform.value)"
              #f="ngForm"
              novalidate
            >
              <div *ngIf="false" class="form-group">
                <label for="email_sales" [translate]="'Coupon.U_Email'"
                  >Your Email:</label
                ><br />
                <span class="badge badge-info">{{ model.distributor }}</span>
              </div>
              <div class="row mb-2">
                <div class="col-10">
                  <label
                    for="email_customer"
                    [translate]="'Coupon.Issue_Coupon'"
                  >
                    Please enter a valid email to issue the coupon.</label
                  >
                </div>
              </div>
              <div
                class="form-group"
                *ngFor="let applicant of this.emails; let key = index"
              >
                <div class="row">
                  <div class="col-10">
                    <input
                      class="form-control input-applicant"
                      pInputText
                      type="email"
                      [(ngModel)]="applicant.email"
                      (keydown.enter)="applicantAdd($event)"
                      accessKey="0"
                      [formControlName]="applicant.name"
                      placeholder="{{ 'Coupon.Required' | translate }}"
                    />
                  </div>
                  <div class="col-2" *ngIf="key === 0">
                    <a
                      class="btn btn-outline-primary"
                      href="javascript:void(0)"
                      (click)="applicantAdd($event)"
                    >
                      <i class="fa fa-user-plus"></i
                    ></a>
                  </div>
                  <div class="col-2" *ngIf="key > 0">
                    <a
                      class="btn btn-outline-primary"
                      href="javascript:void(0)"
                      (click)="applicantRemove(applicant)"
                    >
                      <i class="fa fa-user-times"></i
                    ></a>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <button
                  [disabled]="loading || !userform.valid"
                  class="btn btn-primary"
                  [translate]="'Coupon.Submit'"
                >
                  Submit
                </button>
                <img
                  *ngIf="loading"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
              </div>
            </form>
          </div>
          <a
            *ngIf="issue_type === 'Personal'"
            routerLink="/customer/management/coupon-professional"
            [translate]="'Coupon.Issue_Professional_Coupon'"
            >Issue Professional Coupon</a
          >
          <div class="col-12">
            <p-dataTable
              [value]="this.IssueHistory"
              [rows]="10"
              [paginator]="true"
              [globalFilter]="gb"
              sortMode="single"
            >
              <p-header>
                <div
                  class="ui-widget-header d-flex justify-content-between align-items-center"
                  style="padding: 4px 10px; border-bottom: 0 none"
                >
                  <div>
                    <span [translate]="'Coupon.Issue_History_List'"
                      >Issue History List</span
                    >
                    <button class="mx-2" (click)="this.getIssueHistory()">
                      <i class="fas fa-sync-alt"></i>
                    </button>
                  </div>
                  <div>
                    <span [translate]="'Coupon.Search'">Search</span>
                    <i class="fa fa-search mx-1"></i>
                    <input
                      #gb
                      type="text"
                      pInputText
                      size="50"
                      placeholder="{{ 'Coupon.Find' | translate }}"
                    />
                  </div>
                </div>
              </p-header>
              <p-column
                field="applicant_email"
                header="{{ 'Coupon.Email' | translate }}"
              ></p-column>
              <p-column
                field="isCheck"
                header="{{ 'Coupon.Validated' | translate }}"
              >
                <ng-template let-item="rowData" pTemplate="body">
                  {{ item.distribute_time | date: "fullDate" }}
                  <button
                    *ngIf="!item.distribute_time"
                    type="button"
                    class="btn btn-outline-primary btn-sm small"
                    style="font-size: 0.75em"
                    (click)="sendAgain(item)"
                    [innerHTML]="'Coupon.Send_Again' | translate"
                  >
                    <i class="fa fa-envelope mx-2"></i>Send Again <br />
                    To Your ViewSonic Email
                  </button>
                </ng-template>
              </p-column>
              <p-column
                field="isRegister"
                header="{{ 'Coupon.Status' | translate }}"
              >
                <ng-template let-item="rowData" pTemplate="body">
                  <span
                    *ngIf="item.isRegister"
                    class="badge badge-success"
                    [translate]="'Coupon.Send_Success'"
                  >
                    <i class="fa fa-thumbs-up"></i> Success !
                  </span>
                </ng-template>
              </p-column>
              <p-column
                field="limit_time"
                header="{{ 'Coupon.Expire' | translate }}"
              >
                <ng-template let-item="rowData" pTemplate="body">
                  {{ item.limit_time | date: "fullDate" }}
                </ng-template>
              </p-column>
              <!-- <p-column styleClass="col-button" [style]="{'width':'90px'}">
                 <ng-template let-item="rowData" pTemplate="body">

                   <button *ngIf="!item.isRegister" type="button" class="btn btn-outline-primary btn-sm"
                           (click)="sendAgain(item)"
                           icon="fa-search">Send Again
                   </button>
                 </ng-template>
               </p-column>-->
            </p-dataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
