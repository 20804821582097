<header (window:resize)="onResize($event)">

  <nav
    class="navbar navbar-expand-lg fixed-top"
    [dir]="languageService.dir | async"
  >
    <div class="container-fluid banner">
     <span class="" [translate]="'Main_Menu.Banner_Content'">
              We've updated our website to a new design intended to improve your experience!
       <a class="banner-action"
          [translate]="'Main_Menu.Banner_Action'"
          (click)="switchToRevamp()">
         Try it now
       </a>
     </span>
    </div>
    <div style="margin-top: 32px" class="nav-container container-fluid">
      <a class="navbar-brand" [routerLink]="routeUrl"
        ><img
          src="images/logo_myviewboard.svg"
          class="logo"
          alt="logo text for myViewBoard"
        />
      </a>
      <button
        class="navbar-toggler custom-toggler ml-auto"
        type="button"
        data-toggle="collapse"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30"
            height="30"
            focusable="false"
          >
            <title>Menu</title>
            <path
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-miterlimit="10"
              d="M4 7h22M4 15h22M4 23h22"
            ></path>
          </svg>
        </span>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <div class="line1">
          <div class="ml-auto">
            <app-main-menu></app-main-menu>
          </div>
        </div>
        <div class="line2">
          <ul class="text-right" style="font-size: 1em; margin: auto 0">
            <li class="list-unstyled" [translate]="'Main_Menu.MVB_Name'">
              myViewBoard Name
              <span>: {{ currentUser.name }}</span>
            </li>
            <li class="list-unstyled" [translate]="'Main_Menu.Login_Email'">
              Login Email
              <span>: {{ currentUser.email }}</span>
            </li>
          </ul>
          <avatar-dropdown [currentUser]="currentUser"></avatar-dropdown>
        </div>
      </div>
    </div>
  </nav>
</header>
