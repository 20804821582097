import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MvbFaqComponent } from './mvb-faq/mvb-faq.component';
import { ArticlePageComponent } from './article-page/article-page.component';

const routes: Routes = [
  { path: 'mvbw-faq', component: MvbFaqComponent },
  { path: 'mvbweb-faq', component: MvbFaqComponent },
  { path: 'companion-faq', component: MvbFaqComponent },
  { path: 'myviewboard-faq', component: MvbFaqComponent },
  { path: 'mvbDM-faq', component: MvbFaqComponent },
  { path: 'mvbOC-faq', component: MvbFaqComponent },
  { path: 'mvbDisplay-faq', component: MvbFaqComponent },
  { path: 'onboarding-faq', component: MvbFaqComponent},
  { path: 'article/:id',  component: ArticlePageComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes) ],
  exports: [RouterModule]
})
export class MvbSupportFaqRoutingModule { }
