import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { UserGuideService } from "@_services/user_guide.service";

@Component({
  selector: "app-step-by-step",
  templateUrl: "./step-by-step.component.html",
  styleUrls: ["./step-by-step.component.scss"],
})
export class StepbyStepComponent implements OnInit {
  @Input() Config = [];
  @Input() StepNo: number;
  @Output() NextStep = new EventEmitter<number>();
  @Output() PrevStep = new EventEmitter<number>();
  @Output() close = new EventEmitter<void>();
  trackingKey = "";
  constructor(
    private $userguide: UserGuideService,
    private $translate: TranslateService
  ) {}
  ngOnInit() {
    this.trackingKey = `${this.StepNo}-${this.Config["userGuide"]["key"]}`;
    this.$userguide.trackStep(this.trackingKey);
  }
  onClose() {
    this.close.emit(this.$userguide.closeStep(true, this.trackingKey));
  }

  onNextStep() {
    this.$userguide.incrementStep();
  }
  onPrevStep() {
    this.$userguide.decrementStep();
  }
}
