<div class="d-flex justify-content-center" *ngIf="list.length>0">
<div class="w-100">
  <p-header>
    <button *ngIf="list.length>1" class="btn btn-secondary btn-sm" (click)="deleteAll()"
      [translate]="'Session.Delete'">Delete all other session
    </button>
  </p-header>
  <p-dataScroller [value]="list" [rows]="10" class="list-group">
    <ng-template let-sessionItem pTemplate="item">
      <div class="ui-g list-group-item">
        <span *ngIf="sessionItem.session_info"> {{sessionItem.session_info.os }} , </span><br/>
        <span *ngIf="sessionItem.session_info"> {{sessionItem.session_info.location.city }} , </span><br/>
        <span> {{sessionItem.create_time  | date:'fullDate'}} </span><br/>
        <button class="btn btn-secondary btn-sm" (click)="deleteFromSessionList(sessionItem.sid)"
          [translate]="'Session.Log_Out'"> Log out from this device
        </button>
      </div>
    </ng-template>
  </p-dataScroller>
</div>
</div>
