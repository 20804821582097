 <div class="body" id="step2">
    <div class="leftarea">
      <img src="images/logo_myviewboard.svg" width="210" />
      <div class="title" [dir]="languageService.dir | async">
        <h1 class="headline1 mb-10" [translate]="'SignUp.Welcome'">Welcome</h1>
        &nbsp;
        <h1 class="headline1">{{ data.name }}</h1>
      </div>
      <div class="inputs">
        <app-eula-confirm
          [checked]="isEULA"
          (onCheck)="isEULA = $event.checked"
        ></app-eula-confirm>
      </div>
      <button
        *ngIf="isEULA; else showMsg"
        mat-raised-button
        color="primary"
        style="text-align: center"
        (click)="onConfirm()"
      >
        {{ "Confirm" | translate }}
      </button>
      <ng-template #showMsg>
        <span class="small" color="primary">{{
          "Home.Confirm_EULA" | translate
        }}</span
        ><br />
      </ng-template>
    </div>
  </div>