

<div class="closewrap">
  <i class="fas fa-times fa-3x cross" (click)="close()"></i>
</div>
<h2 class="intro_header" >{{'Bandwidth.Intro.Header' | translate}}</h2>
<div class="wrap">
  <p>{{'Bandwidth.Intro.Header_P1' | translate}}</p>
  <p>{{'Bandwidth.Intro.Header_P2' | translate}}</p>
  <p>{{'Bandwidth.Intro.Header_P3' | translate}}</p>
  <h3>{{'Bandwidth.Intro.Sec_1' | translate}}</h3>
  <p> {{'Bandwidth.Intro.Sec_1_P1' | translate}}</p>
  <h3>{{'Bandwidth.Intro.Sec_2' | translate}}</h3>
  <p>{{'Bandwidth.Intro.Sec_2_P1' | translate}}</p>
  <h3>{{'Bandwidth.Intro.Sec_3' | translate}}</h3>
  <p>{{'Bandwidth.Intro.Sec_3_P1' | translate}}</p>
  <p>{{'Bandwidth.Intro.Sec_3_P2' | translate}}</p>
  <h3>{{'Bandwidth.Intro.Sec_4' | translate}}</h3>
  <p>{{'Bandwidth.Intro.Sec_4_P1' | translate}}</p>
  <h3>{{'Bandwidth.Intro.Sec_5' | translate}}</h3>
  <p> {{'Bandwidth.Intro.Sec_5_P1' | translate}}</p>
  <h3>{{'Bandwidth.Intro.Sec_6' | translate}}</h3>
  <p>{{'Bandwidth.Intro.Sec_6_P1' | translate}}</p>
</div>