import { Component, OnInit } from "@angular/core";
import { LanguageService } from "@_services/language.service";
import { AccountService } from "@_services/account.service";
import { Observable } from "rxjs";
import { UserState } from "@_services/current/user";
import { AppState } from "@_services";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  year;
  currentLang: string;
  isLogin$: boolean;
  dynamicPath: string;
  certifyUrl: string;
  footerLinkHost = "myviewboard.com";
  supportLink = "";
  icons = [
    {
      link: "https://www.viewsonic.com/",
      alt: "ViewSonic",
      img: "https://myviewboard.com/assets/images/viewsonic.png",
      classes: "img-fluid forIcon",
    },
    {
      link: "http://aws.amazon.com/what-is-cloud-computing",
      alt: 'Powered by AWS Cloud Computing"',
      img: "https://d0.awsstatic.com/logos/powered-by-aws.png",
      classes: "img-fluid forIcon",
    },
    {
      link: "https://www.boclips.com/",
      alt: "boclips",
      img: "https://myviewboard.com/images/powered-by-boclips.png",
      classes: "img-fluid",
    },
  ];

  icons2 = [
    {
      link:
        "https://cloud.withgoogle.com/partners/detail/?id=ACkb94b4UlaXbQEyFMX75XwL_Uu3I5llE2KFTmmEzq2s5McbODILpFHK_DUFr8KgAmt5AzQMme-z&hl=en",
      alt: "Google Education Partner",
      img: "https://myviewboard.com/images/GCPA_Badge_Master.svg",
      classes: "img-fluid icon78",
    },
    {
      link:
        "https://appsource.microsoft.com/en-us/product/web-apps/viewsonic.myviewboard?tab=Overview",
      alt: 'Microsoft Education Specialist Partner Badge"',
      img: "https://myviewboard.com/mkt-assets/images/microsoft-es.svg",
      classes: "img-fluid icon66",
    },
    {
      link:
        "https://salesvideos.intel.com/detail/videos/market-ready-solutions/video/6061759395001/ighf.html",
      alt: "intel-market-ready",
      img: "https://myviewboard.com/images/int-market-ready-hrz-rgb.svg",
      classes: "img-fluid",
    },
  ];

  list1 = [
    { link: "https://myviewboard.com/about/", text: "Footer.About_MVB" },
    {
      link: "https://myviewboard.com/why-myviewboard/how-it-works/",
      text: "How_it_works",
    },
    {
      link:
        "https://myviewboard.com/why-myviewboard/prepare-present-participate/",
      text: "3P-slogan",
    },
    {
      link: "https://myviewboard.com/why-myviewboard/security/",
      text: "Footer.Security",
    },
    { link: "https://myviewboard.com/pricing/", text: "Pricing" },
  ];

  list2 = [
    {
      link: "https://myviewboard.com/products/windows/",
      text: "myViewBoard Windows",
    },
    {
      link: "https://myviewboard.com/products/android/",
      text: "myViewBoard Android",
    },
    {
      link: "https://myviewboard.com/products/companion-app/",
      text: "myViewBoard Companion",
    },
    {
      link: "https://myviewboard.com/products/classroom/",
      text: "Home.Virtual_Class",
    },
    {
      link: "https://myviewboard.com/products/clips/",
      text: "myViewBoard Clips (K-12)",
    },
  ];

  list3 = [];

  communities = [
    {
      link: "https://www.facebook.com/myviewboard",
      classes: "fa-facebook fab fa-stack-1x fa-inverse ",
      translateLabel: "Footer.Facebook",
    },
    {
      link: "https://www.linkedin.com/company/myviewboard",
      classes: "fa-linkedin fab fa-stack-1x fa-inverse ",
      translateLabel: "Footer.LinkedIn",
    },
    {
      link: "https://twitter.com/myviewboard",
      classes: "fa-twitter fab fa-stack-1x fa-inverse ",
      translateLabel: "Footer.Twitter",
    },
    {
      link: "https://www.youtube.com/channel/UC3HH-XIt1KkQo18HDejEqJQ",
      classes: "fa-youtube fab fa-stack-1x fa-inverse ",
      translateLabel: "Footer.Youtube",
    },
  ];
  constructor(
    private userstate: UserState,
    private languageService: LanguageService,
    public accountService: AccountService,
    public appState: AppState
  ) {}

  ngOnInit() {
    this.year = new Date().getFullYear();
    this.languageService.loadCurrentLang().subscribe((res) => {
      this.currentLang = res;
    });
    this.isLogin$ = this.userstate.hasLogin();
    this.supportLink = this.isLogin$ ? "/main-support" : "/support";
    this.appState.checkUserLocation().subscribe((country_code) => {
      if (country_code.trim() === "TW") {
        this.certifyUrl =
          "https://www.viewsonic.com/education/tw/certification.html";
      } else {
        this.certifyUrl = "https://www.viewsonic.com/us/learn/";
      }
    });
  }
  showAccessbilityLink(item) {
    if (!item) {
      return;
    }
    if (item.text !== "Home.Accessibility") {
      return true;
    }
    return this.currentLang === "en" || this.currentLang === "es";
  }
}
