<form [formGroup]="MVB" (ngSubmit)="sendTicket()" #f="ngForm">
  <div class="radiowrap">
    <div class="title"  [translate]="'Support.Ticket_Heading'">What issue are you facing? </div>
    <div class="wrap-content" [dir]="languageService.dir | async">
        <b *ngIf="(currentLang | async) !== 'ja'" [translate]="'Support.Issue_Type'">Issue type for<b class="title-red">&nbsp;Device Management</b></b>
        <b *ngIf="(currentLang | async) === 'ja'" > <span class="title-red"> Device Management</span> <span [translate]="'Support.Issue_Type'">&nbsp; Issue type for</span></b>
      <div class="input-wrap"><span [translate]="'Support.DM_Questions.Agent_Version'">Device Management Agent Version:</span>
        <div>
          <input type="text" formControlName="Version">
        </div>
      </div>
      <div class="input-wrap"><span [translate]="'Support.DM_Questions.MVB_Model_Number'">ViewBoard Model Number:</span>
        <div class="select">
          <select formControlName="Model">
            <option *ngFor="let model of IFP_Model" value="{{ model }}">
              {{ model }}
            </option>
          </select>
          <mat-error class="msgwarning" *ngIf="MVB.controls['Model'].invalid && f.submitted"
            [translate]="'Support.select_error'" style="padding-top:3px;">Please
            select
            an option</mat-error>
        </div>
      </div>
      <div style="margin: 10px auto" [translate]="'Support.DM_Questions.MVB_Android_Build_Number'">ViewBoard Android Build Number (optional): <div class="version select-item">
        </div>
        <input formControlName="AndroidBuildNumber" type="text" class="optional" style="width: 100%">
      </div>
      <div class="title-area">
        <div class="title-header" [translate]="'Support.Title'">Title
        </div>
        <mat-error class="msgwarning" *ngIf="MVB.controls['title'].invalid && f.submitted"
          [translate]="'Support.Error_Title'">Please name a
          title for this ticket</mat-error>
        <input class="title-input" formControlName="title" placeholder="{{ ('Support.Title_placeholder') | translate }}"
          maxlength="60" [ngClass]="{'bidi_dir': (currentLang | async) === 'ar'}" required>
      </div>
      <app-fileupload #fileupload formGroupName="FileGroup"></app-fileupload>
    </div>
  </div>
  <div class="btn-wrap">
    <div class="btns">
      <button class="sendbtn" [class.spinner]="loading" [disabled]="loading"
        [ngClass]="{'btn-loading':loading === true}" [translate]="'Support.Send'" type="submit">Send</button>
      <button class="cancelbtn" [disabled]="loading" [ngClass]="{'btn-loading':loading === true}"
        [mat-dialog-close]="true" [translate]="'Support.Cancel'">Cancel</button>
    </div>
  </div>
</form>