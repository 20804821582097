import {
  Observable,
} from "rxjs";

import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AuthGuard } from './auth.guard';

@Injectable()
export class PublicPortalGuard implements CanActivate {
  constructor(
    public router: Router,
    private authGuard: AuthGuard
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.authGuard.canActivate(route, state);
  }
}
