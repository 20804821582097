import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../material/material.module'
import { SharedModule } from '@shared';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionnaireComponent } from './questionnaire-form/questionnaire-form.component';
@NgModule({
  declarations: [QuestionnaireComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule
  ],
  exports: [QuestionnaireComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QuestionnaireModule { }
