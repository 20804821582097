import { ClipsComponent } from "./clips/clips.component";
import { BrowserModule } from "@angular/platform-browser";
import { DeviceDetectorModule } from "ngx-device-detector";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppComponent } from "./app.component";
import { BsDropdownModule, CollapseModule } from "ngx-bootstrap";
import { TabsModule } from "ngx-bootstrap/tabs";
import { NAV_DROPDOWN_DIRECTIVES } from "./shared/nav-dropdown.directive";
import { SIDEBAR_TOGGLE_DIRECTIVES } from "./shared/sidebar.directive";
import { AsideToggleDirective } from "./shared/aside.directive";
import { BreadcrumbsComponent } from "./shared/breadcrumb.component";
import { AppState } from "./_services/app.service";
import { AccountService } from "./_services/account.service";
import { RssService } from "./_services/rss.service";
import { AuthGuard } from "./_guards/auth.guard";
import {
  ApplicationInsightsModule,
  AppInsightsService,
} from "@markpieszak/ng-application-insights";
import { AppRoutingModule } from "./app.routing";
import { ResponsiveModule } from "ng2-responsive";
import { BidiModule } from "@angular/cdk/bidi";
// Layouts
import { FullLayoutComponent } from "./layouts/full-layout.component";
import { SimpleLayoutComponent } from "./layouts/simple-layout.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { HeaderComponent } from "./header/header.component";
import { EqualValidatorModule } from "./_directives/equal-validator.module";
import { CustomFormsModule } from "ng2-validation";

import {
  ToggleButtonModule,
  DialogModule,
  ButtonModule,
  OverlayPanelModule,
  DataScrollerModule,
  DataGridModule,
  GrowlModule,
  PasswordModule,
  MessagesModule,
  PanelModule,
  DropdownModule,
  CheckboxModule,
  RadioButtonModule,
  InputTextareaModule,
  TooltipModule,
  ProgressBarModule,
  AutoCompleteModule,
  TabViewModule,
  DragDropModule,
  SharedModule,
  MultiSelectModule,
  MessageModule,
  SliderModule,
} from "primeng/primeng";
import { FlexLayoutModule } from "@angular/flex-layout";
import { RegisterCouponComponent } from "./pages/coupoen-register/register-coupon.component";
import { PriceTableModule } from "./price-table/price-table.module";
import { MainLayoutComponent } from "./layouts/main-layout/main-layout.component";
import { MainMenuComponent } from "./main-menu/main-menu.component";
import { EntityGuard } from "./_guards/entity.guard";
import { RemoteTransferModule } from "./remote-transfer/remote-transfer.module";
import { RemoteTransferDialogComponent } from "./remote-transfer/remote-transfer-dialog/remote-transfer-dialog.component";
import { ScriptService } from "./_services/script.service";
import { SessionListComponent } from "./session-list/session-list.component";
import { NotifyBoxComponent } from "./notify-box/notify-box.component";
import { MomentModule } from "ngx-moment";
import {
  TranslateModule,
  MissingTranslationHandler,
  TranslateLoader,
} from "@ngx-translate/core";
import { PasswordUpdateComponent } from "./account/password-update/password-update.component";
import { environment } from "../environments/environment";
import { TopbarComponent } from "./topbar/topbar.component";
import { MaterialModule } from "./material/material.module";
import { NavbarComponent } from "./navbar/navbar.component";
import { ZoomIntegrationComponent } from "./zoom-integration/zoom-integration.component";
import { HttpInterceptor } from "./_injection/HttpInterceptor";
import { RedirectGuard } from "./_guards/redirect.guard";
import { SharedModule as CustomSharedModule } from "./shared/sharedModule";
import { QuestionarieFormComponent } from "./pages/questionarie-form/questionarie-form.component";
import { MvbSupportTicketModule } from "./pages/mvb-support-ticket/mvb-support-ticket.module";
import { EULAComponent } from "./account/common/EULA-agreement";
import { DebounceModule } from "./_directives/debounce/module";
import { UserState } from "@_services/current/user";
import { EULAFormComponent } from "./pages/legal/eula/eula-confirm";
import { BrowserLinksComponent } from "./browser-links/browser-links.component";
import { BindingOptionalComponent } from "./binding-optional/binding-optional.component";
import { AuthButtonsModule } from "@shared/auth-buttons/auth-buttons.module";
import { DynamicComponentDirective } from "./_helpers/DynamicComponentDirective";
import { BandwidthCalculatorComponent } from "./pages/bandwidth-calculator/bandwidth-calculator.component";
import { IntroBandwidthComponent } from "./pages/bandwidth-calculator/intro-bandwidth/intro-bandwidth.component";
import { CouponModule } from "./coupon/coupon.module";
import { PollingQuizComponent } from "./entity/entity-public/polling-quiz/polling-quiz.component";
import "hammerjs";
import { CustomHandler } from "./_helpers/missing-translation-handler.component";
import { NotTranslatedService } from "@_services/not-translated-service.service";
import { FilterTranslationLoader } from "./_helpers/modify-translation-loader.component";

import { LogoutService } from "@_services/logout.service";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ClipsLayoutComponent } from "./layouts/clips-layout.component";
import { ClipsSignupModule } from "./pages/clips-signup/clips-signup.module";
import { PublicPortalGuard } from "./_guards/public-portal.guard";
import { LanguageSelectModule } from "./language-select/language-select.module";
import { FooterModule } from "./footer/footer.module";
import { CookieService } from "ngx-cookie-service";
import { EntityRedirectGuard } from "./_guards/entity-redirect.guard";
import { P302Component } from "./pages/302.component";
import { SupportFaqModule } from "./pages/support-faq/support-faq.module";
import { QuestionnaireModule } from "./pages/questionnaire/questionnaire.module";
import { DownloadpageComponent } from "./pages/downloadpage/downloadpage.component";
import { ExternalEnvGuard } from "./_guards/enternalEnv.guard";
import { PersonalSubscriptionComponent } from "./pages/personal-subscription/personal-subscription.component";
import { AvatarDropdownComponent } from "./header/avatar-dropdown/avatar-dropdown";
import { MVBCommonComponentModule } from "./mvb-common-component/mvb-common-component.module";
import { ReminderComponent } from "./entity/reminder/reminder.component";
import { AuthConfig, OAuthModule, OAuthStorage } from "angular-oauth2-oidc";
import { InternalComponent } from "./internal/internal.component";
import { CookieConsentComponent } from "./cookie-consent/cookie-consent.component";
import { RoleChooseComponent } from "./layouts/user-guide/role-choose/role-choose.component";
import { GreetingComponent } from "./layouts/user-guide/greeting/greeting.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { UserGuideService } from "@_services/user_guide.service";
import { Covert2MSPipe } from "./binding-optional/covert2-ms.pipe";

export function createTranslateLoader(http: HttpClient) {
  return new FilterTranslationLoader(http, "./i18n/", ".json"); // <--- change here
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./i18n/", ".json");
}
@NgModule({
  imports: [
    DebounceModule,
    ApplicationInsightsModule.forRoot({
      instrumentationKey: environment.appInsights.instrumentationKey,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: FilterTranslationLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: CustomHandler,
        deps: [NotTranslatedService],
      },
    }),
    EqualValidatorModule,
    PriceTableModule,
    CustomFormsModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ResponsiveModule,
    MaterialModule,
    FlexLayoutModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    CollapseModule,
    RemoteTransferModule,
    DeviceDetectorModule.forRoot(),
    TabsModule.forRoot(),
    OAuthModule.forRoot(),
    DataScrollerModule,
    TabViewModule,
    DragDropModule,
    DataGridModule,
    SharedModule,
    TooltipModule,
    InputTextareaModule,
    ButtonModule,
    GrowlModule,
    PasswordModule,
    MessagesModule,
    PanelModule,
    DropdownModule,
    DialogModule,
    CheckboxModule,
    ToggleButtonModule,
    RadioButtonModule,
    OverlayPanelModule,
    ProgressBarModule,
    AutoCompleteModule,
    MultiSelectModule,
    MessageModule,
    MomentModule,
    CustomSharedModule,
    MvbSupportTicketModule,
    AuthButtonsModule,
    DialogModule,
    BrowserAnimationsModule,
    CouponModule,
    SliderModule,
    ClipsSignupModule,
    LanguageSelectModule,
    FooterModule,
    BidiModule,
    SupportFaqModule,
    QuestionnaireModule,
    MVBCommonComponentModule,
    OverlayModule,
  ],
  declarations: [
    AppComponent,
    MainMenuComponent,
    RemoteTransferDialogComponent,
    FullLayoutComponent,
    SimpleLayoutComponent,
    NAV_DROPDOWN_DIRECTIVES,
    BreadcrumbsComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    AsideToggleDirective,
    SidebarComponent,
    HeaderComponent,
    SessionListComponent,
    RegisterCouponComponent,
    MainLayoutComponent,
    NotifyBoxComponent,
    PasswordUpdateComponent,
    TopbarComponent,
    QuestionarieFormComponent,
    NavbarComponent,
    ZoomIntegrationComponent,
    EULAComponent,
    EULAFormComponent,
    BrowserLinksComponent,
    BindingOptionalComponent,
    DynamicComponentDirective,
    BandwidthCalculatorComponent,
    IntroBandwidthComponent,
    PollingQuizComponent,
    ClipsLayoutComponent,
    P302Component,
    DownloadpageComponent,
    PersonalSubscriptionComponent,
    AvatarDropdownComponent,
    ReminderComponent,
    InternalComponent,
    CookieConsentComponent,
    RoleChooseComponent,
    GreetingComponent,
    ClipsComponent,
  ],
  providers: [
    UserState,
    AppState,
    LogoutService,
    AccountService,
    AuthGuard,
    RedirectGuard,
    ExternalEnvGuard,
    RssService,
    EntityGuard,
    EntityRedirectGuard,
    PublicPortalGuard,
    ScriptService,
    AppInsightsService,
    UserGuideService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptor,
      multi: true,
    },
    {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpClient],
    },
    { provide: AuthConfig, useValue: environment.oidc_config },
    { provide: OAuthStorage, useValue: localStorage },
    CookieService,
  ],
  entryComponents: [
    QuestionarieFormComponent,
    EULAFormComponent,
    BindingOptionalComponent,
    IntroBandwidthComponent,
    DownloadpageComponent,
    ReminderComponent,
    RoleChooseComponent,
    GreetingComponent,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
