<div class="body" id="step2">
  <div class="leftarea">
    <img src="images/logo_myviewboard.svg" />
    <div class="title" [dir]="languageService.dir | async">
      <h1 class="headline1 mb-10" [translate]="'SignUp.Welcome'">Welcome</h1>
      &nbsp;
      <h1 class="headline1 mb-10">{{ username }}</h1>
      <h1 class="headline1 mb-10">!</h1>
      <!-- <h4 [translate]="'SignUp.Step2_Headline'" class="mb-10"></h4> -->
    </div>
    <!-- <app-questionnaireform #form></app-questionnaireform> -->
    <footer class="btngroup">
      <app-eula-confirm
        [checked]="isEULA"
        (onCheck)="isEULA = $event.checked"
      ></app-eula-confirm>
      <button
        *ngIf="isEULA"
        mat-raised-button
        color="primary"
        (click)="sendQuestionarie()"
        style="text-align: center"
      >
        {{ "SignUp.Finish_Up" | translate }}
      </button>
    </footer>
    <!-- <button
      *ngIf="isEULA; else showMsg"
      class="previous"
      (click)="skip()"
      [translate]="'SignUp.Skip'"
    >
      < skip
    </button> -->
    <ng-template #showMsg>
      <span class="small" color="primary">{{
        "Home.Confirm_EULA" | translate
      }}</span
      ><br />
      <!-- <span class="small" color="primary">{{
        "Home.Confirm_EULA_Update" | translate
      }}</span> -->
    </ng-template>
  </div>
</div>
