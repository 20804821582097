import { Component, OnInit, ViewChild } from "@angular/core";
import { AccountService } from "app/_services/account.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material";
import { SubmitSuccessComponent } from "../submit-success/submit-success.component";
import { SweetAlertService } from "ng2-sweetalert2";
import { AppState } from "@_services";
import { LanguageService } from "@_services/language.service";
import { FileuploadComponent } from "../fileupload/fileupload.component";
import { Observable } from "rxjs";

@Component({
  selector: "app-display",
  templateUrl: "./display.component.html",
  styleUrls: ["./display.component.scss"],
})
export class DisplayComponent implements OnInit {
  @ViewChild("fileupload") file: FileuploadComponent;
  openUpload = false;
  loading = false;
  currentLang: Observable<string>;
  MVB: FormGroup;
  select_os: string;
  display_windows: boolean;
  WindowsVersions: any;
  AndroidVersions: any;
  Win_default: string;
  And_default: string;
  Versions = [];
  default: string;
  IFP_Model = [
    "IFP5550",
    "IFP6530",
    "IFP6550",
    "IFP7530",
    "IFP7550",
    "IFP8650",
    "IFP5550-2",
    "IFP6550-2",
    "IFP7550-2",
    "IFP8650-2",
    "IFP5550-3",
    "IFP6550-3",
    "IFP7550-3",
    "IFP8650-3",
    "N/A",
  ];

  constructor(
    public accountService: AccountService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DisplayComponent>,
    public dialog: MatDialog,
    private swalService: SweetAlertService,
    private appState: AppState,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.MVB = this.fb.group({
      Model: new FormControl("IFP5550"),
      OtherIfpNumber: new FormControl(""),
      Version: new FormControl("", [Validators.required]),
      title: new FormControl("", [Validators.required]),
      FileGroup: this.fb.group({
        Description: new FormControl("", [Validators.required]),
        FlagCtrl: new FormControl(false),
      }),
    });
    this.currentLang = this.languageService.loadCurrentLang();
    this.accountService.getVersion("Display for Windows").subscribe((i) => {
      this.WindowsVersions = i[0]["versions"];
      this.Win_default = this.WindowsVersions[0];
    });
    this.accountService.getVersion("Display for Android").subscribe((i) => {
      this.AndroidVersions = i[0]["versions"];
      this.And_default = this.AndroidVersions[0];
    });
  }

  selectOS(event: any) {
    this.select_os = event.target.value;
    switch (this.select_os) {
      case "Android":
        this.MVB.addControl("AndroidBuildNumber", new FormControl(""));
        this.Versions = this.AndroidVersions;
        this.default = this.And_default;
        break;
      case "Windows":
        this.display_windows = true;
        this.Versions = this.WindowsVersions;
        this.default = this.Win_default;
        break;
      default:
        break;
    }
  }

  errorPop() {
    this.swalService.swal({
      title: "Error",
      text: "Something went wrong, please try again",
      type: "error",
      buttonsStyling: false,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonClass: "btn btn-primary  mr-4",
      confirmButtonText: "Confirm",
    });
  }

  sendTicket() {
    this.file.isClicked = true;
    if (!this.MVB.valid) return false;
    let Android = "";
    const text = this.file.FileGroup.controls["Description"].value;
    const Title = this.MVB.controls["title"].value;
    const version = this.MVB.controls["Version"].value;
    const model = this.MVB.controls["Model"].value;
    const IfpNumber = this.MVB.controls["OtherIfpNumber"].value;
    const Model = `ViewBoard Model : ${model ? model : "N/A"}`;
    const AndroidNumber = this.MVB.controls["AndroidBuildNumber"];
    const Version = `myViewBoard Display ${this.select_os} Version: ${version}`;
    const IFP = `Other IFP Number: ${IfpNumber ? IfpNumber : "N/A"}`;
    if (AndroidNumber && AndroidNumber.value) {
      Android = `ViewBoard Android Build Number: ${
        AndroidNumber ? AndroidNumber.value : "N/A"
      }`;
    }
    this.loading = true;
    const ticket = {
      product: "mvb-0009",
      category: "myViewboard Display",
      title: Title,
      description:
        Model +
        `\n` +
        Android +
        "\n" +
        Version +
        "\n" +
        IFP +
        "\n" +
        "Description: " +
        text,
      screenshots: this.file.keylist,
    };
    // console.log('ticket', ticket)
    this.accountService.d365SendCase(ticket).subscribe(
      () => {
        if (this.appState._state["ticket-cases"])
          delete this.appState._state["ticket-cases"];
        this.loading = false;
        this.dialogRef.close();
        this.dialog.open(SubmitSuccessComponent, {
          width: "400px",
          minWidth: "310px",
          height: "auto",
        });
      },
      () => {
        this.loading = false;
        this.errorPop();
      }
    );
  }
}
