<ng-template #elseTmpl>
  <app-header></app-header>
</ng-template>

<header id="header" class="affix">
  <nav #nav class="navbar navbar-expand-xl">
    <div class="nav-container d-flex container">
      <div class="navbar-brand">
        <a href="/">
          <img
            src="assets/logo_myviewboard.svg"
            class="logo"
            alt="logo text for myViewBoard"
          />
        </a>
      </div>
      <button
        class="navbar-toggler custom-toggler"
        type="button"
        data-toggle="collapse"
        [ngStyle]="{
          'margin-left': (currentLang | async) === 'ar' ? 'unset' : 'auto'
        }"
        data-target="#navbarCollapse"
        aria-controls="navbarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30 30"
            width="30"
            height="30"
            focusable="false"
          >
            <title>Menu</title>
            <path
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-miterlimit="10"
              d="M4 7h22M4 15h22M4 23h22"
            ></path>
          </svg>
        </span>
      </button>
      <!-- language  -->
      <li class="lang">
        <app-language-select></app-language-select>
      </li>
      <!-- dropdown -->
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav ul1">
          <li class="nav-item nav-mobile dropdown">
            <a
              class="nav-link dropdown-toggle"
              role="button"
              id="innerDrop1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              [translate]="'Tools'"
              [attr.aria-label]="'tools'"
            >
              Tools
            </a>
            <div
              [ngClass]="
                (currentLang | async) == 'ar' ? null : 'second-dropdown'
              "
              class="dropdown-menu"
              aria-labelledby="innerDrop1"
              attr.aria-label="navbarDropdown"
              [ngStyle]="{
                'text-align': (currentLang | async) === 'ar' ? 'right' : 'left'
              }"
            >
              <a
                class="dropdown-item indent"
                href="https://myviewboard.com/products/whiteboard/"
                [translate]="'WhiteBoard'"
                attr.aria-label="{{ 'WhiteBoard' | translate }}"
              >
                WhiteBoard
              </a>
              <a
                class="dropdown-item indent"
                href="https://myviewboard.com/products/classroom/"
                [translate]="'p_Classroom'"
                attr.aria-label="{{ 'p_Classroom' | translate }}"
              >
                Classroom
              </a>
              <a
                class="dropdown-item indent"
                href="https://myviewboard.com/products/manager/"
                [translate]="'p_manager'"
                attr.aria-label="{{ 'manager' | translate }}"
              >
                Manager
              </a>
              <a
                class="dropdown-item indent"
                href="https://myviewboard.com/products/companion/"
                [translate]="'myViewBoard Companion'"
                attr.aria-label="{{ 'myViewBoard Companion' | translate }}"
              >
                Companion
              </a>
              <a
                class="dropdown-item indent"
                href="https://myviewboard.com/products/originals/"
                [translate]="'p_Originals'"
                attr.aria-label="{{ 'p_Originals' | translate }}"
              >
                Originals
              </a>
              <a
                class="dropdown-item indent"
                href="https://myviewboard.com/products/sens/"
                [translate]="'p_Sens'"
                attr.aria-label="{{ 'p_Sens' | translate }}"
              >
                Sens
              </a>
              <a
                class="dropdown-item indent"
                href="https://myviewboard.com/solutions/insights/"
                [translate]="'insights'"
                attr.aria-label="{{ 'insights' | translate }}"
              >
                Insights
              </a>
            </div>
          </li>

          <li class="nav-item nav-mobile">
            <a
              class="nav-link"
              href="https://myviewboard.com/pricing/"
              [translate]="'Pricing'"
              attr.aria-label="{{ 'Pricing' | translate }}"
            >
              Pricing
            </a>
          </li>

          <li class="nav-item nav-mobile dropdown">
            <a
              class="nav-link dropdown-toggle"
              id="innerDrop3"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              [translate]="'Resources'"
              [attr.aria-label]="'Resources'"
            >
              Resources
            </a>
            <div
              class="dropdown-menu"
              aria-labelledby="innerDrop3"
              attr.aria-label="navbarDropdown"
              [ngStyle]="{
                'text-align': (currentLang | async) === 'ar' ? 'right' : 'left'
              }"
            >
              <a
                class="dropdown-item"
                href="https://myviewboard.com/solutions/entity/"
                target="_blank"
                [translate]="'Resources_Entity'"
                attr.aria-label="{{ 'Resources_Entity' | translate }}"
              >
                Entity
              </a>
              <a
                class="dropdown-item"
                href="https://myviewboard.com/solutions/entity/"
                target="_blank"
                [translate]="'Open Learning Format'"
                attr.aria-label="{{ 'Open Learning Format' | translate }}"
              >
                Open Learning Format
              </a>
              <a
                class="dropdown-item"
                href="https://myviewboard.com/blog/"
                target="_blank"
                [translate]="'Blog'"
              >
                Blog
              </a>
              <a
                class="dropdown-item"
                href="https://myviewboard.com/kb"
                [translate]="'Knowledge Base'"
                attr.aria-label="{{ 'Knowledge Base' | translate }}"
              >
                Knowledge Base
              </a>
              <a
                class="dropdown-item"
                href="https://www.viewsonic.com/education/msp"
                [translate]="'Certified MSP Program'"
                attr.aria-label="{{ 'Certified MSP Program' | translate }}"
              >
                Certified MSP Program
              </a>
              <a
                class="dropdown-item"
                href="https://www.viewsonic.com/us/resources/myviewboard"
                target="_blank"
                [translate]="'Training & Development'"
                attr.aria-label="{{ 'Training & Development' | translate }}"
              >
                Training & Development
              </a>
              <a
                class="dropdown-item indent"
                href="https://www.viewsonic.com/us/products/shop/viewboard.html"
                [translate]="'recommend_hardware'"
                attr.aria-label="{{ 'recommend_hardware' | translate }}"
              >
                Recommended Hardware
              </a>
            </div>
          </li>
          <li class="nav-item nav-mobile">
            <a
              class="nav-link"
              href="https://myviewboard.com/download/"
              [translate]="'Footer.Download'"
              attr.aria-label="{{ 'Footer.Download' | translate }}"
            >
              Footer.Download
            </a>
          </li>
        </ul>
        <ul class="navbar-nav ul2">
          <!-- <li class="nav-item nav-mobile">
            <a
              class="nav-link"
              (click)="goSignIn()"
              [translate]="'Log In'"
              attr.aria-label="{{ 'Log In' | translate }}"
            >
              Sign In
            </a>
          </li> -->
          <!-- <li class="nav-item nav-mobile">
            <a class="nav-link" (click)="oidcSignInByOriginal()">
              OIDC Sign In
              <span class="badge badge-warning">beta</span>
            </a>
          </li> -->
          <li class="nav-item nav-mobile">
            <a
              class="nav-link"
              (click)="oidcSignIn()"
              [translate]="'Log In'"
              attr.aria-label="{{ 'Log In' | translate }}"
            >
              Sign In
              <!-- <span class="badge badge-warning">beta</span> -->
            </a>
          </li>
          <li class="nav-mobile signupBtn">
            <a
              (click)="goSignUp()"
              class="btn btn-outline-login"
              [translate]="'Try Now'"
              attr.aria-label="{{ 'Try Now' | translate }}"
            >
              Try Now
            </a>
          </li>
          <li class="nav-item nav-mobile">
            <a
              class="nav-link btn-vs"
              href="https://myviewboard.com/contact/"
              [translate]="'Get in touch'"
              attr.aria-label="{{ 'Get in touch' | translate }}"
            >
              Get in touch
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
