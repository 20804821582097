<div [formGroup]="FileGroup">
  <div class="description" >
    <div class="description-header msgwarning" [translate]="'Support.Description'">Description <mat-error class="msgwarning"
      *ngIf="FileGroup.controls['Description'].invalid && isClicked" [translate]="'Support.Error_Description'">
      Please let us know more about the issue</mat-error>
    </div>
     <span *ngIf="display_windows" class="mat-error msgwarning inlineb">Windows Infomation (Settings -> System -> About) screenshot with Device & Windows specifications</span>
    <textarea class="textarea" formControlName="Description" [ngClass]="{'bidi_dir': (currentLang | async) === 'ar'}"
      placeholder="{{ ('Support.DescribeDetail') | translate }}" maxlength="1500" required
      (keyup)="search(text.value)" #text>
    </textarea>
  </div>
  <div class="screenshot">
    <div *ngIf="this.matchKeyword && this.openUpload" [translate]="'Support.mention_photo'" style="color: red;">
      It seems you have mentioned something about photos
    </div>
    <mat-checkbox [(ngModel)]="this.openUpload" formControlName="FlagCtrl" [translate]="'Support.Add_Attachment'"
      (click)="clickAddAttachment()">{{'Support.Add_Attachment' | translate}}
    </mat-checkbox>
    <div *ngIf="this.openUpload" class="animation">
      <span style="color: red" [translate]="'Support.Upload_Rule'">Max file size 2MB in PNG/JPG/JPEG format</span>
      <div id="uploadfile" class="dropzone" ngfDrop selectable="1" [(files)]="files" [maxSize]="2097152"
        accept=".png, .jpg, .jpeg" (fileChange)="uploadpic($event)" [translate]="'Support.Drop_Files_Here'">
        Drop files here
      </div>
      <table class="table">
        <thead>
          <tr>
            <th [translate]="'Support.Name'">Name</th>
            <th class="center" [translate]="'Support.Size'">Size</th>
            <th class="center" [translate]="'Support.Delete'">Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of files; let i = index">
            <td class="paddingChange">
              <div *ngIf="
                ['image/gif', 'image/png', 'image/jpeg'].indexOf(
                item.type
                ) >= 0
              ">
                <div class="previewIcon" [ngfBackground]="item"></div>
              </div>
              <strong>{{ item.name }}</strong>
            </td>
            <td nowrap class="paddingChange center">
              {{ item.size / 1024 / 1024 | number: ".1" }} MB
            </td>
            <td nowrap class="paddingChange center">
              <button type="button" class="btn btn-danger btn-xs" (click)="deleteItem(i)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
