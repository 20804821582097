import {
  AfterViewChecked,
  Component,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { AppState } from "../_services/app.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { LanguageService } from "@_services/language.service";

@Component({
  selector: "app-landing",
  templateUrl: "./simple-layout.component.html",
})
export class SimpleLayoutComponent implements AfterViewChecked {
  @ViewChild("content") content: ElementRef;
  public disabled = false;
  public status: { isopen: boolean } = { isopen: false };
  isMobile: boolean;
  resized = false;
  public toggled(open: boolean): void {}

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  constructor(
    public appstate: AppState,
    public device: DeviceDetectorService,
    public languageService: LanguageService
  ) {
    if (this.device.isMobile() === true) {
      this.isMobile = true;
    }
  }
  ngAfterViewChecked(): void {
    const content = this.content.nativeElement;
    content.style["margin-top"] =
      document.querySelector(".navbar").clientHeight +
      ((document.querySelector(".mvbnc") &&
        document.querySelector(".mvbnc").clientHeight) ||
        0) +
      "px";
  }
}
