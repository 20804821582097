import { Component, Input, OnInit } from "@angular/core";
import { LanguageService } from "@_services/language.service";
import { forkJoin, Observable } from "rxjs";
import { SweetAlertService } from "ng2-sweetalert2";
import { AccountService } from "@_services/account.service";
import { Router } from "@angular/router";
import { AppState } from "@_services";
import { UserState } from "@_services/current/user";
import { OAuthService } from "angular-oauth2-oidc";
import { MatDialog } from "@angular/material/dialog";
import { UserGuideService } from "@_services/user_guide.service";
import { AppInsightsService } from "@markpieszak/ng-application-insights";

@Component({
  selector: "avatar-dropdown",
  templateUrl: "./avatar-dropdown.component.html",
  styleUrls: ["./avatar-dropdown.component.scss"],
  providers: [SweetAlertService],
})
export class AvatarDropdownComponent implements OnInit {
  @Input() currentUser: any;
  currentLang: Observable<string>;
  isLogin: boolean;
  AvatarList: dropDownItem[] = [
    {
      path: "/home",
      translateLabel: "Main_Menu.Home",
      class: "fa fa-home",
      isBasic: true,
      showInProd: true,
      action: "home",
    },
    {
      path: "/coupon/register",
      translateLabel: "Main_Menu.Enter_Coupon",
      class: "fa fa-ticket-alt",
      isBasic: this.isBasic(),
      showInProd: true,
    },
    {
      path: "/viewboard/setting",
      translateLabel: "Main_Menu.MVB_FollowMe_AI",
      class: "fa fa-universal-access",
      isBasic: true,
      showInProd: true,
      action: "followme",
    },
    {
      path: "/account/edit",
      translateLabel: "Main_Menu.Edit_Account",
      class: "fa fa-user",
      isBasic: true,
      showInProd: true,
      action: "edit_account",
    },
    {
      path: "/account/password",
      translateLabel: "Main_Menu.Change_Password",
      class: "fa fa-lock",
      isBasic: true,
      showInProd: true,
      action: "change_password",
    },
    {
      path: "/my-subscription",
      translateLabel: "Main_Menu.Subscription",
      class: "fa fa-crown",
      isBasic: true,
      showInProd: true,
      action: "my_subscriptions",
    },
    {
      translateLabel: "Main_Menu.User_Guide",
      class: "fa fa-info-circle",
      isBasic: true,
      showInProd: true,
      guide: true,
    },
    {
      translateLabel: "Main_Menu.Sign_Out",
      class: "fas fa-sign-out-alt",
      isBasic: true,
      showInProd: true,
      action: "sign-out",
    },
  ];
  constructor(
    public languageService: LanguageService,
    public swalService: SweetAlertService,
    private accountService: AccountService,
    private router: Router,
    public appstate: AppState,
    private userstate: UserState,
    public oauthService: OAuthService,
    public dialog: MatDialog,
    private $userguide: UserGuideService,
    public insight: AppInsightsService
  ) {}
  ngOnInit() {
    this.currentLang = this.languageService.loadCurrentLang();
    this.isLogin = this.userstate.hasLogin();
  }
  isBasic() {
    return this.userstate.getCurrentUser().getCurrentPermission().isBasicTier();
  }
  hideInProd() {
    const result = this.appstate.isProd() ? false : true;
    return result;
  }

  UserGuide() {
    this.$userguide.startUserGuide("click_user_guide");
  }

  onHideUserGuide() {
    return this.router.url === "/home";
  }

  logout({ action }) {
    forkJoin(
      this.languageService.get("Log_Out.Sign_Out_Text"),
      this.languageService.get("Log_Out.Are_You_Sure"),
      this.languageService.get("Confirm"),
      this.languageService.get("Cancel")
    ).subscribe(async (data) => {
      const [txt, title, confirm, cancel] = data;
      try {
        await this.swalService.swal({
          title: title,
          text: txt,
          type: "warning",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonClass: "btn btn-primary mr-4",
          cancelButtonClass: "btn btn-info  mr-4",
          confirmButtonText: confirm,
          cancelButtonText: cancel,
        });
        if (this.userstate.isOIDCFlow()) {
          this.trackClickItem({ action });
          await this.oauthService.logOut({
            post_logout_redirect_uri: location.origin,
          });
          this.accountService.removeUser();
          return;
        }
        await this.accountService.logout().toPromise();
        this.router.navigate(["/"]);
      } catch (err) {
        console.log(`cancel logout`);
      }
    });
  }

  trackClickItem({ action }) {
    this.insight.trackEvent("com_profile_setting", {
      category: "profile_setting",
      action: action,
    });
  }
}

export interface dropDownItem {
  class: string;
  isBasic: boolean;
  path?: string;
  translateLabel: string;
  showInProd: boolean;
  guide?: boolean;
  action?: string;
}
