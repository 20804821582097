<div *ngIf="!guest_user">
  <div *ngIf="!_host_user">
    <ng-container *ngIf="!isLogin$">
      <mat-label [translate]="'Throw.Your_Name'">Your name</mat-label>
      <mat-form-field appearance="outline">
        <input type="text" matInput [(ngModel)]="sender_input" />
      </mat-form-field>
    </ng-container>
    <ng-container>
      <mat-label [translate]="'Throw.ViewBoard_Name'">
        myViewBoard Name
      </mat-label>
      <mat-form-field appearance="outline">
        <input
          type="text"
          matInput
          inputDebounce
          (debounceFunc)="checkSecurePassword($event)"
          [(ngModel)]="target_user"
        />
      </mat-form-field>
    </ng-container>
    <ng-container *ngIf="requireSecureCode">
      <mat-label>{{ "Remote.Session_Password" | translate }}</mat-label>
      <mat-form-field appearance="outline">
        <input
          type="text"
          maxlength="4"
          matInput
          [(ngModel)]="secureCode"
          inputDebounce
          (debounceFunc)="checkSecurePassword()"
        />
        <mat-placeholder
          [translate]="'Remote.Four_Digit_Number'"
        ></mat-placeholder>
      </mat-form-field>
    </ng-container>

    <div class="text-warning">{{ user_alert }}</div>
    <button
      class="btn btn-outline-primary"
      *ngIf="fileList && upload_enable && sender"
      (click)="onUpload({ files: fileList })"
      [translate]="'Throw.Throw_Now'"
    >
      Throw Now
    </button>
  </div>
</div>

<p-fileUpload
  #userUpload
  name="file"
  *ngIf="upload_enable && sender && !fileList"
  (click)="p_upload_click($event)"
  url=""
  withCredentials="true"
  auto="true"
  [chooseLabel]="'Account.Upload' | translate"
  customUpload="true"
  multiple="multiple"
  (onSelect)="onFileSelect($event)"
  [disabled]="!upload_enable"
  (uploadHandler)="onUpload($event)"
  [maxFileSize]="this.current_user ? 1024 * 1024 * 31 : 1024 * 1024 * 31"
>
  <ng-template pTemplate="toolbar"> </ng-template>
  <ng-template let-file pTemplate="file"></ng-template>
  <ng-template pTemplate="content">
    <p-progressBar
      *ngIf="uploadProgress > 0"
      [value]="uploadProgress"
    ></p-progressBar>
    <div class="text-center">
      <p><i class="fa fa-3x icon-header_throw_icon"></i></p>
      <p [translate]="'Throw.Drag_File'">you can drag file to here</p>
    </div>
  </ng-template>
</p-fileUpload>

<div *ngIf="this.current_user">
  <app-remote-transfer-history
    #transferHistory
    (onChange)="clearMessage()"
    (onMessage)="onHistoryMsg($event)"
    [host]="host_unique"
    [target_unique]="target_unique"
  ></app-remote-transfer-history>
</div>
<mvb-message [(value)]="msgs"></mvb-message>
<!-- <p-messages [(value)]="msgs"></p-messages> -->
