<ng-container>
  <div class="overlaybackground"></div>
  <div class="steps arrow">
    <div class="layout">
      <div class="Title">
        <span class="Title__text">
          {{ Config["userGuide"]["title"] | translate }}
        </span>
        <div class="CloseBtn" (click)="onClose()">
          <img src="images/close.svg" />
        </div>
      </div>
      <div class="content">
        <span class="content__text">
          {{ Config["userGuide"]["description"] | translate }}
        </span>
      </div>
      <div class="buttons">
        <span
          class="buttons__previous"
          (click)="onPrevStep()"
          *ngIf="Config['userGuide']['previousStep']"
        >
          {{ Config["userGuide"]["previousStep"] | translate }}
        </span>
        <button
          class="btn btn-primary btn-md buttons__next"
          (click)="onNextStep()"
        >
          {{ Config["userGuide"]["nextStep"] | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
