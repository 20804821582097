import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AccountService } from "../../_services/account.service";
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
  AbstractControl,
} from "@angular/forms";
import { Message } from "primeng/primeng";
import { UserState } from "@_services/current/user";
import { SweetAlertService } from "ng2-sweetalert2";
import { LanguageService } from "@_services/language.service";
import { forkJoin } from "rxjs";

@Component({
  selector: "app-password-update",
  templateUrl: "./password-update.component.html",
  styleUrls: ["./password-update.component.scss"],
})
export class PasswordUpdateComponent implements OnInit {
  objectKeys = Object.keys;
  model: any = {};
  updateModel: any = {};
  loading = false;
  msgs: Message[] = [];
  userform: FormGroup;
  submitted: boolean;
  display = true;
  controls = this.buildControl();
  template_validator;
  current_user;
  edited = false;
  constructor(
    private accountService: AccountService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public userState: UserState,
    public swalService: SweetAlertService,
    private languageService: LanguageService
  ) {
    this.current_user = this.userState.getCurrentUser().user;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((data) => {
      if (data["resetpwd"]) {
        this.msgs.push({
          severity: "Warning",
          summary: "Warning",
          detail:
            "You just receive an system generated password, please change with a new one.",
        });
      }
    });
    if (!!this.current_user.initPW) {
      delete this.controls.c_password;
    }
    this.userform = this.fb.group(this.controls);
  }
  initCPW() {
    if (this.userform.get("c_password")) {
      this.userform.get("c_password").valueChanges.subscribe((value) => {
        if (value || this.userform.get("password").value) {
          this.userform.get("password").updateValueAndValidity();
        }
      });
    }
  }
  buildControl() {
    const password_reg: RegExp =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
    return {
      c_password: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
        ])
      ),
      password: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
          Validators.pattern(password_reg),
        ])
      ),
      confirmPassword: new FormControl("", Validators.required),
    };
  }
  compareValidator() {
    return (currentControl: AbstractControl) => {
      const c_pw = currentControl.value;
      if (this.userform) {
        const p_pw = this.userform.value.c_password || "";
        if (c_pw === p_pw) {
          return { validSame: true };
        }
      }
      return null;
    };
  }
  onSubmit(value: any) {
    const { c_password, password } = this.userform.value;
    this.msgs = [];
    this.accountService
      .changePW({
        current_password: c_password,
        new_password: password,
      })
      .subscribe(
        () => {
          forkJoin(
            this.languageService.get("Password.Password_Has_Been_Change")
          ).subscribe((data) => {
            const [detail] = data;
            this.msgs = [];
            this.loading = false;
            this.msgs.push({
              severity: "success",
              summary: "Success",
              detail: detail,
            });
          });
          if (!password) {
            this.msgs.push({
              severity: "success",
              summary: "Success",
              detail: `<a href="/home">'Start to Use myViewBoard'</a>`,
            });
          }
          this.controls = this.buildControl();
          this.userform = this.fb.group(this.controls);
          this.controls.confirmPassword.setValidators(null);
          this.controls.password.setValidators(null);
          this.controls.c_password.setValidators(null);
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.msgs = [];
          this.msgs.push({
            severity: "error",
            summary: "Error",
            detail: error.body
              ? JSON.parse(error._body).message
              : error.error.message,
          });
          this.controls.c_password.patchValue("");
        }
      );
  }

  onResend() {
    this.languageService
      .get([
        "FollowMe_AI.You_Will_Change_PW",
        "SSO.Confirm",
        "Are you sure",
        "FollowMe_AI.Check_Email_To_Activate",
      ])
      .subscribe((res) => {
        this.swalService
          .swal({
            title: res["Are you sure"],
            text: res["FollowMe_AI.You_Will_Change_PW"],
            type: "info",
            buttonsStyling: false,
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonClass: "btn btn-primary  mr-4",
            cancelButtonClass: "btn btn-info",
            confirmButtonText: res["SSO.Confirm"],
          })
          .then(() => {
            this.accountService.resetPassword(this.current_user).subscribe(
              () => {
                this.msgs = [];
                this.msgs.push({
                  severity: "success",
                  summary: "Success",
                  detail: res["FollowMe_AI.Check_Email_To_Activate"],
                });
                this.loading = false;
              },
              (error) => {
                this.loading = false;
                this.msgs = [];
                this.msgs.push({
                  severity: "error",
                  summary: "Error",
                  detail: error.body
                    ? JSON.parse(error._body).message
                    : error.error.message,
                });
              }
            );
          });
      });
  }

  checkCurrentPW() {
    const userform = this.userform;
    const cpw_control = userform.controls["c_password"];
    const pw_control = userform.controls["password"];
    return cpw_control && cpw_control.value
      ? pw_control.value === cpw_control.value
      : false;
  }

  isExistCurrentPW() {
    const cpw_control = this.userform.controls["c_password"];
    return !cpw_control || (cpw_control && !cpw_control.value);
  }
}
