<div *ngFor="let step of _steps; let i = index; let stepstring = index; let isLast = last"   >
  <div [style.display]="selectedIndex === i ? 'block' : 'none'"
       [@stepTransition]="_getAnimationDirection(i)">
    <ng-container [ngTemplateOutlet]="step.content">
    </ng-container>
  </div>

  <!-- <div class="wrap"  [ngClass]="{'step4': selectedIndex === 3}">
    <div class="btmbtn" >
        <button class="stepicon" matTooltip="Step1" [ngClass]="{'step1': selectedIndex === 0, 'step2': selectedIndex === 1, 'step3': selectedIndex === 2}" 
        StepperIndex [index]='0'></button>
        <span  class="mat-stepper-horizontal-line"></span>
          <button class="stepicon" matTooltip="Step2" [ngClass]="{'step1': selectedIndex === 0, 'step2': selectedIndex === 1, 'step3': selectedIndex === 2}"
        StepperIndex [index]='1'></button>
        <span class="mat-stepper-horizontal-line"></span>
          <button class="stepicon" matTooltip="Step3" [ngClass]="{'step1': selectedIndex === 0, 'step2': selectedIndex === 1, 'step3': selectedIndex === 2}" 
        StepperIndex [index]='2'></button>
    </div>
    
  </div> -->
</div>
