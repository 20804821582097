<div class="overlay" *ngIf="showSpinner">
  <div class="center">
    <div [class.spinner]="loading"></div>
  </div>
</div>
<div class="navwrap">
  <img src="../../../../images/menuIcon.png" alt="" class="icon">
  <nav class="breadcrumb article">
    <a routerLink="/main-support" class="breadcrumb-item" [translate]="'Ticket.Main_support'">Main Support</a>
    <a routerLink="/main-support/cases/" class="breadcrumb-item" [translate]="'Ticket.All_Cases'">All Cases</a>
    <span class="breadcrumb-item active">{{case_number}}</span>
  </nav>
</div>


<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef [translate]="'Instance.Id'"> ID</th>
      <td mat-cell *matCellDef="let row"> {{row.case_number}} </td>
    </ng-container>

    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef [translate]="'Ticket.Title'"> Title </th>
      <td mat-cell *matCellDef="let row"> {{row.title}} </td>
    </ng-container>

    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef [translate]="'Ticket.Created_Time'"> Created Time </th>
      <td mat-cell *matCellDef="let row"> {{row.created_at}} </td>
    </ng-container>

    <ng-container matColumnDef="update">
      <th mat-header-cell *matHeaderCellDef [translate]="'Ticket.Last_update_Time'">Last update Time</th>
      <td mat-cell *matCellDef="let row"> {{row.modified_on}} </td>
    </ng-container>

    <ng-container matColumnDef="state">
      <th mat-header-cell *matHeaderCellDef [translate]="'Ticket.State'"> State </th>
      <td mat-cell *matCellDef="let row"> {{row.state}} </td>
    </ng-container>

    <ng-container matColumnDef="rating">
      <th mat-header-cell *matHeaderCellDef [translate]="'Ticket.Rating'"> Rating </th>
      <td mat-cell *matCellDef="let row">
        <star-rating value="{{rating}}" totalstars="5" checkedcolor="red" uncheckedcolor="grey" size="24px"
          readonly="{{readOnly}}" (rate)="onRate($event)"></star-rating>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="rowww"></tr>
  </table>
</div>
<div class="titlewrap" *ngIf="hasFeedBack">
  <div class="title" [translate]="'Ticket.Feedback'">Feedback</div>
  <div class="feedback">
    <textarea class="textarea" maxlength="1500" [(ngModel)]="feedbacktext"></textarea>
    <div class="btnwrap">
      <button class="send" [translate]="'Support.Send'" (click)="sendFeedback()">send</button>
    </div>
  </div>
</div>