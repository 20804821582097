import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';

export class CustomHandler implements MissingTranslationHandler {
    constructor(private translate: TranslateService) {

    }
    handle(params: MissingTranslationHandlerParams) {
    }
}

