import { Component, OnInit, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { matStepperAnimations, MatTableDataSource, MatDialog } from '@angular/material';
import { IntroBandwidthComponent } from './intro-bandwidth/intro-bandwidth.component';
import { AppState } from '@_services';
import { LanguageService } from '@_services/language.service';


@Component({
  selector: 'app-bandwidth-calculator',
  templateUrl: './bandwidth-calculator.component.html',
  styleUrls: ['./bandwidth-calculator.component.scss'],
  animations: [matStepperAnimations.horizontalStepTransition]
})
export class BandwidthCalculatorComponent implements OnInit, OnChanges {
  teacher_count: number
  isBegin = false;
  hideBtn = false;
  isLinear = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  surveyForm: FormGroup;
  plan_tabless: plan_table[] = [];
  dataSource = new MatTableDataSource<plan_table>();
  secdataSource = new MatTableDataSource<zoom_table>();
  displayedColumns: string[] = ['test', 'Plan A', 'Plan B'];
  displayedColumnss: string[] = ['Plan B'];
  plan_tables: any = [
    { title: 'HQ', HQ: 0.128, HD: 0.65 },
    { title: '720HD',HQ: 0.128,  HD: 1.16 },
    { title: 'FHD',HQ: 0.128, HD: 2.18 }
  ]
  zoom_plans: any = [
    { title: 'HQ',  HD: 1.3, FHD: 1.8 },
    { title: 'HD',  HD: 2, FHD: 3 },
    { title: 'FHD', HD: 3, FHD: 3.5 },
  ]
  currentLang: string;
  constructor(public dialog: MatDialog, private appState: AppState, private languageService: LanguageService) {}
  ngOnInit() {
    this.plan_tables.forEach(i => {
      this.dataSource.data.push(i)
    });

    this.zoom_plans.forEach(i => {
      this.secdataSource.data.push(i)
    });

    this.surveyForm = new FormGroup({
      step1: new FormGroup({
        upload: new FormControl('', Validators.required),
        download: new FormControl('', Validators.required)
      }),
      step2: new FormGroup({
        total_teachers: new FormControl('', Validators.required)
      }),
      step3: new FormGroup({
        students: new FormControl('', Validators.required)
      }),
      step4: new FormGroup({
        room_count: new FormControl('', Validators.required)
      }),
      step5: new FormGroup({
        plans: new FormControl('', Validators.required)
      }),
    });

    this.appState.scrollTop();
    this.languageService.loadCurrentLang().subscribe(res => {
      this.currentLang = res
    })
  }

  ngOnChanges() {}

  start() {
    this.isBegin = true;
    this.hideBtn = true;
  }

  back() {
    this.isBegin = false;
    this.hideBtn = false;
  }
  openDialog(){
    this.dialog.open(IntroBandwidthComponent, {
      width: '850px',
      minWidth: '310px',
      height: '60vh',
    })
  }
}

export interface plan_table {
  title: string, HQ: number, HD: number, FHD: number
}
export interface zoom_table {
  HQ: number, HD: number, FHD: number
}
