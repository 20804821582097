import {
  BehaviorSubject,
  from as observableFrom,
  Observable,
  of,
  Subject,
} from "rxjs";

import { mergeMap, map, concatMap, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  Http,
  RequestOptions,
  URLSearchParams,
  Response,
  RequestOptionsArgs,
  Headers,
} from "@angular/http";

import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { ServiceBase } from "./serviceBase";
import { AppState } from "../../_services";
import * as CryptoJS from "crypto-js";
const uuidv1 = require("uuid/v1");
@Injectable()
export class StorageService extends ServiceBase {
  private storageUrl = environment.apiUrl + "/api/storage/";
  private accountUrl = environment.apiUrl + "/api/account/";
  private windowMessage;
  support_type;
  constructor(private httpClient: HttpClient, public appstate: AppState) {
    super();
  }
  request_id_gen() {
    const uuid1 = uuidv1();
    return uuid1.toString();
  }

  getPGPKeyByEmail(email) {
    // /api/account/email/{email}/storage/config
    return this.httpClient.get<any>(
      environment.apiUrl + `/api/account/email/${email}/storage/config`
    );
  }

  /// api/storage/local/file/{id}/share/pgp
  shareWithPGP(options) {
    return this.httpClient.post(
      this.storageUrl + `local/file/${options.fileId}/share/pgp`,
      { users: options.users }
    );
  }

  checkeAccount(email) {
    return this.httpClient.post<any>(
      this.accountUrl + "check/email/" + email,
      null,
      {}
    );
  }

  getAccountIDArray(email_array) {
    return this.httpClient.post<any>(
      this.accountUrl + "getinfo/email",
      email_array,
      {}
    );
  }

  getStorageInfo() {
    return this.httpClient.get<any>(this.storageUrl, {});
  }

  createStorageFile(data: StorageModel) {
    data.directory = data.directory == "-1" ? "" : data.directory;
    return this.httpClient.post<any>(
      this.storageUrl + "local/file",
      JSON.stringify(data),
      {}
    );
  }

  createStorageFolder(name, directory) {
    directory = directory == "-1" ? "" : directory;
    return this.httpClient.post<any>(
      this.storageUrl + "local/folder",
      JSON.stringify({
        name: name,
        parent: directory,
      }),
      {}
    );
  }

  getStorageFileList(obj: StorageListQuery) {
    return this.httpClient
      .get(this.storageUrl + "local/list", this.queryParam(obj))
      .map((data) => <any>data);
  }

  getStorageFileShareToMe() {
    return this.httpClient.get(this.storageUrl + "share/list");
  }
  getStorageTrashList() {
    /// api/storage/trash/list
    return this.httpClient.get(this.storageUrl + "trash/list");
  }

  deleteStorageTrash(id) {
    /// api/storage/trash/list
    return this.httpClient
      .delete(this.storageUrl + "trash/" + id)
      .pipe(concatMap(() => this.getStorageTrashList()));
  }

  recoverStorageTrash(id) {
    /// api/storage/trash/list
    return this.httpClient
      .put(this.storageUrl + "trash/" + id, {})
      .pipe(concatMap(() => this.getStorageTrashList()));
  }

  activeStorageFile(id) {
    return this.httpClient.put(
      this.storageUrl + "local/file/active",
      { key: id },
      {}
    );
  }

  getStorageDownloadURL(id) {
    return this.httpClient.get<any>(
      super.urlUtil(this.storageUrl + "local/file/:id/download", { id: id }),
      {}
    );
  }

  getShareDownloadURL(id) {
    /// api/storage/share/{id}/download
    return this.httpClient.get<any>(
      super.urlUtil(this.storageUrl + "share/:id/download", { id: id }),
      {}
    );
  }

  getApiDownloadURL(option) {
    return super.urlUtil(this.storageUrl + option.path + "/:id/download", {
      id: option.id,
    });
  }

  moveStorageFile(id, name, parent) {
    return this.updateStorageFile(String(id), {
      name: name,
      type: StorageElementUpdateModel.FileMove,
      parent: parent,
    });
  }

  copyStorageFile(id, name, parent) {
    return this.updateStorageFile(String(id), {
      name: name,
      type: StorageElementUpdateModel.FileCopy,
      parent: parent,
    });
  }

  renameStorageFile(id, name, parent) {
    return this.updateStorageFile(String(id), {
      name: name,
      type: StorageElementUpdateModel.FileRename,
      parent: parent,
    });
  }

  moveStorageFolder(id, name, parent) {
    return this.updateStorageFolder(String(id), {
      name: name,
      type: StorageElementUpdateModel.FolderMove,
      parent: parent,
    });
  }

  renameStorageFolder(id, name, parent) {
    return this.updateStorageFolder(String(id), {
      name: name,
      type: StorageElementUpdateModel.FolderRename,
      parent: parent,
    });
  }

  publishStorageFile(id) {
    const url = this.storageUrl + "local/file/" + id + "/share";
    const gethash = Observable.create((observer) => {
      this.httpClient
        .get(url, this.queryParam({ type: "link" }))
        .subscribe((data: any) => {
          if (data.share.length === 0) {
            this.httpClient
              .put<any>(
                url,
                JSON.stringify({
                  type: "link",
                  expire: -1,
                }),
                {}
              )
              .subscribe((share) => {
                observer.next(share.hash);
              });
          } else {
            observer.next(data.share[0].hash);
          }
        });
    });
    const getUrlLink = Observable.create((observer) => {
      gethash.subscribe((hash) => {
        this.httpClient
          .get(this.storageUrl + "share/link/" + hash, {})
          .subscribe((data) => {
            observer.next(data);
          });
      });
    });

    return getUrlLink;
  }

  sharedStorageFile(fileid, timestamp, useridArr) {
    const url = this.storageUrl + "local/file/" + fileid + "/share";

    return this.httpClient.put(url, {
      type: "user",
      expire: timestamp ? timestamp : -1,
      users: useridArr,
    });

    /* var getData = Observable.create( (observer)=> {
        this.httpClient.get(url, this.queryParam({type: 'user'})).map((response: Response) => response).subscribe(data => {
          Observable.from(useridArr).map(id=>{
            return {'share_with':id}
          }).toArray().subscribe(result=>{
            var newShared=_.differenceBy(result,data.share,'share_with')
            var insertArr=[]
            newShared.forEach(item=>{
              insertArr.push(item.share_with)
            })
            if(insertArr.length>0){
              this.httpClient.put(url, JSON.stringify({
                type: 'user',
                expire: timestamp?timestamp:-1,
                users:insertArr
              }),{}).map((response: Response) => response).subscribe(share => {
                observer.next(share)
              })
            }
          })
        })
     })
     return getData*/
  }

  deleteStorageFolder(id) {
    return this.httpClient.delete(this.storageUrl + "local/folder/" + id, {});
  }

  deleteStorageFile(id) {
    return this.httpClient.delete(this.storageUrl + "local/file/" + id, {});
  }

  private updateStorageFolder(id: string, obj: StorageElementUpdateModel) {
    return this.httpClient.put(
      this.storageUrl + "local/folder/" + id,
      JSON.stringify(obj),
      {}
    );
  }

  private updateStorageFile(id: string, obj: StorageElementUpdateModel) {
    return this.httpClient.put(
      this.storageUrl + "local/file/" + id,
      JSON.stringify(obj),
      {}
    );
  }

  getStorageShareFileList() {
    return this.httpClient.get(this.storageUrl);
  }

  /// api/storage/throw/file
  throwFile(files, user, fullname, isLogin, scratch) {
    const apiUrl = scratch ? "scratch/file" : "throw/file";
    return observableFrom(files).pipe(
      map((file: Blob) => {
        const formData = new FormData();
        formData.append("filedata", file);
        formData.append("user", user);
        formData.append("fullname", fullname);
        return this.httpClient.post(
          this.storageUrl + apiUrl + (isLogin ? "" : "/guest"),
          formData,
          {
            withCredentials: true,
            reportProgress: true,
            observe: "events",
          }
        );
      }),
      mergeMap((response) => response)
    );
  }

  Popquiz(files, user, fullname, isLogin) {
    const apiUrl = "scratch/file";
    var dateTime = Date.now().toString();
    var userHash = sessionStorage.getItem("userHash");
    return observableFrom(files).pipe(
      map((file: Blob) => {
        const formData = new FormData();
        formData.append("filedata", file);
        formData.append("user", user);
        formData.append("fullname", fullname);
        if (!userHash) {
          try {
            userHash = CryptoJS.AES.encrypt(
              CryptoJS.enc.Utf8.parse({ user, dateTime }),
              location.host
            ).toString();
          } catch (e) {
            console.log(e);
          }
          sessionStorage.setItem("userHash", userHash);
          userHash = sessionStorage.getItem("userHash");
        }
        return this.httpClient.post(
          this.storageUrl + apiUrl + (isLogin ? "" : "/guest"),
          formData,
          {
            headers: { "x-throw-session": userHash },
            withCredentials: true,
            reportProgress: true,
            observe: "events",
          }
        );
      }),
      mergeMap((response) => response)
    );
  }

  throwUrl(url, user, fullname, isLogin) {
    return this.httpClient.post<any>(
      this.storageUrl + "/throw/url" + (isLogin ? "" : "/guest"),
      {
        url: url,
        user: user,
        fullname: fullname,
      },
      {
        withCredentials: true,
        reportProgress: true,
        observe: "events",
      }
    );
  }
  subscribeWindowMessage() {
    if (this.windowMessage) {
      return this.windowMessage;
    }
    this.windowMessage = new Subject();
    window.addEventListener("message", (event) => {
      if (!event.data.action) {
        return;
      }
      this.windowMessage.next(event);
    });
    return this.windowMessage;
  }

  /*/api/storage/throw/url

    POST

  (body)

  /api/storage/throw/url/guest*/

  throwFileNotify(instance_id, user_id, extra, isScratch) {
    if (isScratch) {
      return this.throwScratchNotify(instance_id, user_id, extra);
    }
    return this.httpClient.post(
      environment.apiUrl +
        `/viewboard/device/file/import/${instance_id}/${user_id}`,
      extra,
      { withCredentials: false }
    );
  }

  throwScratchNotify(instance_id, user_id, extra) {
    return this.httpClient.post(
      environment.apiUrl +
        `/viewboard/device/scratch/import/${instance_id}/${user_id}`,
      extra,
      { withCredentials: false }
    );
  }

  queryParam(obj: any) {
    if (typeof obj.max === "undefined") {
      obj.max = 1000;
    }
    const requestOption = this.setCredentials();
    // console.log(requestOption)
    requestOption.search = this.makeSearchParams(obj);
    return <any>requestOption;
  }

  // /api/storage/throw/support_types
  getSupportedFileTypes() {
    if (this.support_type) {
      return of(this.support_type);
    } else {
      return this.httpClient
        .get(environment.apiUrl + "/api/storage/throw/support_types", {
          withCredentials: true,
        })
        .pipe(
          tap((data) => {
            this.support_type = data;
          })
        );
    }
  }

  getThrowFileList(user) {
    return this.httpClient.get(
      environment.apiUrl + "/api/storage/throw/me/files?user=" + user,
      { withCredentials: true }
    );
  }

  deleteThrow(item) {
    console.log(item);
    return this.httpClient.delete(
      environment.apiUrl +
        "/api/storage/throw/me/file/" +
        item.id +
        "?user=" +
        item.target_user_id,
      { withCredentials: true }
    );
  }
}

export class StorageModel {
  is_pgp: boolean;
  name: string;
  mimetype: string;
  directory: string;
}

export class StorageListQuery {
  dir: string;
  p: number;
  max: any;
}

export class StorageElementUpdateModel {
  static FolderMove = "move";
  static FolderRename = "rename";
  static FileMove = "move";
  static FileRename = "rename";
  static FileCopy = "copy";
  type: string;
  name: string;
  parent: string;
}
