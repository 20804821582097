import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MvbSupportComponent } from './mvb-support/mvb-support.component';
import { SharedModule } from '@shared';
import { MaterialModule } from 'app/material/material.module';
import { MvbFaqComponent } from './mvb-faq/mvb-faq.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MvbSupportFaqRoutingModule } from './support-faq-routing.module';
import { RouterModule } from '@angular/router';
import { ArticlePageComponent } from './article-page/article-page.component';
import { MvbEntityComponent } from './mvba/mvba.component';
import { MvbwComponent } from './mvbw/mvbw.component';
import { MvbwebComponent } from './mvbweb/mvbweb.component';
import { CompanionComponent } from './companion/companion.component';
import { MyviewboardComponent } from './myviewboard/myviewboard.component';
import { SubmitSuccessComponent } from './submit-success/submit-success.component';
import { CaseDetailComponent } from './case-detail/case-detail.component';
import { OriginalContentComponent } from './original-content/original-content.component';
import { DisplayComponent } from './display/display.component';
import { DeviceManagementComponent } from './device-management/device-management.component';
import { SupportCasesComponent } from './support-cases/support-cases.component';
import { ngfModule } from 'angular-file';
import { RatingModule } from 'ng-starrating';
import { FileuploadComponent } from './fileupload/fileupload.component';
@NgModule({
  declarations: [
    MvbSupportComponent,
    MvbFaqComponent,
    ArticlePageComponent,
    MvbwComponent,
    MvbEntityComponent,
    MvbwebComponent,
    MyviewboardComponent,
    CompanionComponent,
    SubmitSuccessComponent,
    SupportCasesComponent,
    CaseDetailComponent,
    OriginalContentComponent,
    DisplayComponent,
    DeviceManagementComponent,
    FileuploadComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    RouterModule,
    MvbSupportFaqRoutingModule,
    ngfModule,
    RatingModule
  ],
  entryComponents: [
    MvbwComponent,
    MvbEntityComponent,
    MvbwebComponent,
    MyviewboardComponent,
    CompanionComponent,
    SubmitSuccessComponent,
    CaseDetailComponent,
    OriginalContentComponent,
    DisplayComponent,
    DeviceManagementComponent
  ],
  exports: [
    MvbSupportComponent,
    MvbFaqComponent,
    ArticlePageComponent,
    MvbwComponent,
    MvbEntityComponent,
    MvbwebComponent,
    MyviewboardComponent,
    CompanionComponent,
    SubmitSuccessComponent,
    CaseDetailComponent,
    OriginalContentComponent,
    DisplayComponent,
    DeviceManagementComponent
  ]
})
export class SupportFaqModule {}
