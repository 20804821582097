<div class="clips" id="step2">
  <div class="wrap-1200">
    <div class="letft">
      <div class="content">
        <!-- <div class="mobile-pic">
          <img src="../../../../assets/Clips-signup/clip2m.png" alt="">
        </div> -->
        <div class="header-wrap">
          <p class="header">Lets customize your experience with us</p>
          <img src="../../../../assets/Clips-signup/wave.svg" alt="" />
        </div>
        <form
          class="form-horizontal text-left"
          style="text-align: center !important"
          name="Step2Form"
          [formGroup]="Step2Form"
          (ngSubmit)="Step2Form.valid && sendInfo()"
          novalidate
          #f="ngForm"
        >
          <div class="inputs">
            <div class="input auto-width">
              <p class="form-text">I use myViewboard for</p>
              <mat-form-field
                hideRequiredMarker
                style="text-align: center"
                floatLabel="never"
              >
                <mat-select
                  formControlName="EntityType"
                  required
                  placeholder="- Select -"
                  #Edu
                >
                  <mat-option
                    *ngFor="let education of ClipsEducations"
                    [value]="education.name"
                  >
                    {{ education.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span class="form-text-dot">.</span>
            </div>

            <div class="input auto-width">
              <p class="form-text">I am a</p>
              <mat-form-field
                hideRequiredMarker
                style="text-align: center"
                floatLabel="never"
              >
                <mat-select
                  formControlName="Role"
                  placeholder="- Select -"
                  #Job
                  required
                >
                  <ng-container>
                    <mat-option
                      *ngFor="let job of ClipsJobs"
                      [value]="job.name"
                    >
                      {{ job.name }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
              <span class="form-text-dot">.</span>
            </div>

            <div *ngIf="Job?.value" class="input auto-width">
              <p
                *ngIf="Job?.value !== 'parent' && Job?.value !== 'student'"
                class="form-text"
              >
                I work in
              </p>
              <p *ngIf="Job?.value === 'parent'" class="form-text">
                My kid(s) is/are in
              </p>
              <p *ngIf="Job?.value === 'student'" class="form-text">I am in</p>
              <mat-form-field
                hideRequiredMarker
                style="text-align: center"
                floatLabel="never"
              >
                <mat-select
                  #place
                  (selectionChange)="customJob($event)"
                  formControlName="EduOrg"
                  placeholder="- Select -"
                  required
                >
                  <mat-option
                    *ngFor="let place of ClipsPlaces"
                    [value]="place.name"
                  >
                    {{ place.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span class="form-text-dot">.</span>
            </div>
            <div class="input auto-width" *ngIf="showOtherJobInput">
              <p class="form-text">If other</p>
              <mat-form-field
                hideRequiredMarker
                style="text-align: center"
                floatLabel="never"
              >
                <input
                  type="text"
                  matInput
                  formControlName="otherRole"
                  required
                />
              </mat-form-field>
              <span class="form-text-dot">.</span>
            </div>
            <div class="input auto-width">
              <p class="form-text">I am interested in</p>
              <mat-form-field
                hideRequiredMarker
                style="text-align: center"
                floatLabel="never"
              >
                <mat-select
                  #subject
                  formControlName="Subject"
                  placeholder="- Select -"
                  required
                >
                  <mat-option *ngFor="let list of subjectList" [value]="list">
                    {{ list.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <span class="form-text-dot">.</span>
            </div>
            <div class="input auto-width">
              <p class="form-text">Located in</p>
              <mat-form-field
                hideRequiredMarker
                style="text-align: center"
                floatLabel="never"
              >
                <input
                  type="text"
                  matInput
                  formControlName="Location"
                  required
                  #country
                  [matAutocomplete]="locations"
                  placeholder="- Select -"
                  (keyup)="search(country.value)"
                  class="auto-text"
                />
                <mat-autocomplete #locations="matAutocomplete">
                  <mat-option
                    *ngFor="let location of LocationOptions | async"
                    [value]="location.country_name"
                  >
                    {{ location.country_name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="
                    Step2Form.controls['Location'].touched ||
                    Step2Form.controls['Location'].dirty ||
                    Step2Form.invalid
                  "
                >
                  <mat-error
                    *ngIf="
                      Step2Form.controls['Location'].invalid &&
                      Step2Form.controls['Location'].value
                    "
                  >
                    The name is not on the list
                  </mat-error>
                </mat-error>
              </mat-form-field>
              <span class="form-text-dot">.</span>
            </div>

            <div
              class="input auto-width"
              *ngIf="Job?.value !== 'parent' && Job?.value !== 'student'"
            >
              <p class="form-text">I have a class of</p>
              <mat-form-field
                hideRequiredMarker
                style="text-align: center"
                floatLabel="never"
              >
                <mat-select
                  formControlName="Size"
                  placeholder="- Select -"
                  required
                >
                  <mat-option *ngFor="let size of EduSize" [value]="size.name">
                    {{ size.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <p class="form-text">students</p>
              <span class="form-text-dot">.</span>
            </div>
          </div>
          <button class="btn-next" [disabled]="!Step2Form.valid" type="submit">
            <p>Finish Up</p>
          </button>
          <button cdkStepperPrevious class="previous">← Back</button>
        </form>
      </div>
    </div>

    <div class="bg">
      <div class="img"></div>
    </div>
  </div>
</div>
