import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-clips-layout',
  templateUrl: './clips-layout.component.html',
  styleUrls: ['./clips-layout.component.scss']
})
export class ClipsLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
