<div class="wrapper">
  <div class="consent" *ngIf="show">
    <span [innerHTML]="'cookie_consent' | translate"></span>
    <span
      ><button
        mat-raised-button
        color="primary"
        [translate]="'Accept'"
        (click)="accept()"
      >
        Accept
      </button></span
    >
  </div>
</div>
