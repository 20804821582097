<div id="language">
  <button
    class="btn btn-menu dropdown-toggle"
    type="button"
    id="langDropdown"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false"
  >
    <i class="fal fa-globe"></i>
  </button>
  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="langDropdown">
    <ng-container *ngFor="let option of options">
      <a
        class="dropdown-item"
        href="javascript:void(0)"
        (click)="set(option.value)"
        >{{ option.label }}</a
      >
    </ng-container>
  </div>
  <!-- <mat-select
    #list
    (selectionChange)="set($event.value)"
    style="visibility: hidden; width: 0"
  >
    <mat-option *ngFor="let option of options" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select> -->
</div>
