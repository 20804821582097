<div class="overlay" *ngIf="showSpinner">
  <div class="center">
    <div [class.spinner]="loading"></div>
  </div>
</div>
<div class="main">
  <div class="content-wrap">
    <div class="content">
      <div class="support-header">
        <div class="support-header-text" [translate]="'Support.Heading'">
          Welcome to myViewBoard Support
        </div>
      </div>
    </div>
    <div class="bottomContent">
      <div class="da">
        <div class="logo">
          <img
            *ngIf="!isOnboarding; else showOnBoarding"
            [src]="current_path.icon"
            alt="Image"
          />
          <ng-template #showOnBoarding>
            <i class="fas fa-users fa-8x entity_onboarding" alt=""></i>
          </ng-template>
        </div>
        <div class="lists">
          <p class="ultitle" [translate]="current_path.title">
            myViewBoard: FAQ
          </p>
          <ul class="list">
            <li
              [routerLink]="['/', path, 'article', i.article_number]"
              *ngFor="let i of articles | slice: 0:5"
            >
              {{ i.title }}
            </li>
          </ul>
          <ng-container *ngIf="isWhiteboard">
            <p class="ultitle" [translate]="'Support.Android_faq'">
              myViewBoard Android : FAQ
            </p>
            <ul class="list">
              <li
                [routerLink]="['/', path, 'article', i.article_number]"
                *ngFor="let i of MVBW_And_articles | slice: 0:5"
              >
                {{ i.title }}
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="report" (click)="redirect()">
    <div class="report-content">
      <img
        class="reportimg"
        src="../../../images/Support_pics/MVBreport.svg"
        alt=""
      />
      <div class="reportContent">
        <div class="title-text" [translate]="'Support.Report_Issue'">
          Click here to submit a ticket
        </div>
        <div
          class="body-text"
          [translate]="'Support.Bottom_Content_SignIn'"
          *ngIf="!isLogin$"
        >
          Please sign in to let us know if something isn't working
        </div>
      </div>
    </div>
  </div>
</div>
