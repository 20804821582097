import { of as observableOf, Observable } from "rxjs";
import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { AppState } from "@_services";

declare var ga: any;

@Injectable()
export class ExternalEnvGuard implements CanActivate {
  constructor(private router: Router, private appstate: AppState) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this.appstate.get("hostEnv") === "flutter") {
      this.router.navigate(["/viewboard/setting"]);
      return observableOf(true);
    }
    return observableOf(true);
  }
}
