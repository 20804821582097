import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Bindings } from "../_models/bindings";
import { AccountService } from "@_services/account.service";

@Component({
  selector: "app-binding-optional",
  templateUrl: "./binding-optional.component.html",
  styleUrls: ["./binding-optional.component.scss"],
})
export class BindingOptionalComponent implements OnInit {
  options = [
    { key: "google_drive", checked: true },
    // {key: 'google_calendar',checked:true},
    //{key: 'google_class',checked:true}
  ];
  bindingLable;
  icons;

  constructor(
    public accountService: AccountService,
    public dialogRef: MatDialogRef<BindingOptionalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.bindingLable = Bindings.bindingLable;
    this.icons = Bindings.icons;
  }

  onAuthClick($event) {
    window.location.href = this.accountService.googleServiceDetection(
      this.options
    );
  }

  skip() {
    this.dialogRef.close();
  }
}
