import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-clips",
  templateUrl: "./clips.component.html",
  styleUrls: ["./clips.component.scss"],
})
export class ClipsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
