import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class SignupService {
  private Step1Info = new Subject<any>();
  private Step2Info = new Subject<any>();
  private Step3Validation = new Subject<any>();
  constructor() { }

  sendStep1Info(info: any){
    this.Step1Info.next(info);
  }

  sendStep2Info(info: any){
    this.Step2Info.next(info);
  }

  receiveStep1Info(): Observable<any> {
    return this.Step1Info.asObservable();
  }

  receiveStep2Info(): Observable<any> {
    return this.Step2Info.asObservable();
  }

  sendStep3Info(info: any){
    this.Step3Validation.next(info);
  }

  receiveStep3Info(): Observable<any> {
    return this.Step3Validation.asObservable();
  }

}
