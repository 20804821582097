import { trigger, transition, style, animate } from "@angular/animations";
import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
} from "@angular/core";
import { PrimeTemplate } from "primeng/primeng";
import { ALERT_MESSAGE } from "../models";
@Component({
  selector: "mvb-message",
  templateUrl: "./mvb-message.component.html",
  styleUrls: ["./mvb-message.component.scss"],
  animations: [
    trigger("messageAnimation", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(-25%)" }),
        animate("{{showTransitionParams}}"),
      ]),
      transition(":leave", [
        animate(
          "{{hideTransitionParams}}",
          style({
            height: 0,
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            overflow: "hidden",
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
})
export class MVBMessageComponent implements OnInit {
  @ContentChildren(PrimeTemplate) templates: QueryList<PrimeTemplate>;
  @Input() value: ALERT_MESSAGE[];
  @Input() showTransitionOptions: string = "300ms ease-out";
  @Input() hideTransitionOptions: string =
    "200ms cubic-bezier(0.86, 0, 0.07, 1)";
  @Input() closable: boolean = true;
  @Output() valueChange: EventEmitter<ALERT_MESSAGE[]> = new EventEmitter<
    ALERT_MESSAGE[]
  >();
  footerTemplate: TemplateRef<any>;
  constructor() {}
  ngOnInit() {}
  ngAfterContentInit() {
    this.templates.forEach((item) => {
      if (item.getType() === "footer") {
        this.footerTemplate = item.template;
      }
    });
  }
  removeMessage(i: number) {
    this.value = this.value.filter((msg, index) => index !== i);
    this.valueChange.emit(this.value);
  }
}
