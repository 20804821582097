import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import {GoogleSignInComponent} from "@shared/auth-buttons/google-sign-in.component";
import {FlexLayoutModule} from "@angular/flex-layout";

@NgModule({
  declarations: [GoogleSignInComponent],
  imports: [
    CommonModule,FlexLayoutModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [GoogleSignInComponent]
})
export class AuthButtonsModule { }
