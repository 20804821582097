import { AfterContentInit, ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Output, QueryList, ViewEncapsulation, ViewChildren, Input, HostListener, Directive,   } from '@angular/core';
import {AnimationEvent} from '@angular/animations';
import {CdkStep, CdkStepper, StepContentPositionState} from '@angular/cdk/stepper';
import {Subject} from 'rxjs';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import { matStepperAnimations } from '@angular/material';

@Component({
  selector: 'app-clips-customstep',
  templateUrl: './clips-custom.component.html',
  styleUrls: ['./clips-custom.component.scss'],
  providers: [{provide: CdkStepper, useExisting: ClipsCustomstepComponent}],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [matStepperAnimations.horizontalStepTransition],
})

export class ClipsCustomstepComponent extends CdkStepper  implements AfterContentInit {
   @ContentChildren(CdkStep) _steps: QueryList<CdkStep>;
   @Output() readonly animationDone: EventEmitter<void> = new EventEmitter<void>();
   _animationDone: any = new Subject<AnimationEvent>();

   ngAfterContentInit() {
       this._steps.changes.pipe(takeUntil(this._destroyed)).subscribe(() => this._stateChanged());
       this._animationDone.pipe(
           distinctUntilChanged((x: any, y: any) => x.fromState === y.fromState && x.toState === y.toState),
           takeUntil(this._destroyed)
       ).subscribe(event => {
           if ((event.toState as StepContentPositionState) === 'current') {
               this.animationDone.emit();
           }
       });
   }

   onClick(index: number): void {
    this.selectedIndex = index;
  }

}
