<!-- FOOTER -->
<footer class="container-fluid">
  <div class="f-icons container pt-5 pb-3 clearpadding text-md-center">
    <div class="row" style="max-width: 1200px; margin: auto">
      <div class="f-partner col-4 col-md-2 m-auto text-center">
        <a href="http://www.viewsonic.com/">
          <img
            alt="ViewSonic"
            data-src="https://myviewboard.com/mkt-assets/images/viewsonic-build-2021.png"
            class="img-fluid"
            style="max-height: 58px; width: auto"
            uk-img=""
            src="https://myviewboard.com/mkt-assets/images/viewsonic-build-2021.png"
        /></a>
      </div>
      <div class="f-partner col-4 col-md-2 m-auto text-center">
        <a href="http://aws.amazon.com/what-is-cloud-computing" target="_blank">
          <img
            alt="Powered by AWS Cloud Computing"
            data-src="https://myviewboard.com/mkt-assets/images/powered-by-aws.svg"
            class="img-fluid"
            style="max-height: 50px; width: auto"
            uk-img=""
            src="https://myviewboard.com/mkt-assets/images/powered-by-aws.svg"
        /></a>
      </div>
      <div class="f-partner col-4 col-md-2 m-auto text-center">
        <a
          href="https://cloud.withgoogle.com/partners/detail/?id=ACkb94b4UlaXbQEyFMX75XwL_Uu3I5llE2KFTmmEzq2s5McbODILpFHK_DUFr8KgAmt5AzQMme-z&amp;hl=en"
          target="_blank"
        >
          <img
            style="max-height: 60px; width: auto"
            alt="Google Education Partner"
            data-src="https://myviewboard.com/mkt-assets/images/partner-google-for-education.svg"
            class="img-fluid"
            uk-img=""
            src="https://myviewboard.com/mkt-assets/images/partner-google-for-education.svg"
        /></a>
      </div>
      <div class="f-partner col-4 col-md-2 m-auto text-center">
        <a
          href="https://appsource.microsoft.com/en-us/product/web-apps/viewsonic.myviewboard?tab=Overview"
          target="_blank"
        >
          <img
            style="max-height: 60px; width: auto"
            alt="Microsoft Education Specialist Partner Badge"
            data-src="https://myviewboard.com/mkt-assets/images/microsoft-partner-education-specialist.svg"
            class="img-fluid"
            uk-img=""
            src="https://myviewboard.com/mkt-assets/images/microsoft-partner-education-specialist.svg"
        /></a>
      </div>
      <div class="f-partner col-4 col-md-2 m-auto text-center">
        <a
          href="https://marketplace.intel.com/s/offering/a5b3b000000TcGfAAK/viewboard-with-myviewboard"
          target="_blank"
        >
          <img
            style="max-height: 60px; width: auto"
            alt="intel-market-ready"
            data-src="https://myviewboard.com/mkt-assets/images/intel-market-ready.svg"
            class="img-fluid"
            uk-img=""
            src="https://myviewboard.com/mkt-assets/images/intel-market-ready.svg"
        /></a>
      </div>
    </div>
  </div>

  <br />
  <hr />
  <div class="container">
    <!--nav-container-->
    <div class="row pt-md-5">
      <div class="col-6 col-md my-3">
        <h6>myViewBoard</h6>
        <ul class="list-unstyled text-small pt-2">
          <li>
            <a href="https://myviewboard.com/pricing/" [translate]="'Pricing'"
              >Pricing</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/white-paper/security/"
              [translate]="'Footer.Security'"
              >Security</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/about/accessibility/"
              target="_blank"
              [translate]="'Home.Accessibility'"
              >Accessibility</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/contact/"
              [translate]="'Contact Us'"
              >Contact Us</a
            >
          </li>
        </ul>
      </div>
      <div class="col-6 col-md my-3">
        <h6 [translate]="'Tools'">Tools</h6>
        <ul class="list-unstyled text-small pt-2">
          <li>
            <a
              href="https://myviewboard.com/products/whiteboard/"
              [translate]="'p_Whiteboard'"
              >Whiteboard</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/products/classroom/"
              [translate]="'p_Classroom'"
              >Classroom</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/products/companion/"
              [translate]="'p_Companion'"
              >Companion</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/products/originals/"
              [translate]="'p_Originals'"
              >Originals</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/products/sens/"
              [translate]="'p_Sens'"
              >Sens</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/products/manager/"
              [translate]="'p_manager'"
              >Manager</a
            >
          </li>
        </ul>
      </div>
      <div class="col-6 col-md my-3">
        <h6 [translate]="'Solutions'">Solutions</h6>
        <ul class="list-unstyled text-small pt-2">
          <li>
            <a
              href="https://myviewboard.com/solutions/entity/"
              [translate]="'Menu.Entity'"
              >Entity</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/solutions/insights/"
              [translate]="'Insights'"
              >Insights</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/solutions/olf/"
              [translate]="'Open Learning Format'"
              >Open Learning Format</a
            >
          </li>
        </ul>
      </div>
      <div class="col-6 col-md my-3">
        <h6 [translate]="'Resources'">Resources</h6>
        <ul class="list-unstyled text-small pt-2">
          <li>
            <a
              href="https://myviewboard.com/download/"
              [translate]="'Footer.Download'"
              >Download</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/kb/en_US/whats-new"
              target="_blank"
              [translate]="'Footer.Blog'"
              >What's New</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/kb"
              target="_blank"
              [translate]="'Knowledge Base'"
              >Knowledge Base</a
            >
          </li>
          <li>
            <a
              [href]="this.supportLink"
              target="_blank"
              [translate]="'Footer.Support'"
              >Support &amp; FAQ</a
            >
          </li>
          <li>
            <a
              href="https://myviewboard.com/resources/video-center/"
              [translate]="'Video Center'"
              >Video Center</a
            >
          </li>
          <li>
            <a
              href="https://community.myviewboard.com/"
              target="_blank"
              [translate]="'Footer.Community'"
              >Community</a
            >
          </li>
        </ul>
      </div>
      <div class="col-6 col-md my-3">
        <h6 [translate]="'Social'">Socials</h6>
        <ul class="list-unstyled text-small pt-2">
          <li class=" ">
            <a href="https://www.facebook.com/myviewboard" target="_blank">
              <span class="">
                <i class="fab fa-facebook"></i>
              </span>
              Facebook</a
            >
          </li>
          <li class=" ">
            <a
              href="https://www.linkedin.com/company/myviewboard"
              target="_blank"
            >
              <span class="">
                <i class="fab fa-linkedin"></i>
              </span>
              LinkedIn</a
            >
          </li>

          <li class=" ">
            <a href="https://twitter.com/myviewboard" target="_blank">
              <span class="">
                <i class="fab fa-twitter"></i>
              </span>
              Twitter</a
            >
          </li>

          <li class=" ">
            <a href="https://www.youtube.com/c/myviewboard" target="_blank">
              <span class="">
                <i class="fab fa-youtube"></i>
              </span>
              YouTube</a
            >
          </li>
          <li class=" ">
            <a href="https://www.instagram.com/myviewboard" target="_blank">
              <span class="">
                <i class="fab fa-instagram"></i>
              </span>
              Instagram</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="text-center pt-5 pb-5">
    <p>
      <a href="/policy" target="_blank" [translate]="'Privacy_Policy'"
        >Privacy Policy</a
      >
    </p>
    <p class="copyright">
      ViewSonic 2017-{{ year }}. ©<span [translate]="'All_Rights_Reserved'">
        All Rights Reserved.</span
      >
    </p>
  </div>
</footer>
