import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '@_services/account.service';
import { AppState } from '@_services';
import { SweetAlertService } from 'ng2-sweetalert2';
import { LanguageService } from '@_services/language.service';
@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.scss'],
  providers: [SweetAlertService]
})

export class ArticlePageComponent implements OnInit {
  article_number: any;
  article: any;
  showSpinner = false;
  article_id: any;
  loading: boolean;
  isMainSupport: boolean
  currentLang: string;
  constructor(private activatedRoute: ActivatedRoute,
    private accoutService: AccountService,
    public appService: AppState,
    private swalService: SweetAlertService,
    private languageService: LanguageService) {

    }

  ngOnInit() {
    this.languageService.loadCurrentLang().subscribe( i => this.currentLang = i)
    const a = window.location.pathname.split('/')[1]
    a == 'main-support' ? this.isMainSupport = true : this.isMainSupport = false;
    this.activatedRoute.params.subscribe(param => {
      console.log('param', param)
      this.article_id = param.id;
      this.loadArticle(param.id)
    })
  }

  loadArticle(id) {
    this.showSpinner = true;
    this.loading = true
    this.accoutService.getDetailArticle(id, this.currentLang).subscribe(data => {
      this.article = data['content'].replace(/"/g, '');
      this.showSpinner = false;
      this.loading = false;
    }, () => {
      this.swalService.swal({
        title: 'Error',
        text: 'Something went wrong, please try again',
        type: 'warning',
        buttonsStyling: false,
        showCancelButton: false,
        confirmButtonClass: 'btn btn-primary',
        confirmButtonText: 'Confirm',
      })
    })
  }

}
