<form [formGroup]="MVB" (ngSubmit)="sendTicket()" #f="ngForm" novalidate>
  <div class="wrap radiowrap">
    <div class="title" [translate]="'Support.Ticket_Heading'">
      What issue are you facing?
    </div>
    <div class="wrap-content" [dir]="languageService.dir | async">
      <div class="selects">
        <div class="switch" *ngIf="!select_os">
          <div class="version select-item">
            <div class="select-name" [translate]="'Support.Choose_Platform'">
              Please choose Platform
            </div>
            <select required (change)="selectOS($event)">
              <option value="" style="text-align: center">-Select-</option>
              <option value="windows">Windows</option>
              <option value="android">Android</option>
              <option value="web">Web</option>
            </select>
          </div>
        </div>
        <div class="windows" *ngIf="select_os === 'windows'">
          <div class="version select-item">
            <div class="select-name" [translate]="'Support.Windows_Version'">
              Windows Version
            </div>
            <select
              formControlName="os_version"
              required
              (change)="
                changeVersionSelection($event, 'Change Windows Version')
              "
              [value]="'Windows 10 Pro'"
            >
              <option
                *ngFor="let version of win_versions"
                value="{{ version.Value }}"
              >
                {{ version.Name }}
              </option>
            </select>
          </div>
          <div class="otherinput">
            <input
              *ngIf="showinput"
              [(ngModel)]="otherVersion"
              formControlName="other"
              required
              maxlength="64"
              [ngStyle]="
                showinput ? { 'margin-top': ' 10px' } : { 'margin-top': 0 }
              "
            />
          </div>
          <div class="version select-item">
            <div
              class="select-name"
              [translate]="'Support.MVB_Windows_Version'"
            >
              myViewBoard Windows Version
            </div>
            <select
              formControlName="MVB_Version"
              class="select-width"
              required
              (change)="changeSelection($event, 'Change Windows MVB Version')"
              [value]="Win_default"
            >
              <option
                *ngFor="let version of WindowsVersions"
                value="{{ version }}"
              >
                {{ version }}
              </option>
            </select>
          </div>
        </div>
        <div class="android" *ngIf="select_os === 'android'">
          <div class="version select-item">
            <div class="select-name" [translate]="'Support.Android_Version'">
              Android Version
            </div>
            <select
              formControlName="os_version"
              class="select-width"
              (change)="
                changeVersionSelection($event, 'Change Android Version')
              "
              [value]="'10.0'"
            >
              <option
                *ngFor="let version of and_versions"
                value="{{ version.Value }}"
              >
                {{ version.Name }}
              </option>
            </select>
          </div>
          <div class="otherinput">
            <input
              *ngIf="showinput"
              [(ngModel)]="otherVersion"
              formControlName="other"
              maxlength="64"
              required
              [ngStyle]="
                showinput ? { 'margin-top': ' 10px' } : { 'margin-top': 0 }
              "
            />
          </div>
          <div class="version select-item">
            <div
              class="select-name"
              [translate]="'Support.MVB_Android_Version'"
            >
              myViewBoard Android Version
            </div>
            <select
              formControlName="MVB_Version"
              class="select-width"
              [value]="And_default"
              (change)="changeSelection($event, 'Change Android MVB Version')"
            >
              <option
                *ngFor="let version of AndroidVersions"
                value="{{ version }}"
              >
                {{ version }}
              </option>
            </select>
          </div>
        </div>
        <ng-container *ngIf="select_os === 'web'">
          <div class="selects">
            <div class="browser">
              <b [translate]="'Support.Browser_Type'">Browser type</b>
              <mat-error
                class="msgwarning"
                *ngIf="MVB.controls['os_version'].invalid && f.submitted"
                [translate]="'Support.Error_Browser'"
                >Please choose a browser</mat-error
              >
              <mat-radio-group
                class="example-radio-group"
                formControlName="os_version"
              >
                <mat-radio-button value="Edge">{{
                  "Support.Browserlist.Edge" | translate
                }}</mat-radio-button>
                <mat-radio-button value="Chrome">{{
                  "Support.Browserlist.Chrome" | translate
                }}</mat-radio-button>
                <mat-radio-button value="Firefox">{{
                  "Support.Browserlist.Firefox" | translate
                }}</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="ticket-title">
              <b
                [translate]="'Support.Issue_Type'"
                *ngIf="(currentLang | async) !== 'ja'"
                >Issue type for<span class="title-red"
                  >&nbsp;myViewBoard Whiteboard(web)</span
                ></b
              >
              <b *ngIf="(currentLang | async) === 'ja'">
                <span class="title-red"> myViewBoard Whiteboard(web)</span>
                <span [translate]="'Support.Issue_Type'"
                  >&nbsp; Issue type for</span
                ></b
              >
              <mat-error
                class="msgwarning"
                *ngIf="MVB.controls['MVB_Version'].invalid && f.submitted"
                [translate]="'Support.Error_Issue'"
                >Please choose your issue type</mat-error
              >
              <mat-radio-group
                class="example-radio-group"
                formControlName="MVB_Version"
              >
                <mat-radio-button value="Unable to sign in"
                  >{{ "Support.web_options.Unable_signin" | translate }}
                </mat-radio-button>
                <mat-radio-button
                  value="How to sync users through Azure AD or G Suite"
                  >{{ "Support.web_options.sync_users" | translate }}
                </mat-radio-button>
                <mat-radio-button value="Others"
                  >{{ "Support.web_options.Others" | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
        </ng-container>
        <div
          class="version select-item"
          *ngIf="select_os && select_os !== 'web'"
        >
          <div class="select-name" [translate]="'Support.IFP_Model'">
            IFP Model<mat-error
              class="msgwarning"
              *ngIf="MVB.controls['Model'].invalid && f.submitted"
              [translate]="'Support.select_error'"
              >Please select an option</mat-error
            >
          </div>
          <select
            formControlName="Model"
            class="select-width"
            required
            [value]="'IFP5550'"
            (change)="changeSelection($event, 'Change Windows IFP Model')"
          >
            <option *ngFor="let model of IFP_Model" value="{{ model }}">
              {{ model }}
            </option>
          </select>
        </div>
      </div>
      <div class="title-area" *ngIf="select_os">
        <div class="title-header" [translate]="'Support.Title'">
          Title<mat-error
            class="msgwarning"
            *ngIf="MVB.controls['Title'].invalid && f.submitted"
            [translate]="'Support.Error_Title'"
            >Please name a title for this ticket</mat-error
          >
        </div>
        <input
          class="title-input"
          formControlName="Title"
          placeholder="{{ 'Support.Title_placeholder' | translate }}"
          maxlength="60"
          [ngClass]="{ bidi_dir: (currentLang | async) === 'ar' }"
          required
        />
      </div>
      <div *ngIf="select_os">
        <app-fileupload #fileupload formGroupName="FileGroup"></app-fileupload>
      </div>
    </div>
  </div>
  <div class="btn-wrap" *ngIf="select_os">
    <div class="btns">
      <button
        class="sendbtn"
        [class.spinner]="loading"
        [disabled]="loading"
        [ngClass]="{ 'btn-loading': loading === true }"
        [translate]="'Support.Send'"
        type="submit"
      >
        Send
      </button>
      <button
        class="cancelbtn"
        [disabled]="loading"
        [ngClass]="{ 'btn-loading': loading === true }"
        [mat-dialog-close]="true"
        [translate]="'Support.Cancel'"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
