import { Component, OnInit, ViewChild } from "@angular/core";
import { SweetAlertService } from "ng2-sweetalert2";
import { AppState } from "@_services";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { AccountService } from "@_services/account.service";
import { MatDialogRef, MatDialog } from "@angular/material";
import { SubmitSuccessComponent } from "../submit-success/submit-success.component";
import { FileuploadComponent } from "../fileupload/fileupload.component";
import { LanguageService } from "@_services/language.service";
import { Observable } from "rxjs";
import { Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "app-mvbw",
  templateUrl: "./mvbw.component.html",
  styleUrls: ["./mvbw.component.scss"],
  providers: [SweetAlertService],
})
export class MvbwComponent implements OnInit {
  @ViewChild("fileupload") file: FileuploadComponent;
  MVB: FormGroup;
  loading = false;
  showinput: boolean;
  select_os: string;
  otherVersion: string;
  version: string;
  win_versions = [
    { Name: "Windows 10 Pro", Value: "Windows 10 Pro" },
    { Name: "Windows 10 Enterprise", Value: "Windows 10 Enterprise" },
    { Name: "Windows 10 Education", Value: "Windows 10 Education" },
    { Name: "Windows 10 Home", Value: "Windows 10 Home" },
    { Name: "Other Windows version", Value: "other" },
  ];
  currentLang: Observable<string>;
  WindowsVersions: any;
  AndroidVersions: any;
  Win_default: string;
  And_default: string;
  and_versions = [
    { Name: "10.0", Value: "10.0" },
    { Name: "9.0", Value: "9.0" },
    { Name: "8.0", Value: "8.0" },
    { Name: "7.0", Value: "7.0" },
    { Name: "6.0", Value: "6.0" },
    { Name: "5.0", Value: "5.0" },
    { Name: "Other", Value: "other" },
  ];

  IFP_Model = [
    "IFP5550",
    "IFP6530",
    "IFP6550",
    "IFP7530",
    "IFP7550",
    "IFP8650",
    "IFP5550-2",
    "IFP6550-2",
    "IFP7550-2",
    "IFP8650-2",
    "IFP5550-3",
    "IFP6550-3",
    "IFP7550-3",
    "IFP8650-3",
    "N/A",
  ];

  constructor(
    public accountService: AccountService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MvbwComponent>,
    public dialog: MatDialog,
    private swalService: SweetAlertService,
    private appState: AppState,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.currentLang = this.languageService.loadCurrentLang();
    this.accountService.getVersion("Whiteboard for Windows").subscribe((i) => {
      this.WindowsVersions = i[0]["versions"];
      this.Win_default = this.WindowsVersions[0];
    });
    this.accountService.getVersion("Whiteboard for Android").subscribe((i) => {
      this.AndroidVersions = i[0]["versions"];
      this.And_default = this.AndroidVersions[0];
    });

    this.MVB = this.fb.group({
      os_version: new FormControl("", [Validators.required]),
      MVB_Version: new FormControl("", [Validators.required]),
      Title: new FormControl("", [Validators.required]),
      FileGroup: this.fb.group({
        Description: new FormControl("", [Validators.required]),
        FlagCtrl: new FormControl(false),
      }),
    });
  }

  selectOS(event: any) {
    const val = event.target.value;
    const form = this.MVB;
    this.select_os = val;
    this.showinput = false;
    switch (val) {
      case "windows":
        form.patchValue({ os_version: "Windows 10 Pro" });
        form.patchValue({ MVB_Version: this.Win_default });
        form.addControl(
          "Model",
          new FormControl("IFP5550", [Validators.required])
        );
        break;
      case "android":
        form.patchValue({ os_version: "10.0" });
        form.patchValue({ MVB_Version: this.And_default });
        form.addControl(
          "Model",
          new FormControl("IFP5550", [Validators.required])
        );
        break;
      default:
        break;
    }
  }

  errorPop() {
    this.swalService.swal({
      title: "Error",
      text: "Something went wrong, please try again",
      type: "error",
      buttonsStyling: false,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonClass: "btn btn-primary",
      confirmButtonText: "Confirm",
    });
  }

  changeVersionSelection(event: any, name: string) {
    const form = this.MVB;
    form.patchValue({ other: "" });
    if (event.target.value === "other") {
      this.showinput = true;
      form.addControl("other", new FormControl("", Validators.required));
    } else {
      form.patchValue({ other: "" });
      this.showinput = false;
      form.removeControl("other");
    }
    form.updateValueAndValidity();
  }

  changeSelection($event: any, name: string) {}

  sendTicket() {
    this.file.isClicked = true;
    const form = this.MVB;
    let finaltext = "";
    const text = this.file.FileGroup.controls["Description"].value;
    const model = this.MVB.controls["Model"];
    const mvb_w = this.MVB.controls["MVB_Version"].value;
    const title = this.MVB.controls["Title"].value;
    const title_mvb_w =
      this.select_os === "web" ? "Issue Type: " : "MVB_Version: ";
    const breakingpoint = "\n";
    const product =
      this.select_os === "windows"
        ? "mvb-0002"
        : this.select_os === "web"
        ? "mvb-0001"
        : "mvb-0003";
    const Cate =
      this.select_os === "windows"
        ? "Whiteboard for Windows"
        : this.select_os === "web"
        ? "Whiteboard"
        : "Whiteboard for Android";
    const basicFinal =
      title_mvb_w + mvb_w + breakingpoint + "Description: " + text;
    form.controls["other"]
      ? (this.version = form.controls["other"].value)
      : (this.version = form.controls["os_version"].value);
    model && model.value
      ? (finaltext = "IFP Model: " + model.value + breakingpoint + basicFinal)
      : (finaltext = basicFinal);
    if (!this.MVB.valid) return false;
    this.loading = true;
    const ticket = {
      product: product,
      category: Cate + breakingpoint + "Browser: " + this.version,
      title: title,
      description: finaltext,
      screenshots: this.file.keylist,
    };
    console.log("white board ticket", ticket, this.select_os);
    this.accountService.d365SendCase(ticket).subscribe(
      () => {
        if (this.appState._state["ticket-cases"])
          delete this.appState._state["ticket-cases"];
        this.loading = false;
        this.dialogRef.close();
        this.dialog.open(SubmitSuccessComponent, {
          width: "400px",
          minWidth: "310px",
          height: "auto",
        });
      },
      () => {
        this.loading = false;
        this.errorPop();
      }
    );
  }
}
