import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { UserState } from "@_services/current/user";
import { UserGuideService } from "@_services/user_guide.service";
import { SweetAlertService } from "ng2-sweetalert2";

@Component({
  selector: "greeting",
  templateUrl: "./greeting.component.html",
  styleUrls: ["./greeting.component.scss"],
  providers: [SweetAlertService],
})
export class GreetingComponent implements OnInit {
  Name = "Sam";
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private userState: UserState
  ) {}
  ngOnInit() {
    this.Name = this.userState.getCurrentUser().getUserFirstName();
  }
}
