import { Component, OnInit } from "@angular/core";
import { AccountService } from "app/_services/account.service";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { ClipsSignupService } from "../clips.service";
import { AppState } from "../../../_services";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { environment } from "@environment";

const password_reg: RegExp =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;
const username_reg: RegExp = /^[a-zA-Z\s\d.@._]*$/;
// tslint:disable-next-line: max-line-length
const email_reg: RegExp =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
@Component({
  selector: "app-step1",
  templateUrl: "./step1.component.html",
  styleUrls: ["./step1.component.scss"],
})
export class Step1Component implements OnInit {
  Step1Form: FormGroup;
  getLatestNews = false;
  emailSubject = new Subject<string>();
  constructor(
    public accountService: AccountService,
    private fb: FormBuilder,
    private clipsService: ClipsSignupService,
    private appstate: AppState
  ) {}

  ngOnInit() {
    this.Step1Form = this.fb.group({
      firstname: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(username_reg),
          Validators.maxLength(20),
        ])
      ),
      lastname: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(username_reg),
          Validators.maxLength(20),
        ])
      ),
      email: new FormControl(
        "",
        Validators.compose([
          Validators.email,
          Validators.required,
          Validators.pattern(email_reg),
        ])
      ),
      password: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(32),
          Validators.pattern(password_reg),
        ])
      ),
      check: new FormControl(false, Validators.requiredTrue),
      getNews: new FormControl(false),
    });
    const thirdId = this.appstate.localStorage.getItem("third-id");
    if (thirdId) {
      this.Step1Form.setValue({
        email: this.appstate.localStorage.getItem("email"),
      });
    }
    this.emailSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        const email = this.Step1Form.controls["email"];
        if (email.valid) {
          this.isEmailUnique(value).then(
            () => {},
            () => {
              email.setErrors({ incorrect: true });
            }
          );
        }
      });
  }

  search(serachParam: string) {
    this.emailSubject.next(serachParam);
  }

  goPolicy() {
    window.open("/policy", "_blank");
  }

  goTerms() {
    window.open("/legal/terms-of-service", "_blank");
  }

  step1Info() {
    const control = this.Step1Form.controls;
    const form = this.Step1Form;
    console.log("form", form);
    const check = control["check"];
    const email = control["email"];
    const password = control["password"];
    const firstname = control["firstname"];
    const lastname = control["lastname"];
    email.markAsTouched();
    password.markAsTouched();
    firstname.markAsTouched();
    lastname.markAsTouched();
    check.markAsDirty();
    const sendInfo = {
      fullname: firstname.value + lastname.value,
      first_name: firstname.value,
      last_name: lastname.value,
      email: email.value,
      password: password.value,
      getNews: this.getLatestNews,
    };
    if (this.Step1Form.valid === true) {
      this.clipsService.sendStep1Info(sendInfo);
    }
  }

  isEmailUnique(value) {
    const result = new Promise<void>((resolve, reject) => {
      this.accountService.accountInspect({ email: value }).subscribe(
        () => {
          reject();
        },
        () => {
          resolve();
        }
      );
    });
    return result;
  }

  openGoogle() {
    window.parent.postMessage(
      JSON.stringify({
        event: "redirect",
        payload: { target: environment.apiUrl + "/auth/google/" },
      }),
      "*"
    );
  }

  openMS() {
    window.parent.postMessage(
      JSON.stringify({
        event: "redirect",
        payload: { target: environment.apiUrl + "/auth/microsoft/" },
      }),
      "*"
    );
  }

  redirect() {
    const prod = environment.production;
    window.parent.postMessage(
      JSON.stringify({
        event: "open-modal",
        payload: { target: "signin-modal" },
      }),
      "*"
    );
    if (prod) {
      window.location.href = "https://myviewboard.com/clips/signin";
    } else {
      window.location.href = "https://stage.myviewboard.com/clips/signin";
    }
  }
}
