<div id="wrap" fxLayout="row" fxLayoutAlign="center center">
  <table class="table" mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef [translate]="'Entity.name'">
        Name
      </th>
      <td mat-cell *matCellDef="let data" class="name">
        <span> {{ data.name }}</span>
      </td>
    </ng-container>
    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef [translate]="'Link'">Link</th>
      <td mat-cell *matCellDef="let data">
        <a [href]="data.link" _target="blank" rel="noreferrer noopener">{{
          data.link
        }}</a>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
