import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AppState } from '@_services/app.service';
import { Message } from 'primeng/primeng';

@Component({
  selector: 'app-coupon-message',
  templateUrl: './coupon-message.component.html',
  styleUrls: ['./coupon-message.component.scss']
})
export class CouponMessageComponent implements OnInit {
  msgs: Message[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appstate: AppState,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if (params['code'] == 200) {
        this.msgs.push({severity: 'success', summary: 'Success', detail: params['message']});
      } else {
        this.msgs.push({severity: 'error', summary: 'Error', detail: params['message']});
      }
    });
  }

}
