import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { AccountService } from "app/_services/account.service";
import { ClipsSignupService } from "../clips.service";
import { CountryService } from "app/_services/CountryService";
import { Observable, Subject } from "rxjs";
import { startWith, map, debounceTime } from "rxjs/operators";
import { Message } from "primeng/primeng";
import { AppState } from "@_services";
import { SweetAlertService } from "ng2-sweetalert2";
@Component({
  selector: "app-step2",
  templateUrl: "./step2.component.html",
  styleUrls: ["./step2.component.scss"],
  providers: [CountryService, SweetAlertService, AccountService],
  encapsulation: ViewEncapsulation.None,
})
export class Step2Component implements OnInit {
  @Output() formEvent = new EventEmitter();
  education: string;
  place: string;
  job: string;
  username: string;
  msgs: Message[] = [];
  showOtherJobInput = false;
  correctCountryName = false;
  newList = [];
  subjectList = [];
  ClipsEducations = [
    // use clips for
    { name: "Teaching", value: "teaching" },
    { name: "Lesson plan building", value: "plan building" },
    { name: "Learning ", value: "learning" },
    { name: "Entertainment", value: "entertainment" },
    { name: "Inspiration", value: "inspiration" },
  ];

  EduSize = [
    { name: "Below 50", value: "below50" },
    { name: "50 - 100", value: "50to100" },
    { name: "100 and above", value: "100up" },
  ];

  ClipsJobs = [
    // I am a
    { name: "Teacher", value: "teacher" },
    { name: "Curriculum Coordinator", value: "cordinator" },
    { name: "Tutor", value: "tutor" },
    { name: "Parent ", value: "parent" },
    { name: "Student", value: "student" },
  ];

  ClipsPlaces = [
    { name: "Kindergarten", value: "kindergarten" },
    { name: "Elementary/ Primary School", value: "elementary" },
    { name: "Middle/ Secondary School", value: "middle" },
    { name: "High School", value: "high school" },
    { name: "Higher Eduation", value: "higher education" },
    { name: "Other", value: "other" },
  ];

  locations = [];
  Step2Form: FormGroup;
  step1Data: any;
  countdown = 5;
  model: any;
  LocationOptions: Observable<any[]>;
  locattionSubject = new Subject<string>();
  constructor(
    public accountService: AccountService,
    private fb: FormBuilder,
    private countryService: CountryService,
    public appstate: AppState,
    private swalService: SweetAlertService,
    private clipsService: ClipsSignupService
  ) {
    this.clipsService.receiveStep1Info().subscribe((data) => {
      this.username = data.fullname;
      this.step1Data = data;
    });
  }

  ngOnInit() {
    this.getList();
    this.Step2Form = this.fb.group({
      EntityType: new FormControl("", Validators.required),
      Role: new FormControl("", Validators.required),
      EduOrg: new FormControl("", Validators.required),
      Location: new FormControl("", Validators.required),
      Size: new FormControl("", Validators.required),
      Subject: new FormControl("", Validators.required),
    });

    this.locattionSubject.pipe(debounceTime(500)).subscribe((value) => {
      this.checkIfValid(value);
    });
    this.getLocations();
  }

  search(serachParam: string) {
    this.locattionSubject.next(serachParam);
  }

  checkIfValid(value) {
    if (this.newList.includes(value)) {
      this.correctCountryName = false;
    } else {
      this.Step2Form.controls["Location"].setErrors({ incorrect: true });
      this.correctCountryName = true;
    }
  }

  getList() {
    this.accountService.getSubjectList().subscribe((res) => {
      res["_embedded"]["subjects"].forEach((x) => {
        const f = { name: x.name, id: x.id };
        this.subjectList.push(f);
      });
    });
  }

  switchForm($event) {
    const value = $event.value;
    const form = this.Step2Form;
    this.showOtherJobInput = false;
    delete form.controls["otherRole"];
    delete form.controls["EduOrg"];
    form.patchValue({
      Location: "",
      Size: "",
      Role: "",
      otherRole: "",
      EduOrg: "",
    });
    if (value === "enterprise") {
      delete form.controls["otherRole"];
      form.addControl("Size", new FormControl("", Validators.required));
    }
    if (value === "public sector") {
      delete form.controls["Size"];
      delete form.controls["otherRole"];
    }
    if (value === "sports related") {
      delete form.controls["Size"];
      delete form.controls["otherRole"];
    }
    if (value === "education") {
      delete form.controls["otherRole"];
      form.addControl("Size", new FormControl("", Validators.required));
      form.addControl("EduOrg", new FormControl("", Validators.required));
    }
  }

  customJob($event) {
    const value = $event.value;
    const form = this.Step2Form;
    if (value === "other") {
      this.showOtherJobInput = true;
      form.addControl("otherRole", new FormControl("", Validators.required));
    } else {
      this.showOtherJobInput = false;
      delete form.controls["otherRole"];
    }
  }

  private getLocations() {
    this.countryService
      .getData(null)(null)
      .subscribe((data) => {
        this.locations = data;
        console.log("locations", data);
        this.LocationOptions = this.Step2Form.get(
          "Location"
        )!.valueChanges.pipe(
          startWith(""),
          map((value) => this._filterLocation(value))
        );
        data.forEach((i) => {
          this.newList.push(i.country_name);
        });
      });
  }

  private _filterLocation(value: any) {
    if (!value) return this.locations;
    const lowerCaseValue = value.toLowerCase();
    return this.locations.filter((x) =>
      x.country_name.toLowerCase().includes(lowerCaseValue)
    );
  }

  sendInfo() {
    const step1Data = this.step1Data;
    const info = {
      name: step1Data.first_name + " " + step1Data.last_name,
      password: step1Data.password,
      email: step1Data.email,
      last_name: step1Data.last_name,
      first_name: step1Data.first_name,
      questionnaire: {
        topic: "default-profile",
        meta_data: this.Step2Form.value,
      },
    };
    this.accountService.signupClipsAccount(info).subscribe(
      () => {
        this.formEvent.emit(2);
      },
      () => {
        this.swalService.swal({
          title: "Oops...",
          text: "Something went wrong, please try again",
          type: "warning",
          buttonsStyling: false,
          showCancelButton: false,
          confirmButtonClass: "btn btn-primary",
          confirmButtonText: "Confirm",
        });
      }
    );
  }
}
