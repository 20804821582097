<form [formGroup]="MVB" (ngSubmit)="sendTicket()" #f="ngForm">
  <div class="radiowrap">
    <div class="title" [translate]="'Support.Ticket_Heading'">What issue are you facing?</div>
    <div class="wrap-content" [dir]="languageService.dir | async">
      <div class="selects">
        <div class="browser">
          <b [translate]="'Support.Browser_Type'">Browser type</b>
          <mat-error class="msgwarning" *ngIf="MVB.controls['browser'].invalid && f.submitted"
            [translate]="'Support.Error_Browser'">Please choose a browser</mat-error>
            <mat-error class="msgwarning" *ngIf="MVB.controls['browser'].valid && MVB.controls['browserInput'].invalid && f.submitted"
            >Please tell us the version</mat-error>
          <mat-radio-group class="example-radio-group" formControlName="browser">
            <div class="browser-wrap">
              <mat-radio-button value="Edge" (change)="onChangeBrowser($event.value)"></mat-radio-button><span>Edge Version:</span> <input formControlName="browserInput"
                type="text" class="version-input" *ngIf="browserValue === 'Edge'">
            </div>
            <div class="browser-wrap">
              <mat-radio-button value="Chrome" (change)="onChangeBrowser($event.value)"></mat-radio-button><span>Chrome Version:</span> <input formControlName="browserInput"
                type="text" class="version-input" *ngIf="browserValue === 'Chrome'">
            </div>
            <div class="browser-wrap">
              <mat-radio-button value="Firefox" (change)="onChangeBrowser($event.value)"></mat-radio-button><span>Firefox Version:</span><input formControlName="browserInput"
                type="text" class="version-input" *ngIf="browserValue === 'Firefox'">
            </div>
          </mat-radio-group>
        </div>
        <div class="ticket-title">
          <b *ngIf="(currentLang | async) !== 'ja'" [translate]="'Support.Issue_Type'">Issue type for<b class="title-red">&nbsp;myViewBoard ClassRoom</b></b>
          <b *ngIf="(currentLang | async) === 'ja'" > <span class="title-red"> myViewBoard ClassRoom</span> <span [translate]="'Support.Issue_Type'">&nbsp; Issue type for</span></b>
          <mat-error class="msgwarning" *ngIf="MVB.controls['title'].invalid && f.submitted"
            [translate]="'Support.Error_Issue'">Please choose your issue type</mat-error>
          <mat-radio-group class="example-radio-group" formControlName="title">
            <mat-radio-button value="I can't sign in'">{{'Support.class_options.Cannot_Signin' | translate}}</mat-radio-button>
            <mat-radio-button value="Student can't join the session">{{'Support.class_options.Student_Cant_Join_Session' | translate}}</mat-radio-button>
            <mat-radio-button value="I can't record my session">{{'Support.class_options.Cant_Record_Session' | translate}}</mat-radio-button>
            <mat-radio-button value="How do I integrate with Google Classroom">{{'Support.class_options.Integrate_Google_Classroom' | translate}}</mat-radio-button>
            <mat-radio-button value="Live streaming problem">{{'Support.class_options.Live_Streaming' | translate}}</mat-radio-button>
            <mat-radio-button value="Others">{{'Support.class_options.Others' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <app-fileupload #fileupload formGroupName="FileGroup"></app-fileupload>
    </div>
  </div>
  <div class="btn-wrap">
    <div class="btns">
       <button class="sendbtn" [class.spinner]="loading" [disabled]="loading"
        [ngClass]="{'btn-loading':loading === true}" [translate]="'Support.Send'" type="submit">Send</button>
      <button class="cancelbtn" [disabled]="loading" [ngClass]="{'btn-loading':loading === true}"
        [mat-dialog-close]="true" [translate]="'Support.Cancel'">Cancel</button>
    </div>
  </div>
</form>