  <div class="content">
    <app-clips-customstep #stepper linear>
      <cdk-step #step1="cdkStep" [stepControl]="Step1Form">
      <!-- <app-step3 ></app-step3> -->
          <!-- <app-step2 (formEvent)="onFormSuccess()"></app-step2> -->
        <app-step1></app-step1>
      </cdk-step>
      <cdk-step #step2="cdkStep" [stepControl]="Step2Form">
        <app-step2 (formEvent)="onFormSuccess()"></app-step2>
      </cdk-step>
      <cdk-step #step3="cdkStep">
        <app-step3 ></app-step3>
      </cdk-step>
    </app-clips-customstep>
  </div>
