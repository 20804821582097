import { Component, OnInit, ViewChild } from "@angular/core";
import { AccountService } from "app/_services/account.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material";
import { SubmitSuccessComponent } from "../submit-success/submit-success.component";
import { SweetAlertService } from "ng2-sweetalert2";
import { AppState } from "@_services";
import { LanguageService } from "@_services/language.service";
import { FileuploadComponent } from "../fileupload/fileupload.component";
import { Observable } from "rxjs";
@Component({
  selector: "app-myviewboard",
  templateUrl: "./myviewboard.component.html",
  styleUrls: ["./myviewboard.component.scss"],
  providers: [SweetAlertService],
})
export class MyviewboardComponent implements OnInit {
  @ViewChild("fileupload") file: FileuploadComponent;
  openUpload = false;
  MVB: FormGroup;
  loading = false;
  options = ["Edge", "Chrome", "Firefox"];
  mvb_options = [
    "How do I import a PDF lesson?",
    "Video & Audio issue",
    "Huddle",
    "Cloud integration",
    "How to allow/disable students to draw/speak?",
    "Others (describe your problem)",
  ];
  browserValue: string;
  currentLang: Observable<string>;
  constructor(
    public accountService: AccountService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MyviewboardComponent>,
    public dialog: MatDialog,
    private swalService: SweetAlertService,
    private appState: AppState,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.MVB = this.fb.group({
      browserInput: new FormControl("", [Validators.required]),
      title: new FormControl("", [Validators.required]),
      browser: new FormControl("", [Validators.required]),
      FileGroup: this.fb.group({
        Description: new FormControl("", [Validators.required]),
        FlagCtrl: new FormControl(false),
      }),
    });
    this.currentLang = this.languageService.loadCurrentLang();
  }

  onChangeBrowser(value) {
    this.browserValue = value;
    this.MVB.controls["browserInput"].patchValue("");
  }

  errorPop() {
    this.swalService.swal({
      title: "Error",
      text: "Something went wrong, please try again",
      type: "error",
      buttonsStyling: false,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonClass: "btn btn-primary  mr-4",
      confirmButtonText: "Confirm",
    });
  }

  sendTicket() {
    this.file.isClicked = true;
    if (!this.MVB.valid) return false;
    const Version = this.MVB.controls["browserInput"].value;
    const text = this.file.FileGroup.controls["Description"].value;
    const browser = this.MVB.controls["browser"].value;
    const problem = this.MVB.controls["title"].value;
    this.loading = true;
    const ticket = {
      product: "mvb-0004",
      category: "myViewBoard Classroom",
      title: problem,
      description:
        "Browser :" + browser + ": " + Version + "\n" + "Description: " + text,
      screenshots: this.file.keylist,
    };
    // console.log('send ticket', ticket)
    this.accountService.d365SendCase(ticket).subscribe(
      () => {
        if (this.appState._state["ticket-cases"]) {
          delete this.appState._state["ticket-cases"];
        }
        this.loading = false;
        this.dialogRef.close();
        this.dialog.open(SubmitSuccessComponent, {
          width: "400px",
          minWidth: "310px",
          height: "auto",
        });
      },
      () => {
        this.loading = false;
        this.errorPop();
      }
    );
  }
}
