import { OnInit, Component } from "@angular/core";
import { AppState } from "./_services/app.service";
import { AccountService } from "./_services/account.service";
import { AppInsightsService } from "@markpieszak/ng-application-insights";
import { ActivatedRoute, Router } from "@angular/router";
import { LogoutService } from "@_services/logout.service";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "@environment";

@Component({
  // tslint:disable-next-line
  selector: "body",
  template: "<router-outlet></router-outlet>",
})
export class AppComponent implements OnInit {
  private appInSightsService: AppInsightsService;

  constructor(
    private appInsightsService: AppInsightsService,
    private appstate: AppState,
    private logoutService: LogoutService,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    let sessionToken;
    (<any>window).setSession = (string) => {
      sessionToken = string;
      this.accountService.loginWithToken(sessionToken).subscribe((data) => {
        const returnUrl =
          this.route.snapshot.queryParams["returnurl"] || this.router.url;
        const support_ticket =
          this.route.snapshot.queryParams["support_ticket"];
        return this.router.navigate([
          returnUrl,
          { queryParams: { support_ticket: support_ticket } },
        ]);
      });
    };

    window.addEventListener("message", function (message) {
      if (message.origin !== window.location.origin) {
        return;
      }
      if (message.data === "mvb-ext-log-out") {
        window.location.reload(true);
      }
      if (message.data === "mvb-ext-log-in") {
        window.location.reload(true);
        if (window.location.pathname === "/") {
          window.location.href = "/home";
        }
      }
    });

    if (window["ExternalObject"]) {
      try {
        if (typeof window["ExternalObject"].getSession === "function") {
          window["ExternalObject"].getSession();
        } else {
          window["ExternalObject"].getSession().then((<any>window).setSession);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
}
