
import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { LanguageService } from '@_services/language.service';

export const CHECKBOX_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => EULAComponent),
  multi: true
};

@Component({
  selector: 'app-eula-confirm',
  templateUrl: './EULA-agreement.html',
})
export class EULAComponent implements OnInit, ControlValueAccessor {
  @Input() checked = false
  get _checked() {
    return this.checked
  }
  set _checked(value) {
    this.checked = value
  }
  @Input() disabled = false
  @Output() onCheck: EventEmitter<any> = new EventEmitter();
  constructor( public languageService: LanguageService ) {}
  ngOnInit(): void {
  }
  onChange(event) {
    console.log(`-----------------this, onchange -------`, event)
    this.checked = this._checked
    this.onCheck.emit({
      checked: this._checked
    })
  }
  writeValue(obj: any): void {
    this._checked = obj
    this.checked = obj
  }
  registerOnChange(fn: any): void {
  }
  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}