import {NgModule} from '@angular/core';
import { DebounceClickDirective } from './click-debounce.directive';
import { DebounceInputDirective } from './input-debounce.directive';
import { DebounceFunctionDirective } from './event-debounce.directive';



@NgModule({
    declarations: [
        DebounceClickDirective,
        DebounceInputDirective,
        DebounceFunctionDirective
    ],
    exports: [
        DebounceClickDirective,
        DebounceInputDirective,
        DebounceFunctionDirective
    ]
})
export class DebounceModule {}

