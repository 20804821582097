<form [formGroup]="MVB" (ngSubmit)="sendTicket()" #f="ngForm">
  <div class="radiowrap" [dir]="languageService.dir | async">
    <div class="title"  [translate]="'Support.Ticket_Heading'">What issue are you facing?</div>
    <div class="switch" *ngIf="!select_os">
      <div class="version select-item">
        <div class="select-name" [translate]="'Support.Choose_OS'">Please choose an operating system</div>
        <select required (change)="selectOS($event)">
          <option value="" style="text-align: center">-Select-</option>
          <option value="Windows">Windows</option>
          <option value="Android">Android</option>
        </select>
      </div>
    </div>
    <div class="wrap-content" *ngIf="select_os" >
      <b *ngIf="currentLang !== 'ja'" [translate]="'Support.Issue_Type'">Issue type for<b class="title-red">&nbsp;myViewBoard Display for {{select_os}}</b></b>
      <b *ngIf="currentLang === 'ja'"><span class="title-red"> myViewBoard Display</span> <span [translate]="'Support.Issue_Type'">&nbsp; Issue type for</span></b>
      <div class="input-wrap">
        <span [translate]="'Support.Display_Version'">myViewBoard Display Version:</span>
        <div>
         <select
              formControlName="Version"
              class="select-width"
              required
              [value]="default"
            >
              <option
                *ngFor="let version of Versions"
                value="{{ version }}"
              >
                {{ version }}
              </option>
            </select>
        </div>
      </div>
      <mat-error class="msgwarning" style="padding-top:3px;" *ngIf="MVB.controls['Version'].invalid && f.submitted">
        Please enter Android Version
      </mat-error>
      <div class="input-wrap">
        <span [translate]="'Support.DM_Questions.MVB_Model_Number'">ViewBoard Model Number:</span>
        <div class="select">
          <select formControlName="Model">
            <option *ngFor="let model of IFP_Model" value="{{ model }}">
              {{ model }}
            </option>
          </select>
          <mat-error class="msgwarning" *ngIf="MVB.controls['Model'].invalid && f.submitted"
            [translate]="'Support.select_error'" style="padding-top:3px;">Please select an option
          </mat-error>
        </div>
      </div>
      <div style="margin: 10px auto" *ngIf="select_os !== 'Windows'" [translate]="'Support.DM_Questions.MVB_Android_Build_Number'">
        ViewBoard Android Build Number (optional):
        <input formControlName="AndroidBuildNumber" type="text" class="optional" style="width: 100%">
      </div>
      <div class="input-wrap"><span [translate]="'Support.Display_Questions.Other_IFP_Number'">Other IFP Model Number: </span>
        <input formControlName="OtherIfpNumber" type="text">
      </div>
      <div class="title-area">
        <div class="title-header" [translate]="'Support.Title'">Title</div>
        <mat-error class="msgwarning" *ngIf="MVB.controls['title'].invalid && f.submitted"
          [translate]="'Support.Error_Title'">Please name a title for this ticket
        </mat-error>
        <input class="title-input" formControlName="title" placeholder="{{ ('Support.Title_placeholder') | translate }}"
          maxlength="60" [ngClass]="{'bidi_dir': (currentLang | async) === 'ar'}" required>
      </div>
      <app-fileupload #fileupload formGroupName="FileGroup" [display_windows]="display_windows"></app-fileupload>
    </div>
  </div>
  <div class="btn-wrap" *ngIf="select_os">
    <div class="btns">
      <button class="sendbtn" [class.spinner]="loading" [disabled]="loading"
        [ngClass]="{'btn-loading':loading === true}" [translate]="'Support.Send'" type="submit">Send</button>
      <button class="cancelbtn" [disabled]="loading" [ngClass]="{'btn-loading':loading === true}"
        [mat-dialog-close]="true" [translate]="'Support.Cancel'">Cancel</button>
    </div>
  </div>
</form>
