import { PermissionGuard } from "./_guards/permission.guard";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SigninRedirectComponent } from "./pages/signin-redirect/signin-redirect.component";
import { AuthGuard } from "./_guards/auth.guard";
import { EntityGuard } from "./_guards/entity.guard";
import { FullLayoutComponent } from "./layouts/full-layout.component";
import { SimpleLayoutComponent } from "./layouts/simple-layout.component";
import { PasswordUpdateComponent } from "./account/password-update/password-update.component";
import { RegisterCouponComponent } from "./pages/coupoen-register/register-coupon.component";
import { SessionListComponent } from "./session-list/session-list.component";
import { ZoomIntegrationComponent } from "./zoom-integration/zoom-integration.component";
import { BandwidthCalculatorComponent } from "./pages/bandwidth-calculator/bandwidth-calculator.component";
import { PollingQuizComponent } from "./entity/entity-public/polling-quiz/polling-quiz.component";
import { PublicPortalGuard } from "./_guards/public-portal.guard";
import { ClipsLayoutComponent } from "./layouts/clips-layout.component";
import { EntityRedirectGuard } from "./_guards/entity-redirect.guard";
import { P302Component } from "./pages/302.component";
import { LanguageGuard } from "./_guards/language.guard";
import { ExternalEnvGuard } from "./_guards/enternalEnv.guard";
import { PersonalSubscriptionComponent } from "./pages/personal-subscription/personal-subscription.component";
import { InternalComponent } from "./internal/internal.component";
import { ClipsComponent } from "./clips/clips.component";

export const routes: Routes = [
  {
    path: "lacounty",
    loadChildren: "./portal/la-county/la-county.module#LaCountyModule",
  },
  { path: "usc", loadChildren: "./portal/usc-edu/usc-edu.module#UscEduModule" },
  {
    path: "gm",
    loadChildren:
      "./portal/general-motors/general-motors.module#GeneralMotorsModule",
  },
  {
    path: "remote-transfer",
    loadChildren:
      "./remote-transfer/remote-transfer.module#RemoteTransferModule",
  },
  {
    path: "storage", // loads page doesn't need login
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: "storage",
    },
    children: [
      {
        path: ":dir_id",
        loadChildren: "./storage/storage.module#StorageModule",
      },
    ],
  },
  {
    path: "signin", // login with Email
    component: SigninRedirectComponent
  },
  {
    path: "", // loads page doesn't need login
    component: SimpleLayoutComponent,
    canActivate: [LanguageGuard],
    children: [
      {
        path: "",
        loadChildren: "./pages/pages.module#PagesModule",
      },
    ],
  },
  {
    path: "", // loads page doesn't need login
    component: ClipsLayoutComponent,
    data: {
      title: "Clips Signup",
    },
    children: [
      {
        path: "",
        loadChildren:
          "./pages/clips-signup/clips-signup.module#ClipsSignupModule",
      },
    ],
  },
  {
    path: "training",
    component: FullLayoutComponent,
    data: {
      title: "Training",
    },
    children: [
      {
        path: "",
        loadChildren: "./training/training.module#TrainingModule",
      },
    ],
  },
  {
    path: "remote-client",
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Remote",
    },
    children: [
      {
        path: "",
        loadChildren: "./remote/remote.module#RemoteModule",
      },
    ],
  },
  {
    path: "meet",
    component: FullLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      title: "Meet",
    },
    children: [
      {
        path: "",
        loadChildren: "./meet/meet.module#MeetModule",
      },
    ],
  },
  {
    path: "polling-quiz",
    component: PollingQuizComponent,
  },
  {
    path: "",
    component: FullLayoutComponent,
    data: {
      title: "Home",
    },
    children: [
      {
        path: "viewboard/setting/auth-service/zoom",
        component: ZoomIntegrationComponent,
      },
      {
        path: "account/session-list",
        component: SessionListComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "integration/zoom",
        component: ZoomIntegrationComponent,
        canActivate: [AuthGuard],
      },
      {
        path: "customer/management",
        loadChildren: "./coupon/coupon.module#CouponModule",
        canActivate: [AuthGuard],
        data: {
          title: "Issue Coupon",
        },
      },
      {
        path: "coupon/issue",
        loadChildren: "./coupon/coupon.module#CouponModule",
        canActivate: [AuthGuard],
        data: {
          title: "Issue Coupon",
        },
      },
      {
        path: "coupon/register",
        component: RegisterCouponComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Register Coupon",
        },
      },
      {
        path: "customer/management",
        loadChildren: "./admin/admin.module#AdminModule",
        canActivate: [AuthGuard],
        data: {
          title: "Entity Request",
        },
      },
      {
        path: "fileconversion",
        loadChildren:
          "./file-convert-request/file-convert-request.module#FileConvertRequestModule",
        canActivate: [AuthGuard],
        data: {
          title: "File Convert",
        },
      },
      {
        path: "home", // landing when user has login
        loadChildren: "./entity/entity.module#EntityModule",
        // component: EntityComponent,
        canActivate: [AuthGuard, ExternalEnvGuard],
      },
      {
        path: "entity",
        // component: EntityManagerComponent,src/app/entity/entity-manager/entity-manager.module.ts
        loadChildren: "./entity/management/module#EntityManagerModule",
        canActivate: [AuthGuard, PermissionGuard],
        data: {
          title: "",
          tag: ["entity"],
        },
      },
      {
        path: "main-support", // register with Email
        loadChildren:
          "./pages/mvb-support-ticket/mvb-support-ticket.module#MvbSupportTicketModule",
        canActivate: [AuthGuard],
        // component:MvbSupportComponent,
        data: {
          title: "Main Support",
        },
      },
      {
        path: "viewboard/setting",
        loadChildren:
          "./config-setting/config-setting.module#ConfigSettingModule",
        canActivate: [AuthGuard],
        data: {
          index: 0,
          title: "VBoard Setting",
        },
      },
      {
        path: "account/auth-service", // 3rd cloud service (storage) management
        redirectTo: "viewboard/setting/auth-service",
      },
      {
        path: "account", // edit account
        loadChildren: "./account/update/update.module#UpdateModule",
        canActivate: [AuthGuard],
        data: {
          title: "Account Edit",
        },
      },
      {
        path: "account/password", // Change Password
        component: PasswordUpdateComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Change Password",
        },
      },
      {
        path: "bandwidth-calculator",
        component: BandwidthCalculatorComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Bandwith Calculator",
        },
      },
      {
        path: "my-subscription",
        component: PersonalSubscriptionComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Personal Subscriptiton",
        },
      },
      {
        path: "internal-use",
        component: InternalComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Internal use",
        },
      },
      {
        path: "clips",
        component: ClipsComponent,
        canActivate: [AuthGuard],
        data: {
          title: "Clips",
        },
      },
    ],
  },
  {
    path: "manager",
    canActivate: [EntityRedirectGuard],
    component: P302Component,
  },
  {
    path: "**",
    canActivate: [PublicPortalGuard],
    loadChildren:
      "./entity/entity-public/entity-public.module#EntityPublicModule",
    data: { bypass: true },
  },
  {
    path: "classroom",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
