<div class="mh-100" style="width: 1100px; margin: 0 auto">
  <div class="row justify-content-center h-100 align-items-center">
    <h1
      class="p-md-4"
      [translate]="'Coupon.Issue_Customer_Coupon'"
      [translateParams]="{value: issue_type}"
    >
      Issue Subscription Coupon
    </h1>
    <div class="card col-12">
      <div class="card-block text-left">
        <div class="card-title text-center">
          <!-- <p-messages [(value)]="msgs"></p-messages> -->
          <mvb-message [(value)]="msgs"></mvb-message>
        </div>
        <div
          *ngIf="status==CREATE_MODE"
          class="row d-flex justify-content-center"
        >
          <ng-container *ngTemplateOutlet="issue_page"> </ng-container>
        </div>
        <div
          *ngIf="status==LIST_MODE"
          class="row d-flex justify-content-center"
        >
          <ng-container *ngTemplateOutlet="list_page"> </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #list_page>
  <div class="col-12">
    <p-dataTable
      [value]="this.IssueHistory"
      [rows]="10"
      [paginator]="true"
      [globalFilter]="gb"
      sortMode="single"
    >
      <p-header>
        <ng-container *ngTemplateOutlet="option_bar"> </ng-container>
      </p-header>
      <p-column field="applicant_email" header="{{'Coupon.Email' | translate}}">
        <ng-template let-item="rowData" pTemplate="body">
          {{item.applicant_email}}
          <div>
            <span class="badge btn-outline-primary btn-sm small">
              {{item.coupon.name}}
            </span>
            <span
              *ngIf="item.register_time"
              class="badge badge-success"
              [translate]="'Coupon.Send_Success'"
            >
              Success !
            </span>
            <span
              *ngIf="!item.distribute_time"
              class="badge badge-default"
              [translate]="'Coupon.Send_NEW'"
            >
              <i class="fa"></i> New
            </span>
            <span
              *ngIf="item.distribute_time&&!item.register_time"
              class="badge badge-warning"
              [translate]="'Coupon.Send_WAIT_USER'"
            >
              <i class="fa"></i> wait customer
            </span>
          </div>
          <div>
            <span
              pTooltip="created by"
              class="badge btn-outline-primary btn-sm small"
            >
              {{item.distributor.email}}
            </span>
          </div>
          <!-- pTooltip="{{'Profile.Enabled' | translate}}" -->
        </ng-template>
      </p-column>
      <p-column
        field="distribute_time"
        header="{{'Coupon.Validated' | translate}}"
        [style]="{'width':'185px'}"
      >
        <ng-template let-item="rowData" pTemplate="body">
          {{item.distribute_time | date:'yyyy-MM-dd'}}
          <button
            *ngIf="!item.distribute_time"
            type="button"
            class="btn btn-outline-primary btn-sm small"
            style="font-size: 0.75em"
            (click)="sendAgain(item)"
            [innerHTML]="'Coupon.Send_Again' | translate"
          >
            <i class="fa fa-envelope mx-2"></i>Send Again <br />
            To Your ViewSonic Email
          </button>
        </ng-template>
      </p-column>
      <!-- <p-column field="isRegister" header="{{'Coupon.Status' | translate}}">
        <ng-template let-item="rowData" pTemplate="body">
          <span *ngIf="item.isRegister" class="badge badge-success" [translate]="'Coupon.Send_Success'">
            <i class="fa fa-thumbs-up"></i> Success !
          </span>
        </ng-template>
      </p-column> -->
      <p-column
        field="trial_limit_time"
        header="{{'Coupon.Expire' | translate}}"
        [style]="{'width':'160px'}"
      >
        <ng-template let-item="rowData" pTemplate="body">
          <div *ngIf="item.user_subscription">
            {{item.user_subscription.trial_limit_time | date:'yyyy-MM-dd'}}
          </div>
        </ng-template>
      </p-column>

      <!-- <p-column styleClass="col-button" [style]="{'width':'90px'}">
                 <ng-template let-item="rowData" pTemplate="body">

                   <button *ngIf="!item.isRegister" type="button" class="btn btn-outline-primary btn-sm"
                           (click)="sendAgain(item)"
                           icon="fa-search">Send Again
                   </button>
                 </ng-template>
               </p-column>-->
    </p-dataTable>
  </div>
</ng-template>

<ng-template #option_bar>
  <div class="ui-widget-header" style="padding: 4px 0px; border-bottom: 0 none">
    <div class="d-flex justify-content-between align-items-center w-100">
      <div>
        <span>Coupon Selection: </span>
        <mat-form-field>
          <mat-label *ngIf="!option.select_coupon_id"
            >Select a coupon</mat-label
          >
          <mat-select
            [(ngModel)]="option.select_coupon_id"
            (selectionChange)="this.getIssueHistory()"
          >
            <mat-option [value]=""> All </mat-option>
            <mat-option *ngFor="let coupon of coupon_data" [value]="coupon.id">
              {{coupon.name}}
              <span *ngIf="!coupon.publish" class="badge badge-danger"
                >Private</span
              >
              <span
                *ngIf="coupon.publish && isEnd(coupon.action_end_time)"
                class="badge badge-danger"
                >Expire</span
              >
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button class="btn btn-primary btn-xs" (click)="status=CREATE_MODE">
        Create Coupon
      </button>
    </div>
    <div class="d-flex justify-content-between align-items-center w-100">
      <div class="pr-4">
        <button class="mx-2" (click)="this.getIssueHistory()">
          <i class="fas fa-sync-alt"></i>
        </button>
      </div>
      <div>
        <span [translate]="'Coupon.Search'">Search Customer</span>
        <i class="fa fa-search mx-1"></i>
        <input
          inputDebounce
          type="text"
          pInputText
          size="50"
          placeholder="{{'Coupon.Find' | translate}}"
          [(ngModel)]="option.search"
          (debounceFunc)="getIssueHistory()"
          delay="1000"
        />
        <input
          type="checkbox"
          [(ngModel)]="option.only_me"
          (click)="option.only_me=!option.only_me;this.getIssueHistory()"
        /><span>Created by me</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #issue_page>
  <form
    name="form"
    [formGroup]="userform"
    (ngSubmit)="f.form.valid && onSubmit(userform.value)"
    #f="ngForm"
    novalidate
  >
    <div *ngIf="false" class="form-group">
      <label for="email_sales" [translate]="'Coupon.U_Email'">Your Email:</label
      ><br />
      <span class="badge badge-info">{{model.distributor}}</span>
    </div>
    <div class="row mb-2"></div>
    <div class="row">
      <div class="col-12">
        <span>Coupon Selection: </span>
        <mat-form-field>
          <mat-label
            *ngIf="!userform.controls['coupon_id'].valid"
            class="help-block text-danger"
            >Select One Coupon
          </mat-label>
          <mat-select formControlName="coupon_id">
            <mat-option
              *ngFor="let coupon of accept_coupon_data"
              [value]="coupon.id"
            >
              {{coupon.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div
      class="form-group"
      *ngFor="let applicant of this.emails; let key =index"
    >
      <div class="row">
        <div class="col-10">
          <input
            class="form-control input-applicant"
            pInputText
            type="email"
            [(ngModel)]="applicant.email"
            (keydown.enter)="applicantAdd($event)"
            accesskey="0"
            [formControlName]="applicant.name"
            size="50"
            placeholder="{{'Coupon.Required' | translate}}"
          />
          <div
            *ngIf="!userform.controls[applicant.name].valid"
            class=""
            [translate]="'Coupon.Issue_Coupon'"
          >
            <h6 class="text-danger" [translate]="'Coupon.Issue_Coupon'">
              Please enter a valid email to issue the coupon.
            </h6>
          </div>
        </div>
        <div class="col-2" *ngIf="key===0">
          <a
            class="btn btn-outline-primary"
            href="javascript:void(0)"
            (click)="applicantAdd($event)"
          >
            <i class="fa fa-user-plus"></i
          ></a>
        </div>
        <div class="col-2" *ngIf="key>0">
          <a
            class="btn btn-outline-primary"
            href="javascript:void(0)"
            (click)="applicantRemove(applicant)"
          >
            <i class="fa fa-user-times"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="form-group d-flex justify-content-between">
      <a style="width: 20px">
        <i class="fas fa-chevron-left" (click)="status=LIST_MODE"></i>
      </a>
      <button
        [disabled]="loading||!userform.valid"
        class="btn btn-primary"
        [translate]="'Coupon.Submit'"
      >
        Submit
      </button>
      <img
        *ngIf="loading"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
      />
    </div>
  </form>
</ng-template>
