import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { empty, from, Observable, pipe, throwError } from "rxjs";
import { mergeMap, tap, map, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { UserState } from "@_services/current/user";
import { OAuthService } from "angular-oauth2-oidc";

@Injectable()
export class HttpInterceptor implements HttpInterceptor {
  constructor(
    private userState: UserState,
    public oauthService: OAuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    request = this.getCurrentRequestPolicy(request);
    if (
      request.url.startsWith(environment.apiUrl) ||
      request.url.startsWith(environment.apiv2Url)
    ) {
      const access_token = this.oauthService.getAccessToken();
      let headersConfig = {
        "x-version": "1",
      };
      if (access_token) {
        headersConfig["Authorization"] = `bearer ${access_token}`;
      }
      request = request.clone({
        setHeaders: headersConfig,
        withCredentials: true,
      });
    }
    const originalProcess = pipe(
      map((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          // convert Object data to array
          //console.log(request.url,request.method, evt.body)
          if (evt.body && evt.body.total && evt.body.list) {
            return evt.clone({
              body: {
                rows: evt.body.list,
                count: evt.body.count,
              },
            });
          }
        }
        return evt;
      }),
      map((data: HttpEvent<any>) => {
        if (data instanceof HttpResponse) {
          const headers: HttpHeaders = data.headers;
          const expired = headers.get("x-sid-expired");
          if (
            expired &&
            new Date(expired).getTime() >
              new Date(this.userState.getExpireDate()).getTime()
          ) {
            this.userState.setExpireDate(expired);
          }
        }
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 503) {
          return Observable.throwError({ error: { messageCode: "503" } });
        }
        return Observable.throwError(err);
      })
    );
    return next
      .handle(request)
      .pipe(originalProcess)
      .catch((err: HttpErrorResponse) => {
        if (err.status === 400 && err.url == this.oauthService.tokenEndpoint) {
          this.oauthService.initLoginFlow();
        }
        if (err.status === 401) {
          return from(this.oauthService.refreshToken()).pipe(
            mergeMap((x) => {
              request = this.getCurrentRequestPolicy(request);
              return next.handle(request).pipe(originalProcess);
            })
          );
        }
        return Observable.throwError(err);
      });
  }
  getCurrentRequestPolicy(request: HttpRequest<any>) {
    const isAuthAPI =
      request.url.startsWith(environment.apiUrl) ||
      request.url.startsWith(environment.sensApiUrl);
    if (isAuthAPI) {
      let headersConfig = {};
      if (this.userState.isOIDCFlow()) {
        const access_token = this.oauthService.getAccessToken();
        headersConfig["x-version"] = `1`;
        if (access_token) {
          headersConfig["Authorization"] = `Bearer ${access_token}`;
        }
      }
      request = request.clone({
        setHeaders: headersConfig,
        withCredentials: true,
      });
    }
    return request;
  }
}
