import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Step1Component } from './step1/step1.component';
import { Step2Component } from './step2/step2.component';
import { Step3Component } from './step3/step3.component';
import { SharedModule, PrimengSharedModule } from '@shared';
import { MaterialModule } from '../../material/material.module'
import { ClipsSignupComponent, StepperIndex } from './clips-signup.component';
import { ClipsSignupRoutingModule } from './clips-signup-routing.module';
import { ClipsCustomstepComponent } from './clips-custom/clips-custom.component';

@NgModule({
  declarations: [Step1Component, Step2Component, Step3Component,
   StepperIndex, ClipsCustomstepComponent, ClipsSignupComponent],
  imports: [
    CommonModule,
    ClipsSignupRoutingModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    PrimengSharedModule,
  ]
})
export class ClipsSignupModule { }
