import {
  catchError,
  concatMap,
  distinctUntilChanged,
  map,
  tap,
} from "rxjs/operators";
import { Component, OnInit, ViewChild, Input, OnDestroy } from "@angular/core";
import { AccountService } from "../_services/account.service";
import { StorageService } from "../storage/service/storage.service";
import { AppState } from "../_services/app.service";
import { SweetAlertService } from "ng2-sweetalert2";
import { FileUpload, Message } from "primeng/primeng";
import { throwError } from "rxjs/internal/observable/throwError";
import { RemoteTransferHistoryComponent } from "./remote-transfer-history/remote-transfer-history.component";
import { CurrentUser, UserState } from "@_services/current/user";
import { LanguageService } from "@_services/language.service";
import { Observable } from "rxjs/Observable";
import { of } from "rxjs/index";
import { AppInsightsService } from "@markpieszak/ng-application-insights";
const uuidv1 = require("uuid/v1");
@Component({
  selector: "app-remote-transfer",
  templateUrl: "./remote-transfer.component.html",
  styleUrls: ["./remote-transfer.component.scss"],
})
export class RemoteTransferComponent implements OnInit {
  @ViewChild("userUpload") public userUpload: FileUpload;
  public _host_user;
  private _guest_user;

  @ViewChild("transferHistory")
  public transferHistory: RemoteTransferHistoryComponent;

  @Input() set host_user(host_user) {
    console.log("got host_user: ", host_user);
    this._host_user = host_user;
  }

  @Input() set guest_user(guest_user) {
    console.log("got guest_user: ", guest_user);
    this._guest_user = guest_user;
  }

  @Input() set password(password) {
    console.log("password", password);
    this.secureCode = password;
    this.updateUploadEnable();
  }

  get guest_user() {
    return this._guest_user;
  }

  request: any;
  target_user = "";
  host_unique = "";
  target_unique = "";
  subscribe = [];
  requests = new Map();
  upload_enable = false;
  uploading = false;
  user_alert = "";
  isEmbed = false;
  sender = "";
  fileList;
  msgs: Message[] = [];
  uploadProgress = 0;
  fileAcception;
  current_user = this.userstate.getUser();
  requireSecureCode = false;
  secureCode: string;
  secureCodePassed = false;
  sender_input: string;
  isLogin$: boolean;
  msgTranslate: {
    prop: string;
  };
  translateStrings: string[] = [
    "Remote_Transfer.Limit_10_Exceed",
    "Remote_Transfer.Type_Not_Allowed",
    "Remote_Transfer.Permission_Not_Allowed",
    "Remote_Transfer.Sending",
    "Remote_Transfer.Host_Not_Online",
    "Remote_Transfer.Has_Been_Sent",
    "Public_Portal.Send_Success",
  ];
  constructor(
    public languageService: LanguageService,
    public accountService: AccountService,
    public storageService: StorageService,
    public appstate: AppState,
    public swalService: SweetAlertService,
    public userstate: UserState,
    private appInsightsService: AppInsightsService
  ) {}

  ngOnInit() {
    this.isLogin$ = this.userstate.hasLogin();
    console.log("this.isLogin$", this.isLogin$);
    this.target_user = this._host_user || "";
    this.storageService.getSupportedFileTypes().subscribe((data: any) => {
      this.fileAcception = data.extensions;
    });

    if (window.location.hash) {
      this.isEmbed = true;
      this.target_user = window.location.hash.split("#").join("");
    }

    if (this.current_user) {
      this.sender = this.userstate.getCurrentUser().getUserFullName();
    }

    this.appstate.fileSubject.subscribe((fileList) => {
      if ((<any>fileList).length < 1) {
        return;
      }
      this.fileList = fileList;
      this.appstate.set("remote-transfer-dialog", true);
      this.msgs = [];
    });
    this.storageService.subscribeWindowMessage().subscribe((event) => {
      if (event.data.user !== this._guest_user) {
        return;
      }
      if (event.data.action === "throwImage") {
        const block = event.data.payload.split(";");
        const contentType = block[0].split(":")[1]; // In this case "image/png"
        const realData = block[1].split(",")[1]; // In this case "R0lGODlhPQBEAPeoAJosM...."
        const blob = b64toBlob(realData, contentType);
        const fileOfBlob = new File([blob], "Scratch");
        this.onUpload({ files: [fileOfBlob], type: "scratch" });
      }
    });
    this.loadMessage().subscribe();
  }

  loadMessage() {
    return this.languageService.get(this.translateStrings).pipe(
      tap((res) => {
        this.msgTranslate = res;
      })
    );
  }

  p_upload_click($event) {
    if (this.uploading) {
      return $event.preventDefault();
    }
    if ($event.target.tagName.toUpperCase() === "INPUT") {
      this.userUpload.progress = 0;
      this.userUpload.msgs = [];
      this.msgs = [];
    }
  }

  updateUploadEnable() {
    this.upload_enable = !!(this._host_user && this._guest_user);
    this.sender = this._guest_user;
    this.target_user = this._host_user;
    this.checkSecurePassword();
  }

  onHostRequireSecure() {
    this.requireSecureCode = true;
  }

  request_id_gen() {
    const uuid1 = uuidv1();
    return uuid1.toString();
  }

  cancelUpload() {
    this.userUpload.clear();
    this.request.unsubscribe();
    this.uploadProgress = 0;
    this.userUpload.progress = 0;
    this.upload_enable = true;
    this.uploading = false;
  }

  clearMessage() {
    this.msgs = [];
  }

  onHistoryMsg(item) {
    this.languageService.get("has been recall from").subscribe((translate) => {
      this.msgs = [];
      this.msgs.push({
        severity: "success",
        summary: `${item.name} ${translate} ${item.target_user_name}`,
        detail: "",
      });
    });
  }

  onHostPrivate() {
    this.languageService.get("HostIsPrivate").subscribe((translate) => {
      this.user_alert = translate;
    });
  }

  checkSecurePassword() {
    this.msgs = [];
    if (!this.target_user) {
      this.user_alert = "";
      this.requireSecureCode = false;
      this.upload_enable = false;
      this.target_unique = "";
      this.secureCode = "";
      this.host_unique = "";
      return;
    }
    if (!this.sender) {
      this.sender = this.sender_input;
    }
    this.msgs = [];
    const checkOnline = ({ isOnline, target_unique, unique }) => {
      console.log(isOnline);
      isOnline = true;
      if (!isOnline) {
        throw {
          error: {
            errorMessage: this.msgTranslate["Remote_Transfer.Host_Not_Online"],
          },
        };
      }
      return of({ target_unique, unique });
    };
    const checkUserPermission = ({ target_unique, unique }) => {
      return this.accountService
        .getHostPermission({
          accountId: unique,
          key: "THROW",
        })
        .pipe(
          map((data) => {
            const { list } = data;
            const hasThrow = list.find(
              (item) => item.name === "THROW" && item.value === 1
            );
            console.log(hasThrow);
            if (!hasThrow) {
              throw {
                error: {
                  errorMessage:
                    this.msgTranslate["Remote_Transfer.Permission_Not_Allowed"],
                },
              };
            }
          }),
          map(() => ({ target_unique, unique }))
        );
    };

    this.accountService
      .getUserByUserName(this.target_user, this.secureCode)
      .pipe(
        distinctUntilChanged(),
        concatMap(checkOnline),
        concatMap(checkUserPermission),
        catchError((err) => throwError(err))
      )
      .subscribe(
        (data) => {
          this.requireSecureCode = false;
          this.target_unique = data.target_unique;
          this.host_unique = data.unique;
          this.upload_enable = true;
        },
        ({ error }) => {
          this.upload_enable = false;
          this.user_alert = "";
          if (error.messageCode === 20001) {
            this.languageService
              .get("Public_Portal.Error_20001")
              .subscribe((translate) => {
                const [title, content] = translate.split(",");
                this.msgs.push({
                  severity: "error",
                  summary: title,
                  detail: content,
                });
              });
            return;
          }
          if (error.messageCode === 12329) {
            this.languageService
              .get("Public_Portal.Error_12329")
              .subscribe((translate) => {
                this.msgs.push({
                  severity: "error",
                  summary: translate,
                  detail: "",
                });
              });
            return;
          }
          if (error.status === 403) {
            if (error.messageCode === 12328) {
              this.onHostRequireSecure();
              return;
            }
            this.onHostPrivate();
          }
          if (error.status === 404) {
            this.languageService
              .get("hostcode_12308")
              .subscribe((translate) => {
                this.msgs.push({
                  severity: "error",
                  summary: translate,
                  detail: "",
                });
              });
            return;
          }
          console.log(error);
          this.msgs.push({
            severity: "error",
            summary: error.errorMessage,
            detail: "",
          });
        }
      );
  }

  onUpload($event) {
    console.log("onUpload", $event);
    const isScratch = $event.type === "scratch";
    this.msgs = [];
    let instance_id = "";
    let uploadProgress;
    if (!isScratch) {
      uploadProgress = this.fileList
        ? this.uploadProgress
        : (this.userUpload.progress = 0);
    }
    this.msgs = [];

    if ($event.files.length > 10) {
      this.userUpload.clear();
      return this.userUpload.msgs.push({
        severity: "error",
        summary: this.msgTranslate["Remote_Transfer.Limit_10_Exceed"],
        detail: "",
      });
    }

    if (!isScratch) {
      $event.files.map((file) => {
        const extname = file.name.split(".").pop().toLowerCase();
        if (this.fileAcception.indexOf(extname) === -1) {
          this.msgs = [];
          this.msgs.push({
            severity: "warn",
            summary:
              file.name +
              " " +
              this.msgTranslate["Remote_Transfer.Type_Not_Allowed"],
            detail: "",
          });
        } else if (this.fileAcception.indexOf(extname) !== -1) {
          this.msgs.push({
            severity: "success",
            summary: this.msgTranslate["Remote_Transfer.Sending"] + "...",
            detail: "",
          });
        }
      });
      $event.files = $event.files.filter(
        (file) =>
          this.fileAcception.indexOf(
            file.name.split(".").pop().toLowerCase()
          ) !== -1
      );
    }
    if ($event.files.length === 0) {
      return;
    }

    // POP-QUIZ
    this.request = this.accountService
      .getUserByUserName(this.target_user, this.secureCode)
      .pipe(
        concatMap((data: any) => {
          if (!data.isOnline) {
            this.upload_enable = false;
            this.user_alert =
              this.msgTranslate["Remote_Transfer.Host_Not_Online"]; // this.host_msg;
            throwError(this.user_alert);
          }
          instance_id = data.target_unique;
          if (isScratch) {
            return this.storageService.Popquiz(
              $event.files,
              data.unique,
              this.sender,
              this.isLogin$
            );
          } else {
            return this.storageService.throwFile(
              $event.files,
              data.unique,
              this.sender,
              this.isLogin$,
              isScratch
            );
          }
        })
      )
      .subscribe(
        (event) => {
          this.uploading = true;
          console.log("event", event);
          if (event["type"] === 1) {
            console.log("is scratch0");
            if (!isScratch) {
              uploadProgress = this.fileList
                ? this.uploadProgress
                : (this.uploadProgress = ~~(
                    (event["loaded"] / event["total"]) *
                    100
                  ));
            }
          }
          if (event["type"] === 0) {
            console.log("is scratch1");
            if (!isScratch) {
              uploadProgress = this.fileList
                ? this.uploadProgress
                : (this.uploadProgress = 0);
            }
          }
          if (event["type"] === 4) {
            const data = event["body"];
            data.file.is_guest = !this.current_user;
            data.file.fullname = this.sender;
            const senderId = this.current_user
              ? this.current_user.id
              : "00000000-0000-0000-0000-000000000000";
            const msg = {
              sender: senderId,
              subject: "file-import",
              action: "file-import",
              extra: {
                file: data.file,
                from: {
                  fullname: this.sender,
                  email: this.current_user ? this.current_user.email : "",
                },
                requestId: this.request_id_gen(),
              },
            };
            if (isScratch) {
              (msg.subject = "scratch-import"), (msg.action = "scratch-import");
              (<any>window).postMessage(
                {
                  subject: "scratch",
                  data: data.file.name + " has been sent",
                },
                window.location.origin
              );
              this.msgs = [];
            }
            this.requests.set(msg.extra.requestId, msg);
            this.storageService
              .throwFileNotify(instance_id, senderId, msg.extra, isScratch)
              .subscribe((data) => {
                console.log(data);
              });
            this.upload_enable = true;
            console.log(this.userUpload);

            if (!this.fileList && this.userUpload) {
              this.userUpload.clear();
            }

            this.languageService
              .get("Public_Portal.File_Has_Sent", { value: data.file.name })
              .subscribe((res) => {
                if (!isScratch) {
                  this.msgs = [];
                  this.msgs.push({
                    severity: "success",
                    summary: res,
                    detail: "",
                  });
                }
                if (this.current_user) {
                  this.transferHistory.getHistory();
                }
              });
            if (!isScratch) {
              this.appInsightsService.trackEvent("portal_throw", {
                category: "Participation",
                label: JSON.stringify({
                  host_url: location.href,
                  user: this.guest_user,
                }),
              });
            }
            if (isScratch) {
              this.appInsightsService.trackEvent("portal_pop_quiz", {
                category: "Participation",
                label: JSON.stringify({
                  host_url: location.href,
                  user: this.guest_user,
                }),
              });
            }
          }

          if (event["error"]) {
            this.msgs.push({
              severity: "error",
              summary: "upload error, {0}".replace(
                "{0}",
                event["error"].message
              ),
              detail: "",
            });
          }
        },
        (error) => {
          uploadProgress = this.fileList
            ? this.uploadProgress
            : (this.uploadProgress = 0);
          this.fileList = null;
          this.upload_enable = true;
          this.uploading = false;
          if (this.userUpload) {
            this.userUpload.clear();
          }
          this.msgs = [];
          console.log(this.msgs);
          this.languageService
            .get(`Public_Portal.Error_${error.error.messageCode}`)
            .subscribe((res) => {
              this.msgs.push({
                severity: "error",
                summary: res ? res : error.error.message,
                detail: "",
              });
            });
          window.postMessage(
            {
              subject: "transferErr",
              msg: this.msgs[this.msgs.length - 1],
            },
            window.location.origin
          );
        },
        () => {
          this.uploading = false;
          this.msgs[0].summary = this.msgs[0].summary;
          this.fileList = null;
        }
      );
  }

  onFileSelect($event) {
    console.log($event);
    $event.originalEvent.preventDefault();
  }

  getUserFullName() {
    if (!this.current_user) {
      return "guest";
    }
    if (!this.current_user.first_name) {
      return "registered user";
    }
    return this.current_user.first_name + " " + this.current_user.last_name;
  }

  capture() {
    window["chrome"].runtime.sendMessage("lodmdalapilnodpohlpcfedmbjpamkli", {
      message: "capture",
      area: null,
      dpr: devicePixelRatio,
    });
  }
}

function b64toBlob(b64Data, contentType, sliceSize = null) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;
  const byteCharacters = atob(b64Data);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
