import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AccountService } from "@_services/account.service";
import { LanguageService } from "@_services/language.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LanguageGuard implements CanActivate {
  constructor(public $lang: LanguageService, public $account: AccountService) {}
  canActivate(): Observable<boolean> {
    return this.$lang.init().pipe(map(() => true));
  }
}
