import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-intro-bandwidth',
  templateUrl: './intro-bandwidth.component.html',
  styleUrls: ['./intro-bandwidth.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class IntroBandwidthComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<IntroBandwidthComponent>,) { }

  ngOnInit() {
  }
  close() {
    this.dialogRef.close();
  }
}
