<form [formGroup]="MVB" (ngSubmit)="sendTicket()" #f="ngForm" novalidate>
  <div class="wrap">
    <div class="title" [translate]="'Support.Ticket_Heading'">
      What issue are you facing?
    </div>
    <div class="wrap-content" [dir]="languageService.dir | async">
      <div class="selects">
        <div class="switch" *ngIf="!select_topic">
          <div class="version select-item">
            <div class="select-name">
              {{ "Support.Choose_Topic" | translate }}
            </div>
            <select required (change)="selectOS($event)">
              <option value="">{{ "Placeholder_Select" | translate }}</option>
              <option value="onboarding">Onboarding</option>
              <option value="entity">Entity</option>
            </select>
          </div>
        </div>
        <div class="entity" *ngIf="select_topic === 'entity'">
          <div class="inputs selectWrap">
            <div class="version select-item">
              <div>
                {{ "Entity.Name" | translate
                }}<mat-error
                  class="msgwarning"
                  *ngIf="!MVB.controls['Entity'].value && f.submitted"
                  >Required an answer</mat-error
                >
              </div>
              <input type="text" formControlName="Entity" [(ngModel)]="entity_name" required />
            </div>
            <div class="version select-item">
              <div>
                {{ "Domain.Name" | translate
                }}<mat-error
                  class="msgwarning"
                  *ngIf="!MVB.controls['Domain'].value && f.submitted"
                  >Required an answer</mat-error
                >
              </div>
              <input type="text" formControlName="Domain" [(ngModel)]="domain_name" required />
            </div>
          </div>
          <div class="checkType">
            <div>{{ "Support.Select_issues" | translate }}</div>
            <div *ngFor="let i of entity_types">
              <mat-checkbox
                [(ngModel)]="i.completed"
                class="checkbox"
                (ngModelChange)="collectTypes(i)"
                [ngModelOptions]="{ standalone: true }"
                [checked]="false"
              >
                <label style="margin-bottom: 0px">{{
                  "Support.EntityIssueType." + i.value | translate
                }}</label>
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div
          class="onboarding selectWrap"
          *ngIf="select_topic === 'onboarding'"
        >
          <div class="version select-item">
            <div class="select-name">
              {{ "Support.Select_Type" | translate }}
              <mat-error
                class="msgwarning"
                *ngIf="!MVB.controls['onboarding_type'].value && f.submitted"
                >Required an answer</mat-error
              >
            </div>
            <select class="select-width" [(ngModel)]="onboarding_type" formControlName="onboarding_type">
              <option value="">{{ "Placeholder_Select" | translate }}</option>
              <option value="azure">Azure</option>
              <option value="gsuite">Gsuite</option>
              <option value="csv">CSV</option>
            </select>
          </div>
          <div class="checkType">
            <div>{{ "Support.Select_issues" | translate }}</div>
            <div *ngFor="let i of onboarding_types">
              <mat-checkbox
                [(ngModel)]="i.completed"
                class="checkbox"
                (ngModelChange)="collectTypes(i)"
                [ngModelOptions]="{ standalone: true }"
                [checked]="false"
              >
                <label style="margin-bottom: 0px">{{
                  "Support.OnboardingType." + i.value | translate
                }}</label>
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="select_topic">
        <app-fileupload #fileupload formGroupName="FileGroup"></app-fileupload>
      </div>
    </div>
  </div>
  <div class="btn-wrap" *ngIf="select_topic">
    <div class="btns">
      <button
        class="sendbtn"
        [class.spinner]="loading"
        [disabled]="loading"
        [ngClass]="{ 'btn-loading': loading === true }"
        [translate]="'Support.Send'"
        type="submit"
      >
        Send
      </button>
      <button
        class="cancelbtn"
        [disabled]="loading"
        [ngClass]="{ 'btn-loading': loading === true }"
        [mat-dialog-close]="true"
        [translate]="'Support.Cancel'"
      >
        Cancel
      </button>
    </div>
  </div>
</form>
