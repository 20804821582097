import {filter} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewChecked} from '@angular/core';
import {AppState} from '../_services/app.service';
import {Router, NavigationEnd} from '@angular/router';
import {Subscription} from 'rxjs';
import {LanguageService} from '@_services/language.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss']
})
export class FullLayoutComponent implements OnInit, AfterViewChecked, OnDestroy {
    routeSubscription: Subscription
    @ViewChild('mainPanel') mainPanel: ElementRef
    public disabled = false;
    public status: { isopen: boolean } = {isopen: false};
    public home = false;
    public dialog = true;

    public toggled(open: boolean): void {
    }

    constructor(
        public appstate: AppState,
        public languageService: LanguageService,
        private router: Router) {
    }

    public toggleDropdown($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopPropagation();
        this.status.isopen = !this.status.isopen;
    }

    ngOnInit(): void {
        const appstate = this.appstate;
        this.routeSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((evt) => {
            this.home = evt['url'] === '/home' || evt['url'] === '/account';
        });
        if (appstate.device.isMobile()) {
        }
    }

    ngAfterViewChecked(): void {
        if (this.mainPanel) {
            const el = this.mainPanel.nativeElement
            el.style['margin-top'] = `${document.querySelector('.navbar').clientHeight}px`
        }
    }

    ngOnDestroy(): void {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe()
        }
    }
}
