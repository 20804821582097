import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-reminder",
  templateUrl: "./reminder.component.html",
  styleUrls: ["./reminder.component.scss"],
})
export class ReminderComponent implements OnInit {
  confirmed = false;
  constructor(public dialogRef: MatDialogRef<ReminderComponent>) {}
  ngOnInit() {}
  close() {
    if (this.confirmed) window.localStorage.setItem("ReminderConfirmed", "1");
    this.dialogRef.close();
  }
  check(event) {
    this.confirmed = event.checked;
  }
}
