import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { AdminService } from "../_services/admin.service";
import { SharedModule, PrimengSharedModule } from "../shared";
import { MaterialModule } from "../material/material.module";
import { DebounceModule } from "../_directives/debounce/module";
import {
  IssueCouponProComponent,
  IssueCommonCouponComponent,
  IssueCouponComponent,
} from "app/pages/coupon";
import { MVBCommonComponentModule } from "app/mvb-common-component/mvb-common-component.module";

const route: Routes = [
  {
    path: "",
    component: IssueCommonCouponComponent,
    children: [
      { path: "coupon", component: IssueCommonCouponComponent },
      { path: "coupon-personal", component: IssueCouponComponent },
      { path: "coupon-professional", component: IssueCouponProComponent },
      { path: "coupon-instance", component: IssueCouponComponent },
    ],
  },
];

@NgModule({
  imports: [
    DebounceModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PrimengSharedModule,
    SharedModule,
    RouterModule.forChild(route),
    MVBCommonComponentModule,
  ],
  declarations: [
    IssueCouponComponent,
    IssueCouponProComponent,
    IssueCommonCouponComponent,
  ],
  providers: [AdminService],
})
export class CouponModule {}
