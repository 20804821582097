<div class="overlay" *ngIf="showSpinner">
  <div class="center">
    <div [class.spinner]="loading"></div>
  </div>
</div>
<div #overlay class="wrap">
      <nav class="breadcrumb article">
        <a *ngIf="!isMainSupport" routerLink="/support"  class="breadcrumb-item" style="color: #1e87f0" [translate]="'Support.FAQ'">FAQ </a>
        <a *ngIf="isMainSupport" routerLink="/main-support"  class="breadcrumb-item" style="color: #1e87f0" [translate]="'Support.FAQ'">FAQ </a>
        <span class="breadcrumb-item" [translate]="'Support.Article'">Article</span>
        <span class="breadcrumb-item active">{{article_id}}</span>
      </nav>

    <div [innerHTML]="article" class="article"></div>
</div>