import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

import { SecurePipe } from "./securepipe";
import { StepbyStepComponent } from "./step-by-step/step-by-step.component";
import { OverlayModule } from "@angular/cdk/overlay";

@NgModule({
  imports: [CommonModule, OverlayModule, TranslateModule],
  declarations: [SecurePipe, StepbyStepComponent],
  exports: [SecurePipe, StepbyStepComponent, TranslateModule],
})
export class SharedModule {}
