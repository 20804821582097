export class GoogleConversionSetting {
  public static readonly CONVERSION_WATCHVIDEO = "getConvsion_WatchVideo";
  public static readonly CONVERSION_SIGNUPGOOGLE = "getConvsion_SignUpGoogle";
  public static readonly CONVERSION_SIGNUPMICROSOFT =
    "getConvsion_SignUpMicroSoft";
  public static readonly CONVERSION_SIGNUPEMAIL = "getConvsion_SignUpEmail";
  public conversionFuc;

  constructor(conversionFuc: string) {
    this.conversionFuc = conversionFuc;
  }

  getConvsion_WatchVideo() {
    const google_conversion_id = 840767866;
    const google_conversion_label = "YOfeCPK42YMBEPqy9JAD";
    const google_remarketing_only = false;
    return {
      google_conversion_id,
      google_conversion_label,
      google_remarketing_only,
    };
  }

  getConvsion_SignUpGoogle() {
    const google_conversion_id = 840767866;
    const google_conversion_label = "8SuLCKSm2YMBEPqy9JAD";
    const google_remarketing_only = false;
    return {
      google_conversion_id,
      google_conversion_label,
      google_remarketing_only,
    };
  }

  getConvsion_SignUpMicroSoft() {
    const google_conversion_id = 840767866;
    const google_conversion_label = "4IUuCM2T8IMBEPqy9JAD";
    const google_remarketing_only = false;
    return {
      google_conversion_id,
      google_conversion_label,
      google_remarketing_only,
    };
  }

  getConvsion_SignUpEmail() {
    const google_conversion_id = 840767866;
    const google_conversion_label = "ZAYBCN7mxYQBEPqy9JAD";
    const google_remarketing_only = false;
    return {
      google_conversion_id,
      google_conversion_label,
      google_remarketing_only,
    };
  }

  public get data() {
    return this[this.conversionFuc]();
  }
}
