import { Component, OnInit, ViewChild } from "@angular/core";
import { AccountService } from "app/_services/account.service";
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material";
import { SubmitSuccessComponent } from "../submit-success/submit-success.component";
import { SweetAlertService } from "ng2-sweetalert2";
import { AppState } from "@_services";
import { FileuploadComponent } from "../fileupload/fileupload.component";
import { Observable } from "rxjs/Observable";
import { LanguageService } from "@_services/language.service";

@Component({
  selector: "app-companion",
  templateUrl: "./companion.component.html",
  styleUrls: ["./companion.component.scss"],
  providers: [SweetAlertService],
})
export class CompanionComponent implements OnInit {
  @ViewChild("fileupload") file: FileuploadComponent;
  openUpload = false;
  MVB: FormGroup;
  loading = false;
  Versions: any;
  default: string;
  currentLang: Observable<string>;
  constructor(
    public accountService: AccountService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CompanionComponent>,
    public dialog: MatDialog,
    private swalService: SweetAlertService,
    private appState: AppState,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.currentLang = this.languageService.loadCurrentLang();
    this.MVB = this.fb.group({
      os: new FormControl("", [Validators.required]),
      Companion: new FormControl("", [Validators.required]),
      osinput: new FormControl("", [Validators.required]),
      Title: new FormControl("", [Validators.required]),
      FileGroup: this.fb.group({
        Description: new FormControl("", [Validators.required]),
        FlagCtrl: new FormControl(false),
      }),
    });
    this.accountService.getVersion("Companion").subscribe((i) => {
      this.Versions = i[0]["versions"];
      this.default = this.Versions[0];
      this.MVB.controls["Companion"].patchValue(this.default);
    });
  }

  errorPop() {
    this.swalService.swal({
      title: "Error",
      text: "Something went wrong, please try again",
      type: "error",
      buttonsStyling: false,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonClass: "btn btn-primary  mr-4",
      confirmButtonText: "Confirm",
    });
  }

  sendTicket() {
    this.file.isClicked = true;
    if (!this.MVB.valid) return false;
    const os = this.MVB.controls["os"].value;
    const version = this.MVB.controls["osinput"].value;
    const companion = this.MVB.controls["Companion"].value;
    const text = this.file.FileGroup.controls["Description"].value;
    const title = this.MVB.controls["Title"].value;
    this.loading = true;
    const ticket = {
      product: "mvb-0006",
      category: "Companion Version : " + companion,
      title: title,
      description: os + ": " + version + "\n" + text,
      screenshots: this.file.keylist,
    };
    // console.log('send ticket', ticket)
    this.accountService.d365SendCase(ticket).subscribe(
      () => {
        if (this.appState._state["ticket-cases"])
          delete this.appState._state["ticket-cases"];
        this.loading = false;
        this.dialogRef.close();
        this.dialog.open(SubmitSuccessComponent, {
          width: "400px",
          minWidth: "310px",
          height: "auto",
        });
      },
      () => {
        this.loading = false;
        this.errorPop();
      }
    );
  }
}
