import { Component, OnInit, Inject } from "@angular/core";
import { AccountService } from "app/_services/account.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { UserState } from "@_services/current/user";
import { tap } from "rxjs/operators";
import { LanguageService } from "@_services/language.service";
@Component({
  selector: "app-eula-form",
  template: ` <div class="body" id="step2">
    <div class="leftarea">
      <img src="images/logo_myviewboard.svg" width="210" />
      <div class="title" [dir]="languageService.dir | async">
        <h1 class="headline1 mb-10" [translate]="'SignUp.Welcome'">Welcome</h1>
        &nbsp;
        <h1 class="headline1">{{ data.name }}</h1>
      </div>
      <div class="inputs">
        <app-eula-confirm
          [checked]="isEULA"
          (onCheck)="isEULA = $event.checked"
        ></app-eula-confirm>
      </div>
      <button
        *ngIf="isEULA; else showMsg"
        mat-raised-button
        color="primary"
        style="text-align: center"
        (click)="onConfirm()"
      >
        {{ "Confirm" | translate }}
      </button>
      <ng-template #showMsg>
        <span class="small" color="primary">{{
          "Home.Confirm_EULA" | translate
        }}</span
        ><br />
      </ng-template>
    </div>
  </div>`,
  styleUrls: ["../../questionarie-form/questionarie-form.component.scss"],
})
export class EULAFormComponent implements OnInit {
  isEULA = false;
  constructor(
    public userState: UserState,
    public accountService: AccountService,
    public languageService: LanguageService,
    public dialogRef: MatDialogRef<EULAFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    const user = this.userState.getCurrentUser();
    this.isEULA = user.isEULA();
  }
  onConfirm() {
    this.updateEULAObservable().subscribe(() => {
      this.dialogRef.close();
    });
  }
  updateEULAObservable() {
    return this.accountService.update({ is_agreement: this.isEULA }).pipe(
      tap((data) => {
        this.userState.get().is_agreement = this.isEULA;
      })
    );
  }
}
