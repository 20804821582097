<div class="wrap">
  <div class="header">
    <div>
      <div *ngIf="currentLang !== 'ja'">
        <h2 class="heade" [translate]="'SignUp.WelcomeTo'">Welcome to </h2> <img class="logo heade" src="../../../images/logo_myviewboard.svg">
        <h2 class="heade" [translate]="'Bandwidth.Title'">Bandwidth Calculator</h2>
      </div>
      <div *ngIf="currentLang === 'ja'">
        <img class="logo heade" src="../../../images/logo_myviewboard.svg"><h2 class="heade" [translate]="'Bandwidth.Title'">Bandwidth Calculator</h2><h2 class="heade" [translate]="'SignUp.WelcomeTo'">Welcome to</h2>

      </div>

      <h2 *ngIf="!hideBtn" [translate]="'Bandwidth.Intro_Line'">Let's start to calculate your bandwidth needs</h2>
    </div>
    <button class="back" (click)="openDialog()" [translate]="'Bandwidth.What_Bandwidth'">What's bandwidth?</button>
  </div>
  <div class="form animation" *ngIf="isBegin">
    <form [formGroup]="surveyForm">
      <mat-horizontal-stepper class="stepper" [linear]="isLinear" #stepper>
        <mat-step [stepControl]="surveyForm.get('step1')" formGroupName="step1">
          <p class="p-15" [translate]="'Bandwidth.How_Fast_Speed'">How fast is your internet speed?</p>
          <div class="combo">
            <div class="inputs">
              <p [translate]="'Bandwidth.Upload'">Upload</p>
              <mat-form-field>
                <input type="number" matInput class="Text_Center" min="1" max="10240" formControlName="upload" required>
              </mat-form-field><span>Mbps</span>
            </div>
            <div class="inputs">
              <p [translate]="'Bandwidth.Download'">Download</p>
              <mat-form-field>
                <input type="number" matInput class="Text_Center" min="1" max="10240" formControlName="download"
                  required>
              </mat-form-field><span>Mbps</span>
            </div>

          </div>

          <div class="btm">
            <button mat-button matStepperNext>{{'Entity_Request.Next' | translate}}</button>
          </div>
        </mat-step>

        <mat-step formGroupName="step2" [stepControl]="surveyForm.get('step2')">
          <p class="p-15" [translate]="'Bandwidth.Total_Teachers'">How many teachers are there in your school?</p>
          <mat-form-field>
            <input type="number" min="1" max="2000" matInput required class="Text_Center"
              formControlName="total_teachers" required>
          </mat-form-field>
          <div class="btm">
            <button mat-button matStepperPrevious>{{'Bandwidth.Back' | translate}}</button>
             <button mat-button matStepperNext>{{'Entity_Request.Next' | translate}}</button>
          </div>
        </mat-step>

        <mat-step formGroupName="step3" [stepControl]="surveyForm.get('step3')">
          <p class="p-15" [translate]="'Bandwidth.Teaching_Same_Time'">How many teachers will be teaching at the same
            time?</p>
          <mat-form-field>
            <input type="number" min="1" max="30000" [(ngModel)]="teacher_count" matInput class="Text_Center"
              formControlName="students" required>
          </mat-form-field>
          <div class="btm">
            <button mat-button matStepperPrevious>{{'Bandwidth.Back' | translate}}</button>
             <button mat-button matStepperNext>{{'Entity_Request.Next' | translate}}</button>
          </div>
        </mat-step>

        <mat-step formGroupName="step4" [stepControl]="surveyForm.get('step4')">
          <p class="p-15" [translate]="'Bandwidth.Students_in_A_room'">Can you estimate the number of students that will
            be in a virtual classroom?</p>
          <mat-form-field>
            <input type="number" min="1" max="1000" matInput class="Text_Center" formControlName="room_count" required>
          </mat-form-field>
          <div class="btm">
            <button mat-button matStepperPrevious>{{'Bandwidth.Back' | translate}}</button>
             <button mat-button matStepperNext>{{'Entity_Request.Next' | translate}}</button>
          </div>
        </mat-step>

        <mat-step formGroupName="step5" [stepControl]="surveyForm.get('step5')">
          <div class="tables">
            <div class="mvb-wrap">
              <p><img src="../../../images/logo_myviewboard.svg"></p>
              <table mat-table [dataSource]="dataSource" class="tablelist mvb">
                <ng-container matColumnDef="test">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;"></th>
                  <td mat-cell *matCellDef="let element"> {{element.title}}</td>
                </ng-container>
                <ng-container matColumnDef="Plan A">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;" class="headrow">{{'Bandwidth.Audio' | translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.HQ * this.teacher_count | number : '1.1-1'}} Mbps
                  </td>
                </ng-container>
                <ng-container matColumnDef="Plan B">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;" class="headrow">{{'Bandwidth.Audio_Video' | translate}}<img
                      class="crown" src="../../../images/crow.png"></th>
                  <td mat-cell *matCellDef="let element"> {{element.HD * this.teacher_count | number : '1.1-1'}} Mbps
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>
            </div>

            <div class="zoom-wrap">
              <img src="../../../images/zoom_1.svg" style="height:35px; width:209px;">
              <table mat-table [dataSource]="secdataSource" class="tablelist zoom">
                <ng-container matColumnDef="test">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;"></th>
                  <td mat-cell *matCellDef="let element"> </td>
                </ng-container>
                <ng-container matColumnDef="Plan A">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;" class="headrow">{{'Bandwidth.Audio' | translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.HQ * this.teacher_count | number : '1.1-1'}} Mbps
                  </td>
                </ng-container>
                <ng-container matColumnDef="Plan B">
                  <th mat-header-cell *matHeaderCellDef style="text-align: center;" class="headrow">{{'Bandwidth.Audio_Video' | translate}}</th>
                  <td mat-cell *matCellDef="let element"> {{element.HD * this.teacher_count | number : '1.1-1'}} Mbps
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumnss; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumnss;"></tr>
              </table>
            </div>
          </div>
          <div class="btm">
            <button mat-button matStepperPrevious >{{'Bandwidth.Back' | translate}}</button>
            <button mat-button (click)="back()" >{{'Bandwidth.Reset' | translate}}</button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
  <div class="btn-wrap" *ngIf="!hideBtn">
    <button mat-raised-button class="sendbtn" (click)="start()">{{'Bandwidth.Start' | translate}}</button>
  </div>
</div>