import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: "app-internal",
  templateUrl: "./internal.component.html",
  styleUrls: ["./internal.component.scss"],
})
export class InternalComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ["name", "link"];
  ELEMENT_DATA = [
    {
      name: "Sales Portal",
      link: "https://sales.myviewboard.com",
    },
    {
      name: "Software ordering form - VSI",
      link: "https://forms.office.com/r/1ACcN86z2h",
    },
    {
      name: "Software ordering form - VSE",
      link: "https://forms.office.com/r/YE2AX4HRqd",
    },
  ];

  constructor() {}

  ngOnInit() {
    this.dataSource.data = this.ELEMENT_DATA;
  }
}
