import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { SupportFaqModule } from "../support-faq/support-faq.module";
import { MvbSupportComponent } from "../support-faq/mvb-support/mvb-support.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([{ path: "", component: MvbSupportComponent }]),
    SupportFaqModule
  ]
})
export class MvbSupportTicketModule {}
