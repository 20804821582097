<form [formGroup]="MVB" (ngSubmit)="sendTicket()" #f="ngForm">
  <div class="companionwrap">
    <div class="title" [translate]="'Support.Ticket_Heading'">What issue are you facing?</div>
    <div class="wrap-content" [dir]="languageService.dir | async">
      <div class="companion-wrap">
        <div class="companion-name" [translate]="'Support.Companion_Version'">Companion AppVersion:</div>
          <select formControlName="Companion" required [value]="default">
            <option *ngFor="let version of Versions" value="{{ version }}" >
                {{ version }}
            </option>
          </select>
      </div>
      <div class="selects">
        <mat-radio-group class="example-radio-group" formControlName="os" required>
          <div class="android">
            <mat-radio-button value="Android"></mat-radio-button>
            <div class="button-content">{{'Support.And_Sys_Ver' | translate}}</div>
            <input class="OSInput" type="text" name="other_reason" formControlName="osinput"
              *ngIf="MVB.controls['os'].value == 'Android'" required />
          </div>
          <div class="ios">
            <mat-radio-button value="iOS"></mat-radio-button>
            <div class="button-content">{{'Support.iOS_Sys_Ver' | translate}}</div>
            <input class="OSInput" type="text" name="other_reason" formControlName="osinput"
              *ngIf="MVB.controls['os'].value == 'iOS'" required />
          </div>
        </mat-radio-group>
      </div>
      <div class="center">
        <mat-error class="versionwarning center"
        *ngIf="(MVB.controls['os'].invalid || MVB.controls['osinput'].invalid) && f.submitted"
        [translate]="'Support.Error_OS_Required'">Android/iOS version is required</mat-error>
      </div>

        <div class="title-area">
          <div class="title-header" [translate]="'Support.Title'">Title<mat-error class="msgwarning" *ngIf="MVB.controls['Title'].invalid && f.submitted" [translate]="'Support.Error_Title'"
            >Please name a title for this ticket</mat-error></div>
          <input class="title-input" [ngClass]="{'bidi_dir': (currentLang | async) === 'ar'}" formControlName="Title" placeholder="{{ ('Support.Title_placeholder') | translate }}" maxlength="60" required>
        </div>
      <app-fileupload #fileupload formGroupName="FileGroup"></app-fileupload>
    </div>
  </div>
  <div class="btn-wrap">
    <div class="btns ">
      <button class="sendbtn" [class.spinner]="loading" [disabled]="loading"
        [ngClass]="{'btn-loading':loading === true}" [translate]="'Support.Send'" type="submit">Send</button>
      <button class="cancelbtn" [disabled]="loading" [ngClass]="{'btn-loading':loading === true}"
        [mat-dialog-close]="true" [translate]="'Support.Cancel'">Cancel</button>
    </div>
  </div>
</form>