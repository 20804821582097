import { Component, OnInit } from '@angular/core';
import { environment } from '@environment';
@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss']
})
export class Step3Component implements OnInit {
  constructor() {}
  ngOnInit() {}
  redirect() {
    const prod = environment.production
    window.parent.postMessage(JSON.stringify({ event: 'open-modal', payload:  { target: 'signin-modal' } }), '*');
    if (prod) {
      window.location.replace('https://myviewboardclips.com')
    }else {
      window.location.replace('https://stage.myviewboardclips.com')
    }
  }
}


