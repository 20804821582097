import { Component, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material';
import { AccountService } from '@_services/account.service';
import { SweetAlertService } from 'ng2-sweetalert2';
import { AppState } from '@_services';

@Component({
  selector: 'app-case-detail',
  templateUrl: './case-detail.component.html',
  styleUrls: ['./case-detail.component.scss']
})

export class CaseDetailComponent implements OnInit, OnChanges {
  case_number: string;
  state: string;
  title: string;
  time: string;
  dataSource = new MatTableDataSource<Case>();
  cases: Case[] = [];
  rating: number;
  list: any;
  readOnly: boolean;
  showSpinner = false;
  loading: boolean;
  feedbacktext: string;
  hasFeedBack: boolean;
  caseDetail: any; 
  last_update: any;
  displayedColumns: string[] = ['id', 'title', 'time', 'update', 'state', 'rating'];
  constructor(private activatedRoute: ActivatedRoute,
    private accountService: AccountService,
    private swalService: SweetAlertService,
    private appState: AppState,
    private route: Router) { }

  ngOnInit() {
    if (this.appState.get('case-detail')) {
      this.list = this.appState.get('case-detail');
    } else {
      this.route.navigate(['/main-support/cases'])
    }
    console.log(this.list)
    this.dataSource.data.push(this.list);
    this.getState(this.list.state);
    this.case_number = this.list.case_number;
    this.checkFeedBack(this.list.feedback);
    this.convertRating(this.list.rating);
  }

  ngOnChanges() {
    this.dataSource = new MatTableDataSource<any>(this.cases);
  }

  checkFeedBack(value) {
    if (value === null && this.readOnly === false) {
      this.hasFeedBack = true;
    }
    else if (value || this.readOnly === true) {
      this.hasFeedBack = false;
    }
  }

  convertRating(passValue) {
    if (passValue === 'null') {
      this.rating = 0;
    } else {
      this.rating = passValue
    }
  }

  getState(state) {
    switch (state) {
      case 'Active':
        this.readOnly = false;
        break;
      case 'Cancelled':
        this.readOnly = true;
        break;
      case 'Resolved':
        this.readOnly = true;
        break;
    }
  }

  loadingSpinnerOn() {
    this.showSpinner = true;
    this.loading = true;
  }

  loadingSpinnerOff() {
    this.showSpinner = false;
    this.loading = false;
  }

  onRate($event) {
    this.loadingSpinnerOn();
    const newRating = $event.newValue;
    this.accountService.updateSupportCase(this.case_number, { rating: newRating }).subscribe(
      res => {
        delete this.appState._state['ticket-cases']
        this.loadingSpinnerOff()
      }, err => {
        this.showError()
        this.rating = $event.oldValue
        this.loadingSpinnerOff()
      }
    )
  }

  sendFeedback() {
    this.loadingSpinnerOn();
    const newrating = +this.rating;
    const feedbacktext = this.feedbacktext;
    const data = {
      rating: newrating,
      feedback: feedbacktext
    }
    this.accountService.updateSupportCase(this.case_number, data).subscribe(res => {
      this.loadingSpinnerOff();
      delete this.appState._state['ticket-cases']
      this.hasFeedBack = false;
    }, err => {
      this.loadingSpinnerOff()
      this.showError()
    })
  }

  showError() {
    this.swalService.swal(
      {
        title: 'Error',
        text: 'Something went wrong, please try again',
        type: 'error',
        buttonsStyling: false,
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonClass: 'btn btn-primary  mr-4',
        confirmButtonText: 'Confirm',
      }
    )
  }
}

export interface Case {
  case_number: string;
  title: string;
  created_at: string;
  state: string;
  rating: number;
  modified_on: string
}