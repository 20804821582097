<form [formGroup]="MVB" (ngSubmit)="sendTicket()" #f="ngForm">
  <div class="radiowrap" [dir]="languageService.dir | async">
    <div class="title" [translate]="'Support.Ticket_Heading'">What issue are you facing?</div>
    <div class="wrap-content" >
      <div class="selects">
        <div class="browser">
          <b [translate]="'Support.Browser_Type'">Browser type</b>
          <mat-error class="msgwarning" *ngIf="MVB.controls['browser'].invalid && f.submitted"
            [translate]="'Support.Error_Browser'">Please choose a browser</mat-error>
          <mat-radio-group class="example-radio-group" formControlName="browser">
            <mat-radio-button value="Edge">{{'Support.Browserlist.Edge' | translate}}</mat-radio-button>
            <mat-radio-button value="Chrome">{{'Support.Browserlist.Chrome' | translate}}</mat-radio-button>
            <mat-radio-button value="Firefox">{{'Support.Browserlist.Firefox' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
        <div class="ticket-title">
          <b [translate]="'Support.Issue_Type'" *ngIf="(currentLang | async) !== 'ja'">Issue type for<span class="title-red">&nbsp;myViewBoard.com</span></b>
          <b *ngIf="(currentLang | async) === 'ja'" > <span class="title-red"> myViewBoard.com</span> <span [translate]="'Support.Issue_Type'">&nbsp; Issue type for</span></b>
          <mat-error class="msgwarning" *ngIf="MVB.controls['title'].invalid && f.submitted"
            [translate]="'Support.Error_Issue'">Please choose your issue type</mat-error>
          <mat-radio-group class="example-radio-group" formControlName="title">
            <mat-radio-button value="Unable to sign in">{{'Support.web_options.Unable_signin' | translate}}
            </mat-radio-button>
            <mat-radio-button value="Need to reset password">{{'Support.web_options.Reset_password' | translate}}
            </mat-radio-button>
            <mat-radio-button value="Cloud integration not working">{{'Support.web_options.Cloud_integration' | translate}}
            </mat-radio-button>
            <mat-radio-button value="How to sync users through Azure AD or G Suite">{{'Support.web_options.sync_users' | translate}}
            </mat-radio-button>
            <mat-radio-button value="Coupon problem">{{'Support.web_options.Coupon' | translate}}
            </mat-radio-button>
            <mat-radio-button value="Others">{{'Support.web_options.Others' | translate}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="title-area">
        <div class="title-header" [translate]="'Support.Title'">Title<mat-error class="msgwarning"
            *ngIf="MVB.controls['Title'].invalid && f.submitted" [translate]="'Support.Error_Title'">Please name a
            title for this ticket</mat-error>
        </div>
        <input class="title-input" formControlName="Title" placeholder="{{ ('Support.Title_placeholder') | translate }}"
          maxlength="60" [ngClass]="{'bidi_dir': (currentLang | async) === 'ar'}" required>
      </div>
      <app-fileupload #fileupload formGroupName="FileGroup"></app-fileupload>
    </div>
  </div>
  <div class="btn-wrap">
    <div class="btns">
        <button class="sendbtn" [class.spinner]="loading" [disabled]="loading"
        [ngClass]="{'btn-loading':loading === true}" [translate]="'Support.Send'" type="submit">Send</button>
      <button class="cancelbtn" [disabled]="loading" [ngClass]="{'btn-loading':loading === true}"
        [mat-dialog-close]="true" [translate]="'Support.Cancel'">Cancel</button>
    </div>
  </div>
</form>