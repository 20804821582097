<div class="full-center">

  <div class="card fixed-card m-0">
    <div class="card-block p-0">
      <div class="text-center">
        <h4 class="text-title" [translate]="'Public_Portal.Poll_Quiz'">Poll/Quiz</h4>
      </div>
      <hr class="line-top m-0">

      <div *ngIf="isHostLogout" class="text-content">
        <div class="text-center">
          <h4 [translate]="'Public_Portal.Host_Offline'">Host is offline.</h4>
        </div>
      </div>

      <div *ngIf="!question && !isHostLogout" class="text-content">
        <div class="text-left">
          <h4 [translate]="'Public_Portal.Wait_Question'">Wait for Question...</h4>
        </div>
        <div class="text-center">
          <img src="/assets/images/192x192_loading_transparent.gif">
        </div>
      </div>

      <!-- random draw -->
      <div *ngIf="question && question.type==='random_draw'" class="text-content">
        <div *ngIf="question.options.length===0">
          <div class="text-left">
            <h4 [translate]="'Public_Portal.Wait_Random_Draw_1'">A random draw is taking place now.</h4>
              <h4 [translate]="'Public_Portal.Wait_Random_Draw_2'">Please wait for the result.</h4>
          </div>
          <div class="text-center">
            <img src="/assets/images/192x192_loading_transparent.gif">
          </div>
          <mat-grid-list cols="1">
            <div class="row timer">
              <img src="/assets/images/clock.svg">
              <div class="timer-text">{{timerCount}}</div>
            </div>
          </mat-grid-list>
        </div>
        
        <div *ngIf="question.options.length > 0">
          <div class="text-center">
            <h4 [translate]="'Public_Portal.Random_Draw_Result'">Random Draw Result</h4>
          </div>
          <ul>
            <li *ngFor="let item of question.options">
              <p>{{item}}</p>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="question && question.type!=='random_draw'" class="text-content">
        <div class="question-text">
          <h4>{{question.title}}</h4>
        </div>
        <h5 class="text-instruction mt-0">{{question.instruction}}</h5>
        <!-- multiple choice -->
        <mat-grid-list class="mvb-relative-grid-list" cols="6" *ngIf="question.type==='multiple_choice'">
          <div *ngFor="let item of multiple_options; let i = index">
            <mat-grid-tile colspan="1">{{i+1}}</mat-grid-tile>
            <mat-grid-tile colspan="5">
              <div fxFlex fxLayoutAlign="start center">
                <mat-checkbox [(ngModel)]="item.checked" disableRipple="true" [disabled]="isLocked">{{item.value}}
                </mat-checkbox>
              </div>
            </mat-grid-tile>
          </div>
        </mat-grid-list>
        <!-- true/false -->
        <mat-grid-list cols="1" *ngIf="question.type==='true_false'">
          <mat-grid-tile colspan="1">
            <mat-button-toggle-group class="question-toggle" vertical="true" [(ngModel)]="answer"
              [disabled]="isLocked">
              <mat-button-toggle class="question-toggle" value="{{question.options[0]}}" disableRipple="true">
                {{question.options[0]}}
              </mat-button-toggle>
              <mat-button-toggle class="question-toggle" value="{{question.options[1]}}" disableRipple="true">
                {{question.options[1]}}
              </mat-button-toggle>
            </mat-button-toggle-group>
          </mat-grid-tile>
        </mat-grid-list>
        <!-- rating -->
        <mat-grid-list cols="1" rowHeight="8:1" class="question-container" *ngIf="question.type==='rating'">
          <mat-grid-tile colspan="1">
            <div fxFlex fxLayoutAlign="space-between center">
              <div class="rating-text">
                {{question.options[0]}} {{question.options[3]}}
              </div>
              <div class="rating-text">
                {{question.options[1]}} {{question.options[3]}}
              </div>
            </div>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="3">
            <mat-slider class="slider-width" [max]="question.options[1]" [min]="question.options[0]" (input)="sliderMove($event)"
              [step]="toInt(question.options[2])" [thumbLabel]="true" [(ngModel)]="answer" [disabled]="isLocked">
            </mat-slider>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
            <div fxFlex fxLayoutAlign="space-between center">
              <mat-icon class="arrow-left">arrow_right_alt</mat-icon>
              <mat-icon>arrow_right_alt</mat-icon>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <!-- free response -->
        <mat-grid-list cols="1" rowHeight="2:1" *ngIf="question.type==='free_response'">
          <mat-grid-tile colspan="1">
            <textarea [rows]="5" [cols]="30" pInputTextarea placeholder="Enter your response here" [(ngModel)]="answer"
              [disabled]="isLocked"></textarea>
          </mat-grid-tile>
        </mat-grid-list>
        <!-- voting -->
        <mat-grid-list class="mvb-relative-grid-list" cols="6" *ngIf="question.type==='voting'">
          <mat-radio-group class="question-radio-group" [(ngModel)]="answer" [disabled]="isLocked">
            <div *ngFor="let item of question.options; let i = index">
              <mat-grid-tile colspan="1">{{i+1}}</mat-grid-tile>
              <mat-grid-tile colspan="5">
                <div fxFlex fxLayoutAlign="start center">
                  <mat-radio-button [value]="item" disableRipple="true">
                    {{item}}
                  </mat-radio-button>
                </div>
              </mat-grid-tile>
            </div>
          </mat-radio-group>
        </mat-grid-list>

        <mat-grid-list cols="1">
          <div class="row timer">
            <img src="/assets/images/clock.svg">
            <div class="timer-text">{{timerCount}}</div>
          </div>
        </mat-grid-list>

        <mat-grid-list cols="1" rowHeight="5:1" *ngIf="!isLocked">
          <mat-grid-tile colspan="1">
            <button class="btn btn-primary" (click)="submitAnswer()" (focus)="isBtnPopCloseFocused = true"
              (blur)="isBtnPopCloseFocused = false" [translate]="'Submit'">Submit</button>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="1" rowHeight="5:1" *ngIf="isLocked">
          <mat-grid-tile colspan="1">
            <button class="btn btn-secondary" [translate]="'Public_Portal.Answer_Submitted'">Answer Submitted</button>
          </mat-grid-tile>
        </mat-grid-list>

        <mat-grid-list cols="1" rowHeight="70px" class="prompt-text" *ngIf="question.type!=='free_response'">
          <mat-grid-tile colspan="1" *ngIf="question.type==='multiple_choice'" [translate]="'Public_Portal.Hot_Key_Prompt_1'">
            You can press a number key (1~6) to choose an according option.
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="question.type==='true_false'" [translate]="'Public_Portal.Hot_Key_Prompt_2'">
            You can press Up or Down arrow key to choose an according option.
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="question.type==='rating'" [translate]="'Public_Portal.Hot_Key_Prompt_3'">
            You can press Left or Right arrow key to change the rating.
          </mat-grid-tile>
          <mat-grid-tile colspan="1" *ngIf="question.type==='voting'" [translate]="'Public_Portal.Hot_Key_Prompt_4'">
            You can press a number key (1~9 and 0) to choose an according option during 1~10.
          </mat-grid-tile>
        </mat-grid-list>
      </div>

      <!-- submit loading -->
      <div class="spinner-container" *ngIf="isLoaded">
        <img src="/assets/images/192x192_loading_transparent.gif">
      </div>
      <div class="overlay" *ngIf="isLoaded"></div>

    </div>
  </div>

</div>
