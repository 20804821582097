import { Component, OnInit } from '@angular/core';
import { from as observableFrom } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { AccountService } from '../_services/account.service';
import { AppState } from '../_services';
import { UserState } from '@_services/current/user';

@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.scss'],
})
export class SessionListComponent implements OnInit {
  list: any = [];

  constructor(
    private accountService: AccountService,
    public appstate: AppState,
    public userstate: UserState
  ) {}
  
  ngOnInit() {
    this.getSessionList()
  }

  getSessionList() {
    this.accountService.getSessionList().subscribe((data: any[]) => {
      this.list = data.filter(session => session.sid !== this.userstate.getUser().sid)
    })
  }

  deleteFromSessionList(sessionId) {
    this.accountService.deleteFromSessionList(sessionId).subscribe(data => {
      this.getSessionList()
    })
  }

  deleteAll() {
    observableFrom(this.list).pipe(concatMap((item: any) => this.accountService.deleteFromSessionList(item.sid))).subscribe({
      complete: () => {
        this.getSessionList()
      }
    })
  }
}
