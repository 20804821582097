<ng-container *ngFor="let msg of value; let i = index">
  <div
    [ngClass]="['mvb-alert', 'mvb-alert-' + msg.severity]"
    role="alert"
    [@messageAnimation]="{
      value: 'visible',
      params: {
        showTransitionParams: showTransitionOptions,
        hideTransitionParams: hideTransitionOptions
      }
    }"
  >
    <button
      *ngIf="msg.summary && closable"
      type="button"
      class="close"
      aria-label="Close"
      (click)="removeMessage(i)"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <br />
    <h4
      *ngIf="msg.summary"
      class="mvb-alert-heading"
      [attr.aria-label]="msg.summary"
    >
      <b>{{ msg.summary }}</b>
    </h4>
    <p
      class="mvb-mb-0"
      *ngIf="msg.detail"
      [attr.aria-label]="msg.detail"
      [ngClass]="{ 'text-center': msg.align === 'center' }"
    >
      {{ msg.detail }}
      <button
        *ngIf="!msg.summary && closable"
        type="button"
        class="close"
        aria-label="Close"
        (click)="removeMessage(i)"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </p>
    <ng-container
      *ngTemplateOutlet="footerTemplate; context: { $implicit: field }"
    >
    </ng-container>
  </div>
</ng-container>
