import { of as observableOf, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationStart,
} from "@angular/router";
import { AccountService } from "../_services/account.service";
import { AppState } from "../_services/app.service";
import { Title } from "@angular/platform-browser";

declare var ga: any;

@Injectable()
export class EntityGuard implements CanActivate {
  constructor(
    private router: Router,
    private appstate: AppState,
    private accountService: AccountService,
    private titleService: Title
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.accountService.checkEntities(route.url[0]).pipe(
      map((val) => false),
      catchError((error) => {
        console.log(error);
        if (error.status === 404) {
          return observableOf(true);
        }
        if (route.url.length < 2) {
          window.location.href = this.accountService.checkEntitiesUrl(
            route.url[0]
          );
        }
        return observableOf(false);
      })
    );
  }
}
