<div class="container h-100">
  <div class="row justify-content-center h-100 align-items-center">
    <div class="col-lg-6 text-center">
      <!--<a class="navbar-brand text-center" routerLink="/">myViewBoard<span>©</span></a>-->

      <div class="mb-0">
        <div class="card p-4 m-4">
          <div class="card-block text-left">
            <h3 class="mb-3" [translate]="'Coupon.Enter_Coupon'">
              Please enter Your Coupon
            </h3>
            <!-- <p-messages [(value)]="msgs"></p-messages> -->
            <mvb-message [(value)]="msgs"></mvb-message>
            <form
              name="form"
              [formGroup]="userform"
              (ngSubmit)="f.form.valid && onSubmit(userform.value)"
              #f="ngForm"
              novalidate
            >
              <div class="form-group">
                <label for="email_sales" [translate]="'Coupon.Code'"
                  >Coupon Code:</label
                >
                <textarea
                  class="form-control"
                  pInputText
                  type="text"
                  [(ngModel)]="model.code"
                  name="code"
                  formControlName="code"
                  placeholder="Required"
                ></textarea>
                <div
                  class="help-block"
                  *ngIf="
                    !userform.controls['code'].valid &&
                    userform.controls['code'].dirty
                  "
                  [translate]="'Coupon.Code_Required'"
                >
                  <i class="fa fa-close"></i>
                  coupon code is required
                </div>
              </div>

              <div class="form-group">
                <button
                  [disabled]="loading || !userform.valid"
                  class="btn btn-primary"
                  [translate]="'Coupon.Submit'"
                >
                  Submit
                </button>
                <img
                  *ngIf="loading"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
              </div>

              <div
                style="text-align: center; margin-top: 20px"
                *ngIf="submitted"
              ></div>
            </form>
          </div>
        </div>

        <div class="card p-4 m-4">
          <div
            class="card-block text-left"
            *ngIf="!appstate.environment.production"
          >
            <h3 class="mb-3" [translate]="'Coupon.Active'">
              Use Serial Number to active
            </h3>
            <p [translate]="'Coupon.Test'">this feature only for stage test</p>
            <!-- <p-messages [(value)]="msgs_serial"></p-messages> -->
            <mvb-message [(value)]="msgs_serial"></mvb-message>
            <div class="form-group">
              <label for="Serial" [translate]="'Coupon.Serial_Number'"
                >Serial Number:</label
              >
              <textarea
                class="form-control"
                pInputText
                type="text"
                [(ngModel)]="serialnumber"
                name="code"
                placeholder="Required"
              ></textarea>
            </div>
            <div class="form-group">
              <button
                class="btn btn-primary"
                [disabled]="!serialnumber"
                (click)="onSerialSubmit($event)"
                [translate]="'Coupon.Submit'"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
