import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { environment } from "@environment";
import { AccountService } from "@_services/account.service";
import { UserState } from "@_services/current/user";
import { LanguageService } from "@_services/language.service";
import { OAuthService } from "angular-oauth2-oidc";
import { combineLatest, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
@Component({
  selector: "app-personal-subscription",
  templateUrl: "./personal-subscription.component.html",
  styleUrls: ["./personal-subscription.component.scss"],
})
export class PersonalSubscriptionComponent implements OnInit {
  @ViewChild("frame") frameElement: ElementRef;
  subUrl: SafeResourceUrl;
  requiredItems = {
    current_lang: undefined,
    ac_token: undefined,
    hasDefault: undefined,
  };
  lang$: Observable<string>;
  containerMinWidth: number = 0;
  containerMinHeight: number = 0;
  containerWidth: number = this.containerMinWidth;
  containerHeight: number = this.containerMinHeight;
  constructor(
    public sanitizer: DomSanitizer,
    private accountService: AccountService,
    private $lang: LanguageService,
    private userState: UserState,
    private route: Router,
    public oauthService: OAuthService
  ) {}
  @HostListener("window:resize", [
    "$event.target.innerWidth",
    "$event.target.innerHeight",
  ])
  onResize(width: number, height: number): void {
    let top = this.frameElement.nativeElement.offsetTop;
    let left = this.frameElement.nativeElement.offsetLeft;
    this.containerWidth = Math.max(width - left, this.containerMinWidth);
    if (this.containerWidth < 1024) {
      this.containerHeight = Math.max(height - top, this.containerMinHeight);
    } else {
      this.containerHeight = 1198;
    }
  }
  @HostListener("window:message", ["$event"])
  onMessage(event: MessageEvent): void {
    this.sentDataLayerEvent(event);
    const res = event.data;
    if (res.event == "from-billing-portal") {
      if (res.payload.code === "40100") this.updateToken();
      if (res.payload.code === "30000")
        this.route.navigateByUrl("/account/edit");
    }
  }

  ngOnInit() {
    this.inurl();
    this.onResize(window.innerWidth, window.innerHeight);
  }

  inurl() {
    combineLatest(
      this.getLang(),
      this.getDefaultEntity(),
      this.getToken()
    ).subscribe((res) => {
      const [lang, entity, token] = res;
      const items = this.requiredItems;
      items.current_lang = lang;
      items.hasDefault = entity;
      items.ac_token = token;
      this.subUrl = this.getUrl();
    });
  }

  getLang() {
    return this.$lang.loadCurrentLang().pipe(
      tap((res) => {
        return res;
      })
    );
  }

  getDefaultEntity(): Observable<any> {
    const account_id = this.userState.getUserID();
    return this.accountService.getDefaultEntity(account_id).pipe(
      map((res) => {
        const data = res["rows"];
        const result = data.length >= 1 ? true : false;
        return result;
      })
    );
  }

  getToken(): Observable<any> {
    if (this.oauthService.tokenEndpoint) {
      const access_token = this.oauthService.getAccessToken();
      return Observable.of(access_token);
    }
    return this.accountService.getSubscriptionToken().pipe(
      map((data) => {
        this.saveRefreshToken(data["refresh_token"]);
        return data["access_token"];
      })
    );
  }

  getUrl() {
    const { current_lang, hasDefault, ac_token } = this.requiredItems;
    const url = `${environment.subUrl}/${current_lang}/my/subscriptions?ac=${ac_token}&entityid=${hasDefault}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getRefreshToken() {
    if (this.oauthService.tokenEndpoint)
      return this.oauthService.getRefreshToken();
    this.getRefreshTokenBySession();
  }
  getRefreshTokenBySession() {
    this.accountService.getSubscriptionToken().pipe(
      tap((data) => {
        return this.reloadPage(data["refresh_token"]);
      })
    );
  }
  updateToken() {
    if (this.oauthService.tokenEndpoint) return this.updateTokenByOIDC();
    this.updateTokenBySession();
  }
  updateTokenByOIDC() {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) {
      this.oauthService.refreshToken().then((x) => {
        this.subUrl = this.getUrl();
      });
    }
  }
  updateTokenBySession() {
    const refreshToken = window.localStorage.getItem("subscription_refresh_tk");
    refreshToken ? this.reloadPage(refreshToken) : this.getRefreshToken();
  }
  reloadPage(refreshToken: string) {
    this.accountService
      .refreshSubscriptionToken(refreshToken)
      .subscribe((data) => {
        this.requiredItems.ac_token = data["access_token"];
        this.saveRefreshToken(data["refresh_token"]);
        this.subUrl = this.getUrl();
      });
  }

  saveRefreshToken(token: string) {
    window.localStorage.setItem("subscription_refresh_tk", token);
  }

  sentDataLayerEvent(event) {
    const URLparams = new URLSearchParams(window.location.search);
    const res = event.data;
    const DataLayerParam = {
      event: res.event,
      product: res.payload.product,
      type: res.payload.type,
      source: {
        utm_source: URLparams.get("utm_source"),
        utm_medium: URLparams.get("utm_medium"),
        utm_content: URLparams.get("utm_content"),
      },
    };

    if (URLparams.has("utm_source")) {
      (<any>window).dataLayer.push(DataLayerParam);
    }
  }
}
