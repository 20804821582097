import { Component, OnInit } from '@angular/core';
import { environment } from '@environment';

@Component({
  selector: 'app-signin-redirect',
  template: ''
})
export class SigninRedirectComponent implements OnInit {
  ngOnInit() {
    window.location.href = environment.oidc_config.redirectUri;
  }
}
