<div class="content-wrap" (click)="hideSearch()">
  <div class="content">
    <div class="support-header-text" [translate]="'Support.Heading'">Welcome to myViewBoard Support</div>
    <div class="searchbar">
      <img [src]="search_icon" class="icon" alt="">
      <input type="search" matInput placeholder="Search Support" class="searchinput" (keyup)="search(article.value)"
        #article [ngStyle]="{'text-align': (currentLang | async) === 'ar' ? 'right' : 'left' }" />
      <div [class.spinner]="loading"></div>
      <div class="list" *ngIf="list">
        <ul class="searchTable" *ngFor="let item of list | slice:0:10;">
          <li [routerLink]="['article', item.article_number]">{{item.title}}</li>
        </ul>
      </div>
    </div>
    <div class="row">
      <ng-template ngFor let-item [ngForOf]="lists">
        <div class="item-wrap">
          <div class="item" (click)="redirect(item.switchCode)" *ngIf="item.isVS">
            <div class="clickimg" [ngClass]="{'height-96': !item.imgSrc}">
              <a>
                <img *ngIf="item.imgSrc" [class]="item.classes" [src]="item.imgSrc" alt="img" />
                <i *ngIf="!item.imgSrc" [class]="item.classes"></i>
              </a>
            </div>
            <p>{{item.translateLabel | translate}}</p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="bottom">
    <h3 class="comm-title" [translate]="'Support.Connect_With'"></h3>
    <p class="comm-text" [translate]="'Support.Share_Your_Experience'"></p>
    <button mat-raised-button class="joinbtn" (click)="redirectTofaq()" ><span [translate]="'Support.Join_Us'"></span></button>
  </div>
  <div class="bigpic">
    <img src="../../../images/Support_pics/asset.svg" alt="">
  </div>
</div>