import { from as observableFrom, empty as observableEmpty, Observable } from 'rxjs';

import { mergeMap, map, delay } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '@_services/admin.service';
import { AppState } from '@_services/app.service';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Message } from 'primeng/primeng';
import { UserState } from '@_services/current/user';

@Component({
  selector: 'app-issue-coupon',
  templateUrl: './issue-coupon.component.html',
  styleUrls: ['./issue-coupon.component.scss'],
  providers: [AdminService]
})
export class IssueCouponComponent implements OnInit {
  model: any = {};
  loading = false;
  msgs: Message[] = [];
  userform: FormGroup;
  emails: any = [];
  submitted: boolean;
  display = true;
  applicantNo = 0;
  IssueHistory: any = [];
  issue_type = 'Personal'

  constructor(
    public fb: FormBuilder,
    public adminService: AdminService,
    public appstate: AppState,
    public userstate: UserState,
  ) {}

  ngOnInit() {
    const email_sales = this.userstate.getUserEmail();
    this.model = {
      distributor: email_sales,
      applicant: ''
    }
    this.userform = this.fb.group({
      // 'email_sales': new FormControl('', Validators.compose([Validators.email,Validators.required])),
      // 'email_customer': new FormControl('', Validators.compose([Validators.email]))
    });
    // this.userform.addControl()

    this.applicantAdd(null);
    this.getIssueHistory();
  }

  getIssueHistory() {
    this.adminService.getIssuePersonalHistory()
      .subscribe(data => {
        this.IssueHistory = data;
      }, error2 => {
        console.log(error2);
      })
  }

  applicantAdd($event) {
    console.log($event, this.emails);
    this.emails.push({name: 'email_customer' + this.applicantNo, email: ''});
    this.userform.addControl('email_customer' + this.applicantNo, new FormControl('', Validators.compose([Validators.email, Validators.required])));
    const target = 'email_customer' + this.applicantNo;
    this.applicantNo++;

    observableEmpty().pipe(delay(200)).subscribe({
      complete: () => {
        const inputs = document.querySelectorAll('.input-applicant')
        console.log(inputs[inputs.length - 1])
        const el = inputs[inputs.length - 1] as HTMLElement;
        el.focus();
      }
    })
  }

  applicantRemove(applicant) {
    console.log(applicant)
    this.userform.removeControl(applicant.name);
    this.emails.splice(this.emails.indexOf(applicant), 1);
  }

  sendAgain(applicant) {
    this.adminService.resend(applicant).subscribe(data => {
      this.msgs.push({
        severity: 'success',
        summary: 'Success',
        detail: `Coupon for ${applicant.applicant_email} has been sent, please check your ViewSonic Account Email to confirm this Coupon`
      });
    });
  }

  onSubmit(value) {
    console.log(this.userform);

    console.log(this.emails);
    this.msgs = [];
    console.log(this.model);
    observableFrom(this.emails).pipe(map((item: any) => item.email), delay(1000), mergeMap(email => this.issueCoupon({
      distributor: this.model.distributor,
      applicant: email
    })),)
      .subscribe(
        (data: any) => {
          console.log(data);
          if (data.status === 403) {
            //this.msgs.push({severity: 'warning', summary: `Coupon for ${data.applicant} has been created`, detail: data.error.message});
            //this.msgs.push({severity: 'warning', summary: `Warning`, detail: 'Please check your ViewSonic Email Account to approve the coupon'});
          } else {
            this.msgs.push({
              severity: 'success',
              summary: 'Success',
              detail: `Coupon for ${data.applicant} Submitted, please check your ViewSonic Account Email to issue this Coupon`
            });
          }
        },
        error => {
          console.log(error);
          this.loading = false;
          this.msgs.push({severity: 'warning', summary: 'Warning', detail: (error.body)? JSON.parse(error._body).message: error.error.message});
        },
        () => {
          console.log('complete');
          this.getIssueHistory();
        }
      )
  }

  validator(model) {

    console.log(model)
    /*if (model.filter(item => item.email).length > 0) {
      return true
    }*/
    return false
  }

  issueCoupon(data: any) {
    /* if(this.appstate.environment.production){
       return this.adminService.goodoldissueCoupon(data)
     }*/
    return this.adminService.issuePersonalCoupon(data)
  }
}
