<div class="clips" id="step2">
  <div class="wrap-1200">
    <div class="left">
      <div class="content">
        <!-- <div class="mobile-pic">
          <img src="../../../../assets/Clips-signup/clip3m.png" alt="">
        </div> -->
        <div class="header-wrap">
          <p class="header">Congratulations</p>
          <img src="../../../../assets/Clips-signup/wave.svg" alt="">
        </div>
        <div class="content-text-wrap">
          <p class="content-text">You are now a member of the Clips Family</p>
          <p class="content-text">Thank you and enjoy educational videos anywhere.</p>
        </div>
        <button class="blue-btn" (click)="redirect()">
          <p>Login and start now</p>
        </button>
      </div>
    </div> 

    <div class="bg">
      <div class="img">
      </div>
    </div>
  </div>
</div>