import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StorageService} from "../../storage/service/storage.service";
import {AppState} from "@_services/app.service";
import { UserState } from '@_services/current/user';

@Component({
    selector: 'app-remote-transfer-history',
    templateUrl: './remote-transfer-history.component.html',
    styleUrls: ['./remote-transfer-history.component.scss']
})
export class RemoteTransferHistoryComponent implements OnInit {

    @Input() list: any[]
    //@Input() target: any

    _host;
    _target_unique;

    @Input() set host(host_user) {
        this._host = host_user;
        console.log(this.host);
        this.getHistory()
    }

    @Input() set target_unique(unique) {
        this._target_unique = unique;
        //this.getHistory()
    }

    @Output() onChange: EventEmitter<any>= new EventEmitter();
    @Output() onMessage: EventEmitter<any>= new EventEmitter();



    constructor(private storageService: StorageService, private appstate: AppState,public userstate: UserState) {
    }

    ngOnInit() {
        console.log(this._host);
        this.getHistory();
    }

    getHistory() {
        console.log(this._host);
        if(this._host){
            this.storageService.getThrowFileList(this._host).subscribe(
                (data: any) => this.list = data.list
            )
        }
    }

    deleteItem($event, item) {
        this.storageService.deleteThrow(item).subscribe(
            (data: any) => {
                this.getHistory()
                const senderId = this.userstate.getUserID()
                const msg = {
                    'sender': senderId,
                    'subject': 'file-import',
                    'action': 'file-import-recall',
                    'extra': {
                        'file': item,
                    }
                };

                this.appstate.mqttNotify({message: msg, device_id: this._target_unique}).subscribe(data=>{
                 //   this.onChange.emit(null);
                    this.onMessage.emit(item);
                })

            }
        )
    }

}
