import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-cookie-consent",
  templateUrl: "./cookie-consent.component.html",
  styleUrls: ["./cookie-consent.component.scss"],
})
export class CookieConsentComponent implements OnInit {
  public show = false;

  constructor(public cookieService: CookieService) {
    let showPolicy = !(
      this.cookieService.get("policyAccepted") ||
      localStorage.getItem("policyAccepted")
    );
    if (showPolicy) {
      this.show = true;
    }
  }

  ngOnInit() {}

  accept() {
    this.cookieService.set(
      "policyAccepted",
      "true",
      null,
      "/",
      ".myviewboard.com",
      true,
      'Lax'
    );
    localStorage.setItem("policyAccepted", "true");
    this.show = false;
  }
}
