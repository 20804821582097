<div
  style="height: 50vh"
  class="container"
     fxLayout="row"
     fxLayoutAlign="center center"
     fxLayoutGap="10px"
     fxLayoutGap.xs="0">
  <div class="item item-2" fxFlex="33"><p>
    <a href="https://zoom.us/oauth/authorize?response_type=code&client_id=EIgtlrvaRQWhzMQCjLfgg&redirect_uri=https://ssi.myviewboard.com/auth/zoom/return" target="_blank" rel="noopener noreferrer">
      <img src="https://marketplacecontent.zoom.us/zoom_marketplace/img/add_to_zoom.png" height="32" alt="Add to ZOOM" />
    </a>
  </p></div>
</div>
