export class Bindings {
    public static bindingLable: any = {
        google_drive: 'Google Drive',
        google_calendar: 'Google Calendar',
        dropbox: 'Dropbox',
        box: 'Box',
        zoom: 'Zoom',
        gotomeeting: 'GoToMeeting',
        one_drive: 'OneDrive (Personal Account)',
        one_drive_professional: 'OneDrive for Business (Work Account)',
        one_drive_business: 'OneDrive for Business (Work Account)',
        google_class: 'Google Classroom'
    };
    public static icons = {
        google_drive: 'icon-google-drive',
        google_calendar: 'icon-google-calendar',
        one_drive: 'icon-onedrive',
        one_drive_professional: 'icon-onedrive',
        one_drive_business: 'icon-onedrive',
        dropbox: 'icon-dropbox',
        box: 'icon-box',
        zoom: 'icon-zoom',
        gotomeeting: 'icon-gotomeeting',
        google_class: 'icon-google-class'
    };
    constructor() {  }
}
