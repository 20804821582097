import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AdminService } from '../../_services/admin.service';
import { AppState } from '../../_services/app.service';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Message, SelectItem } from 'primeng/primeng';
import { SweetAlertService } from 'ng2-sweetalert2';
import { AccountService } from '../../_services/account.service';
import { UserState } from '@_services/current/user';

@Component({
  selector: 'app-register-coupon',
  templateUrl: './register-coupon.component.html',
  styleUrls: ['./register-coupon.component.scss'],
  providers: [AdminService, SweetAlertService]
})
export class RegisterCouponComponent implements OnInit {
  model: any = {code: ''};
  loading = false;
  msgs: Message[] = [];
  userform: FormGroup;
  submitted: boolean;
  display = true;
  serialnumber = '';
  subscribes = [];
  msgs_serial: Message[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public userstate: UserState,
    private fb: FormBuilder,
    public adminService: AdminService,
    public swalService: SweetAlertService,
    public accountService: AccountService,
    public appstate: AppState
  ) {}

  ngOnInit() {
    if (this.appstate.localStorage.getItem('coupon')) {
      this.model.code = this.appstate.localStorage.getItem('coupon');
      console.log(this.model.code)
      this.onSubmit(this.model);
      localStorage.removeItem('coupon');
    }
    this.userform = this.fb.group({
      'code': new FormControl('', Validators.compose([Validators.required]))
    });
  }

  onSubmit(value) {
    this.msgs = [];
    console.log(this.model)
    this.adminService.enterCoupon(this.model).subscribe(
      data => {
        console.log(data)
        this.msgs.push({severity: 'success', summary: 'Success', detail: ''});
        this.swalService.swal(
          {
            title: ' Success',
            text: 'You have successfully upgraded to a complimentary 1 Year Personal Subscription !',
            type: 'success',
            buttonsStyling: false,
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonClass: 'btn btn-primary  mr-4',
            confirmButtonText: 'Confirm',
          }
        ).then(() => {
          this.accountService.logout()
            .subscribe(
              data => {
                console.log('logout')
                this.router.navigate(['/']);
              },
              error => {

              });
        })
        // this.msgs.push({severity:'success', summary:'Success', detail:data});
      },
      error => {
        console.log(error);
        this.loading = false;
        this.msgs = [];
        this.msgs.push({severity: 'error', summary: 'Error', 
          detail: (error.body)? JSON.parse(error._body).message: error.error.message});
      }
    )
  }

  onSerialSubmit() {
    this.subscribes.push(
      this.adminService.enterSerial({'serial': this.serialnumber, 'applicant':this.userstate.getUserID()}).subscribe(
        data => {
          this.msgs.push({severity: 'success', summary: 'Success', detail: ''});
          this.swalService.swal(
            {
              title: ' Success',
              text: 'You have successfully upgraded to a complimentary 1 Year Personal Subscription !',
              type: 'success',
              buttonsStyling: false,
              showCancelButton: false,
              allowOutsideClick: false,
              confirmButtonClass: 'btn btn-primary  mr-4',
              confirmButtonText: 'Confirm',
            }
          ).then(() => {
            this.accountService.logout()
              .subscribe(
                data => {
                  console.log('logout')
                  this.router.navigate(['/']);
                },
                error => {

                });
          })
        }, error => {
          this.msgs_serial = [];
          this.msgs_serial.push({severity: 'error', summary: 'Error', 
                detail: (error.body)? JSON.parse(error._body).message: error.error.message});
        })
    )
  }

  ngOnDestroy() {
    this.subscribes.forEach(s => {
      s.unsubscribe();
    });
  }

}
