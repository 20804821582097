import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { AccountService } from "@_services/account.service";
import { LanguageService } from "@_services/language.service";
import { SweetAlertService } from "ng2-sweetalert2";

@Injectable({
  providedIn: "root",
})
export class PermissionGuard implements CanActivate {
  constructor(
    public swal: SweetAlertService,
    private languageService: LanguageService,
    private accountService: AccountService,
    private router: Router
  ) {}

  canActivate() {
    let locationURL = window.location.href.split("/");
    if (locationURL.includes("list") || locationURL.includes("home")) {
      return true;
    }

    this.accountService.defaultEntity.subscribe((data) => {
      if (!data) return;
      if (data["id_role"] > 2) {
        this.router.navigate(["/home"]);
        this.noPermission();
      }
    });
    return true;
  }

  async noPermission() {
    const [title, content, close] = await Promise.all([
      this.languageService.get("Entity.No_Permission").toPromise(),
      this.languageService.get("Entity.No_Permission_Content").toPromise(),
      this.languageService.get("Entity.Close").toPromise(),
    ]);
    try {
      this.swal.swal({
        title: title,
        text: content,
        type: "error",
        buttonsStyling: false,
        showCancelButton: false,
        allowOutsideClick: false,
        confirmButtonClass: "btn btn-primary",
        confirmButtonText: close,
      });
    } catch (e) {
      console.error(e);
    }
  }
}
