import { Injectable } from '@angular/core';
import { SweetAlertService } from 'ng2-sweetalert2';
import { LanguageService } from './language.service';
import { switchMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { Observer } from 'rxjs/Observer';
import { forkJoin, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SweetalertService {

  constructor(
    private swalService: SweetAlertService,
    private languageService: LanguageService) {
  }

  success({ title = 'Success',
            text = '',
            textParam = {value: ''},
            buttonsStyling = false,
            showCancelButton = false,
            allowOutsideClick = false }) {
    return Observable.create((observer: Observer<any>) => {
      forkJoin([this.languageService.get(title),this.languageService.get(text)])
      .subscribe(([title, text]) => {
        this.swalService.swal(
          {
            title: title,
            text: text,
            type: 'success',
            buttonsStyling: buttonsStyling,
            showCancelButton: showCancelButton,
            allowOutsideClick: allowOutsideClick,
            confirmButtonClass: 'btn btn-primary',
            confirmButtonText: 'Confirm',
          }
        ).then(() => {
          observer.next('')
        })
      })
    })
  }

  info({ title = 'Info',
         text = '',
         textParam = {value: ''},
         buttonsStyling = false,
         showCancelButton = false,
         allowOutsideClick = false }) {
    return Observable.create((observer: Observer<any>) => {
       // TODO @Ian please think how to make more simple
      this.languageService.get(title).pipe(
        switchMap(title => this.languageService.get(text).pipe(
          map(text => ({ title, text }))
        ))
      ).subscribe(({ title, text }) => {
        this.swalService.swal(
          {
            title: title,
            text: text,
            type: 'info',
            buttonsStyling: buttonsStyling,
            showCancelButton: showCancelButton,
            allowOutsideClick: allowOutsideClick,
            confirmButtonClass: 'btn btn-primary mr-4',
            cancelButtonClass: 'btn btn-info',
            confirmButtonText: 'Confirm',
          }
        ).then(() => {
          observer.next('')
        })
      })
    })
  }

  warn({ title = 'Warning',
         text = '',
         textParam = {value: ''},
         buttonsStyling = false,
         showCancelButton = false,
         allowOutsideClick = false,
         input = 'text',
         inputValue  = '',
         inputValidator = (value) => {} }) {
    return Observable.create((observer: Observer<any>) => {
      // TODO @Ian please think how to make more simple
      this.languageService.get(title).pipe(
        switchMap(title => this.languageService.get(text).pipe(
          map(text => ({ title, text }))
        ))
      ).subscribe(({ title, text }) => {
        this.swalService.swal(
          {
            title: title,
            text: text,
            input: input,
            type: 'warning',
            inputValue: inputValue,
            inputValidator: inputValidator,
            buttonsStyling: buttonsStyling,
            showCancelButton: showCancelButton,
            allowOutsideClick: allowOutsideClick,
            confirmButtonClass: 'btn btn-primary mr-4',
            cancelButtonClass: 'btn btn-secondary mr-4',
            confirmButtonText: 'Confirm',
          }
        ).then((value) => {
          observer.next(value)
        },(dismiss)=>{
          observer.error(dismiss)
        })
      })
    })
  }

  error({ title = 'Error',
          text = '',
          textParam = {value: ''},
          buttonsStyling = false,
          showCancelButton = false,
          allowOutsideClick = false }) {
    // TODO @Ian please think how to make more simple
    if (isNumeric(text)) {
      text = `hostcode_${text}`
    }
    this.languageService.get(title).pipe(
      switchMap(title => this.languageService.get(text).pipe(
        map(text => ({ title, text }))
      ))
    ).subscribe(({ title, text }) => {
      this.swalService.swal(
        {
          title: title,
          text: text,
          type: 'error',
          buttonsStyling: buttonsStyling,
          showCancelButton: showCancelButton,
          allowOutsideClick: allowOutsideClick,
          confirmButtonClass: 'btn btn-primary',
          confirmButtonText: 'Confirm',
        }
      )
    })
  }
}

function isNumeric(num) {
  return !isNaN(num)
}
