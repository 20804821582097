import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AccountService } from "@_services/account.service";
import { AppState } from "@_services";
import { SweetAlertService } from "ng2-sweetalert2";
import { MvbwebComponent } from "../mvbweb/mvbweb.component";
import { MatDialog } from "@angular/material";
import { CompanionComponent } from "../companion/companion.component";
import { DeviceManagementComponent } from "../device-management/device-management.component";
import { DisplayComponent } from "../display/display.component";
import { MvbEntityComponent } from "../mvba/mvba.component";
import { MyviewboardComponent } from "../myviewboard/myviewboard.component";
import { OriginalContentComponent } from "../original-content/original-content.component";
import { MvbwComponent } from "../mvbw/mvbw.component";
import { Observable } from "rxjs";
import { LanguageService } from "@_services/language.service";
import { UserState } from "@_services/current/user";

@Component({
  selector: "app-mvb-faq",
  templateUrl: "./mvb-faq.component.html",
  styleUrls: ["./mvb-faq.component.scss"],
  providers: [SweetAlertService],
})
export class MvbFaqComponent implements OnInit {
  url: any;
  showSpinner = false;
  loading: boolean;
  articles: any;
  isMainSupport: boolean;
  savedCategory: any;
  isOnboarding = false;
  MVBW_And_articles: [];
  faq_path: string;
  current_path: any;
  path: string;
  isWhiteboard = false;
  url2: string;
  currentLang: Observable<string>;
  lang: string;
  isLogin$: boolean;
  pathItems = [
    {
      path: "mvbweb-faq",
      key: "myViewBoard.com",
      title: "Support.Web_faq",
      icon: "../../../../assets/icons/36x36.svg",
      template: MvbwebComponent,
      ticket: "web",
    },
    {
      path: "companion-faq",
      key: "myViewBoard Companion",
      title: "Support.Companion_faq",
      icon: "../../../../assets/support/icon_companion.svg",
      template: CompanionComponent,
      ticket: "companion",
    },
    {
      path: "myviewboard-faq",
      key: "myViewBoard Classroom",
      title: "Support.Classroom_faq",
      icon: "../../../../assets/support/icon_Classroom.svg",
      template: MyviewboardComponent,
      ticket: "quickboard",
    },
    {
      path: "mvbOC-faq",
      key: "myViewBoard Original Content",
      title: "Support.OG_faq",
      icon: "../../../../assets/support/icon_originals.svg",
      template: OriginalContentComponent,
      ticket: "OC",
    },
    {
      path: "mvbDM-faq",
      key: "myViewBoard Device Management",
      title: "Support.DM_faq",
      icon: "../../../images/Support_pics/DM.svg",
      template: DeviceManagementComponent,
      ticket: "DM",
    },
    {
      path: "mvbDisplay-faq",
      key: "myViewBoard Display",
      title: "Support.Display_faq",
      icon: "../../../../assets/support/icon_Display.svg",
      template: DisplayComponent,
      ticket: "Display",
    },
    {
      path: "onboarding-faq",
      key: "myViewBoard Entity & On boarding",
      title: "Support.onboarding_faq",
      icon: "",
      template: MvbEntityComponent,
      ticket: "onboarding",
    },
    {
      path: "mvbw-faq",
      key: "myViewBoard for Windows",
      title: "Support.Windows_faq",
      icon: "../../../../assets/support/icon_Whiteboard.svg",
      template: MvbwComponent,
      ticket: "whiteboard",
    },
  ];
  constructor(
    private router: Router,
    private accoutService: AccountService,
    private appState: AppState,
    private swalService: SweetAlertService,
    private dialog: MatDialog,
    private languageService: LanguageService,
    private userstate: UserState
  ) {}

  ngOnInit() {
    this.currentLang = this.languageService.loadCurrentLang();
    this.languageService.loadCurrentLang().subscribe((i) => (this.lang = i));
    this.path = window.location.pathname.split("/")[1];
    this.faq_path = window.location.pathname.split("/")[2];
    this.isWhiteboard = this.faq_path == "mvbw-faq" ? true : false;
    this.isMainSupport = this.path == "main-support" ? true : false;
    this.isLogin$ = this.userstate.hasLogin();
    this.pathItems.map((i) => {
      if (i.path === this.faq_path) this.current_path = i;
    });
    this.isOnboarding =
      this.current_path.key == "myViewBoard Entity & On boarding"
        ? true
        : false;
    this.showLoading(true);
    this.checkSavedNumber();
  }

  showLoading(value: boolean) {
    this.loading = value;
    this.showSpinner = value;
  }

  getCategoryFromSession() {
    this.savedCategory = this.appState.get("faq_category");
    this.savedCategory.filter((e) => {
      if (e.title === this.current_path.key) {
        this.url = e.category_number;
        this.loadArticle();
      }
      if (this.isWhiteboard) {
        if (e.title === "myViewBoard for Android") {
          this.url2 = e.category_number;
          this.loadMVBW_Android();
        }
      }
    });
  }

  checkSavedNumber() {
    if (this.appState.get("faq_category")) {
      this.getCategoryFromSession();
    } else {
      this.accoutService.getSupportCategory().subscribe(
        (res) => {
          this.appState.set("faq_category", res["rows"]);
          this.getCategoryFromSession();
        },
        () => {
          this.showError();
        }
      );
    }
  }

  loadArticle() {
    if (this.appState.get(this.current_path.key)) {
      this.articles = this.appState.get(this.current_path.key);
      this.showLoading(false);
    } else {
      this.accoutService.geSupportArticles(this.url, this.lang).subscribe(
        (res) => {
          this.appState.set(this.current_path.key, res["rows"]);
          this.articles = res["rows"];
          this.showLoading(false);
        },
        () => {
          this.showLoading(false);
          this.showError();
        }
      );
    }
  }

  loadMVBW_Android() {
    if (this.appState.get("MVBA_article")) {
      this.MVBW_And_articles = this.appState.get("MVBA_article");
      this.showLoading(false);
    } else {
      this.accoutService.geSupportArticles(this.url2, this.lang).subscribe(
        (res) => {
          this.appState.set("MVBA_article", res["rows"]);
          this.MVBW_And_articles = res["rows"];
          this.showLoading(false);
        },
        () => {
          this.showLoading(false);
          this.showError();
        }
      );
    }
  }

  redirect() {
    if (this.isMainSupport)
      return this.openPopUp(this.current_path.template, this.current_path.key);
    this.router.navigate(["/signin"], {
      queryParams: {
        returnurl: "/main-support",
        support_ticket: this.current_path.ticket,
      },
    });
  }

  openPopUp(component: Component, tracking: string) {
    this.openDialog(component);
  }

  openDialog(Component: any) {
    this.dialog.open(Component, {
      panelClass: "my-dialog",
      width: "42em",
      maxWidth: "80vw",
      minWidth: "310px",
      height: "85vh",
      data: { lang: this.currentLang },
    });
  }

  showError() {
    this.swalService.swal({
      title: "Oops...",
      text: "Something went wrong, please try again",
      type: "warning",
      buttonsStyling: false,
      showCancelButton: false,
      confirmButtonClass: "btn btn-primary",
      confirmButtonText: "Confirm",
    });
  }
}
