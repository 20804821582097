
<div *ngIf="appstate.get('hostEnv')==='flutter'; else elseTmpl" [dir]="languageService.dir | async" >
  <router-outlet></router-outlet>
  <app-language-select></app-language-select>
</div>
<ng-template #elseTmpl >
  <div [dir]="languageService.dir | async" >
    <app-header></app-header>
    <div #mainPanel class="full-layout-main">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>

    <!-- <app-footer></app-footer> -->

    <app-remote-transfer-dialog></app-remote-transfer-dialog>
  </div>
</ng-template>

