import { Directive, EventEmitter, HostListener, OnInit, Output, OnDestroy, Input, ViewContainerRef, ElementRef } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from "rxjs/Subscription";
import 'rxjs/add/operator/debounceTime';
import { CheckboxModule, Checkbox } from 'primeng/primeng';
@Directive({
    selector: '[funcDebounce]'
})
export class DebounceFunctionDirective implements OnInit, OnDestroy {
    current_com: ViewContainerRef
    @Input() func
    @Input() delay = 500;
    @Input() connectFunc: EventEmitter<any>
    @Output() debounceFunc = new EventEmitter();
    private event = new Subject<any>();
    private subscription: Subscription;
    constructor(private vcRef: ViewContainerRef, public el: Checkbox) {
        this.current_com = this.vcRef['_data'].componentView.component
    }
    ngOnInit() {
        const current_func = this.func
        const com = this.current_com
        if (!this.func) throw Error('please connect this component function with property "func"')
        if (typeof this.func !== 'string') throw Error('func must string to override event')
        this.subscription = com[`${current_func}`].debounceTime(this.delay)
            .subscribe(e => {
                this.debounceFunc.emit(e)
            });
    }
    ngAfterViewInit(): void {
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}