import { Component, OnInit, ViewChild } from "@angular/core";
import { AccountService } from "app/_services/account.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MatDialog } from "@angular/material";
import { SubmitSuccessComponent } from "../submit-success/submit-success.component";
import { SweetAlertService } from "ng2-sweetalert2";
import { AppState } from "@_services";
import { LanguageService } from "@_services/language.service";
import { FileuploadComponent } from "../fileupload/fileupload.component";
import { Observable } from "rxjs";

@Component({
  selector: "app-device-management",
  templateUrl: "./device-management.component.html",
  styleUrls: ["./device-management.component.scss"],
})
export class DeviceManagementComponent implements OnInit {
  @ViewChild("fileupload") file: FileuploadComponent;
  openUpload = false;
  loading = false;
  MVB: FormGroup;
  currentLang: Observable<string>;
  IFP_Model = [
    "IFP5550",
    "IFP6530",
    "IFP6550",
    "IFP7530",
    "IFP7550",
    "IFP8650",
    "IFP5550-2",
    "IFP6550-2",
    "IFP7550-2",
    "IFP8650-2",
    "IFP5550-3",
    "IFP6550-3",
    "IFP7550-3",
    "IFP8650-3",
    "N/A",
  ];

  constructor(
    public accountService: AccountService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DeviceManagementComponent>,
    public dialog: MatDialog,
    private swalService: SweetAlertService,
    private appState: AppState,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.MVB = this.fb.group({
      Model: new FormControl("IFP5550"),
      AndroidBuildNumber: new FormControl(""),
      Version: new FormControl(""),
      title: new FormControl("", [Validators.required]),
      FileGroup: this.fb.group({
        Description: new FormControl("", [Validators.required]),
        FlagCtrl: new FormControl(false),
      }),
    });
    this.currentLang = this.languageService.loadCurrentLang();
  }

  errorPop() {
    this.swalService.swal({
      title: "Error",
      text: "Something went wrong, please try again",
      type: "error",
      buttonsStyling: false,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonClass: "btn btn-primary  mr-4",
      confirmButtonText: "Confirm",
    });
  }

  sendTicket() {
    this.file.isClicked = true;
    if (!this.MVB.valid) return false;
    const text = this.file.FileGroup.controls["Description"].value;
    const Title = this.MVB.controls["title"].value;
    const version = this.MVB.controls["Version"].value;
    const AndroidNumber = this.MVB.controls["AndroidBuildNumber"].value;
    const model = this.MVB.controls["Model"].value;
    const FullModel = "ViewBoard Model :";
    const Android = `ViewBoard Android Build Number: ${
      AndroidNumber ? AndroidNumber : "N/A"
    }`;
    const Version = `Device Management Agent Version: ${
      version ? version : "N/A"
    }`;
    this.loading = true;
    const ticket = {
      product: "mvb-0008",
      category: "Device Management",
      title: Title,
      description:
        FullModel +
        model +
        `\n` +
        Android +
        "\n" +
        Version +
        "\n" +
        "Description: " +
        text,
      screenshots: this.file.keylist,
    };
    // console.log('send ticket', ticket)
    this.accountService.d365SendCase(ticket).subscribe(
      () => {
        if (this.appState._state["ticket-cases"])
          delete this.appState._state["ticket-cases"];
        this.loading = false;
        this.dialogRef.close();
        this.dialog.open(SubmitSuccessComponent, {
          width: "400px",
          minWidth: "310px",
          height: "auto",
        });
      },
      () => {
        this.loading = false;
        this.errorPop();
      }
    );
  }
}
