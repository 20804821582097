import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { AppState } from "@_services";
import { UserState } from "@_services/current/user";
import { AppInsightsService } from "@markpieszak/ng-application-insights";
@Component({
  selector: "app-downloadpage",
  templateUrl: "./downloadpage.component.html",
  styleUrls: ["./downloadpage.component.scss"],
})
export class DownloadpageComponent implements OnInit {
  items = [];
  item = {
    productName: {
      Whiteboard: "whiteboard",
      Companion: "companion",
      myViewBoard_Extension: "myviewboard_extension",
      Display: "display",
      Manager: "manager",
      Sens: "sens",
    },
    icon: {
      chrome: "/assets/Chrome.svg",
      google: "/assets/Google.svg",
      windows: "/assets/Windows.svg",
      windows_store: "/assets/windows_store.png",
      android: "/assets/Android.svg",
      apple: "/assets/apple.png",
    },
    text: {
      w_64: "Windows",
      a_apk: "Android APK",
      g_play: "Google Play",
      c_ext: "Chrome extension",
      a_store: "App Store",
    },
  };

  whiteboard_href = {
    stage: {
      windows: "https://dl.myviewboard.com/latest?&64&stage&" + Date.now(),
      android: "https://dl.myviewboard.com/latest?&android_open&stage",
      google:
        "https://play.google.com/store/apps/details?id=com.viewsonic.droid",
    },
    prod: {
      windows: "https://dl.myviewboard.com/latest?64&" + Date.now(),
      android: "https://dl.myviewboard.com/latest?android_open",
      google:
        "https://play.google.com/store/apps/details?id=com.viewsonic.droid",
    },
  };
  display_href = {
    stage: {
      windows: "https://dl.myviewboard.com/latest?display_windows&stage",
      android: "https://dl.myviewboard.com/latest?display_open&stage",
    },
    prod: {
      windows: "https://dl.myviewboard.com/latest?display_windows",
      android: "https://dl.myviewboard.com/latest?display_open",
    },
  };
  sens_href = {
    stage: {
      windows: "https://dl.myviewboard.com/latest?sens_windows&stage",
      android: "https://dl.myviewboard.com/latest?sens_android&stage",
    },
    prod: {
      windows: "https://dl.myviewboard.com/latest?sens_windows",
      android: "https://dl.myviewboard.com/latest?sens_android",
    },
  };
  constructor(
    private dialogref: MatDialogRef<DownloadpageComponent>,
    private appState: AppState,
    private userstate: UserState,
    public insight: AppInsightsService
  ) {}

  ngOnInit() {
    const isProd = this.appState.isProd();
    const whiteboard = isProd
      ? this.whiteboard_href.prod
      : this.whiteboard_href.stage;
    const display = isProd ? this.display_href.prod : this.display_href.stage;
    const manager = isProd
      ? "https://dl.myviewboard.com/latest?device_management"
      : "https://dl.myviewboard.com/latest?&device_management&stage";
    const recorder = isProd
      ? "https://dl.myviewboard.com/latest?recorder_open"
      : "https://dl.myviewboard.com/latest?&recorder_open&stage";
    const live = isProd
      ? "https://dl.myviewboard.com/latest?livestream"
      : "https://dl.myviewboard.com/latest?&livestream&stage";
    const sens = isProd ? this.sens_href.prod : this.sens_href.stage;

    this.items = [
      {
        imgurl: "/assets/Whiteboard.svg",
        classes: "mt5",
        label: "Home.Start_MVB",
        links: [
          {
            imgurl: this.item.icon.windows,
            class: "inline",
            text: "Download_Page.Windows",
            link: `${whiteboard.windows}`,
            imgClass: "toHover",
            productName: this.item.productName.Whiteboard,
            action_text: "download_page.windows",
          },
          {
            imgurl: this.item.icon.android,
            class: "inline",
            text: "Download_Page.Android",
            link: `${whiteboard.android}`,
            imgClass: "toHover",
            productName: this.item.productName.Whiteboard,
            action_text: "download_page.android",
          },
          {
            imgurl: this.item.icon.google,
            class: "inline",
            text: "Download_Page.Google",
            link: `${whiteboard.google}`,
            imgClass: "toHover",
            productName: this.item.productName.Whiteboard,
            action_text: "download_page.google",
          },
          {
            imgurl: this.item.icon.apple,
            class: "inline",
            text: "Download_Page.Apple",
            link: "https://apps.apple.com/us/app/myviewboard-whiteboard/id1546982350",
            imgClass: "toHover resize",
            productName: this.item.productName.Whiteboard,
            action_text: "download_page.apple",
          },
        ],
      },
      {
        imgurl: "/assets/Companion.svg",
        classes: "mt5",
        label: "myViewBoard Companion",
        links: [
          {
            imgurl: this.item.icon.google,
            class: "inline",
            text: "Download_Page.Google",
            link: "https://play.google.com/store/apps/details?id=com.viewsonic.myviewboard",
            imgClass: "toHover",
            productName: this.item.productName.Companion,
            action_text: "download_page.google",
          },
          {
            imgurl: this.item.icon.apple,
            class: "inline",
            text: "Download_Page.Apple",
            link: "https://apps.apple.com/us/app/myviewboard/id1321808551",
            imgClass: "toHover resize",
            productName: this.item.productName.Companion,
            action_text: "download_page.apple",
          },
        ],
      },
      {
        imgurl: "/assets/myViewBoard.png",
        classes: "MVB_Ext",
        label: "MVB_Ext",
        links: [
          {
            imgurl: this.item.icon.chrome,
            text: "Download_Page.Chrome",
            link: "https://chrome.google.com/webstore/detail/myviewboard-extension/bflolcmnokpcochfhappdfipeficedjf",
            imgClass: "toHover",
            productName: this.item.productName.myViewBoard_Extension,
            action_text: "download_page.chrome",
          },
        ],
      },
      {
        imgurl: "/assets/Display.svg",
        classes: "mt5",
        label: "Download_Page_Display",
        links: [
          {
            imgurl: this.item.icon.windows,
            class: "inline",
            text: "Download_Page.Windows",
            link: `${display.windows}`,
            imgClass: "toHover",
            productName: this.item.productName.Display,
            action_text: "download_page.windows",
          },
          {
            imgurl: this.item.icon.android,
            class: "inline",
            text: "Download_Page.Android",
            link: `${display.android}`,
            imgClass: "toHover",
            productName: this.item.productName.Display,
            action_text: "download_page.android",
          },
          {
            imgurl: this.item.icon.google,
            class: "inline",
            text: "Download_Page.Google",
            link: "https://play.google.com/store/apps/details?id=com.mvbcast.crosswalk",
            imgClass: "toHover",
            productName: this.item.productName.Display,
            action_text: "download_page.google",
          },
        ],
      },
      {
        imgurl: "/assets/Manager.svg",
        classes: "mt5",
        label: "Entity.Device_Management",
        links: [
          {
            imgurl: this.item.icon.android,
            text: "Download_Page.Android",
            link: `${manager}`,
            imgClass: "toHover",
            productName: this.item.productName.Manager,
            action_text: "download_page.android",
          },
        ],
      }
    ];
  }
  trackClickItem({ action_text, link, productName }) {
    this.insight.trackEvent("click", {
      category: "download_page." + productName,
      action: action_text,
      link: link,
    });
  }

  close() {
    this.dialogref.close();
  }
}
