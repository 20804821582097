import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { AppState } from "../../../_services";
import { PollingQuizService } from "../../../_services/pollingQuiz.service";
import { SweetAlertService } from "ng2-sweetalert2";
import { LanguageService } from "@_services/language.service";
import { AppInsightsService } from "@markpieszak/ng-application-insights";

enum KEY_CODE {
  ENTER = 13,
  LEFT_ARROW = 37,
  UP_ARROW = 38,
  RIGHT_ARROW = 39,
  DOWN_ARROW = 40,
  DIGIT_0 = 48,
  DIGIT_1 = 49,
  DIGIT_2 = 50,
  DIGIT_3 = 51,
  DIGIT_4 = 52,
  DIGIT_5 = 53,
  DIGIT_6 = 54,
  DIGIT_7 = 55,
  DIGIT_8 = 56,
  DIGIT_9 = 57,
  NUMPAD_0 = 96,
  NUMPAD_1 = 97,
  NUMPAD_2 = 98,
  NUMPAD_3 = 99,
  NUMPAD_4 = 100,
  NUMPAD_5 = 101,
  NUMPAD_6 = 102,
  NUMPAD_7 = 103,
  NUMPAD_8 = 104,
  NUMPAD_9 = 105,
}

@Component({
  selector: "app-polling-quiz",
  templateUrl: "./polling-quiz.component.html",
  styleUrls: ["./polling-quiz.component.scss"],
  providers: [SweetAlertService, PollingQuizService],
})
export class PollingQuizComponent implements OnInit, OnDestroy {
  user: any;
  question: any;
  answer: any;
  isLocked = false;
  isLoaded = false;
  isBtnSubmitFocused = false;
  isHostLogout = false;
  timerInterval: any;
  timerCount: string;
  multiple_options: any[];

  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (!this.question || this.isLocked) {
      return;
    }

    if (this.question.type === "multiple_choice") {
      let item;
      if (
        event.keyCode === KEY_CODE.DIGIT_1 ||
        event.keyCode === KEY_CODE.NUMPAD_1
      ) {
        item = this.question.options[0];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_2 ||
        event.keyCode === KEY_CODE.NUMPAD_2
      ) {
        item = this.question.options[1];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_3 ||
        event.keyCode === KEY_CODE.NUMPAD_3
      ) {
        item = this.question.options[2];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_4 ||
        event.keyCode === KEY_CODE.NUMPAD_4
      ) {
        item = this.question.options[3];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_5 ||
        event.keyCode === KEY_CODE.NUMPAD_5
      ) {
        item = this.question.options[4];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_6 ||
        event.keyCode === KEY_CODE.NUMPAD_6
      ) {
        item = this.question.options[5];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_7 ||
        event.keyCode === KEY_CODE.NUMPAD_7
      ) {
        item = this.question.options[6];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_8 ||
        event.keyCode === KEY_CODE.NUMPAD_8
      ) {
        item = this.question.options[7];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_9 ||
        event.keyCode === KEY_CODE.NUMPAD_9
      ) {
        item = this.question.options[8];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_0 ||
        event.keyCode === KEY_CODE.NUMPAD_0
      ) {
        item = this.question.options[9];
      }
      if (item) {
        this.multiple_options = this.multiple_options.map((x) => {
          if (x.value === item) {
            x.checked = !x.checked;
          }
          return x;
        });
      }
    } else if (this.question.type === "true_false") {
      if (event.keyCode === KEY_CODE.UP_ARROW) {
        this.answer = this.question.options[0];
      } else if (event.keyCode === KEY_CODE.DOWN_ARROW) {
        this.answer = this.question.options[1];
      }
    } else if (this.question.type === "rating") {
      if (event.keyCode === KEY_CODE.LEFT_ARROW) {
        if (this.answer - 1 >= this.question.options[0]) {
          this.answer -= 1;
        }
      } else if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
        if (this.answer + 1 <= this.question.options[1]) {
          this.answer += 1;
        }
      }
    } else if (this.question.type === "voting") {
      if (
        event.keyCode === KEY_CODE.DIGIT_1 ||
        event.keyCode === KEY_CODE.NUMPAD_1
      ) {
        this.answer = this.question.options[0];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_2 ||
        event.keyCode === KEY_CODE.NUMPAD_2
      ) {
        this.answer = this.question.options[1];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_3 ||
        event.keyCode === KEY_CODE.NUMPAD_3
      ) {
        this.answer = this.question.options[2];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_4 ||
        event.keyCode === KEY_CODE.NUMPAD_4
      ) {
        this.answer = this.question.options[3];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_5 ||
        event.keyCode === KEY_CODE.NUMPAD_5
      ) {
        this.answer = this.question.options[4];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_6 ||
        event.keyCode === KEY_CODE.NUMPAD_6
      ) {
        this.answer = this.question.options[5];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_7 ||
        event.keyCode === KEY_CODE.NUMPAD_7
      ) {
        this.answer = this.question.options[6];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_8 ||
        event.keyCode === KEY_CODE.NUMPAD_8
      ) {
        this.answer = this.question.options[7];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_9 ||
        event.keyCode === KEY_CODE.NUMPAD_9
      ) {
        this.answer = this.question.options[8];
      } else if (
        event.keyCode === KEY_CODE.DIGIT_0 ||
        event.keyCode === KEY_CODE.NUMPAD_0
      ) {
        this.answer = this.question.options[9];
      }
    }
    if (event.keyCode === KEY_CODE.ENTER && this.isBtnSubmitFocused) {
      this.submitAnswer();
    }
  }

  constructor(
    private router: Router,
    private appstate: AppState,
    private pollingQuizService: PollingQuizService,
    private swalService: SweetAlertService,
    private languageService: LanguageService,
    private appInsightsService: AppInsightsService
  ) {}

  ngOnInit() {
    this.languageService.init().subscribe(() => {});
    this.user = this.appstate.localStorage.getItem("quizUser");
    // console.log('quiz', data);
    if (this.user) {
      const options = {
        clientId: this.user.user_id,
        username: "polling_quiz",
        password: btoa(this.user.unique_id),
      };
      this.pollingQuizService.mqtt_connect(options);
      this.pollingQuizService.eventMessage.subscribe((data: any) => {
        // console.log(data);
        this.handleMessage(data);
      });
      this.pollingQuizService.eventClose.subscribe(() => {
        this.router.navigate([`${this.user.host_name}`]);
      });
    } else {
      this.router.navigate(["home"]);
    }
  }

  ngOnDestroy() {
    this.pollingQuizService.mqtt_disconnect();
  }

  handleMessage(data) {
    clearInterval(this.timerInterval);
    const message = JSON.parse(data);
    switch (message.action) {
      case "start":
        this.isLocked = false;
        this.answer = null;
        this.question = message;
        this.startTimer();
        // set default value if question type is rating
        if (this.question.type === "rating") {
          this.answer = parseInt(this.question.options[0]);
        } else if (this.question.type === "multiple_choice") {
          this.multiple_options = this.question.options.map((x) => {
            return {
              value: x,
              checked: false,
            };
          });
        }
        break;
      case "stop":
        this.question = null;
        break;
      case "host_login":
        this.question = null;
        this.isHostLogout = false;
        break;
      case "host_logout":
        this.question = null;
        this.isHostLogout = true;
        break;
      case "random_draw":
        this.question.options = message.content.map((x) => x.option);
        break;

      default:
        break;
    }
  }

  submitAnswer() {
    if (this.question.type === "multiple_choice") {
      this.answer = [];
      this.multiple_options.forEach((x) => {
        if (x.checked) {
          this.answer.push(x.value);
        }
      });
    }
    if (
      this.answer === undefined ||
      this.answer === null ||
      this.answer === "" ||
      this.answer.length === 0
    ) {
      return 0;
    }
    this.isLoaded = true;
    let answer_arr = [];

    if (!Array.isArray(this.answer)) {
      answer_arr = [this.answer.toString()];
    } else {
      answer_arr = this.answer;
    }
    const submitData = {
      user_id: this.user.user_id,
      question_id: this.question.question_id,
      data: {
        answer: answer_arr,
      },
    };
    // console.log('submitData', submitData);
    this.pollingQuizService.submitAnswer(submitData).subscribe(
      (data: any) => {
        this.isLoaded = false;
        this.isLocked = true;
        this.appInsightsService.trackEvent("portal_polls", {
          category: "Participation",
          label: JSON.stringify({ host_url: location.href}),
        });
      },
      (error) => {
        this.isLoaded = false;
        this.swalService.swal({
          title: "Error",
          text: error.body
            ? JSON.parse(error._body).message
            : error.error.message,
          type: "error",
          buttonsStyling: false,
          showCancelButton: false,
          allowOutsideClick: false,
          confirmButtonClass: "btn btn-primary mr-4",
          confirmButtonText: "Confirm",
        });
      }
    );
  }

  toInt(data) {
    return parseInt(data, 10);
  }

  formatTime(time: number) {
    let minutes: any = Math.floor(time / 60);
    let seconds: any = time % 60;

    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    return `${minutes} : ${seconds}`;
  }

  startTimer() {
    if (!this.question.time) {
      return;
    }

    let timeLeft = parseInt(this.question.time);
    this.timerCount = this.formatTime(timeLeft);

    this.timerInterval = setInterval(() => {
      timeLeft -= 1;
      this.timerCount = this.formatTime(timeLeft);

      if (timeLeft === 0) {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  sliderMove($event) {
    this.answer = $event.value;
  }
}
