import {
  of as observableOf,
  from as observableFrom,
  Observable,
  forkJoin,
  pipe,
} from "rxjs";
import { catchError, map, tap, concatMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationStart,
} from "@angular/router";
import { AccountService } from "../_services/account.service";
import { AppState } from "../_services/app.service";
import { Title } from "@angular/platform-browser";
import { AppInsightsService } from "@markpieszak/ng-application-insights";
import { UserState } from "@_services/current/user";
import { OAuthService } from "angular-oauth2-oidc";
import { environment } from "@environment";
import { OIDCAuthGuard } from "./oidc.guard";
import { UserGuideService } from "@_services/user_guide.service";
declare var ga: any;

@Injectable()
export class AuthGuard extends OIDCAuthGuard implements CanActivate {
  tricky_redirect = 0;
  constructor(
    public router: Router,
    private appstate: AppState,
    public userstate: UserState,
    private accountService: AccountService,
    private appInsightsService: AppInsightsService,
    private titleService: Title,
    public oauthService: OAuthService,
    private userGuide: UserGuideService
  ) {
    super(router, oauthService);
  }
  async registerCurrentUser(user_data: CurrentUser) {
    this.userstate.userLogin(user_data);
    const data = await this.accountService
      .getQuestionnaire(
        this.userstate.getUserID(),
        this.userstate.getUser().shard_region
      )
      .toPromise();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const getPermissionScope = this.accountService.getPermissionScope.bind(
      this.accountService
    );
    const getMyPermission = this.accountService.getPermissionByScope.bind(
      this.accountService,
      this.appstate.appScope
    );
    const getMyServices = this.accountService.getServices.bind(
      this.accountService
    );

    const setMyPermission = (data) => {
      const { list } = data;
      if (!list) {
        return Observable.of(false);
      }
      this.userstate
        .getCurrentUser()
        .getCurrentPermission()
        .setMyPermission({ scope: this.appstate.appScope, list });
      return Observable.of(true);
    };
    const setPermissionScope = (data) => {
      const { list } = data;
      if (!list) {
        return Observable.of(false);
      }
      this.userstate.getCurrentUser().setPermissionScope(list);
      return Observable.of(true);
    };
    const setMyServices = (data) => {
      this.userstate.getCurrentUser().getCurrentPermission().setServices(data);
      return Observable.of(true);
    };
    const errorHandler = (err) => {
      console.log(err);
      if (
        window.location.pathname === "/account/password" &&
        window.location.search === "?isFirst=true"
      ) {
        this.router.navigate(["password-reset"]);
        return;
      }
      if (route.data.bypass) {
        this.tricky_redirect = 0;
        return observableOf(true);
      }
      if (this.userstate.getCurrentUser()) {
        this.userstate.userLogout();
        try {
          // skip mqtt disconnect
          if (this.appstate.mqtt) {
            this.appstate.mqtt.end(true);
          }
        } catch (err) {
          console.log(err);
        }
      }
      if (this.tricky_redirect <= 2) {
        this.router.navigate(["signin"], {
          queryParams: { returnurl: state.url },
        });
        this.tricky_redirect++;
      }
      return observableOf(false);
    };
    const is_login = this.userstate.hasLogin();
    console.log('is_login'+is_login)
    if (is_login) {
      const is_permission_scope = this.userstate
        .getCurrentUser()
        .getPermissionScope();
      if (is_permission_scope) return observableOf(true);
    }

    let process_flow: Observable<any> = this.accountService.getSession();
    if (this.userstate.isOIDCFlow()) {
      process_flow = this.getUserDataByCodeOrToken().pipe(
        tap((x) => {
          this.accountService.setJWT(this.oauthService.getAccessToken());
        }),
        map(this.transformCurrentUse.bind(this)),
        catchError(() => {
          // when token all fail
          this.accountService.removeUser();
          return Observable.of(false);
        }),
        map((x) => {
          const returnUrl = `${this.appstate.sessionStorage.getItem("return")}`;
          if (returnUrl) this.appstate.sessionStorage.removeItem("return");
          if (returnUrl && !/^(null|\/home|home)/.test(returnUrl)) {
            location.href = decodeURIComponent(returnUrl);
            throw Error(`not good`);
          }
          return x;
        })
      );
    }
    return process_flow.pipe(
      pipe(
        // set submodule
        tap(async (x) => {
          const data: string[] = await this.accountService
            .getSubModule()
            .toPromise();
          this.userstate.getCurrentUser().setSubmodule(data);
        })
      ),
      tap(this.registerCurrentUser.bind(this)),
      tap(this.registerRocketChat.bind(this)),
      tap(this.checkNeedsRedirect.bind(this)),
      concatMap(getPermissionScope),
      concatMap(setPermissionScope),
      concatMap(getMyPermission),
      concatMap(setMyPermission),
      concatMap(getMyServices),
      concatMap(setMyServices),
      concatMap((x) => {
        return Observable.forkJoin(
          this.accountService.hasEntityAdmin().map((is_admin) => {
            this.userstate.getCurrentUser().setEntityAdmin(is_admin);
          })
        ).map(() => {
          return x;
        });
      }),
      map((x) => {
        // hide code
        if (route.routeConfig.path === "home" && route.queryParams["code"]) {
          this.router.navigate(["."], { queryParams: {} });
        }
        return x;
      }),
      catchError(errorHandler)
    );
  }
  registerRocketChat(currentUserData: CurrentUser) {
    if (!(<any>window).RocketChat) return;
    (<any>window).RocketChat(function () {
      this.registerGuest({
        name: currentUserData.first_name,
        email: currentUserData.email,
      });
      this.showWidget();
    });
  }
  checkNeedsRedirect() {
    if (!sessionStorage.getItem("redirect-uri")) return;
    const redirect_uri = sessionStorage.getItem("redirect-uri");
    sessionStorage.removeItem("redirect-uri");
    this.router.navigate([redirect_uri]);
  }
  isUserExist(currentUserData: CurrentUser) {
    return currentUserData ? true : false;
  }
  transformCurrentUse(x): CurrentUser {
    const {
      id,
      name,
      first_name,
      last_name,
      email,
      default_entity,
      locale,
      picture,
      third_id,
      agreement_apply,
      isInitial,
      isLock,
      isUse,
      shard_region,
    } = x.info;
    const user: CurrentUser = {
      id,
      email,
      name,
      first_name,
      last_name,
      pic_url: picture,
      third_id,
      locale,
      default_entity,
      is_agreement: agreement_apply,
      isInitial,
      isLock,
      isUse,
      shard_region,
    };
    return user;
  }
}



interface CurrentUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  name: string;
  pic_url: string;
  third_id: string;
  locale?: string;
  default_entity: string;
  is_agreement: boolean;
  isInitial: boolean;
  isLock: boolean;
  isUse: boolean;
  shard_region: string;
}
