<div id="header">
  <div id="icon">
    <i class="icon-header_download_icon downloadIcon"></i>
    <p [translate]="'DownloadPage.Download'">Download</p>
  </div>
  <div (click)="close()">
    <img class="cross" src="/assets/close.svg" alt="Logo" />
  </div>
</div>
<div id="itemContainer">
  <ng-template ngFor let-item [ngForOf]="items">
    <div class="itemWrap">
      <img [class]="item.classes" [src]="item.imgurl" alt="Logo" />
      <span [translate]="item.label" class="label"></span>
      <div class="linkWrap">
        <ng-template ngFor let-link [ngForOf]="item.links">
          <div [class]="link.class">
            {{ item.text }}
            <a
              [href]="link.link"
              download
              target="_blank"
              matTooltip="{{ link.text | translate }}"
              (click)="trackClickItem(link)"
              ><img [class]="link.imgClass" [src]="link.imgurl" alt="Link"
            /></a>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>
