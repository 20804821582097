/**
 * Created by angle on 1/18/18.
 */
import { RequestOptions, URLSearchParams, Headers } from '@angular/http';

export class ServiceBase {
  defautDividedPage = 10

  getDefautDividedPage(){
    return this.defautDividedPage
  }

  setDefautDividedPage(page: number){
    this.defautDividedPage = page
  }

  setURL(page: number, search: string, sort: string) {
    const params: URLSearchParams = new URLSearchParams();
    if (!isNaN(page)) {
      params.set('count', String(this.defautDividedPage));
      params.set('page', String(page));
    }
    if (search != '' && typeof search === 'string') {
      params.set('q', String(search));
    } else if (typeof search === 'object') {
      const key = Object.keys(search)
      key.forEach(function (item) {
        params.set(String(item), String(search[item]));
      })
    }
    if (sort != '' && sort) {
      params.set('sort', String(sort));
    }
    return this.setCredentials(params);
  }

  setCredentials(params?: URLSearchParams) {
    const headers = new Headers()
    headers.set('Accept', ' application/json')
    headers.set('content-type', ' application/json')
    const requestOptions = new RequestOptions();
    if (params) {
      requestOptions.search = params
    }
    requestOptions.headers = headers
    requestOptions.withCredentials = true
    return requestOptions;
  }

  makeSearchParams(Obj: any){
    const params: URLSearchParams = new URLSearchParams();
    const key = Object.keys(Obj)
    key.forEach(function (item) {
      params.set(String(item), String(Obj[item]));
    })
    return params
  }

  /**
   * paser url
   * @param str 'test/:test/test/:test2'
   * @param obj {test:1234,test2:'angle'}
   * @returns {string}
   */
  urlUtil(url: string, obj: any) {
    let schemaAndDomain = ''
    let str = url
    if (url.indexOf('http') == 0) {
      schemaAndDomain = str.substring(0, str.indexOf('/', str.indexOf('//') + 2))
      str = str.substring(str.indexOf('/', str.indexOf('//') + 2))
    }
    const breakPoint = str.indexOf(':')
    if (breakPoint >= 0) {
      const endPoint = ((str.indexOf('/', breakPoint) >= 0) ? str.indexOf('/', breakPoint) : str.length)

      const prefix = str.substring(0, breakPoint)
      const subfix = str.substring(breakPoint + 1, endPoint)
      const nextfix = (str.length - 1 > endPoint) ? this.urlUtil(str.substr(endPoint, str.length), obj) : str.substr(endPoint, str.length)
      return schemaAndDomain + prefix + obj[subfix] + nextfix
      // str.substring(breakPoint,)
    } else {
      return schemaAndDomain + str
    }
  }
}
