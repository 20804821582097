import { Component, OnInit, ViewChild, Directive, Input, HostListener } from '@angular/core';
import { AccountService } from '../../_services/account.service';
import { AppState } from '../../_services/app.service';
import { CdkStepper } from '@angular/cdk/stepper';

import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder
} from '@angular/forms';
import { MatStepper, matStepperAnimations } from '@angular/material';
import { Step1Component } from '../clips-signup/step1/step1.component';
import { Step2Component } from '../clips-signup/step2/step2.component';

@Directive({
  selector: 'button[StepperIndex]',
  host: {
    '[type]': 'type',
  }
})
export class StepperIndex {
  @Input() type = 'button';
  @Input() index = 0;
  constructor(public _stepper: CdkStepper) {}
  @HostListener('click')
  _handleClick() {
    this._stepper.selectedIndex = this.index;
  }
}

@Component({
  selector: 'app-clips-signup',
  templateUrl: './clips-signup.component.html',
  styleUrls: ['./clips-signup.component.scss'],
  providers: [MatStepper],
  animations: [matStepperAnimations.horizontalStepTransition],
})

export class ClipsSignupComponent implements OnInit {
  @ViewChild('stepper') stepper: CdkStepper;
  @ViewChild(Step1Component) Step1Child;
  @ViewChild(Step2Component) Step2Child;
  Step1Form: FormGroup;
  Step2Form: FormGroup;
  current = 0;
  previous = 0;
  isCompleted = false;
  constructor(
    public accountService: AccountService,
    public appstate: AppState,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.Step1Form = this.fb.group({
      firstname: new FormControl('', Validators.required),
      lastname: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      check: new FormControl(false, Validators.requiredTrue)
    });

    this.Step2Form = this.fb.group({
      EntityType: new FormControl('', Validators.required),
      Role: new FormControl('', Validators.required),
      Location: new FormControl('', Validators.required)
    });

  }

  ngAfterViewInit() {
    this.Step1Form = this.Step1Child.Step1Form
    this.Step2Form = this.Step2Child.Step2Form
    if (document.querySelector('.rocketchat-widget')){
      document.querySelector('.rocketchat-widget')['style'].display = 'none';
    }
  }

  _selectIndex(index: number){
    if ( this.current !== index){
      this.current = index;
    }
    if ( this.previous !== this.current){
      this.previous = this.current;
    }
    this.stepper.selectedIndex = index;
  }

  onFormSuccess() {
    this.stepper.selectedIndex = this.stepper._getFocusIndex() + 1
  }
}
