import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninRedirectComponent } from './signin-redirect.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [SigninRedirectComponent]
})
export class SigninRedirectModule { }