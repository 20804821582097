import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { AppState } from "../_services";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { LanguageService } from "@_services/language.service";
import "bootstrap/dist/js/bootstrap.bundle";
import { Observable, Subject } from "rxjs/Rx";
import { UserState } from "@_services/current/user";
import { ViewportScroller } from "@angular/common";
import { OAuthService } from "angular-oauth2-oidc";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
@HostListener("window:resize", ["$event"])
export class NavbarComponent implements OnInit {
  @ViewChild("nav") nav: ElementRef;
  isMobile: boolean;
  isOnSignupAndSignin = new Subject<boolean>();
  currentLang: Observable<string>;
  isBannerExpired: Observable<boolean>;
  browserName;
  constructor(
    public appstate: AppState,
    public userstate: UserState,
    private router: Router,
    public device: DeviceDetectorService,
    public languageService: LanguageService,
    public viewPort: ViewportScroller,
    private oauthService: OAuthService
  ) {
    if (this.device.isMobile() === true && window.innerWidth < 550) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  ngOnInit() {
    this.currentLang = this.languageService.loadCurrentLang();
  }

  ngAfterContentChecked() {
    this.isBannerExpired = this.appstate.getBanner();
    this.appstate.getCurrentPath().subscribe((res: string) => {
      const regex = /[signin][signup]*/m;
      const rule = new RegExp(regex);
      const isMatch = rule.test(res);
      this.isOnSignupAndSignin.next(false);
      if (isMatch) this.isOnSignupAndSignin.next(true);
    });
  }

  closeTop() {
    this.appstate.set("isClickedNews", true);
  }

  goSignUp() {
    this.router.navigate(["/signup"]);
  }

  goSignIn() {
    this.userstate.useDefaultFlow();
    this.router.navigate(["/signin"]);
  }

  async oidcSignInByOriginal() {
    this.router.navigate(["/signin"], {
      queryParams: {
        oidc: true,
      },
    });
  }

  detectIp() {
    this.appstate.checkUserLocation().subscribe((country_code) => {
      if (country_code.trim() === "TW") {
        window.open(
          "https://www.viewsonic.com/education/tw/certification.html",
          "_blank",
          "noopener"
        );
      } else {
        window.open(
          "https://www.viewsonic.com/us/learn/",
          "_blank",
          "noopener"
        );
      }
    });
  }
  async oidcSignIn() {
    this.userstate.useOIDCFlow();
    if (!this.oauthService.tokenEndpoint)
      await this.oauthService.loadDiscoveryDocument();
    this.oauthService.initLoginFlow();
    // this.oauthService.initLoginFlowInPopup().then(() => {
    //   // this.loadUserProfile();
    // });
  }
  tabDownload() {
    window.open("https://myviewboard.com/download/", "_blank", "noopener");
  }

  checkBrowser() {
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Chrome')) {
      this.browserName = 'Google Chrome';
    } else if (userAgent.includes('Firefox')) {
      this.browserName = 'Mozilla Firefox';
    } else if (userAgent.includes('Edge')) {
      this.browserName = 'Microsoft Edge';
    } else {
      this.browserName = 'Unknown';
    }
  }
}
