<form [formGroup]="MVB" (ngSubmit)="sendTicket()" #f="ngForm">
  <div class="radiowrap">
    <div class="title">We are here to help</div>
    <div class="wrap-content" [dir]="languageService.dir | async">
      <div class="selects">
        <div class="ticket-title">
         <b *ngIf="(currentLang | async) !== 'ja'" [translate]="'Support.Question_For'">Issue type for<b class="title-red">&nbsp;myViewBoard Original Content / Backgrounds</b></b>
          <b *ngIf="(currentLang | async) === 'ja'" > <span class="title-red">myViewBoard Original Content / Backgrounds</span> <span [translate]="'Support.Question_For'">&nbsp; Issue type for</span></b>
          <mat-error class="msgwarning" *ngIf="MVB.invalid && f.submitted">
            Please answer all the questions below</mat-error>
        </div>
        <div class="input-name">
          <span  [translate]="'Support.OC_Questions.Your_Role'">Your Role:</span>
          <input type="text" formControlName="Role" [ngClass]="{'bidi_dir': (currentLang | async) === 'ar'}" required>
        </div>
        <div class="input-name">
          <span [translate]="'Support.OC_Questions.Your_Org_Name'">Your Organization Name:</span>
          <input type="text" [ngClass]="{'bidi_dir': (currentLang | async) === 'ar'}" formControlName="Org" required>
        </div>
        <div class="input-name">
          <span [translate]="'Support.OC_Questions.Best_Describe_Region'">What best describe your region:</span>
          <input type="text" [ngClass]="{'bidi_dir': (currentLang | async) === 'ar'}"  formControlName="Region" required>
        </div>
        <div class="input-name">
          <span [translate]="'Support.OC_Questions.Subjects'">Subjects:</span>
          <input type="text" [ngClass]="{'bidi_dir': (currentLang | async) === 'ar'}" formControlName="Subject" required>
        </div>
        <div class="input-name">
          <span [translate]="'Support.OC_Questions.Grades'">Grades:</span>
          <input type="text" [ngClass]="{'bidi_dir': (currentLang | async) === 'ar'}" formControlName="Grades" required>
        </div>
      </div>
      <div class="title-area">
        <div class="title-header" [translate]="'Support.Title'">Title
        </div>
        <input class="title-input" formControlName="title" placeholder="{{ ('Support.Title_placeholder') | translate }}"
          maxlength="60" [ngClass]="{'bidi_dir': (currentLang | async) === 'ar'}" required>
          <mat-error class="msgwarning"
            *ngIf="MVB.controls['title'].invalid && f.submitted" [translate]="'Support.Error_Title'">Please name a
            title for this ticket</mat-error>
      </div>
      <app-fileupload #fileupload formGroupName="FileGroup"></app-fileupload>
    </div>
  </div>
  <div class="btn-wrap">
    <div class="btns">
       <button class="sendbtn" [class.spinner]="loading" [disabled]="loading"
        [ngClass]="{'btn-loading':loading === true}" [translate]="'Support.Send'" type="submit">Send</button>
      <button class="cancelbtn" [disabled]="loading" [ngClass]="{'btn-loading':loading === true}"
        [mat-dialog-close]="true" [translate]="'Support.Cancel'">Cancel</button>
    </div>
  </div>
</form>