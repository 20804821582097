import {
  from as observableFrom,
  empty as observableEmpty,
  Observable,
} from "rxjs";

import { mergeMap, map, delay, publish } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { AdminService } from "@_services/admin.service";
import { AppState } from "@_services/app.service";
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
} from "@angular/forms";
import { Message } from "primeng/primeng";
import { UserState } from "@_services/current/user";

@Component({
  selector: "app-issue-common-coupon",
  templateUrl: "./coupon-common-issue.html",
  providers: [AdminService],
})
export class IssueCommonCouponComponent implements OnInit {
  LIST_MODE = 1;
  CREATE_MODE = 2;
  current_user;
  option = {
    only_me: true,
    search: null,
    select_coupon_id: null,
  };
  coupon_data = [];
  accept_coupon_data = [];
  status = this.LIST_MODE;

  model: any = {};
  loading = false;
  msgs: Message[] = [];
  userform: FormGroup;
  emails: any = [];
  submitted: boolean;
  display = true;
  applicantNo = 0;
  IssueHistory: any = [];
  issue_type = "Personal";

  constructor(
    public fb: FormBuilder,
    public adminService: AdminService,
    public appstate: AppState,
    public userstate: UserState
  ) {}

  ngOnInit() {
    this.current_user = this.userstate.getUser();
    this.model = {
      distributor: this.current_user.email,
      applicant: "",
    };
    this.initialForm();
    this.getIssueHistory();
    this.getCoupon();

    this.appstate.scrollTop();
  }
  initialForm() {
    this.userform = this.fb.group({
      coupon_id: new FormControl("", Validators.required),
      // 'email_customer': new FormControl('', Validators.compose([Validators.email]))
      // 'email_sales': new FormControl('', Validators.compose([Validators.email,Validators.required])),
      // 'email_customer': new FormControl('', Validators.compose([Validators.email]))
    });
    // this.userform.addControl()

    this.applicantAdd(null);
  }
  getIssueHistory() {
    const params = {};
    if (this.option.only_me) {
      params["distributor_id"] = this.current_user.id;
    }
    if (this.option.search) {
      params["q"] = this.option.search;
    }
    if (this.option.select_coupon_id) {
      params["coupon_id"] = this.option.select_coupon_id;
    }
    this.adminService.getCouponOrder(params).subscribe(
      (data) => {
        this.IssueHistory = data;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getCoupon() {
    this.adminService.getCoupon().subscribe(
      (data) => {
        this.coupon_data = []; //[...data];
      },
      (error) => {
        console.log(error);
      }
    );
    this.adminService
      .getCoupon({
        publish: "true",
        action_start_time: `lte$${new Date().getTime()}$null`,
        action_end_time: `gte$${new Date().getTime()}$null`,
      })
      .subscribe(
        (data) => {
          this.accept_coupon_data = [...data];
        },
        (error) => {
          console.log(error);
        }
      );
  }

  applicantAdd($event) {
    console.log($event, this.emails);
    this.emails.push({ name: "email_customer" + this.applicantNo, email: "" });
    this.userform.addControl(
      "email_customer" + this.applicantNo,
      new FormControl(
        "",
        Validators.compose([Validators.email, Validators.required])
      )
    );
    const target = "email_customer" + this.applicantNo;
    this.applicantNo++;

    observableEmpty()
      .pipe(delay(200))
      .subscribe({
        complete: () => {
          const inputs = document.querySelectorAll(".input-applicant");
          console.log(inputs[inputs.length - 1]);
          const el = inputs[inputs.length - 1] as HTMLElement;
          // el.focus();
        },
      });
  }

  applicantRemove(applicant) {
    console.log(applicant);
    this.userform.removeControl(applicant.name);
    this.emails.splice(this.emails.indexOf(applicant), 1);
  }

  sendAgain(order) {
    this.adminService.resendCouponConfirm(order.id).subscribe((data) => {
      this.msgs.push({
        severity: "success",
        summary: "Success",
        detail: `Coupon for ${order.applicant_email} has been sent, please check your ViewSonic Account Email to confirm this Coupon`,
      });
    });
  }

  onSubmit(value) {
    const coupon_id = this.userform.value.coupon_id;
    console.log(`-------------`, this.userform.controls);
    console.log(this.emails);
    this.msgs = [];
    console.log(this.model);
    observableFrom(this.emails)
      .pipe(
        map((item: any) => item.email),
        delay(1000),
        mergeMap((email) =>
          this.issueCoupon({
            coupon_id: coupon_id,
            applicant_email: email,
          })
        )
      )
      .subscribe(
        (data: any) => {
          console.log(data);
          if (data.status === 403) {
            this.msgs.push({
              severity: "warn",
              summary: "",
              detail: data.error.message,
            });
          } else {
            this.msgs.push({
              severity: "success",
              summary: "Success",
              detail: `Coupon for ${data.applicant_email} has been created, please check your ViewSonic Account Email to confirm this Coupon`,
            });
            this.applicantRemove("coupon_id");
            this.emails.map((x) => {
              this.applicantRemove(x);
            });
            this.initialForm();
            this.status = this.LIST_MODE;
          }
        },
        (error) => {
          console.log(error);
          this.loading = false;
          this.msgs.push({
            severity: "info",
            summary: "Warning",
            detail: error.body
              ? JSON.parse(error._body).message
              : error.error.message,
          });
        },
        () => {
          console.log("complete");
          this.getIssueHistory();
        }
      );
  }
  isEnd(time) {
    if (!time) return false;
    return new Date(time).getTime() < new Date().getTime();
  }
  validator(model) {
    console.log(model);
    /*if (model.filter(item => item.email).length > 0) {
      return true
    }*/
    return false;
  }

  issueCoupon(data: any) {
    console.log(data);
    return this.adminService.issueCommonCoupon(data);
  }
}
