<div class="container h-100">
  <div class="row justify-content-center h-100 align-items-center">
    <div class="col-lg-6">
      <div class="card m-4 p-4">
        <div class="card-block">
          <h2 [translate]="'Main_Menu.Change_Password'">Change Password</h2>
          <!-- <p-messages [(value)]="msgs"></p-messages> -->
          <mvb-message [(value)]="msgs"></mvb-message>
          <form
            name="form"
            [formGroup]="userform"
            (ngSubmit)="
              !checkCurrentPW() && f.form.valid && onSubmit(userform.value)
            "
            #f="ngForm"
            novalidate
          >
            <div
              *ngIf="this.userform.controls['c_password']"
              class="form-group"
            >
              <mat-label
                for="c_password"
                [translate]="'Password.Current_Password'"
                >Outline form field</mat-label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  class="form-control"
                  [(ngModel)]="model.c_password"
                  type="password"
                  name="c_password"
                  reverse="true"
                  formControlName="c_password"
                  spellcheck="false"
                />
              </mat-form-field>
              <!-- <label for="c_password" [translate]="'Password.Current_Password'">Current Password</label> -->
              <!-- <input class="form-control" [(ngModel)]="model.c_password" pInputText type="password" name="c_password"
                reverse="true" formControlName="c_password" /> -->
              <div
                class="help-block small text-danger"
                *ngIf="
                  !userform.controls['c_password'].valid &&
                  userform.controls['c_password'].dirty
                "
              >
                <ng-container
                  *ngTemplateOutlet="
                    errorMessage;
                    context: { $implicit: userform.controls['c_password'] }
                  "
                >
                </ng-container>
              </div>
            </div>
            <div class="form-group">
              <mat-label for="password" [translate]="'Password.Password'"
                >Outline form field</mat-label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  class="form-control"
                  [(ngModel)]="model.password"
                  type="password"
                  name="password"
                  validateEqual="confirmPassword"
                  reverse="true"
                  formControlName="password"
                  spellcheck="false"
                />
              </mat-form-field>
              <!-- <label for="password" [translate]="'Password.Password'">Password</label>
              <input class="form-control" [(ngModel)]="model.password" pInputText type="password" name="password"
                validateEqual="confirmPassword" reverse="true" formControlName="password" /> -->
              <div
                class="help-block small text-danger"
                *ngIf="
                  !userform.controls['password'].valid &&
                  userform.controls['password'].dirty
                "
              >
                <ng-container
                  *ngTemplateOutlet="
                    errorMessage;
                    context: { $implicit: userform.controls['password'] }
                  "
                >
                </ng-container>
              </div>
              <span
                class="small text-danger"
                *ngIf="checkCurrentPW()"
                [translate]="'Password.Password_Need_Change'"
                >Can't same with current password
              </span>
              <div
                class="small help-block"
                *ngIf="
                  !userform.get('confirmPassword').value &&
                  !userform.get('password').value
                "
              >
                <span
                  *ngIf="isExistCurrentPW()"
                  [translate]="'Password.Password_Format_Error'"
                >
                  Minimum of 8 characters that includes at least one lowercase,
                  uppercase, number, and symbol
                </span>
              </div>
            </div>

            <div class="form-group">
              <mat-label
                for="confirmPassword"
                [translate]="'Password.Retype_Password'"
                >Outline form field</mat-label
              >
              <mat-form-field appearance="outline">
                <input
                  matInput
                  class="form-control"
                  type="password"
                  name="confirmPassword"
                  validateEqual="password"
                  formControlName="confirmPassword"
                  spellcheck="false"
                />
              </mat-form-field>
              <!-- <label for="confirmPassword" [translate]="'Password.Retype_Password'">Retype Password</label>
              <input class="form-control" pInputText type="password" name="confirmPassword" validateEqual="password"
                formControlName="confirmPassword" /> -->
              <div
                class="help-block small text-danger"
                *ngIf="
                  !userform.controls['confirmPassword'].valid &&
                  userform.controls['confirmPassword'].dirty
                "
              >
                <ng-container
                  *ngTemplateOutlet="
                    errorMessage;
                    context: { $implicit: userform.controls['confirmPassword'] }
                  "
                >
                </ng-container>
              </div>
            </div>

            <div
              class="form-group d-flex justify-content-between align-items-center"
            >
              <div>
                <button
                  [disabled]="loading || !userform.valid || checkCurrentPW()"
                  class="btn btn-primary"
                  [translate]="'Confirm'"
                >
                  Confirm
                </button>
                <img
                  *ngIf="loading"
                  src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                />
              </div>
              <a (click)="onResend()" mat-button>{{
                "FollowMe_AI.Forget_Password" | translate
              }}</a>
            </div>
            <div
              style="text-align: center; margin-top: 20px"
              *ngIf="submitted"
              [translate]="'Form_Submitted'"
            >
              Form Submitted
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #errorMessage let-input>
  <span *ngFor="let item of objectKeys(input.errors); let i = index">
    <div *ngIf="item === 'required'" [translate]="'Password.Password_Required'">
      Password is required
    </div>
    <div *ngIf="item === 'minlength'" [translate]="'Password.Longer_Than_8'">
      Must be longer than 8 characters
    </div>
    <div *ngIf="item === 'maxlength'" [translate]="'Password.Longer_Less_32'">
      Must be longer than 8 characters
    </div>
    <div *ngIf="item === 'pattern'" [translate]="'Password.Password_Format'">
      Require Lowercase(a) Uppercase(B) Number(1) and Symbol(!@#$%^&*)
    </div>
    <div
      *ngIf="item === 'validateEqual'"
      [translate]="'Password.Password_SAME'"
    >
      Password not the same
    </div>
  </span>
</ng-template>
