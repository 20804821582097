<mat-card *ngIf="list && list.length>0">
<mat-list>
    <mat-list-item *ngFor="let item of list">
        <div matLine fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="space-between center">
            <span matLine><img class="preview" [src]="item.preview" style="max-width:64px;height:32px;margin-right: 5px"/> {{ item.name}} </span>
            <mat-icon class="crimson-red-icon delete" (click)="deleteItem($event,item)" matTooltip="{{'Recall the thrown file' | translate}}">cancel-presentation</mat-icon>
        </div>
        <mat-divider></mat-divider>
    </mat-list-item>
</mat-list>
</mat-card>
