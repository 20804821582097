import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { AppState } from "@_services";
import { AccountService } from "@_services/account.service";
import { CountryService } from "@_services/CountryService";
import { LanguageService } from "@_services/language.service";
import { SignupService } from "app/pages/sign-up/signup.service";
import { Observable, Subject } from "rxjs";
import { debounceTime, startWith, map } from "rxjs/operators";

@Component({
  selector: "app-questionnaireform",
  templateUrl: "./questionnaire-form.component.html",
  styleUrls: ["./questionnaire-form.component.scss"],
  providers: [CountryService],
})
export class QuestionnaireComponent implements OnInit {
  education: string;
  place: string;
  job: string;
  username: string;
  showOtherJobInput = false;
  correctCountryName = false;
  newList = [];
  Educations = [
    { name: "Education", value: "education" },
    { name: "Enterprise", value: "enterprise" },
    { name: "Public Sector", value: "public sector" },
    { name: "Sports Related", value: "sports related" },
  ];

  Sizes = [
    { name: "1 - 50 people", value: "1-50" },
    { name: "50 - 300 people", value: "50-300" },
    { name: "300 - 600 people", value: "300-600" },
    { name: "600 - 1000 people", value: "600-1000" },
    { name: "1,000 - 5,000 people", value: "1000-5000" },
    { name: "5,000 and above", value: "5000" },
  ];

  EduJobs = [
    { name: "IT Related", value: "it related" },
    { name: "Teacher", value: "teacher" },
    { name: "Student", value: "student" },
    { name: "Principal/ Head Teacher", value: "principal/ head teacher" },
    { name: "Curriculum Coordinator", value: "curriculum coordinator" },
    { name: "Other", value: "other" },
  ];

  EnterpriseJobs = [
    { name: "Accounting", value: "accounting" },
    { name: "Business Development", value: "business development" },
    { name: "Customer Service", value: "customer service" },
    { name: "Finance", value: "finance" },
    { name: "Human Resource", value: "human resource" },
    { name: "Information Technology Related", value: "it related" },
    { name: "Management", value: "management" },
    { name: "Marketing", value: "marketing" },
    { name: "Other", value: "other" },
  ];

  PublicJobs = [
    { name: "Law Enforcement", value: "law enforcement" },
    { name: "Military", value: "military" },
    { name: "Infrastructure", value: "infrastructure" },
    { name: "Public Transit", value: "public transit" },
    { name: "Health Care", value: "health care" },
    { name: "Emergency", value: "emergency" },
    { name: "Other", value: "other" },
  ];

  SportsJobs = [
    { name: "Athlete", value: "athlete" },
    { name: "Coach", value: "coach" },
    { name: "General Manager", value: "general manager" },
    {
      name: "Strength and Conditioning Coach",
      value: "strength and conditioning coach",
    },
    { name: "Scout", value: "scout" },
    { name: "Administrated Related", value: "administrated related" },
    { name: "Other", value: "other" },
  ];

  Places = [
    { name: "Pre-School", value: "Pre-School" },
    { name: "Elementary/ Primary School", value: "elementary/ primary school" },
    { name: "Middle/ Secondary School", value: "middle/secondary school" },
    { name: "High School", value: "high school" },
    { name: "Higher Eduation", value: "higher education" },
    { name: "Other", value: "other" },
  ];

  locations = [];
  QuestionarieForm: FormGroup;
  LocationOptions: Observable<any[]>;
  locattionSubject = new Subject<string>();
  currentLang: Observable<string>;
  constructor(
    public accountService: AccountService,
    private signupService: SignupService,
    private fb: FormBuilder,
    private countryService: CountryService,
    public languageService: LanguageService,
    private appState: AppState
  ) {
    this.signupService.receiveStep1Info().subscribe((data) => {
      this.username = data.fullname;
    });
  }

  ngOnInit() {
    this.QuestionarieForm = this.fb.group({
      EntityType: new FormControl("", Validators.required),
      Role: new FormControl("", Validators.required),
      Location: new FormControl("", Validators.required),
      Size: new FormControl("", Validators.required),
    });

    this.locattionSubject.pipe(debounceTime(500)).subscribe((value) => {
      this.checkIfValid(value);
    });
    this.getLocations();
    this.currentLang = this.languageService.loadCurrentLang();
  }

  scrollTop() {
    this.appState.scrollTop();
  }

  search(serachParam: string) {
    this.locattionSubject.next(serachParam);
  }

  checkIfValid(value: string) {
    if (this.newList.includes(value)) {
      this.correctCountryName = false;
    } else {
      this.QuestionarieForm.controls["Location"].setErrors({ incorrect: true });
      this.correctCountryName = true;
    }
  }

  switchForm($event) {
    const value = $event.value;
    const form = this.QuestionarieForm;
    this.showOtherJobInput = false;
    delete form.controls["otherRole"];
    delete form.controls["EduOrg"];
    form.patchValue({
      Location: "",
      Size: "",
      Role: "",
      otherRole: "",
      EduOrg: "",
    });
    if (value === "enterprise") {
      delete form.controls["otherRole"];
      form.addControl("Size", new FormControl("", Validators.required));
    }
    if (value === "public sector") {
      delete form.controls["Size"];
      delete form.controls["otherRole"];
    }
    if (value === "sports related") {
      delete form.controls["Size"];
      delete form.controls["otherRole"];
    }
    if (value === "education") {
      delete form.controls["otherRole"];
      form.addControl("Size", new FormControl("", Validators.required));
      form.addControl("EduOrg", new FormControl("", Validators.required));
    }
  }

  customJob($event) {
    const value = $event.value;
    const form = this.QuestionarieForm;
    if (value === "other") {
      this.showOtherJobInput = true;
      form.addControl("otherRole", new FormControl("", Validators.required));
    } else {
      this.showOtherJobInput = false;
      delete form.controls["otherRole"];
    }
  }

  private getLocations() {
    this.countryService
      .getData(null)(null)
      .subscribe((data) => {
        console.log("api country", data);
        this.locations = data;
        this.LocationOptions = this.QuestionarieForm.get(
          "Location"
        )!.valueChanges.pipe(
          startWith(""),
          map((value) => this._filterLocation(value))
        );
        data.forEach((i) => {
          this.newList.push(i.country_name);
        });
      });
  }

  private _filterLocation(value: any) {
    if (!value) return this.locations;
    const lowerCaseValue = value.toLowerCase();
    return this.locations.filter((x) =>
      x.country_name.toLowerCase().includes(lowerCaseValue)
    );
  }

  sendStep2Info() {
    const form = this.QuestionarieForm;
    if (form.valid === true) {
      this.scrollTop();
      this.signupService.sendStep2Info(form.value);
    }
  }
}
