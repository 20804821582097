import { Injectable, Inject } from "@angular/core";
import { AppState } from "@_services/app.service";
import { interval, forkJoin } from 'rxjs';
import { SweetAlertService } from 'ng2-sweetalert2';
import { AccountService } from "@_services/account.service";
import { Router } from "@angular/router";
import { DOCUMENT } from '@angular/common';
import _ from 'lodash';
import { UserState } from "./current/user";
import { TranslateService } from "@ngx-translate/core";

export enum State {
  idle,
  warning,
  expired
}
@Injectable()
export class LogoutService {
  is_swal = false
  constructor(@Inject(DOCUMENT) private document: HTMLDocument, public accountService: AccountService,
    private appState: AppState, private swalService: SweetAlertService, private userState: UserState,
    private router: Router, private translateService: TranslateService) {
    console.log(`logout service is start`)
    if(this.appState.get("hostEnv")==='flutter'){
      return;
    }
    this.initExpireTime();
    interval(1000).subscribe({
      next: () => {
        if (this.userState.getExpireDate()) {
          const diff = this.userState.checkExpiredDate()
          if (diff < 20000 && diff > 0) {
            this.is_swal = true
            this.openSwal(Math.floor(diff/1000))
          } else if (diff <= 0) {
            /* diff time close and logout */
            this.cleanAndRedirect()
            this.removeSwal()
          } else if (diff > 20000 && this.is_swal) {
            this.removeSwal()
          }
        }
        if (this.accountService.getCleanSWal() && this.is_swal) {
          /* for other tab, sync remove dialog */
          this.cleanAndRedirect()
          this.removeSwal()
        }
      }
    });
  }
  removeSwal() {
    let swal_dom = document.body.querySelector('.swal2-container.swal2-in')
    if (swal_dom && swal_dom.children[0].className.indexOf('swal2-modal dg-l-1') >= 0) {
      //&&swal_dom.children[0].getElementsByTagName("h2")[0].innerHTML == 'Session Timeout'
      this.is_swal = false
      swal_dom.remove()
      document.body.className = document.body.className.replace(/(^|)swal2-in($|)/g, '').trim()

    }
  }
  initExpireTime() {
    this.userState.removeExpireDate()
  }
  cleanAndRedirect() {
    this.initExpireTime()
    this.accountService.removeUser()
    this.router.navigate(['/'])
  }

  openSwal(timeout) {
    forkJoin(
      this.translateService.get('Session.Sign_out_action', {value: timeout}),
      this.translateService.get('Session.Session_timeout'),
      this.translateService.get('Session.Continue'),
      this.translateService.get('Main_Menu.Sign_Out')
    ).subscribe(data => {
      const [txt, title, confirm, cancel] = data;
      this.swalService.swal({
        title: title,
        text: txt,
        type: 'warning',
        customClass: 'dg-l-1',
        buttonsStyling: false,
        showCancelButton: true,
        allowOutsideClick: false,
        confirmButtonClass: 'btn btn-primary mr-4',
        cancelButtonClass: 'btn btn-primary  mr-4',
        confirmButtonText: confirm,
        cancelButtonText: cancel,
        imageWidth: 200,
        imageHeight: 200,
        animation: false,
      }).then(() => {
        this.removeSwal()
        /* extend expired time wait for request return */
        this.userState.setExpireDate(new Date(Date.now() + 60000))
        this.accountService.getSession().subscribe(() => {
        }, () => {
          this.userState.removeExpireDate()
        })
      }, () => {
        this.removeSwal()
        this.is_swal = false
        this.accountService.logout().subscribe(() => {
          this.cleanAndRedirect()
        })
      })
    })
  }
}
