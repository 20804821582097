import { from as observableFrom } from "rxjs";
import { filter, flatMap, map, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AppState } from "./app.service";
import { Account } from "../_models/account";
import { environment } from "../../environments/environment";
import { of } from "rxjs/internal/observable/of";
import { UserState } from "./current/user";
import { BehaviorSubject, Observable } from "rxjs/Rx";
import { OAuthService, TokenResponse } from "angular-oauth2-oidc";
import { HttpParameterCodec } from "@angular/common/http";
import { QuestionnaireContent } from "app/_models/questionnaire";
import { CookieService } from "ngx-cookie-service";
/**
 * This custom encoder allows charactes like +, % and / to be used in passwords
 */
export class WebHttpUrlEncodingCodec implements HttpParameterCodec {
  encodeKey(k: string): string {
    return encodeURIComponent(k);
  }

  encodeValue(v: string): string {
    return encodeURIComponent(v);
  }

  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }

  decodeValue(v: string) {
    return decodeURIComponent(v);
  }
}

@Injectable()
export class AccountService {
  lang_data;
  private token = new BehaviorSubject<string>(null);
  public defaultEntity = new BehaviorSubject<any>(null);

  constructor(
    private appstate: AppState,
    private userstate: UserState,
    private httpClient: HttpClient,
    private oauthService: OAuthService,
    public cookieService: CookieService
  ) {}

  agreeMsp(accountId) {
    return this.httpClient.patch(
      `${environment.apiUrl}/api/v1/account/${accountId}/msp/agreement`,
      {}
    );
  }

  create(account: Account) {
    return this.httpClient.post<any>(
      environment.apiUrl + "/api/account/",
      account
    );
  }

  couponActiveSignUp(account: any, code: String) {
    return this.httpClient.post<any>(
      environment.apiUrl + "/api/account/active/coupon?code=" + code,
      account
    );
  }
  getUserPii() {
    const { id, shard_region } = this.userstate.getUser();
    return this.httpClient.post<any>(environment.apiUrl + "/api/account-pii", {
      queries: [
        {
          key: shard_region,
          shard_region,
          ids: [id],
        },
      ],
    });
  }
  updateUserPii(data) {
    return this.httpClient.put(environment.apiUrl + "/api/account-pii", data);
  }
  getUser() {
    const id = this.userstate.getUser().id;
    return this.httpClient.get<any>(environment.apiUrl + "/api/account/" + id);
  }

  update(account: any) {
    const id = this.userstate.getUser().id;
    return this.httpClient.put(
      environment.apiUrl + "/api/account/" + id,
      account
    );
  }

  changePW({ current_password, new_password }) {
    return this.httpClient.post(
      environment.apiUrl + "/api/v1/account/setting/password",
      { current_password, new_password }
    );
  }

  getUrlByTOTP(password) {
    const id = this.userstate.getUser().id;
    return this.httpClient.post(
      `${environment.apiUrl}/api/v1/account/${id}/setting/totp/uri`,
      { password }
    );
  }

  storeTOTP(totp_token) {
    const id = this.userstate.getUser().id;
    return this.httpClient.post(
      `${environment.apiUrl}/api/v1/account/${id}/setting/totp`,
      { totp_token }
    );
  }

  removeTOTP(password: string) {
    const id = this.userstate.getUser().id;
    return this.httpClient.request(
      "delete",
      `${environment.apiUrl}/api/v1/account/${id}/setting/totp`,
      {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
        body: { password: password },
      }
    );
  }

  upload_avatar(avatar) {
    return this.httpClient.post<any>(
      environment.apiUrl + "/api/account/avatar",
      avatar
    );
  }

  login(account) {
    return this.httpClient
      .post(environment.apiUrl + "/api/login/", account, {
        withCredentials: true,
      })
      .pipe(
        tap((current_user) => {
          console.log("login current user data", current_user);
          this.userstate.userLogin(current_user);
        }),
        tap(() => this.removeCleanSWal())
      );
  }

  accountInspect(account) {
    return this.httpClient.post(
      environment.apiUrl + "/api/account/email/inspect",
      account,
      { withCredentials: true }
    );
  }

  loginWithToken(accessToken) {
    const httpOptions = {
      withCredentials: true,
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      }),
    };
    return this.httpClient.post(
      environment.apiUrl + "/api/login/",
      {},
      httpOptions
    );
  }

  clearLocalStorage() {
    const { tour_finished, tutorial_finished, mvb2_whatsNewShown } = (<any>(
      window
    )).localStorage;
    localStorage.clear();
    if (mvb2_whatsNewShown) {
      (<any>window).localStorage.tour_finished = mvb2_whatsNewShown;
    }
    if (tour_finished) {
      (<any>window).localStorage.tour_finished = tour_finished;
    }
    if (tutorial_finished) {
      (<any>window).localStorage.tutorial_finished = tutorial_finished;
    }
  }

  logout() {
    return this.httpClient.post(environment.apiUrl + "/api/logout/", {}).pipe(
      tap(() => {
        this.removeUser();
      })
    );
  }

  removeUser() {
    this.cookieService.set(
      "myLang",
      localStorage.getItem("LandingPageLang"),
      null,
      "/",
      environment.domainURL,
      true,
      'Lax'
    );
    /* Display the language set before login */
    localStorage.removeItem("LandingPageLang");

    if (window.sessionStorage.getItem("skipQuestion")) {
      window.sessionStorage.removeItem("skipQuestion");
    }
    this.clearLocalStorage();
    this.userstate.userLogout();
    this.appstate.localStorage.setItem("cleanSwal", true);
    this.appstate.set("remote-transfer-dialog", false);
    if (this.appstate.mqtt && this.appstate.mqtt.connected) {
      try {
        this.appstate.mqtt.end(true);
      } catch (err) {
        console.log(err);
      }
    }
    observableFrom(this.appstate.windows).subscribe(
      (w) => {
        w.close();
      },
      (error) => console.log("Error: " + error)
    );
  }

  getCleanSWal() {
    return this.appstate.localStorage.getItem("cleanSwal");
  }

  removeCleanSWal() {
    this.appstate.localStorage.removeItem("cleanSwal");
  }

  googleServiceDetection(google_bindings) {
    console.log(google_bindings);
    const query = google_bindings
      .map((item) => {
        if (item.key === "google_drive" && item.checked) {
          return "isDrive=true";
        }
        if (item.key === "google_calendar" && item.checked) {
          return "isCalendar=true";
        }
        if (item.key === "google_class" && item.checked) {
          return "isClassroom=true";
        }
        return "";
      })
      .filter((item) => item !== "")
      .join("&");
    return environment.apiUrl + "/auth/google/service/detection?" + query;
  }
  oneDriveServiceDetection() {
    return (
      environment.apiUrl +
      `/auth/onedrive/service/detection?access_token=${this.oauthService.getAccessToken()}&return_url=/home`
    );
  }
  async calculateOAuthTransferState(state?: string) {
    const { nonceStateSeparator } = environment.oidc_config;
    const nonce = await this.oauthService.createAndSaveNonce();
    if (state) {
      state = nonce + nonceStateSeparator + encodeURIComponent(state);
    } else {
      state = nonce;
    }
    return {
      nonce,
      state,
    };
  }

  async googleLoginPathByOIDC() {
    const { issuer, redirectUri, clientId, scope, nonceStateSeparator } =
      environment.oidc_config;
    const { nonce, state } = await this.calculateOAuthTransferState();
    return (
      issuer +
      `/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&force=google` +
      `&nonce=${nonce}&state=${state}`
    );
  }

  async fetchTokenUsingPasswordFlowByOIDC(
    userName: string,
    password: string,
    headers: HttpHeaders = new HttpHeaders()
  ) {
    const {
      scope,
      clientId,
      tokenEndpoint,
      fallbackAccessTokenExpirationTimeInSec,
      oidc,
    } = this.oauthService;
    // if (!tokenEndpoint) throw Error('needs oidc setting')
    const nonce = await this.oauthService.createAndSaveNonce();
    return new Promise((resolve, reject) => {
      let params = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() })
        .set("grant_type", "urn:mvb-exchange")
        .set("scope", scope)
        .set("email", userName)
        .set("password", password)
        .set("client_id", clientId)
        .set("nonce", nonce);

      headers = headers.set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );

      this.httpClient
        .post<TokenResponse>(tokenEndpoint, params, {
          headers,
          withCredentials: true,
        })
        .subscribe(
          (tokenResponse) => {
            localStorage.setItem("access_token", tokenResponse.access_token);
            localStorage.setItem(
              "granted_scopes",
              JSON.stringify(tokenResponse.scope)
            );
            localStorage.setItem("access_token_stored_at", "" + Date.now());
            const expiresInMilliSeconds = tokenResponse.expires_in * 1000;
            const now = new Date();
            const expiresAt = now.getTime() + expiresInMilliSeconds;
            localStorage.setItem("expires_at", "" + expiresAt);
            localStorage.setItem("refresh_token", tokenResponse.refresh_token);
            if (oidc && tokenResponse.id_token) {
              this.oauthService
                .processIdToken(
                  tokenResponse.id_token,
                  tokenResponse.access_token
                )
                .then((result) => {
                  const idToken = result;
                  localStorage.setItem("id_token", idToken.idToken);
                  localStorage.setItem(
                    "id_token_claims_obj",
                    idToken.idTokenClaimsJson
                  );
                  localStorage.setItem(
                    "id_token_expires_at",
                    "" + idToken.idTokenExpiresAt
                  );
                  localStorage.setItem("id_token_stored_at", "" + Date.now());

                  resolve(tokenResponse);
                })
                .catch((reason) => {
                  console.log("Error getting token 59633000", reason);
                  reject(reason);
                });
            } else {
              resolve(tokenResponse);
            }
          },
          (err) => {
            console.log("Error getting token", err);
            reject(err);
          }
        );
    });
  }

  googleLoginUrl() {
    return environment.apiUrl + "/auth/google/";
  }

  googleSTLELoginUrl() {
    const isProd = environment.production;
    const host = isProd
      ? "https://myviewboardclips.com/clips"
      : "https://myviewclip.stage.myviewboard.cloud/";
    return environment.apiUrl + `/auth/google/stle?return_url=${host}`;
  }

  facebookLoginUrl() {
    return environment.apiUrl + "/auth/facebook/";
  }

  microsoftLoginUrl() {
    return environment.apiUrl + "/auth/microsoft/";
  }

  microsoftSTLELoginUrl() {
    const isProd = environment.production;
    const host = isProd
      ? "https://myviewboardclips.com/clips"
      : "https://myviewclip.stage.myviewboard.cloud/";
    return environment.apiUrl + `/auth/microsoft/stle?return_url=${host}`;
  }

  appleLoginUrl() {
    return environment.apiUrl + "/auth/apple/";
  }

  mieeLoginUrl() {
    const port = window.location.port;
    const host = window.location.host;
    const return_url = port ? "stage.myviewboard.com" : host;
    return (
      environment.apiUrl +
      `/auth/microsoft/miee?return_url=https://${return_url}/home`
    );
  }

  moetwLoginUrl() {
    return environment.apiUrl + "/auth/moe/tw";
  }

  getById(id: number) {
    return this.httpClient.get(environment.apiUrl + "/api/account/" + id);
  }

  resendActiveEmail(account: Account) {
    return this.httpClient.post(
      environment.authUrl + "/api/v1/account/activation",
      account
    );
  }

  resetPassword(account: Account) {
    return this.httpClient.post(
      environment.authUrl + "/api/v1/account/resend/pw?init=true",
      account
    );
  }

  resendPassword(account: Account) {
    return this.httpClient.post(
      environment.authUrl + "/api/v1/account/resend/pw",
      account
    );
  }

  getSession() {
    return this.httpClient
      .post(environment.apiUrl + "/api/session", {})
      .pipe(tap((x) => this.removeCleanSWal()));
  }

  getWindowAppLog(userId) {
    return this.httpClient.get(
      environment.apiUrl +
        "/viewboard/application/log/login/" +
        userId +
        "/latest?type=windows&" +
        Date.now()
    );
  }

  getAndroidAppLog(userId) {
    return this.httpClient
      .get(
        environment.apiUrl +
          "/viewboard/application/log/login/" +
          userId +
          "/latest?type=android&" +
          Date.now()
      )
      .catch((err) => of({ error: err }));
  }

  getCompanionAppLog(userId) {
    return this.httpClient.get(
      environment.apiUrl +
        "/viewboard/companion/log/login/" +
        userId +
        "/latest?" +
        Date.now()
    );
  }

  getUserLog(userId) {
    return this.httpClient.get(
      environment.apiUrl + "/api/account/log/login/report/" + userId
    );
  }

  getWebLog(account_id, params) {
    return this.httpClient
      .get(`${environment.apiUrl}/api/account/web/log/${account_id}`, {
        withCredentials: true,
        params: params,
      })
      .pipe(
        map((response: any) => {
          return {
            rows: response.rows,
            count: response.count,
          };
        })
      );
  }

  getMobileLog(account_id, params) {
    return this.httpClient
      .get(`${environment.apiUrl}/api/account/mobile/log/${account_id}`, {
        withCredentials: true,
        params: params,
      })
      .pipe(
        map((response: any) => {
          return {
            rows: response.rows,
            count: response.count,
          };
        })
      );
  }

  getAppLog(params) {
    return this.httpClient
      .get(`${environment.apiUrl}/viewboard/application/log/login`, {
        withCredentials: true,
        params: params,
      })
      .pipe(
        map((response: any) => {
          return {
            rows: response.rows,
            count: response.count,
          };
        })
      );
  }

  bindserviceEntity({ service, entity_id = null }) {
    const servLink = {
      one_drive_business:
        environment.apiUrl + `/auth/entity/one_drive_business/${entity_id}`,
    };
    return window.open(servLink[service], "_self");
  }

  bindservice(option) {
    const servLink = {
      google_drive: "/auth/google/drive",
      google_calendar: "/auth/google/calendar",
      dropbox: "/auth/dropbox",
      khan: "/auth/khanacademy",
      box: "/auth/box",
      one_drive: "/auth/microsoft/onedrive",
      one_drive_business: "/auth/microsoft/onedrive/professional",
      zoom: "/auth/zoom",
      gotomeeting: "/auth/gotomeeting",
      google_class: "/auth/google/class",
      sharepoint: "/auth/microsoft/sharepoint",
    };
    const access_token = localStorage.getItem("access_token");
    let append = "";
    if (access_token !== "null") {
      append = `?access_token=${access_token}`;
    }
    return environment.apiUrl + servLink[option] + append;
  }

  get_one_drive_business() {
    return this.httpClient
      .get(environment.apiUrl + `/auth/entity/one_drive_business/`)
      .map((data: any) => data.list);
  }

  bindservice_googleDrive() {
    return environment.apiUrl + "/auth/google/drive";
  }

  bindservice_dropbox() {
    return environment.apiUrl + "/auth/dropbox";
  }

  bindservice_onedrive() {
    return environment.apiUrl + "/auth/microsoft/onedrive";
  }

  bindservice_box() {
    return environment.apiUrl + "/auth/box";
  }

  bindservice_khan() {
    return environment.apiUrl + "/auth/khanacademy";
  }

  getAllowBindingService(account_id) {
    return this.httpClient.get(
      environment.apiUrl + `/api/account/${account_id}/allowbindservice`,
      { withCredentials: true }
    );
  }

  getBindings() {
    return this.httpClient
      .get(environment.apiUrl + "/account/storage/binding")
      .map((data: any) => data.list);
  }

  deleteBinding(service: String) {
    return this.httpClient.delete(
      environment.apiUrl + "/account/storage/unbinding/" + service
    );
  }

  getBindingsConfig(id) {
    return this.httpClient.get(
      environment.apiUrl + "/api/account/" + id + "/config"
    );
  }

  updateBindingsConfig(id, service) {
    return this.httpClient.put(
      environment.apiUrl + "/api/account/" + id + "/config",
      { default_binding: service }
    );
  }

  getConfig() {
    const config_id = this.userstate.getUser().id;
    return this.httpClient.get(
      environment.apiUrl + "/api/account/" + config_id + "/config"
    );
  }

  getConfig_v2() {
    const config_id = this.userstate.getUser().id;
    return this.httpClient.get(
      environment.apiv2Url +
        "/api/v2/follow-me/account/" +
        config_id +
        "/config"
    );
  }

  updateConfig(config) {
    const config_id = this.userstate.getUser().id;
    return this.httpClient.put(
      environment.apiUrl + "/api/account/" + config_id + "/config",
      config
    );
  }

  updateConfig_v2(config) {
    const config_id = this.userstate.getUser().id;
    return this.httpClient.put(
      environment.apiv2Url +
        "/api/v2/follow-me/account/" +
        config_id +
        "/config",
      config
    );
  }

  deleteFingerPrint() {
    return this.httpClient.delete(
      environment.apiUrl +
        "/api/account/" +
        this.userstate.getUser().id +
        "/identify/finger"
    );
  }

  hasFingerPrint() {
    return this.httpClient.get(
      environment.apiUrl +
        "/api/account/" +
        this.userstate.getUser().id +
        "/identify/finger"
    );
  }

  getAvailableInstance() {
    return this.httpClient
      .get(environment.apiUrl + "/api/register/org/myEntity")
      .map((data: any) => data.list);
  }

  getSessionToken() {
    return this.httpClient.post<any>(
      environment.apiUrl + "/api/session/token",
      {}
    );
  }

  getSessionInstance() {
    return this.httpClient
      .post<any>(environment.apiUrl + "/api/session/viewboard", {})
      .map((data: any) => data.list);
  }

  AuthGM() {
    return this.httpClient.post(
      environment.apiUrl + "/api/session/viewboard",
      {}
    );
  }

  entityCreate(item) {
    return this.httpClient.post(environment.apiUrl + "/api/entity", item);
  }

  entityUpdateUser(entity_id, user_id, data) {
    return this.httpClient.put(
      environment.apiUrl + `/api/entity/${entity_id}/user/${user_id}`,
      data
    );
  }

  entityRemoveUser(entity_id, user_id) {
    console.log(`/api/entity/${entity_id}/user/}`);
    return this.httpClient.delete(
      environment.apiUrl + `/api/entity/${entity_id}/user/${user_id}`,
      {}
    );
  }

  entityGetUsers(entity_id) {
    return this.httpClient.get(
      environment.apiUrl + `/api/entity/${entity_id}/users`
    );
  }

  entityGetAzureAppURL(entity_id) {
    return (
      environment.apiUrl +
      `/api/entity/sso/azure/${entity_id}/auth/openid/return`
    );
  }

  entityQuicklyAddUsers(entity_id, body) {
    return this.httpClient.post(
      environment.apiUrl + `/api/entity/${entity_id}/quickly/users`,
      body
    );
  }

  entitySetSSO(entity_id, sso_type) {
    return this.httpClient.put(
      environment.apiUrl + `/api/entity/sso/type/${entity_id}/${sso_type}`,
      null
    );
  }

  entityAzureSyncUser(entity_id) {
    return this.httpClient.post(
      environment.apiUrl + `/api/entity/sso/azure/${entity_id}/sync/users`,
      null
    );
  }

  entityAzureSyncUserUrl(entity_id) {
    return environment.apiUrl + `/api/entity/sso/azure/${entity_id}/sync/users`;
  }

  getEntityDomain(entity_id) {
    return this.httpClient
      .get(environment.apiUrl + `/api/entity/${entity_id}/domain`, {})
      .map((data: any) => {
        return data.rows;
      });
  }

  createEntityDomain(entity_id, model) {
    return this.httpClient.post(
      environment.apiUrl + `/api/entity/${entity_id}/domain`,
      model
    );
  }

  verifyDomain(entity_id, domain_name, type) {
    return this.httpClient.post(
      environment.apiUrl +
        `/api/entity/${entity_id}/domain/${domain_name}/${type}/verify`,
      {}
    );
  }

  checkDomain(domain_name) {
    return this.httpClient.post(
      environment.apiUrl + `/api/utils/domain/inspect`,
      { domain_name: domain_name },
      { withCredentials: true }
    );
  }

  deleteEntityDomain(entity_id, domain_name) {
    return this.httpClient.delete(
      environment.apiUrl + `/api/entity/${entity_id}/domain/${domain_name}`,
      {}
    );
  }

  getEntity(entity_id) {
    return this.httpClient.get(environment.apiUrl + `/api/entity/${entity_id}`);
  }

  getEntities(account_id, queryParam = null) {
    // console.log(account_id, `/api/account/${account_id}/entity`);
    return this.httpClient
      .get(environment.apiUrl + `/api/account/${account_id}/entity`, {
        params: queryParam,
      })
      .pipe(
        map((response: any) => {
          return {
            rows: response.rows,
            count: response.count,
          };
        })
      );
  }

  getPersonalSubscriptionStatus(oath_token) {
    const httpOptions = {
      headers: new HttpHeaders({
        "x-token": oath_token,
      }),
    };
    let queryParam = `query{ getPersonalSubscriptionStatus{ plan\n subscribe_state\n expiry_at\n }}`;
    return this.httpClient.post(
      environment.subUrl + `/graphql/mvb`,
      {
        query: queryParam,
      },
      httpOptions
    );
  }

  getPersonalSubscription(oath_token) {
    const httpOptions = {
      headers: new HttpHeaders({
        "x-token": oath_token,
      }),
    };
    let queryParam = `query getPersonalSubscription {\n getPersonalMVBSubscription {\n id\n name\n trial_limit_time\n source\n }\n getPersonalPaymentProfile {\n id\n first_name\n last_name\n masked_card_number\n card_type\n billing_address\n billing_address_2\n billing_city\n billing_state\n billing_zip\n billing_country\n }\n getPersonalBundle (type: personal) {\n name\n handle\n activated\n delayed_cancel\n canceled\n plans {\n name\n handle\n activated\n details {\n price\n }\n subscribed {\n id\n state\n trial_started_at\n trial_ended_at\n next_product_handle\n is_delayed_cancel\n }\n }\n }\n getPersonalSubscription {\n plans {\n id\n name\n state\n is_delayed_cancel\n is_trialling\n current_period_started_at\n current_period_ends_at\n next_assessment_at\n credit_card {\n first_name\n last_name\n masked_card_number\n card_type\n }\n trial_interval {\n interval\n interval_unit\n interval_format\n }\n interval {\n interval\n interval_unit\n interval_format\n}\n}\n}\n}\n`;
    return this.httpClient.post(
      environment.subUrl + `/graphql/chargify`,
      {
        query: queryParam,
      },
      httpOptions
    );
  }

  getDefaultEntity(account_id) {
    return this.httpClient
      .get(`${environment.apiUrl}/api/account/${account_id}/entity/default`)
      .pipe(
        tap((data) => {
          this.defaultEntity.next(data["rows"][0]);
        })
      );
  }

  putDefaultEntity(account_id, entity_id) {
    return this.httpClient.put(
      environment.apiUrl + `/api/account/${account_id}/entity/default`,
      { default_entity: entity_id }
    );
  }

  autoSyncEntity(entity_id, isAuto) {
    return this.httpClient.post(
      environment.apiUrl + `/api/entity/${entity_id}/auto_user_sync`,
      { auto_user_sync: isAuto }
    );
  }

  getAttachUser(account_id) {
    console.log(account_id, `/api/entity/{entity_id}/user/discover`);
    return this.httpClient.get(
      environment.apiUrl + `/api/entity/${account_id}/user/discover`
    );
  }

  checkEntitiesUrl(entity_name) {
    return environment.apiUrl + `/api/entity/login/${entity_name}`;
  }

  checkEntities(entity_name) {
    return this.httpClient.get(
      environment.apiUrl + `/api/entity/login/${entity_name}`
    );
  }

  getUserEntity(account_id) {
    return this.httpClient.get(
      environment.apiUrl + `/api/account/${account_id}/entity`,
      { withCredentials: true }
    );
  }

  getPGPConfig() {
    const account_id = this.userstate.getUser().id;
    return this.httpClient.get<any>(
      environment.apiUrl + `/api/account/${account_id}/storage/config`
    );
  }

  putPGPConfig(config) {
    const account_id = this.userstate.getUser().id;
    config.account_id = account_id;
    return this.httpClient.put(
      environment.apiUrl + `/api/account/${account_id}/storage/config`,
      config
    );
  }

  getUserByUserName(user_name, password = "") {
    return this.httpClient.post<any>(
      environment.apiUrl + `/api/v1/account/getinfo/username`,
      { name: user_name, password }
    );
  }

  getUserByUserNameV2(user_name, password = '') {
    return this.httpClient.post<any>(environment.apiUrl + `/api/v1/account/getinfo`, { name: user_name, password });
  }

  getHostPermission({ key, scope = "p_mvbw", accountId }) {
    console.log(scope);
    let requestUrl = `${environment.apiUrl}/api/v1/account/${accountId}/permission/${scope}`;
    if (key) {
      requestUrl = `${requestUrl}?key=${key}`;
    }
    return this.httpClient.get<any>(requestUrl);
  }

  getSessionList() {
    return this.httpClient.get(environment.apiUrl + `/api/account/session`, {
      withCredentials: true,
    });
  }

  deleteFromSessionList(sessionId) {
    return this.httpClient.delete(
      environment.apiUrl + `/api/account/session/${sessionId}`,
      { withCredentials: true }
    );
  }

  postEntityRequirement(data: any) {
    return this.httpClient.post(
      environment.apiUrl + "/api/entity/requirement",
      data,
      { withCredentials: true }
    );
  }

  accountInitial(email) {
    return this.httpClient.post(
      environment.apiUrl + "/api/account/initial",
      { email: email },
      { withCredentials: true }
    );
  }

  accountInitialByOTP(email) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/account/initial?type=totp`,
      { email: email, template: "sign_in_wo_2fa" },
      { withCredentials: true }
    );
  }

  getAccountNotifySetting() {
    const account_id = this.userstate.getUser().id;
    return this.httpClient.get(
      environment.apiUrl +
        `/api/notification/notification-setting-content?account_id=${account_id}`,
      { withCredentials: true }
    );
  }

  getNotifycation() {
    const account_id = this.userstate.getUser().id;
    const lanId = 1;
    return this.httpClient.get(
      environment.apiUrl +
        `/api/notification/notification/influencer/${account_id}/${lanId}`,
      { withCredentials: true }
    );
  }

  updateNotifyReadStatus({ ids: ids, isRead: isRead }) {
    const account_id = this.userstate.getUser().id;
    return this.httpClient.put(
      environment.apiUrl + `/api/notification/notification-influencer/read`,
      {
        ids: ids,
        relation_id: account_id,
        isRead: isRead,
      },
      { withCredentials: true }
    );
  }

  getLocale() {
    return this.httpClient.get<any>(environment.apiUrl + `/api/manage/lang`);
  }

  updateAccountSetting(obj: any) {
    const { account_id, ...payload } = obj;
    return this.httpClient.put(
      environment.apiUrl + `/api/account/${account_id}/setting`,
      payload
    );
  }

  getAccountSetting(account_id: string) {
    return this.httpClient.get(
      environment.apiUrl + `/api/account/${account_id}/setting`
    );
  }

  getIp(ip: string) {
    const ip_url = "https://ipinfo.io/" + ip + "/json";
    return this.httpClient.get<any>(ip_url).toPromise();
  }

  getEmailNotifyFromUserSetting(account_id) {
    return this.httpClient
      .get(
        environment.apiUrl + `/api/account/${account_id}/config/notify/email`,
        { withCredentials: true }
      )
      .map((data: any) => data.list);
  }

  putEmailNotifyToUserSetting(account_id, template_id, isEnable: boolean) {
    return this.httpClient.put(
      environment.apiUrl +
        `/api/account/${account_id}/config/notify/email/${template_id}`,
      { isEnable },
      { withCredentials: true }
    );
  }

  getSubModuleService() {
    return this.httpClient.get(
      environment.apiUrl + `/api/subscription/product/entity/service`
    );
  }

  getSubscriptions() {
    return this.httpClient.get(
      environment.apiUrl +
        `/api/account/${this.userstate.getUser().id}/subscription`
    );
  }

  requestModule({
    entity_id: entity_id,
    service_instance_id: service_instance_id,
  }) {
    return this.httpClient.post(
      environment.apiUrl +
        `/api/entity/${entity_id}/subscription/expectation/service`,
      {
        service_id: service_instance_id,
        user_id: this.userstate.getUser().id,
      }
    );
  }

  getEntitySubscription(entity_id, option = null) {
    return this.httpClient.get(
      environment.apiUrl + `/api/entity/${entity_id}/subscription/`,
      { params: option }
    );
  }
  getQuestionnaire(id_account: string, shard_region) {
    return this.httpClient.get(
      environment.apiUrl +
        `/api/account/${id_account}/questionnaire?topic=default-profile&shard_region=${shard_region}`
    );
  }
  submitQuestionnaire(
    id_account: string,
    questionnaire: { topic: string; content: QuestionnaireContent }
  ) {
    questionnaire["shard_region"] = this.userstate.getUser().shard_region;
    return this.httpClient.post(
      environment.apiUrl + `/api/account/${id_account}/questionnaire`,
      questionnaire
    );
  }

  updateQuestionnaire(id_account: string, questionnaire: {}) {
    return this.httpClient.put(
      environment.apiUrl +
        `/api/account/${id_account}/questionnaire/default-profile`,
      questionnaire
    );
  }

  getEntitySubmoduleSubscription(entity_id) {
    // return this.httpClient.get(environment.apiUrl + `/api/entity/${entity_id}/subscription/permission/submodule`)
    return this.httpClient.get(
      environment.apiUrl + `/api/entity/${entity_id}/subscription`
    );
  }

  getEntityOnboardingTempDataByNew(entity_id, service_id) {
    const params = {
      entity_id,
      service_id,
      status: 1,
    };
    return this.getEntityOnboardingTempData(params);
  }

  getEntityOnboardingTempData(option = null) {
    return this.httpClient.get(
      environment.apiUrl + `/api/entity/subscription/onboard-request`,
      {
        params: option,
      }
    );
  }

  requestSubscriptionForEntity(data, attachment?) {
    console.log(data, attachment);
    const formData = new FormData();
    Object.keys(data).map((key) => {
      formData.append(key, data[key]);
    });
    formData.append("attachment", attachment);
    console.log(formData);
    return this.httpClient.post(
      `${environment.apiUrl}/api/entity/subscription/onboard-request`,
      formData,
      {
        withCredentials: true,
        // reportProgress: true,
        // observe: 'events'
      }
    );
  }

  checkUsername(username: string) {
    return this.httpClient.post(
      environment.apiUrl + `/api/account/check/username/${username}`,
      { withCredentials: true }
    );
  }

  signupAccount(registerInfo) {
    return this.httpClient.post(
      environment.apiUrl + `/api/account/signup`,
      registerInfo
    );
  }

  signupClipsAccount(registerInfo) {
    return this.httpClient.post(
      environment.apiUrl +
        `/api/v1/account/signup/clips?return_url=${environment.clipsUrl}`,
      registerInfo
    );
  }

  checkQuestionarie(id_account) {
    return this.httpClient.get(
      environment.apiUrl +
        `/api/account/${id_account}/questionnaire?topic=default-profile`,
      { withCredentials: true }
    );
  }

  d365UploadPic(file) {
    return this.httpClient.post(
      environment.apiUrl + `/api/support/case/screenshot`,
      file
    );
  }

  d365SendCase(ticket) {
    return this.httpClient.post(
      environment.apiUrl + `/api/support/case`,
      ticket
    );
  }

  getEntityAgent(entity_id) {
    return this.httpClient.get(
      environment.apiUrl + `/api/entity/${entity_id}/reseller`
    );
  }

  createEntityAgent(entity_id, data) {
    return this.httpClient.post(
      environment.apiUrl + `/api/entity/${entity_id}/reseller`,
      data
    );
  }

  deleteEntityAgent(entity_id, account_id) {
    return this.httpClient.delete(
      environment.apiUrl + `/api/entity/${entity_id}/user/${account_id}`,
      {}
    );
  }

  subscribePromotionalEmail() {
    const email = this.userstate.getUser().email;
    return this.httpClient.get(
      environment.apiUrl + `/api/email/subscribe/${email}`,
      {}
    );
  }

  unSubscribePromotionalEmail() {
    const email = this.userstate.getUser().email;
    return this.httpClient.get(
      environment.apiUrl + `/api/email/unsubscribe/${email}`,
      {}
    );
  }

  getKnowledgeArticle(keyWord) {
    return this.httpClient.get(
      environment.apiUrl + `/api/support/knowledge_article?q=${keyWord}`
    );
  }

  getDetailArticle(article_number, lang) {
    return this.httpClient.get(
      environment.apiUrl +
        `/api/support/knowledge_article/${article_number}?countryCode=${lang}`
    );
  }

  getSupportCase() {
    return this.httpClient.get(environment.apiUrl + `/api/support/case`);
  }

  updateSupportCase(case_number, data) {
    return this.httpClient.put(
      environment.apiUrl + `/api/support/case/${case_number}`,
      data
    );
  }

  getSupportCategory() {
    return this.httpClient.get(environment.apiUrl + `/api/support/category`);
  }

  geSupportArticles(category_number, lang) {
    return this.httpClient.get(
      environment.apiUrl +
        `/api/support/category/${category_number}?countryCode=${lang}`
    );
  }

  getEntityPayload(id: string) {
    return this.httpClient.get(
      environment.apiUrl + `/api/v1/entity/requirement/${id}`
    );
  }

  getEntityRegion() {
    return this.httpClient.get(environment.apiUrl + `/api/v1/entity/region`);
  }

  postSendEntityForm(data) {
    return this.httpClient.post(
      environment.apiUrl + `/api/v1/entity/requirement`,
      data
    );
  }

  updateSendEntityForm(data: any, id: string) {
    return this.httpClient.put(
      environment.apiUrl + `/api/v1/entity/requirement/${id}`,
      data
    );
  }

  trackMicroSoftConvsersion() {
    (<any>window).dataLayer.push({ event: "sign-up-form-submit-microsoft" });
  }

  trackGoogleConversion() {
    (<any>window).dataLayer.push({ event: "sign-up-form-submit-google" });
  }

  trackAppleConversion() {
    (<any>window).dataLayer.push({ event: "sign-up-form-submit-apple" });
  }

  getEntityType() {
    return this.httpClient.get(environment.apiUrl + `/api/v1/entity/type`);
  }

  getLanguageList() {
    // persist lang
    const support_lang = this.appstate.sessionStorage.getItem("_lang");
    if (support_lang) {
      return of(support_lang);
    }
    return this.httpClient
      .get(environment.apiUrl + `/api/common/mvb/support/language`)
      .pipe(
        tap((data) => {
          this.appstate.sessionStorage.setItem("_lang", data);
          return of(data);
        })
      );
  }

  getSubjectList() {
    return this.httpClient.get(`https://api.boclips.com/v1/subjects`);
  }

  checkMspAgreement(accountId) {
    return this.httpClient.get(
      environment.apiUrl + `/api/v1/account/${accountId}/msp/agreement`
    );
  }

  getEntityMsp(accountId, entityId) {
    return this.httpClient.get(
      environment.apiUrl + `/api/v1/account/${accountId}/msp/entity/${entityId}`
    );
  }

  createMSP(accountId, entityId, email) {
    return this.httpClient.post(
      environment.apiUrl +
        `/api/v1/account/${accountId}/msp/entity/${entityId}`,
      email
    );
  }

  removeMSP(accountId, entityId, payload) {
    return this.httpClient.request(
      "delete",
      environment.apiUrl +
        `/api/v1/account/${accountId}/msp/entity/${entityId}`,
      {
        headers: new HttpHeaders({ "Content-Type": "application/json" }),
        body: payload,
      }
    );
  }

  getMspInviteList(accountId, queryParam) {
    return this.httpClient
      .get(environment.apiUrl + `/api/v1/account/${accountId}/entity/msp`, {
        params: queryParam,
      })
      .pipe(
        map((response: any) => {
          return {
            rows: response.rows,
            count: response.count,
          };
        })
      );
  }

  updateMspInvite(account_id, entity_id, date) {
    const body = { expiryAt: date };
    return this.httpClient.patch(
      environment.apiUrl +
        `/api/v1/account/${account_id}/entity/${entity_id}/msp`,
      body
    );
  }

  getNotification(userId, entityId) {
    return this.httpClient.get(
      environment.apiUrl + `/api/v1/account/${userId}/entity/${entityId}/notice`
    );
  }

  patchNotification(userId, entityId, value) {
    return this.httpClient.patch(
      environment.apiUrl +
        `/api/v1/account/${userId}/entity/${entityId}/notice`,
      { instance_reg: value }
    );
  }

  getVersion(type: string) {
    return this.httpClient.get<any>(
      `https://sls.myviewboard.cloud/application/versions?deviceType=${type}`
    );
  }

  getSubscriptionToken() {
    const httpOptions = {
      withCredentials: false,
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
    };

    return this.httpClient.post(
      `${environment.apiUrl}/api/v1/session/token`,
      {},
      httpOptions
    );
  }

  getMyEntity(params) {
    return this.httpClient.get(`${environment.apiUrl}/api/v1/me/entity`, {
      params,
    });
  }
  hasMsp(): Observable<boolean> {
    return this.httpClient
      .get<mspProfile>(`${environment.apiUrl}/api/v1/me/entity/msp`)
      .map((x) => {
        return x.isMSP;
      });
  }

  hasEntityAdmin() {
    return this.getMyEntity({
      count: 1,
      role_type: "only_admin",
    }).map((x: any) => {
      return x.rows.length !== 0;
    });
  }

  hasEntity() {
    return this.getMyEntity({
      count: 1,
    }).map((x: any) => {
      return x.rows.length !== 0;
    });
  }

  refreshSubscriptionToken(token: string) {
    const httpOptions = {
      withCredentials: false,
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
    };
    const body = new HttpParams()
      .set("grant_type", "refresh_token")
      .set("scope", "openid profile email")
      .set("refresh_token", token)
      .set("client_id", "mvb-core-service");
    return this.httpClient.post(
      `${environment.authUrl}/oidc/v1/token`,
      body.toString(),
      httpOptions
    );
  }

  checkSensSessionList() {
    return this.httpClient.get(
      `${environment.sensApiUrl}/api/my/rooms/list-stats?page=0&perpage=1`
    );
  }

  getPermissionByScope(scope = this.appstate.appScope) {
    return this.httpClient.get(
      environment.apiUrl + `/api/v1/me/permission/scope/${scope}`
    );
  }

  getPermissionScope() {
    return this.httpClient.get(
      environment.apiUrl + `/api/v1/me/permission/scope`
    );
  }

  getServices() {
    return this.httpClient.get(environment.apiUrl + `/api/v1/me/services`);
  }

  _getSubModule() {
    return this.httpClient.get(environment.apiUrl + `/api/v1/me/submodule`);
  }

  getSubModule() {
    return this._getSubModule().map((x: any) => {
      if (x && x.submodule) {
        return x.submodule.map((submodule) => submodule.name);
      }
    });
  }

  getJWT() {
    if (this.token.value) {
      return this.token.value;
    }
    return this.httpClient
      .post<{ access_token: string }>(
        `${environment.apiUrl}/api/v1/session/token`,
        {}
      )
      .map((r) => {
        this.setJWT(r.access_token);
        return this.token.value;
      });
  }
  setJWT(access_token) {
    this.token.next(access_token);
  }
  waitJWT(ofn: (token) => Observable<any>) {
    return this.token.pipe(
      filter((s) => !!s),
      flatMap(ofn)
    );
  }
  deleteAccount() {
    return this.httpClient.post(environment.apiUrl + `/api/v1/me/delete`, {});
  }
}

interface mspProfile {
  isMSP: boolean;
}
