import { filter } from "rxjs/operators";
import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { AppState } from "../../_services";
import { NavigationStart, Router } from "@angular/router";
import { Dialog } from "primeng/dialog";
import { DomHandler } from "primeng/api";
import { RemoteTransferComponent } from "../remote-transfer.component";
import { Subscription } from "rxjs";
@Component({
  selector: "app-remote-transfer-dialog",
  templateUrl: "./remote-transfer-dialog.component.html",
  styleUrls: ["./remote-transfer-dialog.component.scss"],
})
export class RemoteTransferDialogComponent
  implements OnInit, AfterViewInit, OnDestroy {
  msgs = [];
  routeSubscription: Subscription;
  @ViewChild("remoteTransfer") public remoteTransfer: RemoteTransferComponent;
  @ViewChild("dialog") public dialog: Dialog;
  constructor(public appstate: AppState, private router: Router) {}

  ngOnInit() {
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((evt) => {
        // Handle route change
        this.appstate.set("remote-transfer-dialog", false);
      });
  }
  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }
  ngAfterViewInit(): void {
    const keepInViewport = true;
    this.dialog.onDrag = function (event) {
      if (this.dragging) {
        let containerWidth = DomHandler.getOuterWidth(this.container);
        let containerHeight = DomHandler.getOuterHeight(this.container);
        let deltaX = event.pageX - this.lastPageX;
        let deltaY = event.pageY - this.lastPageY;
        let offset = DomHandler.getOffset(this.container);
        let leftPos = offset.left + deltaX;
        let topPos = offset.top + deltaY;
        let viewport = DomHandler.getViewport();

        this.container.style.position = "fixed";

        if (keepInViewport) {
          if (
            leftPos >= this.minX &&
            leftPos + containerWidth < viewport.width
          ) {
            // this._style.left = leftPos + 'px';
            this.lastPageX = event.pageX;
            this.container.style.left = leftPos + "px";
          }
          if (
            topPos >= this.minY &&
            topPos + containerHeight < viewport.height
          ) {
            // this._style.top = topPos + 'px';
            this.lastPageY = event.pageY;
            this.container.style.top = topPos + "px";
          }
          if (topPos + containerHeight > viewport.height) {
            this.container.style.top = viewport.height - containerHeight + "px";
          }
        } else {
          this.lastPageX = event.pageX;
          this.container.style.left = leftPos + "px";
          this.lastPageY = event.pageY;
          this.container.style.top = topPos + "px";
        }
      }
    };
  }
  onclick(isVisible) {
    // If we are clicking the close button and not something else
    if (!isVisible) {
      this.appstate.set("remote-transfer-dialog", false);
      // clear inputs when close dialog, for future request
      // this.remoteTransfer.sender_input = '';
      // this.remoteTransfer.target_user = '';
      // this.remoteTransfer.secureCode = '';
      if (this.remoteTransfer && this.remoteTransfer.userUpload) {
        this.remoteTransfer.cancelUpload();
      }
    }
  }
}
