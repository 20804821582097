import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PriceTableComponent } from './price-table.component'

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [PriceTableComponent],
  exports: [PriceTableComponent]
})
export class PriceTableModule { }
