import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {CryptoJS} from 'crypto-js';


if (environment.production) {
  enableProdMode();
  if ((window as any)) {
    (window as any).console.log = function () {
    };
  }
}
(window as any).global = window;

localStorage.setItem('environment', JSON.stringify(environment));
platformBrowserDynamic().bootstrapModule(AppModule);





