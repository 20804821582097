import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MqttClient, connect  } from 'mqtt';
import { environment } from '../../environments/environment';

@Injectable()
export class PollingQuizService {
  environment: any = environment;
  mqtt = require('mqtt');
  client: MqttClient;
  clientId: String;
  eventMessage: EventEmitter<any> = new EventEmitter();
  eventClose: EventEmitter<any> = new EventEmitter();

  constructor(
    private httpClient: HttpClient
  ) { }

  mqtt_connect({ clientId, username, password }) {
    if (!this.client) {
      const mqttUrl = this.environment.production ? 'mqtts://control.myviewboard.com/' : 'mqtts://stage-control.myviewboard.com/';
      const options = {
        keepalive: 10,
        clientId,
        username,
        password
      };
      this.clientId = clientId;
      // console.log('options', options);
      this.client = connect(mqttUrl, options);
      this.client
        .on('connect', () => {
          this.client.subscribe(`/quiz/${clientId}`, { qos: 1 }, (err) => { });
        })
        .on('message', (topic, message) => {
          this.eventMessage.emit(message.toString());
        })
        .on('close', () => {
          this.client.end();
          this.eventClose.emit();
        });
    }
  }

  mqtt_disconnect() {
    if (this.client) {
      this.client.unsubscribe(`/quiz/${this.clientId}`, (err) => { });
      this.client.end();
    }
  }

  enterQuiz(data: any) {
    return this.httpClient.post(environment.apiUrl + `/api/v1/quiz/user`, data)
  }

  submitAnswer(obj: any) {
    const { user_id, question_id, data } = obj
    return this.httpClient.post(environment.apiUrl + `/api/v1/quiz/user/${user_id}/question/${question_id}`, data)
  }
}

