import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotTranslatedService {

  constructor() { }

  notTranslated(key: string) {
  }
}
