import { of as observableOf, Observable, from as observableFrom, Subject } from "rxjs";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError, map, mergeMap } from "rxjs/operators";
import { EventEmitter, Injectable } from "@angular/core";

import { Http, Headers, RequestOptions, Response } from "@angular/http";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AppState, InternalStateType } from "./app.service";
import { environment } from "../../environments/environment";
import { QueryParam } from "../_helpers/datatablePrototypes";
import { UserState } from "./current/user";
import { EntityUserResponse } from "../_models";

let regModuleEndpoint = "https://zv8nx8q7r2.execute-api.us-east-1.amazonaws.com/dev/items";
let bindingEndPoint = "https://vmxbkzg64f.execute-api.us-east-1.amazonaws.com/dev/items";

if (environment.production) {
  regModuleEndpoint = "https://ys9wxw7829.execute-api.us-east-1.amazonaws.com/prod/items";
  bindingEndPoint = "https://lqos0otjqf.execute-api.us-east-1.amazonaws.com/prod/items";
}

@Injectable()
export class AdminService {
  public coupon_type: any = {
    Personal: 1,
    Professional: 2
  };
  public entity_requirement_status: any = {
    NEW: 1,
    SUCCESS: 2,
    PENDING: 3,
    REJECT: 4
  };
  _state: InternalStateType = {};
  instanceState: EventEmitter<any> = new EventEmitter();
  invokeEvent: Subject<any> = new Subject();
  discoverEvent: Subject<any> = new Subject();

  constructor(private httpClient: HttpClient, private userstate: UserState, private appstate: AppState) {}

  public get state() {
    return (this._state = this._clone(this._state));
  }

  private _clone(object: InternalStateType) {
    return JSON.parse(JSON.stringify(object));
  }

  // never allow mutation
  public set state(value) {
    throw new Error("do not mutate the `.state` directly");
  }

  public get(prop?: any) {
    /**
     * Use our state getter for the clone.
     */
    const state = this.state;
    return state.hasOwnProperty(prop) ? state[prop] : false;
  }

  public set(prop: string, value: any) {
    /**
     * Internally mutate our state.
     */
    if (prop === "instance_state") {
      this.instanceState.emit(value);
    }
    return (this._state[prop] = value);
  }

  getCouponType() {
    return this.coupon_type;
  }

  issueCoupon(data: any, coupon_type?: number) {
    if (coupon_type === undefined) {
      coupon_type = this.coupon_type.Personal;
    }
    return this.httpClient.post(environment.apiUrl + `/api/coupon/${coupon_type}/apply`, data).pipe(
      map((response: Response) => response),
      catchError((response: Response) => {
        const error: any = response;
        error.applicant = data.applicant;
        return observableOf(error);
      })
    );
  }

  goodoldissueCoupon(data: any) {
    // deprecated by angle
    return this.httpClient.post(environment.apiUrl + "/api/freetier/apply", data).pipe(
      map((response: Response) => response),
      catchError((response: Response) => {
        const error: any = response;
        error.applicant = data.applicant;
        return observableOf(error);
      })
    );
  }

  issuePersonalCoupon(data: any) {
    return this.issueCoupon(data, this.coupon_type.Personal);
  }

  issueProfessionalCoupon(data: any) {
    return this.issueCoupon(data, this.coupon_type.Professional);
  }

  enterCoupon(data: any) {
    return this.httpClient.post(environment.apiUrl + "/api/coupon/confirm/code", data);
  }

  enterSerial(data: any) {
    return this.httpClient.post(environment.apiUrl + "/api/coupon/serialnumber/register", data);
  }

  getIssueUnConfirmed() {
    return this.httpClient.get(environment.apiUrl + "/api/coupon/unconfirmed", {
      withCredentials: true
    });
  }

  getIssueHistory(coupon_type?: number) {
    const option = {};
    if (coupon_type === undefined) {
      coupon_type = this.coupon_type.Personal;
    }
    if (!this.appstate.environment.production) {
      option["params"] = { type: coupon_type };
    }
    return this.httpClient.get(environment.apiUrl + `/api/coupon/history`, option).map((data: any) => data.rows);
  }

  getIssuePersonalHistory() {
    return this.getIssueHistory(this.coupon_type.Personal);
  }

  getIssueProfessionalHistory() {
    return this.getIssueHistory(this.coupon_type.Professional);
  }

  getCouponOrder(option = {}) {
    return this.httpClient
      .get(environment.apiUrl + `/api/v1/coupon/order`, {
        params: option
      })
      .map((data: any) => data.rows);
  }

  getCoupon(option = {}) {
    return this.httpClient
      .get(environment.apiUrl + `/api/v1/coupon`, {
        params: option
      })
      .map((data: any) => data.rows);
  }

  issueCommonCoupon(data: any) {
    return this.httpClient.post(environment.apiUrl + `/api/v1/coupon/order`, data).pipe(
      map((response: Response) => response),
      catchError((response: Response) => {
        const error: any = response;
        error.applicant_email = data.applicant_email;
        return observableOf(error);
      })
    );
  }

  resend(data: any) {
    return this.httpClient.post(environment.apiUrl + "/api/coupon/resend", data, { withCredentials: true });
  }

  resendCouponConfirm(order_id) {
    return this.httpClient.post(environment.apiUrl + `/api/v1/coupon/order/${order_id}/confirm/resend`, {}, { withCredentials: true });
  }

  getLatestVersion() {
    return this.httpClient.get(environment.apiUrl + `/api/v1/viewboard/version`, { withCredentials: true });
  }

  getInstance(entity_id, queryParam) {
    console.log(queryParam);
    return this.httpClient.get(`${environment.apiUrl}/api/entity/${entity_id}/instance`, {
      withCredentials: true,
      params: queryParam.value
    });
  }

  getInstanceById(item) {
    const query: any = {
      count: 20,
      offset: 0,
      page: 1,
      q: item.instance_id
    };
    return this.httpClient.get(`${environment.apiUrl}/api/entity/${item.entity_id}/instance`, {
      withCredentials: true,
      params: query
    });
  }

  getEntity(entity_id) {
    return this.httpClient.get(environment.apiUrl + `/api/entity/${entity_id}`, { withCredentials: true });
  }

  entityUpdateOwner(entity_id, user_email) {
    return this.httpClient.put(
      environment.apiUrl + `/api/entity/${entity_id}/user/owner`,
      { transfer_account: user_email },
      { withCredentials: true }
    );
  }

  entityUpdateUser(entity_id, user_id, data) {
    return this.httpClient.put(environment.apiUrl + `/api/entity/${entity_id}/user/${user_id}`, data, { withCredentials: true });
  }

  entityRemoveUser(entity_id, user_id) {
    console.log(`/api/entity/${entity_id}/user/}`);
    return this.httpClient.delete(environment.apiUrl + `/api/entity/${entity_id}/user/${user_id}`, { withCredentials: true });
  }

  updateUser(item) {
    return this.httpClient.put(`${environment.apiUrl}/api/entity/${item.entity_id}/user/${item.id}`, item, { withCredentials: true });
  }

  updateInstanceName({ entity_id, instance_id, remark }) {
    return this.httpClient.put(
      `${environment.apiUrl}/api/entity/${entity_id}/instance/${instance_id}`,
      {
        remark: remark
      },
      { withCredentials: true }
    );
  }

  getEntityCloudService(entity_id) {
    return this.httpClient.get(environment.apiUrl + `/api/entity/${entity_id}/cloudservice`, { withCredentials: true });
  }

  addEntityCloudService(entity_id, service_id) {
    return this.httpClient.post(
      environment.apiUrl + `/api/entity/${entity_id}/cloudservice`,
      { service_id: service_id },
      { withCredentials: true }
    );
  }

  deleteEntityCloudService(entity_id, service_id) {
    return this.httpClient.delete(environment.apiUrl + `/api/entity/${entity_id}/cloudservice/${service_id}`, { withCredentials: true });
  }

  // api/entity/{entity_id}/config

  updateEntityInstanceConfig(entity_id, data) {
    return this.httpClient.put(
      `${environment.apiUrl}/api/entity/${entity_id}/config`,
      {
        isAutoRegisterInstance: data
      },
      { withCredentials: true }
    );
  }

  getEntityInstanceConfig(entity_id) {
    return this.httpClient.get(`${environment.apiUrl}/api/entity/${entity_id}/config`, { withCredentials: true });
  }

  //  /api/entity/{entity_id}/instance/activate

  activateInstance(entity_id, payload) {
    return this.httpClient.put(
      `${environment.apiUrl}/api/entity/${entity_id}/instance/status`,
      {
        is_active: payload.is_active,
        instance_id: payload.instance_id
      },
      { withCredentials: true }
    );
  }

  activateInstance_update_alert(entity_id, payload) {
    return this.httpClient.put(
      `${environment.apiUrl}/api/entity/${entity_id}/instance/status`,
      {
        update_alert: payload.update_alert,
        instance_id: payload.instance_id
      },
      { withCredentials: true }
    );
  }

  updateEntityInstanceSettingProfile(entity_id, payload) {
    return this.httpClient.put(
      `${environment.apiUrl}/api/entity/${entity_id}/instance/status`,
      {
        extra: { profile: payload.profile },
        instance_id: payload.instance_id
      },
      { withCredentials: true }
    );
  }

  getViewboardGeoReport() {
    return this.httpClient.get(environment.apiUrl + "/report/viewboard/geo/count").map((data: any) => data.list);
  }

  removeEntityInstance({ entity_id, instance_id }) {
    return this.httpClient.delete(`${environment.apiUrl}/api/entity/${entity_id}/instance/${instance_id}`, { withCredentials: true });
  }

  // /api/entity/{entity_id}/instance/config

  getInstanceProfiles({ entity_id }) {
    return this.httpClient.get(`${environment.apiUrl}/api/entity/${entity_id}/instance/config`, { withCredentials: true });
  }

  createInstanceProfile({ entity_id, profile }) {
    return this.httpClient.post(`${environment.apiUrl}/api/entity/${entity_id}/instance/config`, profile, { withCredentials: true });
  }

  updateInstanceProfile({ entity_id, profile_id, config }) {
    return this.httpClient.put(
      `${environment.apiUrl}/api/entity/${entity_id}/instance/config/${profile_id}`,
      {
        config: config
      },
      { withCredentials: true }
    );
  }

  updateInstanceProfileTopic({ entity_id, profile_id, topic }) {
    return this.httpClient.put(
      `${environment.apiUrl}/api/entity/${entity_id}/instance/config/${profile_id}/topic`,
      {
        topic: topic
      },
      { withCredentials: true }
    );
  }

  updateEnabledAttributes({ entity_id, profile_id, enabled }) {
    return this.httpClient.put(
      `${environment.apiUrl}/api/entity/${entity_id}/instance/config/${profile_id}/enabled`,
      {
        enabled: enabled
      },
      { withCredentials: true }
    );
  }

  destroyInstanceProfile({ entity_id, profile_id }) {
    return this.httpClient.delete(`${environment.apiUrl}/api/entity/${entity_id}/instance/config/${profile_id}`, { withCredentials: true });
  }

  sendInstanceProfile({ entity_id, profile_id, instance_id }) {
    return this.httpClient.post(
      `${environment.apiUrl}/api/entity/${entity_id}/instance/config/${profile_id}`,
      {
        instance_id: instance_id
      },
      { withCredentials: true }
    );
  }

  getBroadcast(entity_id, queryParam = null) {
    return this.httpClient
      .get(`${environment.apiUrl}/api/entity/${entity_id}/broadcast`, queryParam ? { params: queryParam.value } : {})
      .pipe(
        map((response: any) => {
          return {
            rows: response.rows,
            count: response.count
          };
        })
      );
  }

  createBroadcast({ entity_id, message }) {
    return this.httpClient.post(`${environment.apiUrl}/api/entity/${entity_id}/broadcast`, message, { withCredentials: true });
  }

  destroyBroadcast({ entity_id, broadcast_id }) {
    return this.httpClient.delete(`${environment.apiUrl}/api/entity/${entity_id}/broadcast/${broadcast_id}`, { withCredentials: true });
  }

  getAllPrivateImage({ entity_id }) {
    return this.httpClient.get(`${environment.apiUrl}/api/entity/${entity_id}/instance/config/file`, { withCredentials: true });
  }

  uploadFile({ entity_id, type, files }) {
    return observableFrom(files).pipe(
      map((file: any) => {
        if (!/\.(png|jpg|jpeg)$/i.test(file.name)) {
          return throwError("data format error");
        }
        const formData = new FormData();
        formData.append("filedata", file);
        return this.httpClient.put(`${environment.apiUrl}/api/entity/${entity_id}/instance/config/file?type=${type}`, formData, {
          withCredentials: true,
          reportProgress: true,
          observe: "events"
        });
      }),
      mergeMap((response) => response)
    );
  }

  deleteFile({ entity_id, type, fileName }) {
    return this.httpClient.delete(`${environment.apiUrl}/api/entity/${entity_id}/instance/config/file?type=${type}&name=${fileName}`, {
      withCredentials: true
    });
  }

  // getUser(entity_id, queryParam) {
  //   return this.httpClient.get(
  //     `${environment.apiUrl}/api/entity/${entity_id}/users`,
  //     {
  //       withCredentials: true,
  //       params: queryParam.value,
  //     }
  //   );
  // }
  getUser(entity_id, queryParam) {
    console.log("send user param", queryParam);
    queryParam.sort = "id_role,email";
    return this.httpClient
      .get<EntityUserResponse>(`${environment.apiUrl}/api/entity/${entity_id}/users`, {
        withCredentials: true,
        params: queryParam
      })
      .pipe(
        map((res) => {
          res.rows = res.rows.map((user) => {
            user.entity_account_license = user.entity_account_licenses[0];
            return user;
          });
          return res;
        })
      );
  }

  getAttachUser(account_id, queryParam) {
    return this.httpClient.get(environment.apiUrl + `/api/entity/${account_id}/user/discover`, {
      withCredentials: true,
      params: queryParam
    });
  }

  entityAddUser(entity_id, user) {
    return this.httpClient.post(environment.apiUrl + `/api/entity/${entity_id}/user/`, user, { withCredentials: true });
  }

  entityLockUser(entity_id, user_id) {
    return this.httpClient.post(environment.apiUrl + `/api/entity/${entity_id}/user/${user_id}/lock`, {}, { withCredentials: true });
  }

  entityUnlockUser(entity_id, user_id) {
    return this.httpClient.post(environment.apiUrl + `/api/entity/${entity_id}/user/${user_id}/unlock`, {});
  }

  entityDestroyUser(entity_id, user_id) {
    return this.httpClient.post(environment.apiUrl + `/api/entity/${entity_id}/user/${user_id}/remove`, {});
  }

  entityAzureSyncUserUrl(entity_id) {
    const access_token = localStorage.getItem("access_token");
    let append = "";
    if (access_token !== "null") {
      append = `&access_token=${access_token}`;
    }
    return environment.apiUrl + `/api/entity/sso/azure/${entity_id}/endpoint?is_sync=true` + append;
  }

  entityGsuiteSyncUserUrl(entity_id) {
    const access_token = localStorage.getItem("access_token");
    let append = "";
    if (access_token !== "null") {
      append = `&access_token=${access_token}`;
    }
    return environment.apiUrl + `/api/entity/sso/gsuite/${entity_id}/endpoint?is_sync=true` + append;
  }

  entityAzureSyncGroupUserUrl({ entity_id, group_id, keep_previous }) {
    return environment.apiUrl + `/api/entity/sso/azure/${entity_id}/sync/users?` + `group_id=${group_id}&keep_previous=${keep_previous}`;
  }

  entityGsuiteSyncAllUserUrl({ entity_id, unit_path, keep_previous, domains, group_id }) {
    return (
      environment.apiUrl +
      `/api/entity/sso/gsuite/${entity_id}/sync/users?` +
      `group_id=${group_id}&unit_path=${unit_path}&keep_previous=${keep_previous}&domains=${domains}`
    );
  }

  entityOnBoard(entity_id) {
    const access_token = localStorage.getItem("access_token");
    let append = "";
    if (access_token !== "null") {
      append = `&access_token=${access_token}`;
    }
    return environment.apiUrl + `/api/entity/sso/azure/onboard?entity_id=${entity_id}` + append;
  }

  entityOnBoard_Gsuite(entity_id) {
    const access_token = localStorage.getItem("access_token");
    let append = "";
    if (access_token !== "null") {
      append = `&access_token=${access_token}`;
    }
    return environment.apiUrl + `/api/entity/sso/gsuite/onboard?entity_id=${entity_id}` + append;
  }

  entityGetAzure(entity_id) {
    return this.httpClient.get<any>(environment.apiUrl + `/api/entity/sso/azure/${entity_id}/config`);
  }

  entitySetAzure(entity_id, model) {
    return this.httpClient.put(environment.apiUrl + `/api/entity/sso/azure/${entity_id}/config`, model);
  }

  entityGetGsuite(entity_id) {
    return this.httpClient.get<any>(environment.apiUrl + `/api/entity/sso/gsuite/${entity_id}/config`);
  }

  entitySetGsuite(entity_id, model) {
    return this.httpClient.put(environment.apiUrl + `/api/entity/sso/gsuite/${entity_id}/config`, model);
  }

  entitySamlInfo(entity_id) {
    return this.httpClient.get(environment.apiUrl + `/api/entity/sp/saml/${entity_id}/info`);
  }

  entityGetSaml(entity_id) {
    return this.httpClient.get(environment.apiUrl + `/api/entity/sp/saml/${entity_id}`);
  }

  entitySetSaml(entity_id, model) {
    return this.httpClient.put(environment.apiUrl + `/api/entity/sp/saml/${entity_id}`, model);
  }

  getEntityRegion() {
    // /api/entity/region
    return this.httpClient
      .get(environment.apiUrl + `/api/entity/region`, { withCredentials: true })
      .map((data: any) => data.list || data.rows);
  }

  getEntityRequest(queryParam) {
    return this.httpClient.get(environment.apiUrl + `/api/entity/requirement`, {
      withCredentials: true,
      params: queryParam
    });
  }

  queryAzureGroups({ entity_id, group_name }) {
    const queryParam = {
      group_name: group_name
    };
    return this.httpClient
      .get(environment.apiUrl + `/api/entity/sso/azure/${entity_id}/inspect/group`, {
        withCredentials: true,
        params: queryParam
      })
      .map((data: any) => data.list);
  }

  querySubLinkAzureGroups({ entity_id, group_id }) {
    return this.httpClient
      .get(`${environment.apiUrl}/api/entity/sso/azure/${entity_id}/research/link/group/${group_id}`, { withCredentials: true })
      .map((data: any) => data.list);
  }

  queryGsuiteGroups({ entity_id, group_name, domains }) {
    const queryParam = {
      group_name: group_name,
      domains: domains
    };
    return this.httpClient
      .get(environment.apiUrl + `/api/entity/sso/gsuite/${entity_id}/inspect/group`, {
        withCredentials: true,
        params: queryParam
      })
      .map((data: any) => data.list);
  }

  queryGsuiteDomain({ entity_id }) {
    return this.httpClient
      .get(`${environment.apiUrl}/api/entity/sso/gsuite/${entity_id}/inspect/domain`, { withCredentials: true })
      .map((data: any) => data.list);
  }

  queryGsuiteUnits({ entity_id, unit_name }) {
    return this.httpClient
      .get(`${environment.apiUrl}/api/entity/sso/gsuite/${entity_id}/inspect/unit`, {
        withCredentials: true,
        params: { unit_path: unit_name }
      })
      .map((data: any) => data.list);
  }

  getbindStreamers({ instance_id }) {
    return this.httpClient.get(bindingEndPoint, {
      params: { bind_to: instance_id, direction: "out" },
      withCredentials: false
    });
  }

  bindStreamer({ instance_id, client_id }) {
    return this.httpClient.post(
      bindingEndPoint,
      {
        client: client_id,
        bind_to: instance_id,
        bind_to_name: instance_id,
        direction: "out",
        server: "https://control-io.myviewboard.cloud/", // 'https://rmc-s.myviewboard.com',
        extra: {}
      },
      { withCredentials: false }
    );
  }

  unbindStreamer(id) {
    return this.httpClient.delete(`${bindingEndPoint}/${id}`, {
      withCredentials: false
    });
  }

  findInstanceByStreamer(client_id) {
    return this.httpClient.get(bindingEndPoint, {
      params: { client: client_id, direction: "out" },
      withCredentials: false
    });
  }

  ///api/storage/es/log/list
  entityGetLog({ queryParam }) {
    var querystring = "?";
    for (let key in queryParam) {
      querystring += "&" + JSON.stringify(queryParam[key]);
    }
    return this.httpClient.get(`${environment.apiUrl}/api/storage/es/log/list${encodeURI(querystring)}`, { withCredentials: true });
  }

  checkDomain(domain: string, selectedEntityType: string) {
    let entityTypeMap = {
      regular: "Individual",
      "sub-entity": "Shared",
      ispublic: "Public"
    };
    console.log(selectedEntityType);
    return this.httpClient.post(
      `${environment.apiUrl}/api/utils/domain/inspect`,
      {
        domain_name: domain,
        entity_type: entityTypeMap[selectedEntityType]
      },
      { withCredentials: true }
    );
  }

  getSalesBenchmark(params?) {
    console.log(params);
    return this.httpClient
      .get(environment.apiUrl + `/report/website/entity/sales/benchmark`, {
        withCredentials: true,
        params
      })
      .map((data: any) => data.list);
  }

  getSalesRequestDetail(params?) {
    return this.httpClient
      .get(environment.apiUrl + `/report/website/entity/sales`, {
        withCredentials: true,
        params
      })
      .map((data: any) => data.list);
  }

  uploadEntityCSV(entity_id: string, data) {
    return this.httpClient.post(environment.apiUrl + `/api/entity/${entity_id}/import/user/csv`, data, { withCredentials: true });
  }

  approveScriptionExpectation({ entity_id, service_instance_id, usage_type, quantity }) {
    return this.httpClient.post(environment.apiUrl + `/api/entity/${entity_id}/subscription/service/{service_instance_id}`, {
      usage_type: "limitation",
      quantity: 30
    });
  }

  getSubscriptionExpectation(entity_id) {
    return this.httpClient.get(environment.apiUrl + `/api/entity/${entity_id}/subscription/expectation`);
  }

  getEntitySubscription(entity_id) {
    //return this.httpClient.get(environment.apiUrl + `/api/entity/${entity_id}/subscription/permission/submodule`)
    return this.httpClient.get(environment.apiUrl + `/api/entity/${entity_id}/subscription`);
  }

  getEntitySubmoduleSubscription(entity_id) {
    //return this.httpClient.get(environment.apiUrl + `/api/entity/${entity_id}/subscription/permission/submodule`)
    return this.httpClient.get(environment.apiUrl + `/api/entity/${entity_id}/submodule/subscription`);
  }

  getEntityRanking({ region }) {
    const user_id = this.userstate.getUser().id;
    return this.httpClient.get(`${environment.apiUrl}/api/datacenter/entities/${user_id}​/behavior/rank`, {
      params: { region: region ? region : "all" }
    });
  }

  getSalesChart(entity_id) {
    return this.httpClient.get(environment.apiUrl + `/api/datacenter/entity_Dashboard/dashboard/${entity_id}/user/position_role`);
  }

  getToken(entity_id) {
    return this.httpClient.get(environment.apiUrl + `/api/entity/${entity_id}/scim/v2/getToken`);
  }

  genToken(entity_id) {
    return this.httpClient.get(environment.apiUrl + `/api/entity/${entity_id}/scim/v2/generateToken`);
  }

  listUpdateHook() {
    this.invokeEvent.next("update");
  }

  getListUpdate(): Observable<any> {
    return this.invokeEvent.asObservable();
  }

  discoverListUpdate() {
    this.discoverEvent.next("update");
  }

  getDiscoverListUpdate(): Observable<any> {
    return this.discoverEvent.asObservable();
  }
}

