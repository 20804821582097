<div fxLayout="column" fxLayoutGap="20px">
    <h2 [translate]="'Google Service Integrations'">
        Google Service Integrations
    </h2>
    <p [translate]="'Google Service Integrations description'" >
        You can authorize the following Google services to bind with myViewBoard and get more advanced features
    </p>
    <div *ngFor="let option of options">
        <mat-card>
            <mat-checkbox class="example-margin" [(ngModel)]="option.checked">
                {{this.bindingLable[option.key]}}
             <!--   <i [ngClass]="this.icons[option.key]"></i>-->
            </mat-checkbox>
        </mat-card>
    </div>

    <div fxLayout="row" fxLayoutAlign="center center">
        <app-google-sign-in (click)="onAuthClick($event)"></app-google-sign-in>
    </div>

    <button mat-button (click)="skip()">
        < skip
    </button>

</div>



