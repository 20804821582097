import { EventEmitter, Inject, Injectable } from "@angular/core";
import { AppState } from "@_services/app.service";
import { BehaviorSubject } from "rxjs";
import { SweetAlertService } from "ng2-sweetalert2";
import { DOCUMENT } from "@angular/common";
import _ from "lodash";
import { IPermissionScope } from "@_services/current/scope";
import { QuestionnaireContent } from "app/_models/questionnaire";

export enum State {
  idle,
  warning,
  expired,
}
@Injectable()
export class UserState {
  private storage;
  private current_user: CurrentUser;
  private store_key = "currentUser";
  private changeEvent: EventEmitter<any> = new EventEmitter();
  private onStatusChange: EventEmitter<any> = new EventEmitter();
  private isLogin: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public questionnaireContent: QuestionnaireContent = {
    role: null,
  };
  is_swal = false;

  constructor(
    @Inject(DOCUMENT) private document: HTMLDocument,
    private appState: AppState,
    private swalService: SweetAlertService
  ) {
    this.storage = this.appState.localStorage;
    // previous flow
    this.initFlow();
  }

  set(currentUserData: any) {
    if (!currentUserData) {
      this.storage.removeItem("currentUser");
      this.storage.removeItem("permission");
      return;
    }
    const userData = Object.assign({}, currentUserData);
    this.storage.setItem("currentUser", JSON.stringify(userData));
    if (userData.permission) {
      this.storage.setItem("permission", JSON.stringify(userData.permission));
    }
    this.appState.set(this.store_key, currentUserData);
    this.current_user = new CurrentUser(userData);
    this.isLogin.next(true);
  }

  get() {
    const user = this.current_user.user;
    if (!user) {
      throw Error("This user permission is not initial.");
    }
    return user;
  }
  userLogin(currentUserData) {
    this.set(currentUserData);
    this.changeEvent.emit(currentUserData);
  }

  userLogout() {
    this.isLogin.next(false);
    this.set(null);
  }

  hasLogin(): boolean {
    return this.isLogin.getValue();
  }

  getCurrentUser(): CurrentUser {
    return this.current_user;
  }
  getQuestionnaireContent() {
    return this.questionnaireContent;
  }
  setQuestionnaireContent(content: QuestionnaireContent = { role: null }) {
    if (content) {
      this.questionnaireContent = content;
    }
  }
  getUserID() {
    return this.current_user.user.id;
  }

  getUserEmail() {
    return this.current_user.user.email;
  }

  getUserMyviewboardName() {
    return this.current_user.user.email;
  }

  emitUserChange(any) {
    this.set(Object.assign(this.current_user, any));
    this.changeEvent.emit(any);
  }

  changeUser() {
    return this.changeEvent;
  }

  getUser() {
    if (!this.current_user) {
      return null;
    }
    return this.current_user.user;
  }

  setExpireDate(utc_date) {
    localStorage.setItem("expire_time", utc_date);
  }

  getExpireDate() {
    return localStorage.getItem("expire_time");
  }

  removeExpireDate() {
    localStorage.removeItem("expire_time");
  }

  checkExpiredDate() {
    const current = new Date().getTime();
    const expired = new Date(this.getExpireDate()).getTime();
    const diff = expired - current;
    return diff;
  }
  initFlow() {
    if (!this.getFlow()) {
      this.storage.setItem("flow", "session");
    }
  }
  useDefaultFlow() {
    this.storage.setItem("flow", "session");
  }
  useOIDCFlow() {
    this.storage.setItem("flow", "code");
  }
  getFlow() {
    return this.storage.getItem("flow");
  }
  isOIDCFlow() {
    return this.storage.getItem("flow") === "code";
  }
}

// TODO user interface

export class CurrentUser {
  private _user = null;
  private current_permission: CurrentPermission;
  submodule: SubModule = new SubModule();
  set user(user) {
    this._user = user;
  }

  get user() {
    return this._user;
  }

  constructor(user) {
    this.user = user;
    this.current_permission = new CurrentPermission(this.user.permission);
  }
  getUserFullName() {
    if (!this.user.first_name || !this.user.last_name) {
      return this.user.name;
    }
    return `${this.user.first_name} ${this.user.last_name}`;
  }
  getUserFirstName() {
    return this.user.first_name;
  }
  getUserLastName() {
    return this.user.last_name;
  }
  setEntity(is_admin: boolean) {
    this._user.has_entity = is_admin;
  }
  setEntityAdmin(is_admin: boolean) {
    this._user.has_entity_admin = is_admin;
  }
  setEntityMSP(is_msp: boolean) {
    this._user.has_entity_msp = is_msp;
  }
  getUserEntityList() {
    // TODO this structure will deprecated
    return this.user.entities || [];
  }
  getAvatar() {
    return this.user.pic_url;
  }
  isMsp() {
    return this._user.has_entity_msp;
  }
  hasEntity() {
    return (
      this._user.has_entity ||
      this.getUserEntityList().find((item) => item.entity_account.id_role !== 3)
    );
  }
  hasEntityAdmin() {
    return (
      this._user.has_entity_admin ||
      this.getUserEntityList().find((item) => item.entity_account.id_role <= 3)
    );
  }
  isCanUse() {
    return this.isActivated() && this.isLock();
  }
  isActivated() {
    return this.user.isUse;
  }
  isLock() {
    return this.user.isLock;
  }
  isInitial() {
    return this.user.isInitial;
  }
  isEULA() {
    return this.user.is_agreement;
  }
  isViewSonicCorp() {
    return /@([a-z.]*)(viewsonic([a-z]*)).com$/g.test(this.user.email);
  }
  getCurrentPermission(): CurrentPermission {
    return this._user.current_permission || this.current_permission;
  }
  getPermission() {
    return this.user.permission;
  }
  getPermissionScope(): PermissionScope {
    return this.user.permissionScope;
  }
  setPermissionScope(data: IPermissionScope[]) {
    this._user.current_permission
      ? (this._user.current_permission.scopes = data)
      : (this.current_permission.scopes = data);

    this.user.permissionScope = new PermissionScope(data);
  }
  setSubmodule(submodule: string[]) {
    this.submodule = new SubModule(submodule);
  }
  getSubmodule(): SubModule {
    return this.submodule;
  }
  setMyPermission({ scope, list }) {
    if (!this.user.permission) {
      return;
    }
    this.user.permission[scope] = list;
  }
}
class SubModule {
  data: string[];
  constructor(data = []) {
    this.data = data;
  }
  hasKey(key) {
    return this.data.find((scope) => scope.indexOf(key) == 0);
  }
  hasClips() {
    return this.hasKey("clips");
  }
  toString() {
    return this.data;
  }
}
class PermissionScope {
  data: IPermissionScope[];
  DEF_KEY = {
    MANAGER: "p_manager",
    MVBW: "p_mvbw",
    MVBA: "p_mvba",
    WHITEBOARD: "p_mvbx",
    CLASSROOM: "p_classroom",
    COMPANION: "p_companion",
    MVB: "p_myviewboard_com",
  };
  constructor(data: IPermissionScope[]) {
    this.data = data;
  }
  hasKey(key) {
    return this.data.find(({ scope }) => scope === key);
  }
  hasWhiteboard() {
    return this.hasKey(this.DEF_KEY.WHITEBOARD);
  }
  hasClassroom() {
    return this.hasKey(this.DEF_KEY.CLASSROOM);
  }
  hasDMManager() {
    return this.hasKey(this.DEF_KEY.MANAGER);
  }
  toString() {
    return this.data;
  }
}
export class CurrentPermission {
  public permission;
  public scopes;
  private _role;
  private _role_region;
  public services: {
    myviewboard: {
      role: String;
      region: String[];
      country: String[];
    };
    datacenter: {
      role: String;
      region: String[];
      country: String[];
    };
  };

  constructor(permission) {
    this.permission = permission || {};
    this._role = _.get(permission, "services.myviewboard.role", null);
    this._role_region = _.get(permission, "services.myviewboard.region", null);
  }

  setMyPermission({ scope, list }) {
    if (!this.permission) {
      return;
    }
    this.permission[scope] = list;
  }
  getServices() {
    return this.services;
  }
  setServices(data) {
    this.services = data;
    this._role = _.get(data, "myviewboard.role", null);
    this._role_region = _.get(data, "myviewboard.region", []);
  }
  getTierName() {
    return this.permission.name || "unknow";
  }

  getRoleName() {
    return this._role;
  }

  getRoleRegionName(): string[] {
    return [].concat(this._role_region);
  }

  isViewSonicTier() {
    return this.getTierName() === "ViewSonic Employee";
  }

  isBasicTier() {
    return this.getTierName() === "Basic Tier";
  }

  getSubPermissionValue(sub_permission_name: string) {
    const name = sub_permission_name;
    const subs = this.permission.p_myviewboard_com;
    if (!subs.find((permission) => permission.name === name)) {
      return null;
    }
    return subs.find((permission) => permission.name === name).value;
  }

  getPermissionValueByScopeAndKey({ scope, key }) {
    const scopedPermission = this.permission[scope];
    return scopedPermission.find((item) => item.name === key);
  }

  isSubPermission(sub_permission_name: string) {
    return this.getSubPermissionValue(sub_permission_name) >= 1;
  }

  getSubmodule() {
    return this.permission.submodule || [];
  }

  getSubmoduleName() {
    return this.getSubmodule().map((x) => x.name);
  }

  isSubmodule(submodule_name: string) {
    const sm_name = this.getSubmoduleName();
    if (!sm_name) {
      return false;
    }
    return this.getSubmoduleName().includes(submodule_name);
  }

  isClipsPermission() {
    return (
      this.isSubmodule("clips") ||
      this.isSubmodule("clips: hybrid") ||
      this.isSubmodule("clips: in-class")
    );
  }
  isWhiteBoardPermission() {
    return (
      this.scopes.find(
        (item) => item.scope === "p_mvbx" || item.scope === "p_classroom"
      ) && true
    );
  }

  isClassroomPermission() {
    return this.scopes.find((item) => item.scope === "p_classroom") && true;
  }
  // toString() {
  //   return this.permission
  // }
}
