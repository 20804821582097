import { of as observableOf, Observable, from as observableFrom } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationStart,
} from "@angular/router";
import { AccountService } from "../_services/account.service";
import { AppState } from "../_services/app.service";
import { Title } from "@angular/platform-browser";
import { UserState } from "@_services/current/user";
import { AuthGuard } from "./auth.guard";
import { mapTo } from "rxjs-compat/operator/mapTo";
import { LanguageService } from "@_services/language.service";
import { SweetAlertService } from "ng2-sweetalert2";
declare var ga: any;

@Injectable()
export class EntityRedirectGuard implements CanActivate {
  constructor(
    private router: Router,
    private appstate: AppState,
    public userstate: UserState,
    private accountService: AccountService,
    private languageService: LanguageService,
    private swalService: SweetAlertService,
    private authGuard: AuthGuard,
    private titleService: Title
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    this.checkEntity(route, state);
    return Observable.of(true);
  }

  async checkEntity(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    await this.authGuard.canActivate(route, state).toPromise();
    const currentUser = this.userstate.getCurrentUser();
    const isEntityAdmin = currentUser.hasEntityAdmin();
    if (!isEntityAdmin) {
      this.languageService.init().subscribe(() => {
        console.log("showAlert");
        this.showAlert();
      });
      return Observable.of(true);
    }
    const account_id = this.userstate.getUserID();
    const data = await this.accountService
      .getDefaultEntity(account_id)
      .toPromise();
    const rows = data["rows"];
    if (!rows) {
      return Observable.of(true);
    }
    const [entity] = rows;
    window.open(`/mydashboard/dm/${entity.id}/devices`, "_self");
  }

  showAlert() {
    this.languageService
      .get(["Access Denied", "Require Admin Access", "Confirm"])
      .subscribe((resMessage) => {
        console.log(resMessage);
        this.swalService
          .swal({
            title: resMessage["Access Denied"],
            text: resMessage["Require Admin Access"],
            type: "error",
            buttonsStyling: false,
            showCancelButton: false,
            allowOutsideClick: false,
            confirmButtonClass: "btn btn-primary",
            confirmButtonText: resMessage["Confirm"],
          })
          .then(() => {
            this.router.navigate(["/home"]);
          });
      });
  }
}
