import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MVBMessageComponent } from "./mvb-message/mvb-message.component";
import { MvbColorpickComponent } from "./mvb-colorpick/mvb-colorpick.component";
import { ColorPickerModule } from "ngx-color-picker";
import { FormsModule } from "@angular/forms";
import { MVBRadioComponent } from "./mvb-radio/mvb-radio.component";
import { MaterialModule } from "app/material/material.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
  declarations: [MVBMessageComponent, MvbColorpickComponent, MVBRadioComponent],
  imports: [
    CommonModule,
    ColorPickerModule,
    FormsModule,
    MaterialModule,
    TranslateModule,
  ],
  exports: [MVBMessageComponent, MvbColorpickComponent, MVBRadioComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MVBCommonComponentModule {}
