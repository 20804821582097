
import { of as observableOf, interval as observableInterval, Observable, Observer } from 'rxjs/index';
import { takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class ScriptService {
  private scripts: ScriptModel[] = [];

  public load(script: ScriptModel, reload = false): Observable<ScriptModel> {
    return new Observable<ScriptModel>((observer: Observer<ScriptModel>) => {
      const existingScript = this.scripts.find(s => s.name === script.name);

      // Complete if already loaded
      if (existingScript && existingScript['loaded']) {
        observer.next(existingScript);
        observer.complete();
      }else {
        // Add the script
        this.scripts = [...this.scripts, script];

        // Load the script
        const scriptElement = document.createElement('script');
        scriptElement.type = 'text/javascript';
        scriptElement.src = script.src;
        scriptElement.async = true;
        scriptElement.defer = true;

        scriptElement.onload = () => {
          if (!reload) {
            script['loaded'] = true;
          }
          observableInterval(100).pipe(takeUntil(observableOf((<any>window)[script.name])))
            .subscribe({
              complete: () => {
                console.log((<any>window)[script.name]);
                observer.next(script);
                observer.complete();
              }
            });
        };

        scriptElement.onerror = (error: any) => {
          console.log(error);
          observer.error('Couldn\'t load script ' + script.src);
        };

        document.getElementsByTagName('body')[0].appendChild(scriptElement);
      }
    });
  }
}

export interface ScriptModel {
  name: string,
  src: string,
}
