import { Component, OnInit } from "@angular/core";
import { SelectItem } from "primeng/api";
import { LanguageService } from "../_services/language.service";
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: "app-language-select",
  templateUrl: "./language-select.component.html",
  styleUrls: ["./language-select.component.scss"],
})
export class LanguageSelectComponent implements OnInit {
  options: SelectItem[] = [];
  selected: string;
  constructor(
    public languageService: LanguageService,
    public cookieService: CookieService
  ) {
    this.initLang();
  }
  ngOnInit() {}

  initLang() {
    this.languageService.init().subscribe((res) => {
      const data = res["rows"];
      this.options = data.map((a) => ({ label: a.name, value: a.code }));
    });
  }

  set(lang: string) {
    this.selected = lang;
    this.languageService.setLang(lang);
    window.postMessage("setLang", location.origin);
  }
}
