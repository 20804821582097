import { filter } from "rxjs/operators";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { AppState } from "../_services/app.service";
import { RssService } from "../_services/rss.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { Router, NavigationEnd } from "@angular/router";
import { AccountService } from "../_services/account.service";
import { UserState, CurrentPermission } from "@_services/current/user";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { DownloadpageComponent } from "app/pages/downloadpage/downloadpage.component";
import { UserGuideService } from "@_services/user_guide.service";
import { ScrollStrategy, ScrollStrategyOptions } from "@angular/cdk/overlay";
import { AppInsightsService } from "@markpieszak/ng-application-insights";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"],
})
export class MainMenuComponent implements OnInit, OnDestroy {
  browserName;
  routeSubscription: Subscription;
  permission: any;
  studentStep: number;
  tierName: any = {
    "Basic Tier": "Basic",
    "Basic Personal Tier": "Personal",
  };
  // ['Basic''Personal',]
  downloadlink = "";
  rssItems: any;
  instancelist = [];
  current_user = this.userstate.getUser();
  full_name: string;
  scrollStrategy: ScrollStrategy;
  feature = [
    {
      userGuide: {
        studentStep: 0,
        key: "join_quickboard",
        title: "Main_Menu.Join_Quickboard",
        description: "Main_Menu.Join_Quickboard_description",
        nextStep: "Home.User_Guide.Next_Step",
      },
    },
    {
      userGuide: {
        studentStep: 1,
        key: "throw_viewboard",
        title: "Main_Menu.Throw_ViewBoard",
        description: "Main_Menu.Throw_ViewBoard_description",
        previousStep: "Home.User_Guide.Previous_Step",
        nextStep: "Home.User_Guide.Last_Step",
      },
    },
  ];

  constructor(
    public userstate: UserState,
    public appstate: AppState,
    private accountService: AccountService,
    private rss: RssService,
    public device: DeviceDetectorService,
    private router: Router,
    public dialog: MatDialog,
    private $userguide: UserGuideService,
    private readonly sso: ScrollStrategyOptions,
    public insight: AppInsightsService
  ) {
    this.scrollStrategy = this.sso.block();
  }

  ngOnInit() {
    this.getUserFullName();
    this.checkBrowser();
    this.routeSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((evt) => {});

    if (this.current_user) {
      // this.appstate.get('currentUser')//.entities.find(entity => entity.purpose === 'education')
      // this query params is for temporarily. the best solution is define "myEntity" structure. use different API to call sub function
      // my suggestion is use entity structure to define "myEntity"
      this.downloadlink = "https://dl.myviewboard.com/latest?" + Date.now();
      // this.permission = this.appstate.get('permission');
      // const {sub_permission} = this.permission;
      // console.log(sub_permission);
      // if (sub_permission) {
      //   if (sub_permission.find(sub => sub.name === 'mvb x classroom')) {
      //     this.appstate.set('mvb x classroom', sub_permission.find(sub => sub.name === 'mvb x classroom').value)
      //   }
      //   if (sub_permission.find(sub => sub.name === 'mvb x business')) {
      //     this.appstate.set('mvb x business', sub_permission.find(sub => sub.name === 'mvb x business').value)
      //   }
      // }

      // if (this.permission.name) {
      //   this.tierName = this.permission.name;
      //   switch (this.permission.name) {
      //     case 'Basic Tier':
      //       this.tierName = 'Basic';
      //       break;
      //     case 'Basic Personal Tier':
      //       this.tierName = 'Personal';
      //       break;
      //     default:
      //       break
      //   }
      // }
    }
  }

  openDownload() {
    const dialogProp = {
      width: "80vw",
      maxWidth: "820px",
      height: "auto",
      maxHeight: "650px",
      disableClose: false,
    };
    this.dialog.open(DownloadpageComponent, dialogProp);
  }

  openViewBoard() {
    if (!this.current_user) {
      (<any>window).open("/qc", "_self");
      return;
    }
    (<any>window).open("/classroom/quickboard/classroom", "OPEN A MVB");
  }

  joinViewBoard() {
    try {
      window["ExternalObject"].openWidget(location.origin + "/quickboard");
    } catch (e) {
      // alert((<any>window).ExternalObject)
    }
    (<any>window).open("/quickboard", "QuickBoard");
  }
  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  isCurrentStep(studentStep) {
    return this.$userguide.studentStep === studentStep;
  }

  trackClickItem(action) {
    this.insight.trackEvent("com_banner", {
      category: "banner",
      action: action,
    });
  }

  getUserFullName() {
    if (!this.current_user.first_name || !this.current_user.last_name) {
      return this.current_user.name;
    }
    this.full_name = `${this.current_user.first_name} ${this.current_user.last_name}`;
  }

  openPresentIn() {
    this.appstate.openWindow("/cast2/present-in.html?user=" + this.full_name);
  }

  openPresentOut() {
    this.appstate.openWindow("/cast2/cast-out.html?user=" + this.full_name);
  }

  checkBrowser() {
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Chrome')) {
      this.browserName = 'Google Chrome';
    } else if (userAgent.includes('Firefox')) {
      this.browserName = 'Mozilla Firefox';
    } else if (userAgent.includes('Edge')) {
      this.browserName = 'Microsoft Edge';
    } else {
      this.browserName = 'Unknown';
    }
  }
}
