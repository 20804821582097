<div class="dropdown d-flex align-items-center" dropdown (onToggle)="(false)">
  <a href class="dropdown-toggle" dropdownToggle (click)="(false)">
    <img
      *ngIf="currentUser.pic_url"
      [src]="currentUser.pic_url"
      class="img-avatar mvb_head_2x"
    />
    <img
      *ngIf="!currentUser.pic_url"
      class="mvb_head_2x"
      src="/assets/Login-MVB web_Corp_On.svg"
    />
  </a>
  <div
    id="list"
    class="dropdown-menu"
    [ngClass]="(currentLang | async) === 'ar' ? '' : 'dropdown-menu-right'"
    *dropdownMenu
  >
    <ng-container *ngFor="let item of AvatarList">
      <ng-container *ngIf="item.path && item.isBasic && item.showInProd">
        <a
          class="dropdown-item"
          [ngClass]="
            (currentLang | async) !== 'ar' ? '' : 'dropdown-item-arbic'
          "
          [routerLink]="item.path"
          (click)="trackClickItem(item)"
        >
          <div class="icon-center">
            <i [class]="item.class"></i>
          </div>
          {{ item.translateLabel | translate }}
        </a>
      </ng-container>
      <ng-container *ngIf="item.guide && onHideUserGuide()">
        <a
          class="dropdown-item"
          [ngClass]="
            (currentLang | async) !== 'ar' ? '' : 'dropdown-item-arbic'
          "
          (click)="UserGuide()"
        >
          <div class="icon-center">
            <i [class]="item.class"></i>
          </div>
          {{ item.translateLabel | translate }}
        </a>
      </ng-container>
      <ng-container *ngIf="!item.path && !item.guide">
        <a
          class="dropdown-item"
          [ngClass]="
            (currentLang | async) !== 'ar' ? '' : 'dropdown-item-arbic'
          "
          href="javascript:void(0)"
          (click)="logout(item)"
        >
          <div class="icon-center">
            <i [class]="item.class"></i>
          </div>
          {{ item.translateLabel | translate }}
        </a>
      </ng-container>
    </ng-container>
  </div>
  <div class="language-wrap">
    <app-language-select [hidden]="isLogin"></app-language-select>
  </div>
</div>
