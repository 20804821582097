import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AppState } from '../_services/app.service';
import { SweetAlertService } from 'ng2-sweetalert2';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { UserState } from '@_services/current/user';
import { LanguageService } from '@_services/language.service';
import { User } from 'app/_models';
import { AccountService } from '@_services/account.service';

const home = '/home';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [SweetAlertService]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  routeUrl: string = '/';
  model: User;
  currentUser;
  currentLang: Observable<string>;
  isDev = false;

  constructor(
    public appstate: AppState,
    private userstate: UserState,
    public device: DeviceDetectorService,
    public swalService: SweetAlertService,
    public languageService: LanguageService,
    private accountService: AccountService
  ) {
    const currentUser = this.userstate.getCurrentUser().user;
    if (currentUser) {
      this.currentUser = this.model = currentUser;
      this.routeUrl = home;
    }
    this.isDev = this.appstate.isProd() ? false : true;
  }

  ngOnInit() {
    this.currentLang = this.languageService.loadCurrentLang();
    this.userstate.changeUser().subscribe((data) => {
      this.currentUser = this.model = data;
    });
  }

  ngAfterViewInit() {
    this.onResize(event);
  }

  onResize(event) {
    return this.appstate.set('headerWidth', window.innerWidth);
  }

  switchToRevamp() {
    window.location.assign('/preview/home');
  }
}
