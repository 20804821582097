import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClipsSignupService {
  private Step1Info = new Subject<any>();
  constructor() { }
  sendStep1Info(info: any){
    this.Step1Info.next(info);
  }
  receiveStep1Info(): Observable<any> {
    return this.Step1Info.asObservable();
  }
}
