<div class="close">
  <button class="close__btn" mat-mini-fab [mat-dialog-close]="-1">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">
  <div
    class="layout"
    *ngIf="data.role === 'teacher' || data.role === 'other'; else Student"
  >
    <div class="greeting_pic">
      <img src="images/Teacher_Role_Greeting_pic.png" />
    </div>
    <div class="Title">
      <span class="Title__text">{{
        "Home.User_Guide.Greeting.Teacher_Title" | translate
      }}</span>
    </div>
    <div class="content">
      <span class="content__text">{{
        "Home.User_Guide.Greeting.Teacher_Subtitle" | translate
      }}</span>
    </div>
    <div class="buttons">
      <button
        class="btn btn-primary btn-md buttons__next"
        [mat-dialog-close]="0"
      >
        <span class="buttons__text">
          {{ "Home.User_Guide.Lets_Go" | translate }}
        </span>
      </button>
    </div>
  </div>
  <ng-template #Student>
    <div class="layout" *ngIf="data.role === 'student'; else Admin">
      <div class="greeting_pic">
        <img src="images/Student_Role_Greeting_pic.png" />
      </div>
      <div class="Title">
        <span class="Title__text">{{
          "Home.User_Guide.Greeting.Student_Title"
            | translate: { value: this.Name }
        }}</span>
      </div>
      <div class="buttons">
        <button
          class="btn btn-primary btn-md buttons__next"
          [mat-dialog-close]="0"
        >
          <span class="buttons__text">
            {{ "Home.User_Guide.Lets_Go" | translate }}
          </span>
        </button>
      </div>
    </div>
    <ng-template #Admin>
      <div class="layout">
        <div class="greeting_pic">
          <img src="images/Admin_Role_Greeting_pic.png" />
        </div>
        <div class="Title">
          <span class="Title__text">{{
            "Home.User_Guide.Greeting.Admin_Title" | translate
          }}</span>
        </div>
        <div class="content">
          <span class="content__text">{{
            "Home.User_Guide.Greeting.Admin_Subtitle" | translate
          }}</span>
        </div>
        <div class="buttons">
          <button
            class="btn btn-primary btn-md buttons__next"
            [mat-dialog-close]="0"
          >
            <span class="buttons__text">
              {{ "Home.User_Guide.Lets_Go" | translate }}
            </span>
          </button>
        </div>
      </div>
    </ng-template>
  </ng-template>
</mat-dialog-content>
