import { Observable, Subscription } from "rxjs";
import {
  EventEmitter,
  ElementRef,
  Directive,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { NgModel } from "@angular/forms";
@Directive({
  selector: "[inputDebounce]",
  providers: [NgModel],
})
export class DebounceInputDirective implements OnInit {
  @Input() delay: number = 500;
  @Output() debounceFunc: EventEmitter<any> = new EventEmitter();
  private subscription: Subscription;
  constructor(private elementRef: ElementRef, private model: NgModel) {}

  ngOnInit(): void {
    const eventStream = Observable.fromEvent(
      this.elementRef.nativeElement,
      "keyup"
    )
      .map(() => {
        return this.model.value;
      })
      .debounceTime(this.delay);
    this.subscription = eventStream.subscribe((input) =>
      this.debounceFunc.emit(input)
    );
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
