import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { UserState } from "@_services/current/user";
import { Purpose } from "app/_models/questionnaire";

import { SweetAlertService } from "ng2-sweetalert2";

@Component({
  selector: "role-choose",
  templateUrl: "./role-choose.component.html",
  styleUrls: ["./role-choose.component.scss"],
  providers: [SweetAlertService],
})
export class RoleChooseComponent implements OnInit {
  Education = false;
  Other = false;
  Name = "Sam";
  purpose: Purpose = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private $userState: UserState
  ) {}
  ngOnInit() {
    this.purpose = this.data.purpose;
    this.Name = this.$userState.getCurrentUser().getUserFirstName();
  }
  back() {
    this.purpose = null;
  }
  setRole(purpose: Purpose) {
    this.purpose = purpose;
  }
  finish(role) {
    return {
      role,
      purpose: this.purpose,
    };
  }
}
