<div class="clips" id="step1">
  <div class="wrap-1200">
    <div class="letft">
      <div class="content">
        <!-- <div class="mobile-pic">
          <img src="../../../../assets/Clips-signup/clip1m.png" alt="">
        </div> -->
        <div class="header">Welcome to myViewboard Clips</div>
        <div class="sub-header">Get access now to receive free trial until end of March. Let's get started.</div>
        <div class="outSource">
          <div class="google">
            <a href="{{this.accountService.googleSTLELoginUrl()}}">
              <button class="clips-sso-btn icon-google" (click)="openGoogle()">
                <span class="btn-text google">Sign up with Google</span>
              </button>
            </a>
          </div>
          <div class="MS">
            <a href="{{ this.accountService.microsoftSTLELoginUrl() }}">
              <button class="clips-sso-btn icon-microsoft" (click)="openMS()">
                <span class="btn-text MS">Sign up with Microsoft</span>
              </button>
            </a>
          </div>
        </div>
        <form class="form-horizontal text-left" name="Step1Form" [formGroup]="Step1Form" (ngSubmit)="step1Info()"
          #f="ngForm">
          <div class="inputs">
            <div class="input-wrap">
              <mat-form-field class="inputarea">
                <p class="title">First Name*</p>
                <input class="input-style" matNativeControl formControlName="firstname" required>
              </mat-form-field>
              <mat-error *ngIf="
                    Step1Form.controls['firstname'].touched ||
                    Step1Form.controls['firstname'].dirty">
                <mat-error *ngIf=" !Step1Form.controls['firstname'].value">This
                  field is mandatory
                </mat-error>
                <mat-error *ngIf="Step1Form.controls['firstname'].hasError('pattern')">
                  Sorry,only letters (a-z),numbers (0-9) and symbol( _ )( @ ) are allowed.
                </mat-error>
              </mat-error>
            </div>
            <div class="input-wrap">
              <mat-form-field class="inputarea">
                <p class="title">Last Name*</p>
                <input class="input-style" matNativeControl formControlName="lastname" required>
              </mat-form-field>
              <mat-error *ngIf="
                  Step1Form.controls['lastname'].touched ||
                  Step1Form.controls['lastname'].dirty">
                <mat-error *ngIf=" !Step1Form.controls['lastname'].value">This field is mandatory</mat-error>
                <mat-error *ngIf="Step1Form.controls['lastname'].hasError('pattern')">
                  Sorry,only letters (a-z),numbers (0-9) and symbol( _ )( @ ) are allowed.
                </mat-error>
              </mat-error>
            </div>
            <div class="input-wrap">
              <mat-form-field class="inputarea">
                <p class="title">Email*</p>
                <input class="input-style" matNativeControl (keyup)="search(email.value)" formControlName="email" #email
                  required>
              </mat-form-field>
              <mat-error *ngIf="
                Step1Form.controls['email'].touched || Step1Form.controls['email'].dirty">
                <mat-error class="hint" *ngIf="  !Step1Form.get('email').value ">
                  This field is mandatory
                </mat-error>
                <mat-error class="hint" *ngIf="
              !Step1Form.controls['email'].hasError('pattern') && Step1Form.get('email').invalid &&
              Step1Form.get('email').value &&
              Step1Form.get('email').dirty">
                  This email is already taken!
                </mat-error>
                <mat-error class="hint" *ngIf=" Step1Form.controls['email'].hasError('pattern')   &&
                        !Step1Form.get('email').valid  || Step1Form.controls['email'].hasError('pattern') ">
                  Email format is incorrect
                </mat-error>
              </mat-error>
            </div>
            <div class="input-wrap">
              <mat-form-field class="inputarea">
                <p class="title">Password*</p>
                <input class="input-style" matNativeControl formControlName="password" name="password" type="password"
                  required>

              </mat-form-field>
              <mat-error *ngIf="
            Step1Form.controls['password'].touched ||
            Step1Form.controls['password'].dirty">
                <mat-error *ngIf=" !Step1Form.controls['password'].value">This field is mandatory</mat-error>
                <mat-error class="warnspan" *ngIf="Step1Form.controls['password'].hasError('minlength')">
                  8~32 characters</mat-error>
                <mat-error class="warnspan" *ngIf="Step1Form.controls['password'].hasError('pattern')">Require
                  Lowercase(a) Uppercase(B) Number(1) and Symbol(!@#$%^&*)</mat-error>
              </mat-error>
            </div>
          </div>
          <div>
            <div class="checkbox firstbox">
              <mat-checkbox type="checkbox" name="check" formControlName="check" required>
                <span style="font-size:0.9em">I agree to myViewBoard Clips Terms & Privacy Policy</span>
              </mat-checkbox>
              <mat-error *ngIf="
                  Step1Form.controls['check'].touched ||
                  Step1Form.controls['check'].dirty">
                <mat-error *ngIf=" !Step1Form.controls['check'].value">This field is mandatory</mat-error>
              </mat-error>
            </div>
          </div>
          <div class="checkbox">
            <mat-checkbox formControlName="getNews" [(ngModel)]="getLatestNews">
              <span class="agreement" style="font-size:0.9em">I agree to receive myViewboard Clips news and
                updates</span>
            </mat-checkbox>
          </div>

          <button class="btn-next" cdkStepperNext type="submit">
            Next →
          </button>
        </form>
        <div class="info">
          <p>Already have an account?</p> <a class="redirect" (click)="redirect()">Sign in</a>
        </div>
      </div>
    </div>

    <div class="bg">
      <div class="img">
      </div>
    </div>
  </div>
</div>