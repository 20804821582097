import { Component, OnInit, ViewChild } from "@angular/core";
import { SweetAlertService } from "ng2-sweetalert2";
import { AppState } from "@_services";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { AccountService } from "@_services/account.service";
import { MatDialogRef, MatDialog } from "@angular/material";
import { SubmitSuccessComponent } from "../submit-success/submit-success.component";
import { FileuploadComponent } from "../fileupload/fileupload.component";
import { Observable } from "rxjs";
import { LanguageService } from "@_services/language.service";
@Component({
  selector: "app-mvbw",
  templateUrl: "./mvba.component.html",
  styleUrls: ["./mvba.component.scss"],
  providers: [SweetAlertService],
})
export class MvbEntityComponent implements OnInit {
  @ViewChild("fileupload") file: FileuploadComponent;
  MVB: FormGroup;
  loading = false;
  showinput: boolean;
  select_topic: string;
  otherVersion: string;
  version: string;
  checked_types = [];
  currentLang: Observable<string>;
  entity_name: string;
  domain_name: string;
  onboarding_type: string;
  entity_types = [
    { name: "Change Domain", value: "Domain", completed: false },
    { name: "Change Owner", value: "Owner", completed: false },
    { name: "Change/Add Admin", value: "Admin", completed: false },
  ];
  onboarding_types = [
    { name: "SSO Setting not working", value: "SSO", completed: false },
    { name: "Not able to sync users", value: "Sync_Users", completed: false },
  ];

  constructor(
    public accountService: AccountService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<MvbEntityComponent>,
    public dialog: MatDialog,
    private swalService: SweetAlertService,
    private appState: AppState,
    public languageService: LanguageService
  ) {}

  ngOnInit() {
    this.currentLang = this.languageService.loadCurrentLang();
    this.MVB = this.fb.group({
      onboarding_type: new FormControl("", [Validators.required]),
      Domain: new FormControl("", [Validators.required]),
      Entity: new FormControl("", [Validators.required]),
      FileGroup: this.fb.group({
        Description: new FormControl("", [Validators.required]),
        FlagCtrl: new FormControl(false),
      }),
    });
  }

  collectTypes(i) {
    const list = this.checked_types;
    i.completed === true
      ? list.push(i.name)
      : list.splice(list.indexOf(i.name), 1);
  }

  selectOS(event) {
    this.select_topic = event.target.value;
    this.showinput = false;
    this.checked_types = [];
    this.entity_types.map((i) => (i.completed = false));
    this.onboarding_types.map((i) => (i.completed = false));
    if (this.select_topic === "entity") {
      this.MVB.removeControl("onboarding_type");
    } else if (this.select_topic === "onboarding") {
      this.MVB.removeControl("Domain");
      this.MVB.removeControl("Entity");
    }
    console.log("form", this.MVB);
  }

  errorPop() {
    this.swalService.swal({
      title: "Error",
      text: "Something went wrong, please try again",
      type: "error",
      buttonsStyling: false,
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonClass: "btn btn-primary  mr-4",
      confirmButtonText: "Confirm",
    });
  }

  changeSelection($event, name) {}

  sendTicket() {
    this.file.isClicked = true;
    const breakingpoint = "\n";
    const onboarding = "Onboarding Type: " + this.onboarding_type;
    const entity =
      "Entity Name: " +
      this.entity_name +
      breakingpoint +
      "Domain Name: " +
      this.domain_name;
    const sub_type = this.select_topic === "onboarding" ? onboarding : entity;
    const text = this.file.FileGroup.controls["Description"].value;
    const list = this.checked_types.toString();
    const finaltext = "Issue Types: ".concat(
      list,
      breakingpoint,
      "Description: ",
      text,
      breakingpoint,
      sub_type
    );
    const product = "mvb-0001";
    if (!this.MVB.valid) return false;
    this.loading = true;
    const ticket = {
      product: product,
      category: this.select_topic,
      title: "Entity & Onboarding Issues",
      description: finaltext,
      screenshots: this.file.keylist,
    };
    console.log("ticket", ticket);
    this.accountService.d365SendCase(ticket).subscribe(
      () => {
        if (this.appState._state["ticket-cases"])
          delete this.appState._state["ticket-cases"];
        this.loading = false;
        this.dialogRef.close();
        this.dialog.open(SubmitSuccessComponent, {
          width: "400px",
          minWidth: "310px",
          height: "auto",
        });
      },
      () => {
        this.loading = false;
        this.errorPop();
      }
    );
  }
}
