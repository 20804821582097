import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
@Injectable()
export class CountryService {
  regionCache: string[];
  cityCache: string[];
  constructor(private httpClient: HttpClient) {}

  getCountries() {
    return this.httpClient
      .get("/assets/data/countries.json")
      .toPromise()
      .then((res: any) => <any[]>res.data)
      .then((data) => {
        return data;
      });
  }

  async convertCountryCodeAndName(value, key) {
    const dat = await this.getData(null)(null).toPromise();
    return dat.filter((i) => {
      return i[key] === value;
    })[0];
  }

  getData(country) {
    if (country) {
      return (region) => {
        const { country_code } = country;
        if (region) {
          const { region_name } = region;
          return this.httpClient
            .post(`${environment.apiUrl}/api/common/location/city`, {
              country_code,
              region_name,
            })
            .map((items: any) => items.map((item) => ({ city_name: item })))
            .map((items: any) => sortData(items, "city_name"));
        }
        return this.httpClient
          .post(`${environment.apiUrl}/api/common/location/region`, {
            country_code,
          })
          .map((items: any) => items.map((item) => ({ region_name: item })))
          .map((items: any) => sortData(items, "region_name"));
      };
    }
    return () =>
      this.httpClient
        .get(`${environment.apiUrl}/api/common/location/country`)
        .map((items: any) => sortData(items, "country_name"));
  }

  async getRegions(country_code): Promise<string[]> {
    this.regionCache = (
      await this.httpClient
        .post<string[]>(`${environment.apiUrl}/api/common/location/region`, {
          country_code,
        })
        .toPromise()
    ).sort();
    return this.regionCache;
  }
  async getCities(country_code, region_name): Promise<string[]> {
    this.cityCache = (
      await this.httpClient
        .post<string[]>(`${environment.apiUrl}/api/common/location/city`, {
          country_code,
          region_name,
        })
        .toPromise()
    ).sort();
    return this.cityCache;
  }

  // getPublicDomainList() {
  //   return [
  //     "Australia",
  //     "India",
  //     "Russian Federation",
  //     "France",
  //     "Taiwan, Republic of China",
  //     "Peru",
  //     "Uruguay",
  //     "Chile",
  //     "United States",
  //     "Bolivia, Plurinational State of",
  //     "Viet Nam",
  //     "Turkey",
  //     "Hungary",
  //     "Germany",
  //     "Belgium",
  //     "United Kingdom",
  //     "Malaysia",
  //     "Indonesia",
  //     "Spain",
  //     "Italy",
  //     "Thailand",
  //     "Hong Kong",
  //     "Denmark",
  //   ];
  // }
}

function sortData(data, prop) {
  return data.sort((a, b) =>
    a[prop] > b[prop] ? 1 : a[prop] > b[prop] ? (a.size > b.size ? 1 : -1) : -1
  );
}
