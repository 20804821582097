import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { Subject, BehaviorSubject } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { AccountService } from "@_services/account.service";
import { UserState } from "@_services/current/user";
import { tap } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  current_user;
  public listLangs = new Subject<any>();
  public currentLang: BehaviorSubject<string> = new BehaviorSubject<string>(
    "en"
  );
  public dir: BehaviorSubject<string> = new BehaviorSubject<string>("ltr");

  constructor(
    public translateService: TranslateService,
    public cookieService: CookieService,
    private accountService: AccountService,
    private userstate: UserState
  ) {}

  init(): Observable<any> {
    return this.accountService.getLanguageList().pipe(
      tap(async (res) => {
        const data = res["rows"];
        const langCodeList = data.map((i) => i.code);
        this.addLang(langCodeList);
        this.setDefault("en");
        if (this.userstate.hasLogin()) {
          this.getAccountSetting().subscribe((accountSetting: any) => {
            const prefer_lang = accountSetting["lang_code"];
            const modify_time = accountSetting["modify_time"];
            const cookieLang = this.cookieService.get("myLang");
            const userLang = navigator.language;
            const similarLang = userLang.split("-")[0];

            if (!localStorage.getItem("LandingPageLang")) {
              localStorage.setItem("LandingPageLang", cookieLang);
              /* Save the language set before login  */
            }
            /* timestamp is 2022/09/05 AM09:00 */
            if (
              modify_time == null ||
              Date.parse(modify_time) < 1662339600000
            ) {
              /* Ｗhen user not modify language before (contain first login user & old user) */
              this.setLang(cookieLang);
              this.accountService
                .updateAccountSetting({
                  account_id: this.current_user.id,
                  lang_code: "en",
                })
                .subscribe();
            } else {
              /* use EULA agreement to check if user is first login */
              this.LangCheck(
                langCodeList,
                prefer_lang,
                cookieLang,
                similarLang,
                userLang
              );
            }
          });
        } else {
          const hasCookieLang = this.cookieService.get("myLang");
          this.setLang(hasCookieLang);
        }
        return data;
      })
    );
  }

  saveLangs(data: any) {
    this.listLangs.next(data);
  }

  getData(): Observable<any> {
    return this.listLangs.asObservable();
  }

  addLang(langs: string[]) {
    this.translateService.addLangs(langs);
  }

  setDefault(lang: string) {
    this.translateService.setDefaultLang(lang);
  }

  get(key: string | string[], params?: Object): Observable<any> {
    return this.translateService.get(key, params);
  }

  getAllLangs() {
    return this.translateService.getLangs();
  }

  getTranslation(lang: string): Observable<any> {
    return this.translateService.getTranslation(lang);
  }

  saveCurrentLang(currentLang: string) {
    this.currentLang.next(currentLang);
  }

  loadCurrentLang(): Observable<any> {
    return this.currentLang;
  }

  stream(key: string | string[], params?: Object) {
    return this.translateService.stream(key, params);
  }

  setLang(lang: string) {
    const supports = this.getAllLangs();
    console.log(`support language is:`, supports);
    if (!supports.includes(lang)) {
      console.log(`not support this language(${lang})`);
      return;
    }
    this.dir.next(lang.indexOf("ar") === 0 ? "rtl" : "ltr");
    this.cookieService.set(
      "myLang",
      lang,
      null,
      "/",
      environment.domainURL,
      true,
      'Lax'
    );
    this.saveCurrentLang(lang);
    this.translateService.use(lang);
  }

  getAccountSetting(): any {
    this.current_user = this.userstate.getCurrentUser().user;
    return this.accountService.getAccountSetting(this.current_user.id);
  }

  LangCheck(langs, prefer_lang, cookie, similar, user) {
    if (prefer_lang) {
      this.setLang(prefer_lang);
    } else if (cookie) {
      this.setLang(cookie);
    } else if (langs.includes(similar)) {
      this.setLang(similar);
    } else if (langs.includes(user)) {
      this.setLang(user);
    } else {
      this.setLang("en");
    }
  }
}
