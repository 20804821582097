import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '@_services/admin.service';
import { AppState } from '@_services/app.service';
import { IssueCouponComponent } from './issue-coupon.component';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Message } from 'primeng/primeng';
import { Observable } from 'rxjs';
import { UserState } from '@_services/current/user';

@Component({
  selector: 'app-issue-coupon-pro',
  templateUrl: './issue-coupon.component.html',
  styleUrls: ['./issue-coupon.component.scss'],
  providers: [AdminService]
})

export class IssueCouponProComponent extends IssueCouponComponent implements OnInit {
  issue_type = 'Professional';

  constructor(
    public fb: FormBuilder,
    public adminService: AdminService,
    public appstate: AppState,
    public userstate: UserState,
  ) {
    super(fb, adminService, appstate, userstate)
  }

  ngOnInit() {
    const email_sales = this.userstate.getUserEmail();
    this.model = {
      distributor: email_sales,
      applicant: ''
    }
    this.userform = this.fb.group({
    });
    this.applicantAdd(null);
    this.getIssueHistory();
  }

  issueCoupon(data: any) {
    return this.adminService.issueProfessionalCoupon(data)
  }
  
  getIssueHistory() {
    this.adminService.getIssueProfessionalHistory()
      .subscribe(data => {
        this.IssueHistory = data;
      }, error2 => {
        console.log(error2);
      })
  }
}
