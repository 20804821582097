
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { AppState } from './app.service';
import { Account } from '../_models/account';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class RssService {

  constructor(private httpClient: HttpClient, private appstate: AppState) {
  }

  getRss() {
    return this.httpClient.get('https://rss.myviewboard.com/feed/fi_myviewboard', {withCredentials: false}).pipe(map((response: any) => response));
  }

  getWorkplace(param) {
    return this.httpClient.get('https://cx6o7qk7we.execute-api.us-east-1.amazonaws.com/dev/groups/' + param, {withCredentials: false}).pipe(map((response: any) => JSON.parse(response._body)));
  }

}


