import { NgModule } from '@angular/core';
import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import {
    DataTableModule,
    DropdownModule,
    MessagesModule,
    RadioButtonModule,
    TabMenuModule,
    TabViewModule,
    PanelModule,
    AutoCompleteModule,
    TooltipModule,
    DialogModule,
    CalendarModule,
    FileUploadModule
  } from 'primeng/primeng';

@NgModule({
  exports: [
    TableModule,
    DataTableModule,
    DropdownModule,
    MessagesModule,
    RadioButtonModule,
    TabMenuModule,
    TabViewModule,
    PanelModule,
    AutoCompleteModule,
    TooltipModule,
    DialogModule,
    CalendarModule,
    FileUploadModule,
    ChartModule
  ]
})
export class PrimengSharedModule {
}
