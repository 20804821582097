import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material";
import { RoleChooseComponent } from "app/layouts/user-guide/role-choose/role-choose.component";
import { GreetingComponent } from "app/layouts/user-guide/greeting/greeting.component";
import { UserState } from "./current/user";
import { AppInsightsService } from "@markpieszak/ng-application-insights";
import { AccountService } from "./account.service";
@Injectable({
  providedIn: "root",
})
export class UserGuideService {
  teacherStep = -1;
  studentStep = -1;
  adminStep = -1;
  selected_role = "";
  id;
  eventType = "initial_user_guide";
  constructor(
    private dialog: MatDialog,
    private userState: UserState,
    private appInsightsService: AppInsightsService,
    private accountService: AccountService
  ) {}
  incrementStep() {
    switch (this.selected_role) {
      case "teacher":
        ++this.teacherStep;
        break;
      case "student":
        ++this.studentStep;
        break;
      case "admin":
        ++this.adminStep;
        break;
      case "other":
        ++this.teacherStep;
        break;
      default:
        ++this.teacherStep;
        break;
    }
  }
  decrementStep() {
    switch (this.selected_role) {
      case "teacher":
        --this.teacherStep;
        break;
      case "student":
        --this.studentStep;
        break;
      case "admin":
        --this.adminStep;
        break;
      case "other":
        --this.teacherStep;
        break;
      default:
        --this.teacherStep;
        break;
    }
  }
  trackStep(step?, eventType = this.eventType) {
    const payload = {
      account_id: this.userState.getUserID(),
    };
    if (step) {
      payload["step"] = step;
    }
    if (this.selected_role) {
      payload["role"] = this.selected_role;
    }
    console.log("com_" + eventType, payload);
    this.appInsightsService.trackEvent("com_" + eventType, payload);
  }
  closeStep(track = false, step?) {
    this.teacherStep = -1;
    this.studentStep = -1;
    this.adminStep = -1;
    if (track) {
      this.trackStep(step, "close_user_guide");
    }
  }
  setRole(role) {
    this.closeStep(false);
    this.selected_role = role;
    if (!this.userState.questionnaireContent.role) {
      this.userState.questionnaireContent.role = role;
      return true;
    }
    return false;
  }
  startUserGuide(eventType = "initial_user_guide") {
    this.eventType = eventType;
    const dialogRef = this.dialog.open(RoleChooseComponent, {
      data: {
        firsttime: eventType === "initial_user_guide",
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe(({ role }) => {
      if (role) {
        const init = this.setRole(role);
        const Greeingdialog = this.dialog.open(GreetingComponent, {
          data: {
            role,
          },
          disableClose: true,
        });
        if (init) {
          this.accountService
            .submitQuestionnaire(this.userState.getUserID(), {
              topic: "default-profile",
              content: { role },
            })
            .subscribe(
              () => {
                this.trackStep();
              },
              (error) => {
                console.error(error);
              }
            );
        } else {
          this.trackStep();
        }
        Greeingdialog.afterClosed().subscribe((result) => {
          this.selected_role;
          switch (this.selected_role) {
            case "teacher":
              this.teacherStep = result;
              break;
            case "student":
              this.studentStep = result;
              break;
            case "admin":
              this.adminStep = result;
              break;
            default:
              this.teacherStep = result;
              break;
          }
        });
      }
    });
  }
}
