<div class="tutorial_step_2">
  <ul
    *ngIf="this.current_user"
    class="row c-icon-link nav-item nav-icon space-evenly"
    style="display: flex; align-items: center; padding: 0"
  >
    <li
      [dir]="'ltr'"
      class="nav-item nav-icon"
      (click)="trackClickItem('join_classroom')"
    >
      <a
        class="joinClass"
        title=""
        matTooltip="{{ 'Main_Menu.Join_Quickboard' | translate }}"
        href="javascript:void(0)"
        (click)="appstate.joinClassroom()"
        style="padding-top: 8px"
        cdkOverlayOrigin
        #trigger="cdkOverlayOrigin"
      >
        <!-- <i class="icon-icon-join-a-my-viewboard-off">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
          <span class="path4"></span>
          <span class="path5"></span>
        </i> -->
        <img src="images/icon-join-a-my-viewboard-off.svg" />
      </a>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="
          isCurrentStep(feature[0].userGuide.studentStep)
        "
        [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
      >
        <app-step-by-step
          [Config]="feature[0]"
          [StepNo]="$userguide.studentStep"
        ></app-step-by-step>
      </ng-template>
    </li>

    <li
      class="nav-item nav-icon"
      (click)="trackClickItem('throw_to_whiteboard')"
    >
      <a
        class="nav-link"
        title=""
        matTooltip="{{ 'Main_Menu.Throw_ViewBoard' | translate }}"
        href="javascript:void(0)"
        (click)="appstate.set('remote-transfer-dialog', true)"
        cdkOverlayOrigin
        #trigger1="cdkOverlayOrigin"
      >
        <i class="icon-header_throw_icon"> </i>
      </a>
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger1"
        [cdkConnectedOverlayOpen]="
          isCurrentStep(feature[1].userGuide.studentStep)
        "
        [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
      >
        <app-step-by-step
          [Config]="feature[1]"
          [StepNo]="$userguide.studentStep"
        ></app-step-by-step>
      </ng-template>
    </li>

    <li class="nav-item nav-icon" (click)="trackClickItem('share_your_screen')">
      <a
        class="nav-link"
        title=""
        *ngIf="
          this.browserName === 'Google Chrome' ||
          (this.browserName === 'Microsoft Edge' && !appstate.get('isEmbedded'))
        "
        matTooltip="{{ 'Main_Menu.Send_Screen' | translate }}"
        href="javascript:void(0)"
        (click)="openPresentIn()"
      >
        <i class="icon-icon-castov-selected"></i
      ></a>
    </li>

    <li class="nav-item nav-icon" (click)="trackClickItem('receive_screen')">
      <a
        class="nav-link"
        title=""
        *ngIf="!appstate.get('isEmbedded')"
        matTooltip="{{ 'Main_Menu.Receive_ViewBoard' | translate }}"
        href="javascript:void(0)"
        (click)="openPresentOut()"
      >
        <i class="icon-icon-castow-selected"></i
      ></a>
    </li>
    <!--TODO check basic tier on open_id-->
    <li class="nav-item nav-icon" (click)="trackClickItem('followme')">
      <a
        class="nav-link"
        title=""
        matTooltip="{{ 'Main_Menu.FollowMe_AI' | translate }}"
        routerLink="/viewboard/setting"
      >
        <i class="fa fa-universal-access"></i
      ></a>
    </li>

    <li class="nav-item nav-icon">
      <a
        class="nav-link"
        title=""
        matTooltip="{{ 'Main_Menu.Download_App' | translate }}"
        href="javascript:void(0)"
        (click)="openDownload()"
      >
        <i class="icon-header_download_icon"></i
      ></a>
    </li>
  </ul>
</div>
