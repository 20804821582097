import {
  interval as observableInterval,
  Subject,
  Observable,
  of,
  BehaviorSubject,
} from "rxjs";
import { filter, tap } from "rxjs/operators";
import { Injectable, InjectionToken } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { environment } from "@environment";
import { DeviceDetectorService } from "ngx-device-detector";
import {
  Router,
  ActivatedRoute,
  NavigationStart,
  NavigationEnd,
} from "@angular/router";
import { Title } from "@angular/platform-browser";
import { MqttClient } from "mqtt";
import { ScriptService } from "./script.service";
import { document } from "ngx-bootstrap/utils/facade/browser";
import { GoogleConversionSetting } from "../_helpers/GoogleConversionSetting";
import { HttpClient } from "@angular/common/http";
import { AppInsightsService } from "@markpieszak/ng-application-insights";
import { Iceservers } from "../_models/iceservers";
import { OverlayRef, Overlay, OverlayConfig } from "@angular/cdk/overlay";
import {
  MatProgressSpinnerDefaultOptions,
  MatSpinner,
} from "@angular/material";
import { scrollToTop } from "../_helpers/scrollTo";
import { ComponentPortal } from "@angular/cdk/portal";

const CryptoJS = require("crypto-js");
const mqtt = require("mqtt");
let client: MqttClient;

function mqttMsg(topic, message) {
  // console.log(message.toString())
}

export interface InternalStateType {
  [key: string]: any;
}

if (window["ExternalObject"]) {
  window["ExternalObject"].getSession();
}

class StorageObject {
  private secretKey;
  private storageObject;

  constructor(params) {
    this.secretKey = params["secretKey"];
    this.storageObject = params["storageObject"];
  }

  removeItem(key) {
    this.storageObject.removeItem(key);
  }

  setItem(key, value) {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      this.secretKey
    ).toString();
    this.storageObject.setItem(key, encryptedData);
    return { key, encryptedData };
  }

  getItem(key) {
    const encryptedData = this.storageObject.getItem(key);
    if (encryptedData) {
      try {
        return JSON.parse(
          CryptoJS.AES.decrypt(encryptedData, this.secretKey).toString(
            CryptoJS.enc.Utf8
          )
        );
      } catch (err) {
        console.log(err);
        return null;
      }
    }
    return null;
  }

  getItemObj(key) {
    return JSON.parse(this.getItem(key));
  }
}

const LocalStore = new StorageObject({
  secretKey: location.host,
  storageObject: window["localStorage"],
});
const SessionStore = new StorageObject({
  secretKey: location.host,
  storageObject: window["sessionStorage"],
});

@Injectable()
export class AppState {
  // already return a clone of the current state
  public get state() {
    return (this._state = this._clone(this._state));
  }
  // never allow mutation
  public set state(value) {
    throw new Error("do not mutate the `.state` directly");
  }

  appScope = "p_myviewboard_com";
  _location = "";
  navchange: EventEmitter<any> = new EventEmitter();
  userState: EventEmitter<any> = new EventEmitter();
  instanceState: EventEmitter<any> = new EventEmitter();
  loadingRef: OverlayRef;
  bannerExpire = new BehaviorSubject<boolean>(false);
  currentPath = new BehaviorSubject<string>("");
  public localStorage: any = LocalStore;
  public sessionStorage: any = SessionStore;
  public _state: InternalStateType = {};
  public environment: any = environment;
  public device = this.deviceService;
  public windows: {
    name: string;
    close: Function;
  }[] = [];
  public mqtt: MqttClient = client;

  /* (() => {
     return this.get('mqtt');
     })();*/
  public msgTemp = new Map();

  public fileSubject = new Subject();

  public mqttMsg: any = mqttMsg;
  public downloadLink =
    window.location.host === "stage.myviewboard.com"
      ? "https://s3-us-west-2.amazonaws.com/myviewboardstorage/uploads/vBoard_Stage/myViewBoard_setup_2.7.1.2-S.exe"
      : "https://dl.myviewboard.com/latest?" + Math.round(Date.now() / 1000000);
  public version = "05/15/2018 ";

  MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS: InjectionToken<MatProgressSpinnerDefaultOptions>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private httpClient: HttpClient,
    private deviceService: DeviceDetectorService,
    private scriptService: ScriptService,
    private appInsightsService: AppInsightsService,
    private overlay: Overlay
  ) {
    this.set("isEmbedded", false);
    if ((<any>window).ExternalObject) {
      this.set("isEmbedded", true);
    }
    this.set("headerHeight", 80);
    this.localStorage.setItem("envhost", "browser");
    router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((evt) => {
        this.currentPath.next(evt["url"]);
        switch (evt["url"]) {
          case "/account":
          case "/home":
            titleService.setTitle("myViewBoard - Your Account");
            break;
          case "/account/auth-service":
            titleService.setTitle("myViewBoard - Cloud Service");
            break;
          case "/account/edit":
            titleService.setTitle("myViewBoard - Account Edit");
            break;
          case "/account/password":
            titleService.setTitle("myViewBoard - Change Password");
            break;
          case "/signup":
            titleService.setTitle("myViewBoard - Sign Up");
            break;
          case "/signin":
            titleService.setTitle("myViewBoard - Sign In");
            break;
          case "/forgetpassword":
            titleService.setTitle("myViewBoard - Forgot Password");
            break;
          case "/viewboard/register":
            titleService.setTitle("myViewBoard - Sign Up");
            break;
          case "/activate-success":
            titleService.setTitle("myViewBoard - Activate Successfully");
            break;
          case "/activate-failed":
            titleService.setTitle("myViewBoard - Activate Failed");
            break;
          case "/activate-resend":
            titleService.setTitle("myViewBoard - Resend Activate Email");
            break;
          case "/viewboard/activate-success":
            titleService.setTitle("myViewBoard - Activate Successfully");
            break;
          case "/viewboard/activate-failed":
            titleService.setTitle("myViewBoard - Activate Failed");
            break;
          case "/entity/login-success":
            titleService.setTitle("myViewBoard - Entity Login Success");
            break;
          case "/entity/login-fail":
            titleService.setTitle("myViewBoard - Entity Login Fail");
            break;
          default:
            titleService.setTitle("myViewBoard");
        }
      });
    this.checkExternalEnv();
  }

  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  emitUserStateChange(any) {
    this.userState.emit(any);
  }

  emitNavChangeEvent(any) {
    this.navchange.emit(any);
  }

  getNavChangeEmitter() {
    return this.navchange;
  }

  public get(prop?: any) {
    /**
     * Use our state getter for the clone.
     */
    const state = this.state;
    return state.hasOwnProperty(prop) ? state[prop] : false;
  }

  public set(prop: string, value: any) {
    /**
     * Internally mutate our state.
     */
    if (prop === "instance_state") {
      this.instanceState.emit(value);
    }

    return (this._state[prop] = value);
  }

  private _clone(object: InternalStateType) {
    /**
     * Simple object clone.
     */
    return JSON.parse(JSON.stringify(object));
  }

  extractSubPermission(functionName) {
    if (
      !this.get("permission").sub_permission.find(
        (permission) => permission.name === functionName
      )
    ) {
      return;
    }
    return this.get("permission").sub_permission.find(
      (permission) => permission.name === functionName
    ).value;
  }

  getTierName() {
    if (!this.get("permission").name) {
      return;
    }
    if (this.get("permission").name === "Basic Tier") {
      return "Basic";
    }
    if (this.get("permission").name === "Basic Personal Tier") {
      return "Personal";
    }
    if (this.get("permsission").name === "Professional Tier") {
      return "Professional";
    }
    if (this.get("currentUser").email.indexOf("@facebook") !== -1) {
      return "Facebook";
    }
    if (this.get("currentUser").email.indexOf("@fb.com") !== -1) {
      return "Facebook";
    }
    if (this.get("currentUser").email.indexOf("@microsoft") !== -1) {
      return "MicroSoft";
    }
    if (this.get("currentUser").email.indexOf("@gm.com") !== -1) {
      return "GM";
    }
    return this.get("permission").name;
  }

  public mqtt_connect() {
    return Observable.create((Observer) => {
      const mqttUrl = this.environment.production
        ? "mqtts://control.myviewboard.com/"
        : "mqtts://stage-control.myviewboard.com/";
      this.httpClient
        .post(
          this.environment.apiUrl + "/api/session/token",
          {},
          { withCredentials: true }
        )
        .subscribe((data: any) => {
          if (!this.mqtt) {
            const options = {
              keepalive: 10,
              username: this.get("currentUser").email,
              password: data.token,
            };
            this.set("remoteToken", data.token);
            client = mqtt.connect(mqttUrl, options);
            this.mqtt = client;
            client
              .on("connect", () => {
                console.log("connected", client);
                window["client"] = client;
                Observer.next(client);
              })
              .on("message", mqttMsg)
              .on("close", function () {
                client.reconnect();
              });
          } else {
            Observer.next(this.mqtt);
          }
        });
    });
  }

  public fileDrop($event) {
    $event.preventDefault();
    console.log($event.dataTransfer.files);
    this.fileSubject.next($event.dataTransfer.files);
  }

  public appCacheControl = () => {
    return Math.round(Date.now() / 1000000);
  };

  public openClips() {
    //const url = `https://myviewclip${this.environment.production ? '' : '.stage'}.myviewboard.cloud`;
    const url = `/clips`;
    console.log(url, this.environment);
    const wm = this.windows;
    let windowName = "clips";
    let w = (screen.width * 2) / 3;
    let h = (screen.height * 2) / 3;
    let x = screen.width / 2 - w / 2;
    let y = screen.height / 2 - h / 2;
    wm.push(
      window.open(
        url,
        windowName,
        "height=" + h + ",width=" + w + ",left=" + x + ",top=" + y
      )
    );
  }

  public closeWindow(name: string) {
    const windowToCloseIndex = this.windows.findIndex((window) =>
      window.name.includes(name)
    );
    if (windowToCloseIndex === -1) {
      return;
    }
    this.windows[windowToCloseIndex].close;
    this.windows.splice(windowToCloseIndex, 1);
  }
  public openWindow(url) {
    const wm = this.windows;
    let windowName = "CastWin-" + Date.now();
    let x = screen.width / 2 - 1280 / 2;
    let y = screen.height / 2 - 720 / 2;
    let w = 1280;
    let h = 720;

    if (url.indexOf("cast-in") !== -1) {
      x = screen.width / 2 - 640 / 2;
      y = screen.height / 2 - 550 / 2;
      w = 640;
      h = 550;
      windowName = "CastWin-In-Popup";
    }
    if (url.indexOf("present-in") !== -1) {
      x = screen.width / 2 - 640 / 2;
      y = screen.height / 2 - 550 / 2;
      w = 640;
      h = 550;
      windowName = "CastWin-In-Popup";
    }

    if (url.indexOf("scratch") !== -1) {
      windowName = "Scratch";
    }
    if (url.indexOf("autodraw") !== -1) {
      x = screen.width / 2 - 720 / 2;
      y = screen.height / 2 - 720 / 2;
      w = 720;
      h = 720;
      windowName = "Scratch";
    }
    wm.push(
      window.open(
        url,
        windowName,
        "height=" + h + ",width=" + w + ",left=" + x + ",top=" + y
      )
    );
    return false;
  }

  public openDownload() {
    const w = 720;
    const h = 720;
    const x = screen.width / 2 - w / 2;
    const y = screen.height / 2 - h / 2;
    window.open(
      "/static/download/",
      "downloads",
      "height=" + h + ",width=" + w + ",left=" + x + ",top=" + y
    );
  }

  public gaConversion(conversionFuc: string) {
    const setting = new GoogleConversionSetting(conversionFuc);
    (<any>window).google_trackConversion(setting.data);
    const tag = new Image();
    tag.src = `//www.googleadservices.com/pagead/conversion/${setting.data.google_conversion_id}/?label=${setting.data.google_conversion_label}&amp;guid=ON&amp;script=0"`;
  }

  public getUrlPath(url = null) {
    const _url = url || this.get("url");
    return function (path = null) {
      if (path) {
        if (_url.indexOf("?") !== -1) {
          return _url.split("?")[0].split("/")[path];
        }
        return _url.split("/")[path];
      }
    };
  }

  public setSession(value) {
    window["setSession"](value);
  }

  getCurrentPath(): Observable<any> {
    return this.currentPath;
  }

  public getUrlParam(param) {
    const _url = this.get("url");
    if (_url.indexOf("?") === -1) {
      return false;
    }
    return _url
      .substring(_url.indexOf("?"))
      .substring(1)
      .split("&")
      .map((v) => v.split("="))
      .reduce(
        (map, [key, value]) => map.set(key, decodeURIComponent(value)),
        new Map()
      )
      .get(param);
  }

  public getUserName() {
    if (!this.get("currentUser").first_name) {
      return this.get("currentUser").name;
    }
    if (!this.get("currentUser").last_name) {
      return this.get("currentUser").name;
    }
    return `${this.get("currentUser").first_name} ${
      this.get("currentUser").last_name
    }`;
  }

  public getUserId() {
    return `${this.get("currentUser").id}`;
  }

  public getSession() {
    if ((<any>window).ExternalObject) {
      (<any>window).ExternalObject.getSession();
    }
  }

  getReleaseNoteFile(link) {
    return this.httpClient.get(link, { responseType: "text" });
  }

  getReleaseNote() {
    return this.httpClient.get(
      `${this.environment.environment.apiUrl}/api/release-note`
    );
  }

  // common
  getLocation({ country, region }) {
    if (!country) {
      return this.httpClient.get(
        `${this.environment.environment.apiUrl}/api/common/location/country`
      );
    }
    if (region) {
      return this.httpClient.get(
        `${this.environment.environment.apiUrl}/api/common/location/country/region`
      );
    }
  }

  trackEvent({ action, label, category, metric }) {}

  getIceServers() {
    return this.httpClient
      .get(`https://getice.myviewboard.cloud/`)
      .map((data: Iceservers) => data.list);
  }

  openWhiteBoard() {
    (<any>window).open(location.origin + "/whiteboard", "_blank");
  }

  openViewBoard(permission_type?) {
    const query_params = permission_type
      ? `?permission=${permission_type}`
      : "";
    if (!this.get("currentUser")) {
      (<any>window).open(
        location.origin + this.environment.pathTo_HostBoard + query_params,
        "_self"
      );
      return;
      // this.router.navigate(['/signin'], {queryParams: {return: '/classroom/quickboard/classroom'}})
    } else {
      (<any>window).open(
        location.origin + this.environment.pathTo_HostBoard + query_params,
        "_blank"
      );
    }
  }

  joinViewBoard() {
    try {
      window["ExternalObject"].openWidget(
        location.origin + this.environment.pathTo_JoinBoard
      );
    } catch (e) {
      // alert((<any>window).ExternalObject)
    }
    (<any>window).open(location.origin + this.environment.pathTo_JoinBoard);
  }

  joinClassroom() {
    try {
      window["ExternalObject"].openWidget(
        location.origin + this.environment.pathTo_JoinBoard
      );
    } catch (e) {}
    (<any>window).open(location.origin + this.environment.pathTo_JoinBoard);
  }

  public setLiveChatData() {
    const currentUserData = this.get("currentUser");
    if (!(<any>window).RocketChat) {
      return;
    }
    (<any>window).RocketChat(function () {
      this.registerGuest({
        name: currentUserData.first_name,
        email: currentUserData.email,
      });
      this.showWidget();
    });
    this.set("livechat", true);
    console.log(this.get("livechat"));
  }

  mqttNotify({ message, device_id }) {
    return this.httpClient.post(
      `${environment.apiUrl}/viewboard/device/${device_id}/control`,
      message,
      { withCredentials: true }
    );
  }

  block() {
    if (this.loadingRef) {
      this.unblock();
    }
    const strategy = this.overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const config = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: "cdk-overlay-dark-backdrop",
      positionStrategy: strategy,
    });

    const overlayRef = this.overlay.create(config);
    this.loadingRef = overlayRef;
    overlayRef.attach(new ComponentPortal(MatSpinner, null, null));
  }

  unblock() {
    if (this.loadingRef) {
      this.loadingRef.detach();
      this.loadingRef = null;
    }
  }

  scrollTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  checkUserLocation() {
    const location = this.sessionStorage.getItem("location");
    return location
      ? of(location)
      : this.httpClient
          .get(`${this.environment.apiUrl}/api/common/location`)
          .map((res) => {
            return res["country_code"];
          })
          .pipe(
            tap((data) => {
              this.sessionStorage.setItem("location", data);
            })
          );
  }

  isProd() {
    return this.environment.production;
  }

  checkExternalEnv() {
    const _this = this;
    const externalObj = (<any>window).ExternalObject;
    if (!externalObj) {
      return;
    }
    if (!externalObj.getEnv) {
      return;
    }
    try {
      externalObj.getEnv().then((env) => _this.set("hostEnv", env));
    } catch (e) {
      this.set("hostEnv", externalObj.getEnv());
    }
    if (this.deviceService.browser.toLowerCase().indexOf("safari") !== -1) {
      console.log("hostenv", this.get("hostEnv"));
    }
  }

  checkBanner() {
    const currentTime = new Date().getTime();
    const expireTime = new Date("30 September 2021 11:59:59 UTC").getTime();
    const expired = currentTime > expireTime ? true : false;
    this.bannerExpire.next(expired);
  }

  getBanner(): Observable<boolean> {
    return this.bannerExpire;
  }
}
