import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs/Rx";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { AccountService } from "@_services/account.service";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { SweetAlertService } from "ng2-sweetalert2";
import { AppState } from "@_services";
import { CompanionComponent } from "../companion/companion.component";
import { DeviceManagementComponent } from "../device-management/device-management.component";
import { DisplayComponent } from "../display/display.component";
import { MvbwComponent } from "../mvbw/mvbw.component";
import { MvbwebComponent } from "../mvbweb/mvbweb.component";
import { MyviewboardComponent } from "../myviewboard/myviewboard.component";
import { OriginalContentComponent } from "../original-content/original-content.component";
import { UserState } from "@_services/current/user";
import { MvbEntityComponent } from "../mvba/mvba.component";
import { LanguageService } from "@_services/language.service";
@Component({
  selector: "app-mvb-support",
  templateUrl: "./mvb-support.component.html",
  styleUrls: ["./mvb-support.component.scss"],
  providers: [SweetAlertService],
})
export class MvbSupportComponent implements OnInit, AfterViewInit {
  keyword = new Subject<string>();
  list: any;
  searching = false;
  focusOnList = true;
  content: string;
  url: string;
  categories: any = [];
  savedCategory: any = [];
  showSpinner = false;
  loading: boolean;
  warnning: boolean;
  support_ticket: any;
  currentLang: Observable<string>;
  search_icon = "../../../../../images/Support_pics/icon_search.png";
  lists = [
    {
      switchCode: "mvbw-faq",
      imgSrc: "../../../../assets/support/icon_Whiteboard.svg",
      translateLabel: "Support.whiteboard",
      isVS: true,
    },
    {
      switchCode: "mvbweb-faq",
      imgSrc: ".../../../../assets/icons/36x36.svg",
      translateLabel: "Support.MVB_Com",
      classes: "w70",
      isVS: true,
    },
    {
      switchCode: "companion-faq",
      imgSrc: "../../../../assets/support/icon_companion.svg",
      translateLabel: "Support.MVB_Companion",
      isVS: true,
    },
    {
      switchCode: "myviewboard-faq",
      imgSrc: "../../../../assets/support/icon_Classroom.svg",
      translateLabel: "Support.MVB_Classroom",
      isVS: true,
    },
    {
      switchCode: "mvbOC-faq",
      imgSrc: "../../../../assets/support/icon_originals.svg",
      translateLabel: "Support.MVB_OC",
      isVS: true,
    },
    {
      switchCode: "mvbDM-faq",
      imgSrc: "../../../../assets/support/icon_Manager.svg",
      translateLabel: "Support.MVB_DM",
      isVS: true,
    },
    {
      switchCode: "mvbDisplay-faq",
      imgSrc: "../../../../assets/support/icon_Display.svg",
      translateLabel: "Support.MVB_Display",
      classes: "unionSize",
      isVS: true,
    },
    {
      switchCode: "onboarding-faq",
      translateLabel: "Support.Entity_Onboarding",
      classes: "fas fa-users fa-3x entity_onboarding",
      isVS:
        window.location.pathname === "/main-support" &&
        this.userstate.getCurrentUser().isViewSonicCorp(),
    },
  ];
  constructor(
    private accoutService: AccountService,
    public dialog: MatDialog,
    private route: Router,
    private swalService: SweetAlertService,
    private appState: AppState,
    private userstate: UserState,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.currentLang = this.languageService.loadCurrentLang();
    this.appState.scrollTop();
    this.keyword
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        this.getArticles(value);
      });
    this.accoutService.getSupportCategory().subscribe((res) => {
      this.appState.set("faq_category", res["rows"]);
    });
  }

  ngAfterViewInit() {
    if (this.appState.getUrlParam("support_ticket")) {
      this.support_ticket = this.appState.getUrlParam("support_ticket");
      this.switchPopup(this.support_ticket);
    }
  }

  redirect(value) {
    window.location.pathname === "/main-support"
      ? this.route.navigate(["/main-support/" + value])
      : this.route.navigate(["/support/" + value]);
  }

  search(serachParam: string) {
    this.keyword.next(serachParam);
  }

  movetopbyId() {
    const element = document.getElementById("smooth-top");
    element.scrollIntoView({
      behavior: "smooth",
    });
  }

  redirectTofaq() {
    window.open("https://community.myviewboard.com/", "_blank", "noopener");
  }

  getArticles(key) {
    this.loading = true;
    if (!key) {
      this.loading = false;
      return;
    }
    this.accoutService.getKnowledgeArticle(key).subscribe(
      (data) => {
        this.list = data["rows"];
        this.loading = false;
      },
      () => {
        this.swalService.swal({
          title: "Oops...",
          text: "Something went wrong, please try again",
          type: "warning",
          buttonsStyling: false,
          showCancelButton: false,
          confirmButtonClass: "btn btn-primary",
          confirmButtonText: "Confirm",
        });
      }
    );
  }

  hideSearch() {
    this.list = [];
  }

  openPopUp(component, tracking) {
    this.openDialog(component);
  }

  openDialog(Component: any) {
    this.dialog.open(Component, {
      width: "42em",
      maxWidth: "80vw",
      minWidth: "310px",
      height: "85vh",
      panelClass: "my-dialog",
    });
  }

  switchPopup(support_category) {
    switch (support_category) {
      case "whiteboard":
        this.openPopUp(MvbwComponent, "myViewBoard Whiteboard");
        break;
      case "companion":
        this.openPopUp(CompanionComponent, "myViewBoard Companion");
        break;
      case "web":
        this.openPopUp(MvbwebComponent, "myViewBoard.com");
        break;
      case "classroom":
        this.openPopUp(MyviewboardComponent, "quickboard");
        break;
      case "OC":
        this.openPopUp(OriginalContentComponent, "Original Content");
        break;
      case "DM":
        this.openPopUp(DeviceManagementComponent, "Device Management");
        break;
      case "Display":
        this.openPopUp(DisplayComponent, "Display");
        break;
      case "onboarding":
        this.openPopUp(MvbEntityComponent, "Onboarding");
        break;
    }
  }
}
