import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { AccountService } from "@_services/account.service";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { ControlContainer, FormGroup } from "@angular/forms";
import { SweetalertService } from "@_services/sweetalert.service";
import { LanguageService } from "@_services/language.service";
@Component({
  selector: "[formGroupName] app-fileupload",
  templateUrl: "./fileupload.component.html",
  styleUrls: ["./fileupload.component.scss"],
})
export class FileuploadComponent implements OnInit, OnChanges {
  public FileGroup: FormGroup;
  textareaContent = new Subject<string>();
  openUpload = false;
  matchKeyword = false;
  loading = false;
  keylist = [];
  files: File[] = [];
  isClicked = false;
  currentLang: Observable<string>;
  @Input() display_windows = false;
  constructor(
    public accountService: AccountService,
    private controlContainer: ControlContainer,
    private swalService: SweetalertService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.currentLang = this.languageService.loadCurrentLang();
    this.FileGroup = <FormGroup>this.controlContainer.control;
    this.textareaContent
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.checkKeywords(value);
      });
  }

  ngOnChanges(change: SimpleChanges) {
    console.log("change", change);
  }

  search(content: string) {
    this.textareaContent.next(content);
  }

  checkKeywords(value: string) {
    const filter = RegExp(/\b(screen shot|screenshot|attachment)\b/);
    if (value.match(filter)) {
      this.openUpload = true;
      this.matchKeyword = true;
    } else {
      this.matchKeyword = false;
    }
  }

  clickAddAttachment() {}

  uploadpic(event: any) {
    if (this.files.length > 3) {
      this.files.splice(3, 1);
      alert("We accept only up to 3 photos");
    } else {
      this.loading = true;
      const formdata = new FormData();
      formdata.append("screenshot", event);
      this.accountService.d365UploadPic(formdata).subscribe(
        (res) => {
          this.loading = false;
          this.keylist.push(res["key"]);
        },
        () => {
          this.loading = false;
          this.errorPop();
        }
      );
    }
  }

  deleteItem(item: any) {
    this.keylist.splice(item, 1);
    this.files.splice(item, 1);
  }

  errorPop() {
    this.swalService.error({
      title: "Error",
      text: "Something went wrong, please try again",
      buttonsStyling: false,
      showCancelButton: false,
      allowOutsideClick: false,
    });
  }
}
