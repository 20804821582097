<mat-dialog-content class="mat-typography">
  <div class="layout">
    <div class="Title">
      <div class="Title__rows">
        <span
          class="Title__text"
          [innerHtml]="'Home.User_Guide.Role_Choose.Title' | translate"
        ></span>
      </div>
    </div>
    <div class="content">
      <div class="content__flex">
        <button
          class="content__flex__item"
          [mat-dialog-close]="finish('teacher')"
        >
          <img src="images/Teacher.svg" />
          <span class="content__flex__item__text">{{
            "Home.User_Guide.Role_Choose.Teacher" | translate
          }}</span>
        </button>
        <button
          class="content__flex__item"
          [mat-dialog-close]="finish('student')"
        >
          <img src="images/Student.svg" />
          <span class="content__flex__item__text">
            {{ "Home.User_Guide.Role_Choose.Student" | translate }}
          </span>
        </button>
        <button
          class="content__flex__item"
          [mat-dialog-close]="finish('admin')"
        >
          <img src="images/IT_Admin.svg" />
          <span class="content__flex__item__text">{{
            "Home.User_Guide.Role_Choose.Admin" | translate
          }}</span>
        </button>
        <button
          class="content__flex__item"
          [mat-dialog-close]="finish('other')"
        >
          <img src="images/Other_Role.svg" />
          <span class="content__flex__item__text">{{
            "Home.User_Guide.Role_Choose.Other" | translate
          }}</span>
        </button>
      </div>
    </div>
  </div>
</mat-dialog-content>
