import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { RemoteTransferComponent } from "./remote-transfer.component";
import {
  FileUploadModule,
  InputTextModule,
  ProgressBarModule,
} from "primeng/primeng";
import { StorageService } from "../storage/service/storage.service";
import { FormsModule } from "@angular/forms";
import { SweetAlertService } from "ng2-sweetalert2";
import { SharedModule } from "../shared/sharedModule";
import { RemoteTransferHistoryComponent } from "./remote-transfer-history/remote-transfer-history.component";
import { MatListItem, MatListModule } from "@angular/material/list";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatCardModule } from "@angular/material/card";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { DebounceModule } from "app/_directives/debounce/module";
import { MVBCommonComponentModule } from "app/mvb-common-component/mvb-common-component.module";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FileUploadModule,
    InputTextModule,
    FormsModule,
    CommonModule,
    ProgressBarModule,
    SharedModule,
    MatListModule,
    FlexLayoutModule,
    MatIconModule,
    MatCardModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    RouterModule.forChild([
      { path: "throw", component: RemoteTransferComponent },
    ]),
    DebounceModule,
    MVBCommonComponentModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [RemoteTransferComponent, RemoteTransferHistoryComponent],
  exports: [RemoteTransferComponent],
  providers: [StorageService, SweetAlertService],
})
export class RemoteTransferModule {}
