import {
  of as observableOf,
  from as observableFrom,
  Observable,
  from,
} from "rxjs";
import { catchError, map, tap, concatMap, mergeMap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  NavigationStart,
  UrlTree,
} from "@angular/router";
import { OAuthService } from "angular-oauth2-oidc";
import { filter } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
declare var ga: any;

@Injectable()
export class OIDCAuthGuard implements CanActivate {
  constructor(public router: Router, public oauthService: OAuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    throw new Error("Method not implemented.");
  }

  defaultError(response: Response) {
    // when renew access and refresh token break
    return from(
      (() => {
        return new Promise((resolve, reject) => {
          this.oauthService.logOut({
            post_logout_redirect_uri: location.origin,
          });
          throw Error(`access/refresh all fail...`);
        });
      })()
    );
  }

  getUserDataByCodeOrToken(err = this.defaultError) {
    console.log(`code flow`);
    return this.configureCodeFlow().pipe(
      tap(() => {
        this.oauthService.setupAutomaticSilentRefresh();
      }),
      concatMap((_) => from(this.oauthService.loadUserProfile())),
      catchError((response: HttpErrorResponse) => {
        switch (response.status) {
          case 503:
            this.router.navigate(["/common/message"], {
              queryParams: {
                title: "ERROR.503_TITLE",
                message: "ERROR.503_CONTENT",
                severity: "error",
              },
            });
            break;

          case 500:
            this.router.navigate(["/common/message"], {
              queryParams: {
                title: "ERROR.500_TITLE",
                message: "ERROR.500_CONTENT",
                severity: "error",
              },
            });
            break;

          default:
            let retry_times = Number(
              sessionStorage.getItem("sso-retry") || "0"
            );
            if (retry_times >= 1) {
              sessionStorage.removeItem("sso-retry");
              this.router.navigate(["/common/message"], {
                queryParams: {
                  title: "ERROR.OIDC_REFRESH_FAILED_TITLE",
                  message: "ERROR.OIDC_REFRESH_FAILED_CONTENT",
                  severity: "warn",
                },
              });
              throw Error(`sign in failed...`);
            }
            sessionStorage.setItem("sso-retry", `${retry_times + 1}`);
            return from(this.oauthService.refreshToken()).pipe(
              mergeMap((x) => from(this.oauthService.loadUserProfile()))
            );
            break;
        }
      }),
      catchError(err)
    );
    // if (localStorage.getItem('flow') === 'code') {
    //   console.log(`code flow`)
    //   return this.configureCodeFlow().concatMap(_ => from(this.oauthService.loadUserProfile()))
    // } else {
    //   // this is implicit flow
    //   this.configureImplicitFlow();
    // }

    // return this.oauthService.events.pipe(filter(e => e.type === 'token_received')).concatMap(_ => from(this.oauthService.loadUserProfile()))
  }

  configureCodeFlow() {
    // this.oauthService.configure(environment.oidc_config);
    // const login_process = this.oauthService.tryLogin();
    let login_process = null;

    if (!this.oauthService.tokenEndpoint) {
      login_process = this.oauthService.loadDiscoveryDocumentAndTryLogin();
    } else {
      console.log(this.oauthService);
      login_process = this.oauthService.tryLogin();
    }
    // login_process.then(_ => {
    //   this.router.navigate(['/home']);
    // });

    // Optional
    console.log(`register auto refresh`);
    this.oauthService.setupAutomaticSilentRefresh();
    return from(login_process);
  }
  configureImplicitFlow() {
    this.oauthService.setStorage(localStorage);
    // this.oauthService.tokenValidationHandler = new JwksValidationHandler();

    this.oauthService.loadDiscoveryDocumentAndTryLogin().then((_) => {
      this.router.navigate(["/home"]);
    });

    // Optional
    this.oauthService.setupAutomaticSilentRefresh();

    // Display all events
    this.oauthService.events.subscribe((e) => {
      // tslint:disable-next-line:no-console
      console.debug("oauth/oidc event", e);
    });

    this.oauthService.events
      .pipe(filter((e) => e.type === "session_terminated"))
      .subscribe((e) => {
        // tslint:disable-next-line:no-console
        console.debug("Your session has been terminated!");
      });
  }
}
