<div id="wrap" fxLayout="column">
  <div id="content">
    <h2 id="title">
      <b>{{ "reminder_title_whiteboard" | translate }} </b>
    </h2>
    <p id="text" [innerHtml]="'reminder_text_whiteboard' | translate"></p>
    <div id="check">
      <mat-checkbox (change)="check($event)">{{
        "Do_not_show_again" | translate
      }}</mat-checkbox>
    </div>
  </div>
</div>
<button id="footer" (click)="close()">{{ "OK" | translate }}</button>
