<p-dialog
  #dialog
  header="{{ 'Main_Menu.Throw_ViewBoard' | translate }}"
  [visible]="appstate.get('remote-transfer-dialog')"
  width="480"
  (visibleChange)="onclick($event)"
  [ngClass]="'.offscreen-fix'"
>
  <app-remote-transfer #remoteTransfer></app-remote-transfer>
  <mvb-message [(value)]="msgs"></mvb-message>
  <!-- <p-messages [(value)]="msgs"></p-messages> -->
</p-dialog>
